import {
  createFieldKey,
  createFormContext,
  FieldKey,
  SetValueFn,
  useField,
  useFieldErrors,
  useFieldMapper,
  useFieldValidation,
} from '../../common/utils/forms';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { useCallback } from 'react';
import { TextField } from '@mui/material';
import { BillingCodeFields_LabelFrFragment$key } from './__generated__/BillingCodeFields_LabelFrFragment.graphql';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { BillingCodeFields_LabelEnFragment$key } from './__generated__/BillingCodeFields_LabelEnFragment.graphql';

export interface BillingCodeFieldsMappings {}
export const billingCodeFormContext = createFormContext<BillingCodeFieldsMappings>();

const fieldLabelFrKey = createFieldKey<string>();
export function useFieldBillingCodeLabelFr($key: BillingCodeFields_LabelFrFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeFields_LabelFrFragment on BillingCode {
        microDescription
      }
    `,
    $key,
  );

  const [label, setLabel] = useField(billingCodeFormContext, fieldLabelFrKey, () => $data?.microDescription?.fr ?? '');

  const useValidation = useFieldValidation(billingCodeFormContext, fieldLabelFrKey);
  useValidation((v) => v.trim().length > 0, [], 'save:required');

  const useMapper = useFieldMapper(billingCodeFormContext, fieldLabelFrKey);
  useMapper((val) => ({ frMicroDescription: val }), [], 'save');

  const renderLabelFr = useCallback(
    () => <LabelInput fieldKey={fieldLabelFrKey} setValue={setLabel} value={label} labelKey='billingCodes.field.labelFr' />,
    [label, setLabel],
  );

  return { labelFr: label, setLabelFr: setLabel, renderLabelFr };
}

const fieldLabelEnKey = createFieldKey<string>();
export function useFieldBillingCodeLabelEn($key: BillingCodeFields_LabelEnFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeFields_LabelEnFragment on BillingCode {
        microDescription
      }
    `,
    $key,
  );

  const [label, setLabel] = useField(billingCodeFormContext, fieldLabelEnKey, () => $data?.microDescription?.en ?? '');

  const useValidation = useFieldValidation(billingCodeFormContext, fieldLabelEnKey);
  useValidation((v) => v.trim().length > 0, [], 'save:required');

  const useMapper = useFieldMapper(billingCodeFormContext, fieldLabelEnKey);
  useMapper((val) => ({ enMicroDescription: val }), [], 'save');

  const renderLabelEn = useCallback(
    () => <LabelInput fieldKey={fieldLabelEnKey} setValue={setLabel} value={label} labelKey='billingCodes.field.labelEn' />,
    [label, setLabel],
  );

  return { labelFr: label, setLabelFr: setLabel, renderLabelEn };
}

function LabelInput({
  fieldKey,
  setValue,
  value,
  labelKey,
}: {
  fieldKey: FieldKey<string>;
  value: string;
  setValue: SetValueFn<string>;
  labelKey: string;
}) {
  const { t } = useAmbientTranslation();
  const errors = useFieldErrors(billingCodeFormContext, fieldKey);

  return (
    <TextField
      label={t(labelKey)}
      required
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={(e) => setValue(e.target.value.trim())}
      error={!!errors['required']}
    />
  );
}
