import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { useShowRepresentativeListFragment$key } from './__generated__/useShowRepresentativeListFragment.graphql';
import { useFlag } from '@unleash/proxy-client-react';

export function useShowRepresentativeList($key: useShowRepresentativeListFragment$key) {
  const $data = useFragment(
    graphql`
      fragment useShowRepresentativeListFragment on Employee {
        roles
      }
    `,
    $key,
  );

  const representativeListEnabled = useFlag('app_enable_representatives_lists_view');
  const roles = $data.roles;
  return (
    representativeListEnabled &&
    roles.includes('salesRepresentative') &&
    !roles.some((role) => ['salesSupervisor', 'salesAdmin'].includes(role))
  );
}
