/**
 * @generated SignedSource<<8b5e7a280b2c1561474b32947cbd2c9e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NaturesOfWorkListFragment$data = {
  readonly natureOfWorks: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"NaturesOfWorkListItemFragment">;
  }>;
  readonly " $fragmentType": "NaturesOfWorkListFragment";
};
export type NaturesOfWorkListFragment$key = {
  readonly " $data"?: NaturesOfWorkListFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NaturesOfWorkListFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NaturesOfWorkListFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "order",
          "value": {
            "code": "ASC"
          }
        }
      ],
      "concreteType": "NatureOfWorkLookup",
      "kind": "LinkedField",
      "name": "natureOfWorks",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NaturesOfWorkListItemFragment"
        }
      ],
      "storageKey": "natureOfWorks(order:{\"code\":\"ASC\"})"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "76961567b0e2b2efff633c9ed6da9ab2";

export default node;
