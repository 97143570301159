/**
 * @generated SignedSource<<da9d75a2494f4216e648560e9207bb38>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SubRegionLabelResolver$data = {
  readonly description: Record<string, string>;
  readonly " $fragmentType": "SubRegionLabelResolver";
};
export type SubRegionLabelResolver$key = {
  readonly " $data"?: SubRegionLabelResolver$data;
  readonly " $fragmentSpreads": FragmentRefs<"SubRegionLabelResolver">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubRegionLabelResolver",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "type": "SubRegion",
  "abstractKey": null
};

(node as any).hash = "beeba6c46a7ef78e30781412252b54a2";

export default node;
