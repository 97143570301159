const app = {
  title: 'Guay Ventes',
  version: 'Version',
  changeLanguage: 'English',
  reloading: 'L’application redémarre…',
};

const auth = {
  logout: 'Se déconnecter',
  redirectToConnectionPage: 'Vous serez redirigé vers la page de connection.',
  unauthorizedLogin: 'Connexion non autorisée',
  unauthorizedLoginMessage: 'Vous n’avez pas accès à l’application avec cette adresse courriel',
};

const logoutModal = {
  title: 'Déconnexion',
  confirmationText: 'Êtes-vous certain de vouloir vous déconnecter\u00a0?',
  confirmButton: 'Déconnecter',
};

const forceRefresh = {
  refresh: 'Rafraîchir',
  version: 'Votre version de l’application n’est plus à jour, svp rafraîchir pour obtenir la version la plus récente',
};

export const layout = {
  app,
  auth,
  logoutModal,
  sidebar: {
    billingCodeRules: 'Règles de facturation',
    clients: 'Clients',
    configuration: 'Configuration',
    craneCharts: 'Chartes de grues',
    crm: 'CRM',
    dashboard: 'Tableau de bord',
    opportunities: 'Opportunités',
    maintenance: 'Maintenance',
    quotes: 'Soumissions',
    representatives: 'Représentants',
    sales: 'Ventes',
    serviceCalls: 'Appels de service',
    workPlannings: 'Planif. des travaux',
    worksites: 'Chantiers',
  },
  forceRefresh,
  notification: {
    empty: 'Aucune notification',
    loadNext: 'Voir plus',
    markAllRead: 'Marquer toutes lues',
    modificationRequestedNotification: 'Demande de modification de l’appel de service #{{ friendlyId }}',
    quoteApprovalRequestedNotification: 'La soumission #{{ friendlyId }} requiert une approbation',
    quoteApprovedNotification: 'La soumission #{{ friendlyId }} a été approuvée et est en attente d’une réponse du client',
    quoteChangesRequestedNotification: 'La soumission #{{ friendlyId }} requiert une revue',
    title: 'Notifications',
  },
};
