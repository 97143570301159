/**
 * @generated SignedSource<<f7c85ffa53ea85fe00fc33c69fa2acd3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddendumTemplateLabelResolver$data = {
  readonly title: Record<string, string>;
  readonly " $fragmentType": "AddendumTemplateLabelResolver";
};
export type AddendumTemplateLabelResolver$key = {
  readonly " $data"?: AddendumTemplateLabelResolver$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddendumTemplateLabelResolver">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddendumTemplateLabelResolver",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "AddendumTemplate",
  "abstractKey": null
};

(node as any).hash = "37c40b799bc4fdfd1d74ebe705037225";

export default node;
