import { Box, ListItemIcon, ListItemText, Skeleton, Theme, Typography, useMediaQuery } from '@mui/material';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { visuallyHidden } from '@mui/utils';
import { EllipsisedTypography } from '../common/components/EllipsisedTypography';
import { ResponsiveGridColumnDefinition, ResponsiveGridColumnOrderer } from '../common/components/ResponsiveGrid';
import { NullableCell } from '../common/components/NullableCell';
import { DateTime } from 'luxon';
import { dateFormat } from '../common/utils/dateTimeUtils';
import { useMemo } from 'react';
import { castQuoteKind } from '../__enums__/QuoteKind';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { isQuoteStatus } from '../__enums__/QuoteStatus';
import { QuoteStatusChip } from './QuoteStatusChip';
import { AuthorizationWriteFragment$key } from '../auth/__generated__/AuthorizationWriteFragment.graphql';
import { RequireWrite } from '../auth/Authorization';
import { QuoteList_ActionsFragment$key } from './__generated__/QuoteList_ActionsFragment.graphql';
import { QuoteList_RowFragment$key } from './__generated__/QuoteList_RowFragment.graphql';
import { QuoteList_ItemFragment$key } from './__generated__/QuoteList_ItemFragment.graphql';
import { SaleList_CopyButton } from '../jobs/SaleList.CopyButton';
import { useHideAccessorySection } from '../jobs/accessoryLines/AccessoryLinesFields';
import { CreditCategoryChip } from '../jobs/CreditCategoryChip';
import { TFunction } from 'i18next';

export function getQuoteListColumns(t: TFunction, compact: boolean): ResponsiveGridColumnDefinition[] {
  return [
    { id: 'friendlyId', label: t('list.column.friendlyId'), size: '7rem' },
    { id: 'client', label: t('list.column.client'), size: 'minmax(6rem, 2fr)' },
    { id: 'worksite', label: t('list.column.worksite'), size: 'minmax(6rem, 2fr)' },
    { id: 'kind', label: t('list.column.kind'), size: 'minmax(6rem, 1fr)' },
    { id: 'crane', label: t('list.column.crane'), size: 'auto', sx: { textAlign: 'center' } },
    { id: 'status', label: compact ? '' : t('list.column.status'), size: 'auto' },
    { id: 'date', label: t('list.column.date'), size: 'auto' },
    { id: 'actions', label: '', size: 'minmax(3rem, auto)' },
  ];
}

export const quoteListSx = {
  "&[data-mode='grid']": {
    // Adds a padding in cells to improve readability, specially when elipsing.
    'li.responsive-grid__header  > *': {
      px: '1rem',
    },
    'li:not(.responsive-grid__header)  > *': {
      px: '0.5rem',
    },
  },

  // Controls the gap between the content of list items and the status chip.
  "&[data-mode='list'] > li": {
    gap: '0.5rem',
  },
};

export function QuoteList_Row({
  $key,
  write$key,
  orderByColumns,
}: {
  $key: QuoteList_RowFragment$key;
  write$key: AuthorizationWriteFragment$key;
  orderByColumns: ResponsiveGridColumnOrderer;
}) {
  const { t } = useAmbientTranslation();
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const $data = useFragment(
    graphql`
      fragment QuoteList_RowFragment on QuoteJobRevision {
        friendlyId
        snapshot {
          kind
          statuses
          clientBase {
            assignedClient {
              name
              number
              isDirectSales
              ...CreditCategoryChipFragment
            }
            assignedClientInfo {
              name
            }
          }
          equipmentBase {
            craneSelector {
              favoriteConfiguration {
                capacity {
                  capacity
                }
                equipmentKind {
                  abbreviation
                }
              }
            }
          }
          projectBase {
            assignedWorksiteInfo {
              name
            }
            arrivalDate {
              rawValue
            }
          }
        }
        ...QuoteList_ActionsFragment
      }
    `,
    $key,
  );
  const { snapshot } = $data;
  const clientName = snapshot?.clientBase.assignedClient?.isDirectSales
    ? snapshot.clientBase.assignedClientInfo.name
    : snapshot?.clientBase.assignedClient?.name;
  const clientNameInfo = useMemo(
    () => ({ number: snapshot?.clientBase.assignedClient?.number, name: clientName }),
    [clientName, snapshot?.clientBase.assignedClient?.number],
  );
  const favouriteConfiguration = snapshot?.equipmentBase.craneSelector.favoriteConfiguration;
  const statuses = snapshot?.statuses.every(isQuoteStatus) ? (snapshot?.statuses ?? []) : [];

  return (
    <>
      <h3 style={visuallyHidden}>
        <NullableCell value={$data.friendlyId?.toString()} />
        <br />
        <NullableCell value={clientName} />
        <br />
        <NullableCell value={snapshot?.projectBase.assignedWorksiteInfo?.name} />
      </h3>

      {orderByColumns([
        <Typography key='friendlyId' variant='body2'>
          <NullableCell value={$data.friendlyId?.toString()} />
        </Typography>,

        <Box key='client' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '0.25rem' }}>
          <EllipsisedTypography variant='body2' component='span'>
            <NullableCell
              value={clientNameInfo}
              formatter={(v) => (
                <>
                  {v.number} —&nbsp;{v.name}
                </>
              )}
            />
          </EllipsisedTypography>
          {snapshot?.clientBase.assignedClient && <CreditCategoryChip $key={snapshot.clientBase.assignedClient} />}
        </Box>,

        <EllipsisedTypography key='worksite' variant='body2' component='span'>
          <NullableCell value={snapshot?.projectBase.assignedWorksiteInfo?.name} />
        </EllipsisedTypography>,

        <EllipsisedTypography key='kind' variant='body2' component='span' title={t(`kind.${snapshot?.kind}`)}>
          {t(`kindShort.${snapshot?.kind}`)}
        </EllipsisedTypography>,

        <Typography key='crane' variant='body2' textAlign='center'>
          <NullableCell
            value={
              favouriteConfiguration
                ? {
                    capacity: favouriteConfiguration.capacity?.capacity,
                    equipmentKind: favouriteConfiguration.equipmentKind?.abbreviation,
                  }
                : null
            }
            formatter={({ capacity, equipmentKind }) => (
              <>
                {capacity}
                {capacity && equipmentKind && <span>&ndash;</span>}
                {equipmentKind}
              </>
            )}
          />
        </Typography>,

        <Box key='status' display='flex' alignItems='center'>
          <QuoteStatusChip statuses={statuses} compact={compact} />
        </Box>,

        <Typography
          key='date'
          variant='body2'
          title={DateTime.fromISO(snapshot?.projectBase.arrivalDate?.rawValue ?? '')
            .setLocale(t('locale', { ns: 'common' }))
            .toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' })}>
          <NullableCell
            formatter={(v: string) =>
              DateTime.fromISO(v)
                .setLocale(t('locale', { ns: 'common' }))
                .toLocaleString({
                  month: 'short',
                  day: 'numeric',
                })
            }
            value={snapshot?.projectBase.arrivalDate?.rawValue}
          />
        </Typography>,

        <QuoteList_Actions key='actions' $key={$data} write$key={write$key} />,
      ])}
    </>
  );
}

function QuoteList_Actions({ $key, write$key }: { $key: QuoteList_ActionsFragment$key | null; write$key: AuthorizationWriteFragment$key }) {
  const $data = useFragment(
    graphql`
      fragment QuoteList_ActionsFragment on QuoteJobRevision {
        ...SaleList_CopyButtonFragment
        snapshot {
          kind
        }
      }
    `,
    $key,
  );

  const hideAccessories = useHideAccessorySection(castQuoteKind($data?.snapshot?.kind ?? ''));

  return (
    // need to fallback on an empty element to keep grid columns from displaying correctly
    <RequireWrite $key={write$key} fallback={<div />}>
      <Box>
        <Box sx={{ opacity: 'var(--hover-highlight)' }}>
          <SaleList_CopyButton $key={$data} hideAccessories={hideAccessories} />
        </Box>
      </Box>
    </RequireWrite>
  );
}

export function QuoteList_Item({ $key }: { $key: QuoteList_ItemFragment$key }) {
  const { t } = useAmbientTranslation();

  const quote = useFragment(
    graphql`
      fragment QuoteList_ItemFragment on QuoteJobRevision {
        friendlyId
        snapshot {
          statuses
          clientBase {
            assignedClient {
              name
              isDirectSales
            }
            assignedClientInfo {
              name
            }
          }
          equipmentBase {
            craneSelector {
              favoriteConfiguration {
                capacity {
                  capacity
                }
                equipmentKind {
                  abbreviation
                }
              }
            }
          }
          projectBase {
            assignedWorksiteInfo {
              name
            }
            arrivalDate {
              rawValue
            }
          }
        }
      }
    `,
    $key,
  );
  const { snapshot } = quote;
  const clientName = snapshot?.clientBase.assignedClient?.isDirectSales
    ? snapshot.clientBase.assignedClientInfo.name
    : snapshot?.clientBase.assignedClient?.name;
  const capacityInTons = snapshot?.equipmentBase.craneSelector.favoriteConfiguration?.capacity?.capacity ?? 0;
  const statuses = snapshot?.statuses.every(isQuoteStatus) ? (snapshot?.statuses ?? []) : [];

  return (
    <>
      <h3 style={visuallyHidden}>
        <NullableCell value={quote.friendlyId?.toString()} />
        <br />
        <NullableCell value={snapshot?.clientBase.assignedClient?.name} />
        <br />
        <NullableCell value={snapshot?.projectBase.assignedWorksiteInfo?.name} />
      </h3>

      <ListItemText disableTypography={true}>
        <Typography color='text.secondary' fontSize='0.75rem'>
          <NullableCell value={quote.friendlyId?.toString()} />
          <span> | </span>
          <NullableCell
            formatter={(v: string) => DateTime.fromISO(v).toFormat(dateFormat)}
            value={snapshot?.projectBase.arrivalDate?.rawValue}
          />
        </Typography>
        <EllipsisedTypography variant='subtitle2' component='p' color='text.primary'>
          <NullableCell value={clientName} />
        </EllipsisedTypography>
        <EllipsisedTypography variant='subtitle2' component='p' color='text.primary'>
          <NullableCell value={snapshot?.projectBase.assignedWorksiteInfo?.name} />
        </EllipsisedTypography>
        <Typography variant='body2' component='span' color='text.secondary'>
          {t('unit.full.ton', { ns: 'common', count: capacityInTons })}
          &nbsp;
          {snapshot?.equipmentBase.craneSelector.favoriteConfiguration?.equipmentKind?.abbreviation ?? ''}
        </Typography>
      </ListItemText>
      <ListItemIcon>
        <QuoteStatusChip statuses={statuses} />
      </ListItemIcon>
    </>
  );
}

export function QuoteList_RowSkeleton({ columns }: { columns: ResponsiveGridColumnDefinition[] }) {
  return (
    <span style={{ gridColumn: `1 / span ${columns.length}` }}>
      <Skeleton variant='rounded' height='1.5rem' sx={{ my: '0.875rem' }} />
    </span>
  );
}

export function QuoteList_ListSkeleton() {
  return (
    <>
      <ListItemText>
        <Skeleton variant='rounded' width='6rem' height='1rem' sx={{ mb: '0.25rem' }} />
        <Skeleton variant='rounded' width='14rem' height='1.25rem' sx={{ mb: '0.25rem' }} />
        <Skeleton variant='rounded' width='10rem' height='1.25rem' sx={{ mb: '0.25rem' }} />
        <Skeleton variant='rounded' width='6rem' height='1rem' />
      </ListItemText>
      <ListItemIcon>
        <Skeleton variant='rounded' width='4.5rem' height='1.5rem' sx={{ borderRadius: '1rem' }} />
      </ListItemIcon>
    </>
  );
}
