import { ElementType, forwardRef, ReactElement, Ref, RefAttributes } from 'react';
import { ChipTypeMap } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ForwardAutocompleteProps, SelectPicker, SelectPickerProps } from './SelectPicker';
import { JobStage, jobStages } from '../../jobs/jobStage';

interface JobStageAutocompleteProps {
  options?: ReadonlyArray<JobStage>;
}

export type ForwardJobStageAutocompleteProps<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = Omit<
  SelectPickerProps<JobStage, Multiple, DisableClearable, false, ChipComponent> &
    ForwardAutocompleteProps<JobStage, Multiple, DisableClearable, false, ChipComponent>,
  'ref' | 'options' | 'getOptionLabel' | 'getOptionKey'
>;

export const JobStageAutocomplete = forwardRef<
  HTMLInputElement,
  JobStageAutocompleteProps & ForwardJobStageAutocompleteProps<false, false, ElementType>
>(function JobStageAutocomplete<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  {
    options,
    ...selectPickerProps
  }: JobStageAutocompleteProps & ForwardJobStageAutocompleteProps<Multiple, DisableClearable, ChipComponent>,
  ref: Ref<HTMLInputElement>,
) {
  const { t } = useTranslation('common');

  return (
    <SelectPicker
      ref={ref}
      options={options ?? jobStages}
      getOptionKey={(o) => o}
      getOptionLabel={(o) => t(`jobStages.${o}`, { ns: 'jobs' })}
      {...selectPickerProps}
    />
  );
}) as <
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  props: JobStageAutocompleteProps &
    ForwardJobStageAutocompleteProps<Multiple, DisableClearable, ChipComponent> &
    RefAttributes<HTMLInputElement>,
) => ReactElement;
