import { SalesRateUpdateDialog, SalesRateUpdateDialogFormProvider } from '../salesRate/SalesRateUpdateDialog';
import {
  accessoryLineSubFormContext,
  FieldAccessoryLineSalesRateResult,
  FieldAccessoryRate,
  FieldAccessoryRateUpdateReason,
  useFieldAccessoryRate,
  useFieldAccessoryRateUpdateReason,
} from './AccessoryLineSubFormFields';
import { SetValueFn, useFormValidate } from '../../common/utils/forms';

export interface AccessoryLineRateUpdateDialogProps {
  open: boolean;
  onClose: () => void;
  rate: FieldAccessoryRate;
  setRate: SetValueFn<FieldAccessoryRate>;
  rateUpdateReason: FieldAccessoryRateUpdateReason;
  setRateUpdateReason: SetValueFn<FieldAccessoryRateUpdateReason>;
  salesRateResult: FieldAccessoryLineSalesRateResult;
}

export function AccessoryLineRateUpdateDialog({
  open,
  onClose: handleClose,
  rate,
  setRate,
  rateUpdateReason,
  setRateUpdateReason,
  salesRateResult,
}: AccessoryLineRateUpdateDialogProps) {
  return (
    <SalesRateUpdateDialogFormProvider context={accessoryLineSubFormContext} open={open}>
      <AccessoryLineRateUpdateDialogInner
        open={open}
        onClose={handleClose}
        rate={rate}
        setRate={setRate}
        rateUpdateReason={rateUpdateReason}
        setRateUpdateReason={setRateUpdateReason}
        salesRateResult={salesRateResult}
      />
    </SalesRateUpdateDialogFormProvider>
  );
}

function AccessoryLineRateUpdateDialogInner({
  open,
  onClose: handleClose,
  rate,
  setRate,
  rateUpdateReason,
  setRateUpdateReason,
  salesRateResult,
}: {
  open: boolean;
  onClose: () => void;
  rate: FieldAccessoryRate;
  setRate: SetValueFn<FieldAccessoryRate>;
  rateUpdateReason: FieldAccessoryRateUpdateReason;
  setRateUpdateReason: SetValueFn<FieldAccessoryRateUpdateReason>;
  salesRateResult: FieldAccessoryLineSalesRateResult;
}) {
  const { accessoryRate, renderAccessoryRate } = useFieldAccessoryRate(rate, false);
  const { accessoryRateUpdateReason, renderAccessoryRateUpdateReason } = useFieldAccessoryRateUpdateReason(
    rateUpdateReason,
    accessoryRate,
    salesRateResult,
  );
  const validate = useFormValidate(accessoryLineSubFormContext);

  return (
    <SalesRateUpdateDialog
      open={open}
      onClose={handleClose}
      innerRate={accessoryRate}
      setOuterRate={setRate}
      innerRateUpdateReason={accessoryRateUpdateReason}
      setOuterRateUpdateReason={setRateUpdateReason}
      salesRateResult={salesRateResult}
      renderRate={() =>
        renderAccessoryRate({
          gridMode: false,
          startAdornment: () => null,
          salesRateResultInitialValue: salesRateResult,
          rateUpdateReasonInitialValue: rateUpdateReason,
          inRateUpdateDialog: true,
        })
      }
      renderRateUpdateReason={renderAccessoryRateUpdateReason}
      validate={() => validate('save')}
    />
  );
}
