import { Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { PriceInput } from '../../common/components/PriceInput';
import { nanoid } from 'nanoid';
import { FormContext, FormProvider, SetValueFn } from '../../common/utils/forms';
import { Price } from '../../common/utils/price';
import { SalesRateResult } from '../../common/types/externalResult';

export interface SalesRateUpdateDialogFormProviderProps extends PropsWithChildren {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context: FormContext<any>;
  open: boolean;
}

export function SalesRateUpdateDialogFormProvider({ context, open, children }: SalesRateUpdateDialogFormProviderProps) {
  const [formKey, setFormKey] = useState(nanoid());

  useEffect(() => {
    // Reset sub-form when dialog opens
    if (open) setFormKey(nanoid());
  }, [open]);

  return (
    <FormProvider context={context} key={formKey}>
      {children}
    </FormProvider>
  );
}

export interface SalesRateUpdateDialogProps {
  open: boolean;
  onClose: () => void;
  innerRate: Price | null;
  setOuterRate: SetValueFn<Price | null>;
  innerRateUpdateReason: string | null;
  setOuterRateUpdateReason: SetValueFn<string | null>;
  salesRateResult: SalesRateResult | null;
  renderRate: () => ReactNode;
  renderRateUpdateReason: () => ReactNode;
  validate: () => boolean;
}

export function SalesRateUpdateDialog({
  open,
  onClose: handleClose,
  innerRate,
  setOuterRate,
  innerRateUpdateReason,
  setOuterRateUpdateReason,
  salesRateResult,
  renderRate,
  renderRateUpdateReason,
  validate,
}: SalesRateUpdateDialogProps) {
  const { t } = useTranslation('jobs');

  const salesRateResultPrice = salesRateResult?.value?.price ?? null;
  const hasCustomRate = innerRate !== salesRateResultPrice;

  const handleCancel = () => {
    handleClose();
  };

  const handleUpdate = () => {
    if (!validate()) return;
    setOuterRate(innerRate);
    setOuterRateUpdateReason(hasCustomRate ? innerRateUpdateReason : null);
    handleClose();
  };

  const handleReset = () => {
    setOuterRate(salesRateResultPrice);
    setOuterRateUpdateReason(null);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth='sm' fullWidth>
      <DialogTitle>{t('salesRate.rateUpdateDialog.title')}</DialogTitle>

      <DialogContent>
        <Stack sx={{ gap: '1rem', mt: '0.5rem' }}>
          <Stack direction='row' sx={{ gap: '0.5rem' }}>
            {renderRate()}
            <PriceInput value={salesRateResultPrice} label={t('salesRate.rateUpdateDialog.salesRateLabel')} disabled />
          </Stack>
          <Collapse in={hasCustomRate}>
            <Stack sx={{ gap: '1rem' }}>
              <Typography>{t('salesRate.rateUpdateDialog.changeMessage')}</Typography>
              {renderRateUpdateReason()}
            </Stack>
          </Collapse>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Stack direction='row' sx={{ gap: '0.5rem', width: '100%', justifyContent: 'space-between' }}>
          <Button onClick={handleReset} variant='text' color='error'>
            {t('salesRate.rateUpdateDialog.buttonLabels.reset')}
          </Button>
          <Stack direction='row' sx={{ gap: '0.5rem' }}>
            <Button onClick={handleCancel} variant='text'>
              {t('salesRate.rateUpdateDialog.buttonLabels.cancel')}
            </Button>
            <Button onClick={handleUpdate}>{t('salesRate.rateUpdateDialog.buttonLabels.update')}</Button>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
