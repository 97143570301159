/**
 * @generated SignedSource<<99cad3c9914cd673322338f568e35e3e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CreditCategory = "A" | "AA" | "AAA" | "B" | "C" | "D" | "E" | "F" | "NONE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CreditCategoryChipFragment$data = {
  readonly category: CreditCategory;
  readonly forcedCategory: CreditCategory | null | undefined;
  readonly " $fragmentType": "CreditCategoryChipFragment";
};
export type CreditCategoryChipFragment$key = {
  readonly " $data"?: CreditCategoryChipFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreditCategoryChipFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreditCategoryChipFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "category",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "forcedCategory",
      "storageKey": null
    }
  ],
  "type": "Client",
  "abstractKey": null
};

(node as any).hash = "c649b158c5a05689238d6b5a6d844eb8";

export default node;
