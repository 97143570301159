import { Dimension, DimensionMinMax } from '../utils/dimensions';
import { Logger } from '../utils/logging';
import { ForwardedRef, forwardRef, ReactNode, RefAttributes, useState } from 'react';
import { _throw } from '../utils/_throw';
import { useTranslation } from 'react-i18next';
import { UnitInput } from './UnitInput';

export interface DimensionInputProps<T extends Dimension> {
  value: T | null;
  onChange: (dimension: T | null) => void;
  enabledUnits?: readonly T['unit'][];
  /** Defaults to 0. */
  min?: DimensionMinMax<T>;
  max?: DimensionMinMax<T>;
  /** Defaults to 1. */
  step?: number;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  className?: string;
  label?: ReactNode;
  'data-label-key'?: string;
}

export interface DimensionInputInnerProps<T extends Dimension> {
  factory: (scalar: number, unit: T['unit']) => T;
  isValidUnit: (unit: string) => unit is T['unit'];
  logger: Logger;
  unitKey: string;
}

export const DimensionInput = forwardRef(function DimensionInput<T extends Dimension>(
  {
    value,
    onChange,
    enabledUnits = [],
    min = 0,
    max,
    step = 1,
    required,
    disabled,
    error,
    className,
    label,
    'data-label-key': dataLabelKey,
    factory,
    isValidUnit,
    logger,
    unitKey,
  }: DimensionInputProps<T> & DimensionInputInnerProps<T>,
  ref: ForwardedRef<HTMLTextAreaElement | HTMLInputElement>,
) {
  const defaultUnit = enabledUnits[0] ?? _throw('Empty enabledUnits. At least one unit must be enabled.');

  // Initialize lastValue to null to force unit validation (below) on first render if initial value isn't actually null
  const [lastValue, setLastValue] = useState<typeof value>(null);
  const [lastEnabledUnits, setLastEnabledUnits] = useState<typeof enabledUnits>(enabledUnits);

  // Initialize these states from the contents of lastValue (whatever it is) to make sure they're in sync
  const [scalar, setScalar] = useState<number | undefined>(lastValue?.scalar);
  const [unit, setUnit] = useState<T['unit']>(lastValue ? lastValue.unit : defaultUnit);

  const { t } = useTranslation('common');

  if (value !== lastValue || enabledUnits !== lastEnabledUnits) {
    setLastValue(value);
    setLastEnabledUnits(enabledUnits);

    if (value && !enabledUnits.includes(value.unit)) {
      logger.error('Detected unit is not enabled', value.unit, enabledUnits);
      setScalar(undefined);
      setUnit(defaultUnit);
    } else {
      setScalar(value?.scalar);
      setUnit(value ? value.unit : enabledUnits.includes(unit) ? unit : defaultUnit);
    }
  }

  // Update scalar, unit, lastValue, and call onChange() with the provided value if different from lastValue.
  const handleChange = (newValue: T | null) => {
    if (newValue === lastValue) return;
    setScalar(newValue?.scalar);
    setUnit(newValue?.unit ?? unit);
    setLastValue(newValue);
    onChange(newValue);
  };

  return (
    <UnitInput<T['unit']>
      ref={ref}
      scalar={scalar}
      onScalarChange={(newScalar) => {
        if (newScalar != null && Number.isFinite(newScalar)) {
          handleChange(factory(newScalar, unit));
        } else {
          handleChange(null);
        }
      }}
      unit={unit}
      supportedUnits={enabledUnits}
      onUnitChange={(event) => {
        const eventUnit = event.target.value;
        const newUnit = isValidUnit(eventUnit) && enabledUnits.includes(eventUnit) ? eventUnit : defaultUnit;
        setUnit(newUnit);
        if (scalar != null) {
          handleChange(factory(scalar, newUnit).normalize(min, max, step));
        }
      }}
      onBlur={() => {
        if (scalar != null) {
          handleChange(factory(scalar, unit).normalize(min, max, step));
        }
      }}
      required={required}
      disabled={disabled}
      error={error}
      renderMenuValue={(v) => t(`unit.${unitKey}.short.${v}`)}
      renderAdornmentValue={(v) => t(`unit.${unitKey}.short.${v}`)}
      className={className}
      label={label}
      data-label-key={dataLabelKey}
    />
  );
}) as <T extends Dimension>(
  props: DimensionInputProps<T> & DimensionInputInnerProps<T> & RefAttributes<HTMLTextAreaElement | HTMLInputElement>,
) => ReactNode;
