import graphql from 'babel-plugin-relay/macro';
import { useCallback } from 'react';
import { usePromiseMutation } from '../../common/hooks/usePromiseMutation';
import { BillingCodeSaveButtonMutation, SaveBillingCodeInput } from './__generated__/BillingCodeSaveButtonMutation.graphql';
import { DataID } from 'react-relay';
import { Save } from '@mui/icons-material';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { useFormMappings, useFormValidate } from '../../common/utils/forms';
import { billingCodeFormContext } from './BillingCodeFields';
import LoadingButton from '@mui/lab/LoadingButton';
import { useErrorBanner } from '../../common/components/ErrorBanner';

declare module './BillingCodeFields' {
  interface BillingCodeFieldsMappings {
    save: SaveBillingCodeInput;
  }
}

export function BillingCodeSaveButton({ id }: { id: DataID }) {
  const { t } = useAmbientTranslation();
  const { reportUnexpectedError, reportHandledError, dismissError } = useErrorBanner();

  const [commit, isLoading] = usePromiseMutation<BillingCodeSaveButtonMutation>(graphql`
    mutation BillingCodeSaveButtonMutation($id: ID!, $saveBillingCode: SaveBillingCodeInput!) {
      saveBillingCode(id: $id, saveBillingCode: $saveBillingCode) {
        billingCode {
          id
          ...BillingCodeDetails_FormFragment
        }
        errors {
          __typename
          ... on SalesApiValidationError {
            ...ErrorBannerValidationErrorFragment
          }
        }
      }
    }
  `);

  const { mapAll } = useFormMappings(billingCodeFormContext);
  const validate = useFormValidate(billingCodeFormContext);

  const handleSave = useCallback(async () => {
    if (!validate('save')) return;

    dismissError(true);

    try {
      const saveBillingCode = mapAll('save');

      const { response } = await commit({ variables: { id, saveBillingCode } });

      if (response?.saveBillingCode.errors?.length) {
        reportHandledError(response.saveBillingCode.errors, () => t('errorMessages.errorDuringSave', { ns: 'common' }));
        return;
      }
    } catch {
      reportUnexpectedError(() => t('errorMessages.errorDuringSave', { ns: 'common' }));
    }
  }, [validate, dismissError, mapAll, commit, id, reportHandledError, t, reportUnexpectedError]);

  return (
    <LoadingButton loading={isLoading} onClick={handleSave} startIcon={<Save />}>
      {t('button.save', { ns: 'common' })}
    </LoadingButton>
  );
}
