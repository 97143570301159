import {
  getWorkPlanningListColumns,
  WorkPlanningList_Item,
  WorkPlanningList_ListSkeleton,
  WorkPlanningList_Row,
  WorkPlanningList_RowSkeleton,
  workPlanningListSx,
} from './WorkPlanningList';
import { useNavigatorOnline } from '../common/hooks/useNavigatorOnline';
import { DataID, useFragment, useLazyLoadQuery, useRefetchableFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { useConstantValue } from '../common/hooks/useConstantValue';
import { DateTime } from 'luxon';
import { WorkPlanningFilters_Regular, WorkPlanningResponsiveGridFilters_Regular } from './WorkPlanningFilters';
import {
  ArrivalDateFilter,
  JobStatusFilter,
  ProjectManagerFilter,
  RepresentativeFilter,
  SubRegionFilter,
  TextSearchFilter,
  useJobFilters,
} from '../jobs/JobFilters';
import { convertToTsQuery } from '../common/utils/stringUtils';
import { workPlanningFiltersSharedStateKey } from './WorkPlanningList.Page';
import {
  WorkPlanningList_RegularQuery,
  WorkPlanningList_RegularQuery$variables,
} from './__generated__/WorkPlanningList_RegularQuery.graphql';
import { WorkPlanningList_RegularFragment$key } from './__generated__/WorkPlanningList_RegularFragment.graphql';
import {
  getNodeById,
  ResponsiveGrid,
  ResponsiveGridFilters,
  ResponsiveGridForwardProps,
  ResponsiveGridProps,
  useElementFactory,
  useSkeletonFactory,
} from '../common/components/ResponsiveGrid';
import { Theme, useMediaQuery } from '@mui/material';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { useCallback, useMemo } from 'react';
import { discriminate, isDefined } from '../common/utils/typeUtils';
import { workPlanningStatuses } from '../__enums__/WorkPlanningStatus';
import { WorkPlanningStatusChip } from './WorkPlanningStatusChip';
import { DateRange } from '@mui/x-date-pickers-pro';
import { WorkPlanningList_Regular_FiltersFragment$key } from './__generated__/WorkPlanningList_Regular_FiltersFragment.graphql';
import { WorkPlanningList_Regular_ListContentFragment$key } from './__generated__/WorkPlanningList_Regular_ListContentFragment.graphql';

export function WorkPlanningList_Regular({
  $key,
  ...listProps
}: {
  $key: WorkPlanningList_RegularFragment$key;
} & ResponsiveGridForwardProps) {
  const online = useNavigatorOnline();

  const $data = useFragment(
    graphql`
      fragment WorkPlanningList_RegularFragment on Employee {
        ...JobFilters_useJobFiltersFragment
        roles
        supervisedRegions {
          subRegions {
            id
          }
        }
      }
    `,
    $key,
  );

  const salesDefaultFilters = useConstantValue(() => {
    const today = DateTime.now().startOf('day');
    const isSalesSupervisor = $data.roles.includes('salesSupervisor');
    const subRegionIds = $data.supervisedRegions?.flatMap((r) => r?.subRegions.map((sr) => ({ id: sr.id })) ?? []) ?? [];

    return WorkPlanningFilters_Regular.EMPTY.with({
      arrivalDate: { start: today, end: today.plus({ day: 90 }) },
      statuses: ['inWriting', 'worksiteSelected'],
      subRegions: isSalesSupervisor ? subRegionIds : [],
    });
  });

  const [filters, handleFiltersChange] = useJobFilters<WorkPlanningFilters_Regular>(
    $data,
    workPlanningFiltersSharedStateKey,
    WorkPlanningFilters_Regular.EMPTY,
    salesDefaultFilters,
    (sp) => WorkPlanningFilters_Regular.fromSearchParams(sp),
  );

  const queryFilters = useConstantValue<WorkPlanningList_RegularQuery$variables>(() => ({
    first: 25,
    where: filters.toJobRevisionFilter(),
    searchTerm: convertToTsQuery(filters.get('searchTerm'), '|'),
    projectManagerIds: filters.get('projectManagers').map(({ id }) => id),
    representativeIds: filters.get('representatives').map(({ id }) => id),
    subRegionIds: filters.get('subRegions').map(({ id }) => id),
  }));

  const workPlannings = useLazyLoadQuery<WorkPlanningList_RegularQuery>(
    graphql`
      query WorkPlanningList_RegularQuery(
        $first: Int
        $where: WorkPlanningJobRevisionFilterType
        $projectManagerIds: [ID!]!
        $representativeIds: [ID!]!
        $subRegionIds: [ID!]!
        $searchTerm: String
      ) {
        ...WorkPlanningList_Regular_ListContentFragment
          @arguments(
            searchTerm: $searchTerm
            representativeIds: $representativeIds
            subRegionIds: $subRegionIds
            first: $first
            where: $where
          )
        ...WorkPlanningList_Regular_FiltersFragment
          @arguments(projectManagerIds: $projectManagerIds, representativeIds: $representativeIds, subRegionIds: $subRegionIds)
      }
    `,
    queryFilters,
    { fetchPolicy: online ? 'store-and-network' : 'store-only' }, // store-and-network to make sure the data is up to date following a details page modification
  );

  return (
    <ListContent {...listProps} $key={workPlannings} filters$key={workPlannings} filters={filters} onFiltersChange={handleFiltersChange} />
  );
}

export function ListContent({
  $key,
  filters$key,
  onItemClick,
  filters,
  onFiltersChange: handleFiltersChange,
  ...gridProps
}: {
  $key: WorkPlanningList_Regular_ListContentFragment$key;
  filters$key: WorkPlanningList_Regular_FiltersFragment$key;
  filters: WorkPlanningFilters_Regular;
  onFiltersChange: (filters: WorkPlanningFilters_Regular) => void;
} & ResponsiveGridForwardProps) {
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { t } = useAmbientTranslation();

  const [$data, refetch] = useRefetchableFragment<WorkPlanningList_RegularQuery, WorkPlanningList_Regular_ListContentFragment$key>(
    graphql`
      fragment WorkPlanningList_Regular_ListContentFragment on Query
      @refetchable(queryName: "WorkPlanningList_Regular_ListContentFragmentQuery")
      @argumentDefinitions(
        searchTerm: { type: "String" }
        representativeIds: { type: "[ID!]" }
        subRegionIds: { type: "[ID!]" }
        after: { type: "String" }
        before: { type: "String" }
        first: { type: "Int" }
        last: { type: "Int" }
        where: { type: "WorkPlanningJobRevisionFilterType" }
      ) {
        searchWorkPlannings(
          searchTerm: $searchTerm
          representativeIds: $representativeIds
          subRegionIds: $subRegionIds
          after: $after
          before: $before
          first: $first
          last: $last
          where: $where
          order: [{ snapshot: { statusOrder: ASC } }, { snapshot: { projectBase: { arrivalDate: { date: ASC } } } }, { id: ASC }]
        ) @required(action: THROW) {
          ...ResponsiveGridFragment
          edges {
            node {
              id
              lifeCycleBranchId
              ...WorkPlanningList_RowFragment
              ...WorkPlanningList_ItemFragment
            }
          }
        }
        ...WorkPlanningList_Row_MeFragment
      }
    `,
    $key,
  );

  const edges = $data.searchWorkPlannings?.edges;

  const refetchFn = useCallback<ResponsiveGridProps['refetch']>(
    (vars, options) =>
      refetch(
        {
          ...vars,
          searchTerm: convertToTsQuery(filters.get('searchTerm'), '|'),
          representativeIds: filters.get('representatives').map(({ id }) => id),
          subRegionIds: filters.get('subRegions').map(({ id }) => id),
          where: filters.toJobRevisionFilter(),
        },
        options,
      ),
    [refetch, filters],
  );

  const columns = getWorkPlanningListColumns(t, compact);

  const rowElementFactory = useElementFactory(edges, (node, orderByColumns) => (
    <WorkPlanningList_Row $key={node} me$key={$data} orderByColumns={orderByColumns} />
  ));
  const listElementFactory = useElementFactory(edges, (node) => <WorkPlanningList_Item $key={node} />);
  const rowSkeletonFactory = useSkeletonFactory(() => <WorkPlanningList_RowSkeleton columns={columns} />);
  const listSkeletonFactory = useSkeletonFactory(() => <WorkPlanningList_ListSkeleton />);

  return (
    $data.searchWorkPlannings && (
      <>
        <Filters $key={filters$key} filters={filters} onFiltersChange={handleFiltersChange} />
        <ResponsiveGrid
          connectionFragmentKey={$data.searchWorkPlannings}
          refetch={refetchFn}
          columnDefinitions={columns}
          rowElementFactory={rowElementFactory}
          listElementFactory={listElementFactory}
          rowSkeletonFactory={rowSkeletonFactory}
          listSkeletonFactory={listSkeletonFactory}
          listSx={workPlanningListSx}
          onItemClick={(id: DataID) => onItemClick?.(getNodeById(id, edges).lifeCycleBranchId)}
          {...gridProps}
        />
      </>
    )
  );
}

function Filters({
  $key,
  filters,
  onFiltersChange: handleFiltersChange,
}: {
  $key: WorkPlanningList_Regular_FiltersFragment$key;
  filters: WorkPlanningFilters_Regular;
  onFiltersChange: (filters: WorkPlanningFilters_Regular) => void;
}) {
  const { t } = useAmbientTranslation();
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const $data = useFragment(
    graphql`
      fragment WorkPlanningList_Regular_FiltersFragment on Query
      @argumentDefinitions(representativeIds: { type: "[ID!]!" }, projectManagerIds: { type: "[ID!]!" }, subRegionIds: { type: "[ID!]!" }) {
        representatives: nodes(ids: $representativeIds) {
          __typename
          ... on Representative {
            id
            label
            deletedAt
          }
        }
        projectManagers: nodes(ids: $projectManagerIds) {
          __typename
          ... on Representative {
            id
            label
            deletedAt
          }
        }
        subRegions: nodes(ids: $subRegionIds) {
          __typename
          ... on SubRegion {
            id
            label
            region {
              description
            }
          }
        }
      }
    `,
    $key,
  );

  const responsiveGridFilters: WorkPlanningResponsiveGridFilters_Regular = useMemo(() => {
    // Adding the extra data required by the responsive grid filter components to the filters should only be done once,
    // since this data is only fetched once during list page load. So once the filters have been modified by the user,
    // this extra data might not be up-to-date anymore, and overwriting the filters with it might corrupt the filters.
    // This is safe since the autocompletes which require this data also put it back in the filters on modification.
    if (filters instanceof WorkPlanningResponsiveGridFilters_Regular) return filters;
    return filters.toResponsiveGridFilters({
      representatives: $data.representatives.filter(isDefined).filter(discriminate('__typename', 'Representative')),
      projectManagers: $data.projectManagers.filter(isDefined).filter(discriminate('__typename', 'Representative')),
      subRegions: $data.subRegions.filter(isDefined).filter(discriminate('__typename', 'SubRegion')),
    });
  }, [filters, $data]);

  return (
    <ResponsiveGridFilters<WorkPlanningResponsiveGridFilters_Regular>
      filters={responsiveGridFilters}
      emptyFilters={WorkPlanningResponsiveGridFilters_Regular.EMPTY}
      elements={(mode, state, setState) => [
        mode === 'inline' && (
          <TextSearchFilter
            key='fts'
            value={state.get('searchTerm')}
            placeHolder={t('search', { ns: 'serviceCall' })}
            onChange={(searchTerm) => setState((prev) => prev.with({ searchTerm }))}></TextSearchFilter>
        ),
        mode === 'dialog' && (
          <JobStatusFilter
            key='status'
            value={state.get('statuses')}
            options={workPlanningStatuses.filter((s) => s !== 'locked')}
            onChange={(statuses) => setState((prev) => prev.with({ statuses }))}
            label={t('list.column.status')}
            renderChip={(option, handleDelete) => <WorkPlanningStatusChip key={option} statuses={[option]} onDelete={handleDelete} />}
          />
        ),
        mode === 'dialog' && (
          <SubRegionFilter
            key='subRegion'
            value={state.get('subRegions')}
            onChange={(subRegions) => setState((prev) => prev.with({ subRegions }))}
          />
        ),
        mode === 'dialog' && (
          <ProjectManagerFilter
            key='projectManager'
            value={state.get('projectManagers')}
            onChange={(projectManagers) => setState((prev) => prev.with({ projectManagers }))}
          />
        ),
        mode === 'dialog' && (
          <RepresentativeFilter
            key='representative'
            value={state.get('representatives')}
            onChange={(representatives) => setState((prev) => prev.with({ representatives }))}
          />
        ),
        mode === 'dialog' && (
          <ArrivalDateFilter
            key='arrivalDate'
            value={state.get('arrivalDate')}
            onChange={([start, end]: DateRange<DateTime>) => {
              setState((prev) => prev.with({ arrivalDate: start?.isValid && end?.isValid ? { start, end } : null }));
            }}
          />
        ),
      ]}
      onFiltersChange={handleFiltersChange}
      compact={compact}
      sx={(theme) => ({ [theme.breakpoints.down('sm')]: { mx: '1rem' }, [theme.breakpoints.up('md')]: { justifyContent: 'flex-start' } })}
    />
  );
}
