import { createSearchParam, createSearchParamsClass, SearchParamsProps } from '../common/utils/searchParams';
import {
  ARRIVAL_DATE_FORMAT,
  baseJobFilterParams,
  capacitiesSearchParam,
  dispatchBranchesResponsiveGridSearchParam,
  dispatchBranchesSearchParam,
  equipmentKindsResponsiveGridSearchParam,
  equipmentKindsSearchParam,
  JobFiltersSearchParams,
  projectManagersResponsiveGridSearchParam,
  projectManagersSearchParam,
  representativesResponsiveGridSearchParam,
  representativesSearchParam,
  subRegionsResponsiveGridSearchParam,
  subRegionsSearchParam,
} from '../jobs/JobFilters';
import { isServiceCallKind, ServiceCallKind } from '../__enums__/ServiceCallKind';
import { isServiceCallStatus, ServiceCallStatus } from '../__enums__/ServiceCallStatus';
import { ServiceCallJobRevisionFilterType } from './__generated__/ServiceCallList_RegularQuery.graphql';

const serviceCallKindsSearchParam = createSearchParam<readonly ServiceCallKind[]>(
  (searchParams) => searchParams.getAll(JobFiltersSearchParams.KIND).filter(isServiceCallKind),
  (kinds, searchParams) => {
    for (const kind of kinds) searchParams.append(JobFiltersSearchParams.KIND, kind);
  },
);

const serviceCallStatusesSearchParam = createSearchParam<readonly ServiceCallStatus[]>(
  (searchParams) => searchParams.getAll(JobFiltersSearchParams.STATUS).filter(isServiceCallStatus),
  (statuses, searchParams) => {
    for (const status of statuses) searchParams.append(JobFiltersSearchParams.STATUS, status);
  },
);

const baseServiceCallFiltersParams = {
  ...baseJobFilterParams,
  capacities: capacitiesSearchParam,
  dispatchBranches: dispatchBranchesSearchParam,
  equipmentKinds: equipmentKindsSearchParam,
  kinds: serviceCallKindsSearchParam,
  projectManagers: projectManagersSearchParam,
  statuses: serviceCallStatusesSearchParam,
};

const serviceCallFiltersParams_regular = {
  ...baseServiceCallFiltersParams,
  subRegions: subRegionsSearchParam,
  representatives: representativesSearchParam,
};

export class ServiceCallFilters_Regular extends createSearchParamsClass(serviceCallFiltersParams_regular) {
  static readonly EMPTY = ServiceCallFilters_Regular.fromSearchParams(new URLSearchParams());

  static fromSearchParams(searchParams: URLSearchParams): ServiceCallFilters_Regular {
    return new ServiceCallFilters_Regular(this.searchParamsToProps(searchParams));
  }

  toResponsiveGridFilters(props: ServiceCallToResponsiveGridFiltersProps_Regular): ServiceCallResponsiveGridFilters_Regular {
    return new ServiceCallResponsiveGridFilters_Regular({ ...this.props, ...props });
  }

  toJobRevisionFilter(): ServiceCallJobRevisionFilterType {
    const simplify = (op: 'and' | 'or', fs: ServiceCallJobRevisionFilterType[]): ServiceCallJobRevisionFilterType => {
      const activeFilters = fs.filter((f) => Object.keys(f).length); // ignore empty filters
      return activeFilters.length > 1 ? { [op]: activeFilters } : activeFilters[0] || {};
    };
    const and = (fs: ServiceCallJobRevisionFilterType[]) => simplify('and', fs);
    const or = (fs: ServiceCallJobRevisionFilterType[]) => simplify('or', fs);

    return and([
      this.props.capacities.length
        ? { snapshot: { equipmentBase: { craneSelector: { favoriteConfiguration: { capacity: { in: this.props.capacities } } } } } }
        : {},
      this.props.arrivalDate
        ? {
            snapshot: {
              projectBase: {
                arrivalDate: {
                  date: {
                    gte: this.props.arrivalDate.start.toFormat(ARRIVAL_DATE_FORMAT),
                    lte: this.props.arrivalDate.end.toFormat(ARRIVAL_DATE_FORMAT),
                  },
                },
              },
            },
          }
        : {},
      this.props.dispatchBranches.length
        ? { snapshot: { project: { dispatchBranchId: { in: this.props.dispatchBranches.map(({ id }) => id) } } } }
        : {},
      this.props.equipmentKinds.length
        ? {
            snapshot: {
              equipmentBase: {
                craneSelector: {
                  favoriteConfiguration: { equipmentKindCode: { in: this.props.equipmentKinds.map(({ code }) => code) } },
                },
              },
            },
          }
        : {},
      this.props.kinds.length ? { snapshot: { kind: { in: this.props.kinds } } } : {},
      this.props.projectManagers.length
        ? { snapshot: { clientBase: { projectManagerId: { in: this.props.projectManagers.map(({ id }) => id) } } } }
        : {},
      or(this.props.statuses.map((s) => ({ snapshot: { [`${s}At`]: { neq: null } } }))),
    ]);
  }
}

const serviceCallResponsiveGridFiltersParams_regular = {
  ...serviceCallFiltersParams_regular,
  dispatchBranches: dispatchBranchesResponsiveGridSearchParam,
  equipmentKinds: equipmentKindsResponsiveGridSearchParam,
  projectManagers: projectManagersResponsiveGridSearchParam,
  representatives: representativesResponsiveGridSearchParam,
  subRegions: subRegionsResponsiveGridSearchParam,
};

export type ServiceCallToResponsiveGridFiltersProps_Regular = Pick<
  SearchParamsProps<typeof serviceCallResponsiveGridFiltersParams_regular>,
  'dispatchBranches' | 'equipmentKinds' | 'projectManagers' | 'representatives' | 'subRegions'
>;

export class ServiceCallResponsiveGridFilters_Regular
  extends createSearchParamsClass(serviceCallResponsiveGridFiltersParams_regular)
  implements ServiceCallFilters_Regular
{
  static readonly EMPTY = ServiceCallFilters_Regular.EMPTY.toResponsiveGridFilters({
    dispatchBranches: [],
    equipmentKinds: [],
    projectManagers: [],
    representatives: [],
    subRegions: [],
  });

  toResponsiveGridFilters(props: ServiceCallToResponsiveGridFiltersProps_Regular): ServiceCallResponsiveGridFilters_Regular {
    return new ServiceCallResponsiveGridFilters_Regular({ ...this.props, ...props });
  }

  toJobRevisionFilter(): ServiceCallJobRevisionFilterType {
    return ServiceCallFilters_Regular.prototype.toJobRevisionFilter.call(this);
  }
}

export class ServiceCallFilters_Representative extends createSearchParamsClass(baseServiceCallFiltersParams) {
  static readonly EMPTY = ServiceCallFilters_Representative.fromSearchParams(new URLSearchParams());

  static fromSearchParams(searchParams: URLSearchParams): ServiceCallFilters_Representative {
    return new ServiceCallFilters_Representative(this.searchParamsToProps(searchParams));
  }

  toResponsiveGridFilters(props: ServiceCallToResponsiveGridFiltersProps_Representative): ServiceCallResponsiveGridFilters_Representative {
    return new ServiceCallResponsiveGridFilters_Representative({ ...this.props, ...props });
  }

  toJobRevisionFilter(): ServiceCallJobRevisionFilterType {
    const simplify = (op: 'and' | 'or', fs: ServiceCallJobRevisionFilterType[]): ServiceCallJobRevisionFilterType => {
      const activeFilters = fs.filter((f) => Object.keys(f).length); // ignore empty filters
      return activeFilters.length > 1 ? { [op]: activeFilters } : activeFilters[0] || {};
    };
    const and = (fs: ServiceCallJobRevisionFilterType[]) => simplify('and', fs);
    const or = (fs: ServiceCallJobRevisionFilterType[]) => simplify('or', fs);

    return and([
      this.props.capacities.length
        ? { snapshot: { equipmentBase: { craneSelector: { favoriteConfiguration: { capacity: { in: this.props.capacities } } } } } }
        : {},
      this.props.arrivalDate
        ? {
            snapshot: {
              projectBase: {
                arrivalDate: {
                  date: {
                    gte: this.props.arrivalDate.start.toFormat(ARRIVAL_DATE_FORMAT),
                    lte: this.props.arrivalDate.end.toFormat(ARRIVAL_DATE_FORMAT),
                  },
                },
              },
            },
          }
        : {},
      this.props.dispatchBranches.length
        ? { snapshot: { project: { dispatchBranchId: { in: this.props.dispatchBranches.map(({ id }) => id) } } } }
        : {},
      this.props.equipmentKinds.length
        ? {
            snapshot: {
              equipmentBase: {
                craneSelector: {
                  favoriteConfiguration: { equipmentKindCode: { in: this.props.equipmentKinds.map(({ code }) => code) } },
                },
              },
            },
          }
        : {},
      this.props.kinds.length ? { snapshot: { kind: { in: this.props.kinds } } } : {},
      this.props.projectManagers.length
        ? { snapshot: { clientBase: { projectManagerId: { in: this.props.projectManagers.map(({ id }) => id) } } } }
        : {},
      or(this.props.statuses.map((s) => ({ snapshot: { [`${s}At`]: { neq: null } } }))),
    ]);
  }
}

const serviceCallResponsiveGridFiltersParams_representative = {
  ...baseServiceCallFiltersParams,
  dispatchBranches: dispatchBranchesResponsiveGridSearchParam,
  equipmentKinds: equipmentKindsResponsiveGridSearchParam,
  projectManagers: projectManagersResponsiveGridSearchParam,
};

export type ServiceCallToResponsiveGridFiltersProps_Representative = Pick<
  SearchParamsProps<typeof serviceCallResponsiveGridFiltersParams_representative>,
  'dispatchBranches' | 'equipmentKinds' | 'projectManagers'
>;

export class ServiceCallResponsiveGridFilters_Representative
  extends createSearchParamsClass(serviceCallResponsiveGridFiltersParams_representative)
  implements ServiceCallFilters_Representative
{
  static readonly EMPTY = ServiceCallFilters_Representative.EMPTY.toResponsiveGridFilters({
    dispatchBranches: [],
    equipmentKinds: [],
    projectManagers: [],
  });

  toResponsiveGridFilters(props: ServiceCallToResponsiveGridFiltersProps_Representative): ServiceCallResponsiveGridFilters_Representative {
    return new ServiceCallResponsiveGridFilters_Representative({ ...this.props, ...props });
  }

  toJobRevisionFilter(): ServiceCallJobRevisionFilterType {
    return ServiceCallFilters_Representative.prototype.toJobRevisionFilter.call(this);
  }
}
