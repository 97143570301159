import { Chip, ChipProps, Tooltip } from '@mui/material';
import { asCreditCategory } from '../__enums__/CreditCategory';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { CreditCategoryChipFragment$key } from './__generated__/CreditCategoryChipFragment.graphql';
import { useTranslation } from 'react-i18next';

export function CreditCategoryChip({ $key, size }: { $key: CreditCategoryChipFragment$key; size?: ChipProps['size'] }) {
  const { t } = useTranslation('jobs');
  const $data = useFragment(
    graphql`
      fragment CreditCategoryChipFragment on Client {
        category
        forcedCategory
      }
    `,
    $key,
  );
  const category = $data.category;
  const forcedCategory = $data.forcedCategory ? asCreditCategory($data.forcedCategory) : null;

  return forcedCategory ? (
    <Tooltip title={t('creditCategory.forcedCategoryTooltip', { category })}>
      <Chip label={forcedCategory} color='info' size={size} />
    </Tooltip>
  ) : (
    category !== 'NONE' && <Chip variant='outlined' label={category} size={size} />
  );
}
