/**
 * @generated SignedSource<<4912586e12c4c66e1d59eaadc4de289e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaleProjectFields_useIncludeWeekendsAndHolidays_BaseFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_ArrivalDateReadFragment">;
  readonly " $fragmentType": "SaleProjectFields_useIncludeWeekendsAndHolidays_BaseFragment";
};
export type SaleProjectFields_useIncludeWeekendsAndHolidays_BaseFragment$key = {
  readonly " $data"?: SaleProjectFields_useIncludeWeekendsAndHolidays_BaseFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_useIncludeWeekendsAndHolidays_BaseFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isCopy"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleProjectFields_useIncludeWeekendsAndHolidays_BaseFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isCopy",
          "variableName": "isCopy"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ProjectBaseFields_ArrivalDateReadFragment"
    }
  ],
  "type": "ProjectInternalBase",
  "abstractKey": null
};

(node as any).hash = "be70ae11bc0a1f242ba0e2b7e3d8938a";

export default node;
