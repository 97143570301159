/**
 * @generated SignedSource<<7ac6323ea7463e0f1319a4bdd47683b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type StatutoryHolidayFilterInput = {
  and?: ReadonlyArray<StatutoryHolidayFilterInput> | null | undefined;
  createdAt?: DateTimeOperationFilterInput | null | undefined;
  date?: DateOperationFilterInput | null | undefined;
  deletedAt?: DateTimeOperationFilterInput | null | undefined;
  id?: IdOperationFilterInput | null | undefined;
  or?: ReadonlyArray<StatutoryHolidayFilterInput> | null | undefined;
  provinceCode?: StringOperationFilterInput | null | undefined;
  updatedAt?: DateTimeOperationFilterInput | null | undefined;
};
export type DateTimeOperationFilterInput = {
  eq?: string | null | undefined;
  gt?: string | null | undefined;
  gte?: string | null | undefined;
  in?: ReadonlyArray<string | null | undefined> | null | undefined;
  lt?: string | null | undefined;
  lte?: string | null | undefined;
  neq?: string | null | undefined;
  ngt?: string | null | undefined;
  ngte?: string | null | undefined;
  nin?: ReadonlyArray<string | null | undefined> | null | undefined;
  nlt?: string | null | undefined;
  nlte?: string | null | undefined;
};
export type DateOperationFilterInput = {
  eq?: string | null | undefined;
  gt?: string | null | undefined;
  gte?: string | null | undefined;
  in?: ReadonlyArray<string | null | undefined> | null | undefined;
  lt?: string | null | undefined;
  lte?: string | null | undefined;
  neq?: string | null | undefined;
  ngt?: string | null | undefined;
  ngte?: string | null | undefined;
  nin?: ReadonlyArray<string | null | undefined> | null | undefined;
  nlt?: string | null | undefined;
  nlte?: string | null | undefined;
};
export type IdOperationFilterInput = {
  eq?: string | null | undefined;
  in?: ReadonlyArray<string | null | undefined> | null | undefined;
  neq?: string | null | undefined;
  nin?: ReadonlyArray<string | null | undefined> | null | undefined;
};
export type StringOperationFilterInput = {
  and?: ReadonlyArray<StringOperationFilterInput> | null | undefined;
  contains?: string | null | undefined;
  endsWith?: string | null | undefined;
  eq?: string | null | undefined;
  in?: ReadonlyArray<string | null | undefined> | null | undefined;
  ncontains?: string | null | undefined;
  nendsWith?: string | null | undefined;
  neq?: string | null | undefined;
  nin?: ReadonlyArray<string | null | undefined> | null | undefined;
  nstartsWith?: string | null | undefined;
  or?: ReadonlyArray<StringOperationFilterInput> | null | undefined;
  startsWith?: string | null | undefined;
};
export type FormDateTimePicker_RoundedDateTimePickerQuery$variables = {
  where?: StatutoryHolidayFilterInput | null | undefined;
};
export type FormDateTimePicker_RoundedDateTimePickerQuery$data = {
  readonly statutoryHolidays: ReadonlyArray<{
    readonly date: string;
  }>;
};
export type FormDateTimePicker_RoundedDateTimePickerQuery = {
  response: FormDateTimePicker_RoundedDateTimePickerQuery$data;
  variables: FormDateTimePicker_RoundedDateTimePickerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FormDateTimePicker_RoundedDateTimePickerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "StatutoryHoliday",
        "kind": "LinkedField",
        "name": "statutoryHolidays",
        "plural": true,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FormDateTimePicker_RoundedDateTimePickerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "StatutoryHoliday",
        "kind": "LinkedField",
        "name": "statutoryHolidays",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f426ede28257087b661d7e27f093c54f",
    "id": null,
    "metadata": {},
    "name": "FormDateTimePicker_RoundedDateTimePickerQuery",
    "operationKind": "query",
    "text": "query FormDateTimePicker_RoundedDateTimePickerQuery(\n  $where: StatutoryHolidayFilterInput\n) {\n  statutoryHolidays(where: $where) {\n    date\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "9bec0d22baf84301dd8aaa33924ccac7";

export default node;
