/**
 * @generated SignedSource<<e77e20935555ba1c1988fdbe0eaddf54>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectBaseFields_ArrivalDateReadFragment$data = {
  readonly arrivalDate?: {
    readonly rawValue: string;
  } | null | undefined;
  readonly " $fragmentType": "ProjectBaseFields_ArrivalDateReadFragment";
};
export type ProjectBaseFields_ArrivalDateReadFragment$key = {
  readonly " $data"?: ProjectBaseFields_ArrivalDateReadFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_ArrivalDateReadFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isCopy"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectBaseFields_ArrivalDateReadFragment",
  "selections": [
    {
      "condition": "isCopy",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DateTimeInTimeZone",
          "kind": "LinkedField",
          "name": "arrivalDate",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rawValue",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "ProjectInternalBase",
  "abstractKey": null
};

(node as any).hash = "478794887e73cd204a414cbd5d276a59";

export default node;
