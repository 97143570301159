/**
 * @generated SignedSource<<1ef28354e55b39fb89bb9895a3470c98>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TabBillingCodesQuery$variables = Record<PropertyKey, never>;
export type TabBillingCodesQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodesListFragment">;
};
export type TabBillingCodesQuery = {
  response: TabBillingCodesQuery$data;
  variables: TabBillingCodesQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TabBillingCodesQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "BillingCodesListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TabBillingCodesQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "where",
            "value": {
              "billingCodeCategory": {
                "eq": "accessories"
              }
            }
          }
        ],
        "concreteType": "BillingCode",
        "kind": "LinkedField",
        "name": "billingCodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "microDescription",
            "storageKey": null
          }
        ],
        "storageKey": "billingCodes(where:{\"billingCodeCategory\":{\"eq\":\"accessories\"}})"
      }
    ]
  },
  "params": {
    "cacheID": "b807a5fda478a47eb8fd8ccf79a1e2de",
    "id": null,
    "metadata": {},
    "name": "TabBillingCodesQuery",
    "operationKind": "query",
    "text": "query TabBillingCodesQuery {\n  ...BillingCodesListFragment\n}\n\nfragment BillingCodesListFragment on Query {\n  billingCodes(where: {billingCodeCategory: {eq: accessories}}) {\n    id\n    ...BillingCodesListItemFragment\n  }\n}\n\nfragment BillingCodesListItemFragment on BillingCode {\n  id\n  code\n  subCode\n  description\n  microDescription\n}\n"
  }
};

(node as any).hash = "69d395004f58bc05b1d154fd4809defc";

export default node;
