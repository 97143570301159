/**
 * @generated SignedSource<<7d5723740ad6c3fc1328138efe259f6f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type NatureOfWorkSubCategoryEnum = "accessory" | "airports" | "antennas" | "aqueductsSewer" | "blade" | "bladeChange" | "bridges" | "cages" | "chambers" | "dams" | "demolition" | "distribution" | "docks" | "excavation" | "gearboxes" | "generator" | "gondola" | "hand" | "handling" | "landscaping" | "lifting" | "lines" | "modules" | "newConstruction" | "nonOperated" | "powerPlants" | "pruning" | "pylons" | "refurbishment" | "retentionBasins" | "roadsRails" | "rotor" | "shelters" | "substations" | "towers" | "transformers" | "transport" | "valves" | "ventilationPlumbingElectrical" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SaleProjectFields_NatureOfWorkSubCategoriesFragment$data = {
  readonly natureOfWorkSubCategories: ReadonlyArray<NatureOfWorkSubCategoryEnum>;
  readonly " $fragmentType": "SaleProjectFields_NatureOfWorkSubCategoriesFragment";
};
export type SaleProjectFields_NatureOfWorkSubCategoriesFragment$key = {
  readonly " $data"?: SaleProjectFields_NatureOfWorkSubCategoriesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_NatureOfWorkSubCategoriesFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleProjectFields_NatureOfWorkSubCategoriesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "natureOfWorkSubCategories",
      "storageKey": null
    }
  ],
  "type": "NatureOfWorkLookup",
  "abstractKey": null
};

(node as any).hash = "67faf71a756cd0b445d10b8ab09032e5";

export default node;
