/**
 * @generated SignedSource<<e7db52d0b0ddcb1fcbd436056bb3221e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaleProjectFields_ForbidOvertimeFragment$data = {
  readonly forbidOvertime: boolean;
  readonly " $fragmentType": "SaleProjectFields_ForbidOvertimeFragment";
};
export type SaleProjectFields_ForbidOvertimeFragment$key = {
  readonly " $data"?: SaleProjectFields_ForbidOvertimeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_ForbidOvertimeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleProjectFields_ForbidOvertimeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "forbidOvertime",
      "storageKey": null
    }
  ],
  "type": "ISaleProject",
  "abstractKey": "__isISaleProject"
};

(node as any).hash = "9740c0803657195b033517ab6d5ff5cb";

export default node;
