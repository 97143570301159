import { NumberParser } from './numberUtils';
import { Namespace, TFunction } from 'i18next';
import { _never } from './_never';
import { ValueObject } from 'value-object-cache';

export enum PriceFormat {
  /** Number format optimized for reading (includes group separators). */
  DISPLAY = 'display',
  /** Number format optimized for editing (does not include group separators). */
  EDIT = 'edit',
}

/** The locale used for API serialization of {@link Price} objects. */
const PRICE_API_LOCALE = 'en-US';

/**
 * A type used to wrap, parse, serialize, and format amounts of money (prices).
 *
 * This class doesn't provide access to the underlying amount typed as a number to discourage arithmetic on prices, as
 * floating point arithmetic is inherently lossy with floating point numbers (that JS uses) and money is a subject that
 * tends to make people pretty intolerant of arithmetic errors (go figure).
 *
 * To simplify interoperability with React's change detection, this class provides value object semantics:
 * `new Price(n1) === new Price(n2)` is guaranteed to always evaluate to the same value as `n1 === n2` (i.e. `true` if
 * `n1 === n2` and `false` if `n1 !== n2`).
 *
 * `Price` objects are frozen: trying to set any properties on them will result in a `TypeError` exception being thrown.
 */
export class Price extends ValueObject<{ value: number }> {
  /** The largest price that the backend's graphql API can accept. */
  static readonly MAX_API_PRICE = new Price(99_999_999_999.99);
  /** The smallest price that the backend's graphql API can accept. */
  static readonly MIN_API_PRICE = new Price(-99_999_999_999.99);

  /** Fuzzy parse a price formatted by the backend's graphql API.
   * Returns `null` if the provided argument is either `null` or `undefined`.
   * Returns a `Price` object if parsing succeeds, `null` otherwise. */
  static fromApi(value: string | null | undefined): Price | null {
    if (value == null) return null;
    const asNum = NumberParser.forLocale(PRICE_API_LOCALE).parseFuzzy(value);
    return asNum == null ? null : new Price(asNum);
  }

  /** Fuzzy parse a price formatted according to the provided locale's standard number format.
   * The price is rounded to two decimals, and clamped to `[Price.MIN_API_PRICE, Price.MAX_API_PRICE]`.
   * Returns a `Price` object if parsing succeeds, `null` otherwise.
   * @see Price.MIN_API_PRICE
   * @see Price.MAX_API_PRICE
   */
  static fromUi(value: string, localeOrTFunction: string | TFunction): Price | null {
    const locale = typeof localeOrTFunction === 'string' ? localeOrTFunction : localeOrTFunction('locale', { ns: 'common' });
    const asNum = NumberParser.forLocale(locale).parseFuzzy(value);
    return asNum == null ? null : new Price(+asNum.toFixed(2)).clamp(Price.MIN_API_PRICE, Price.MAX_API_PRICE);
  }

  /** Returns the smallest of the provided `Price` objects, or `null` if none were provided. */
  static min(): null;
  static min(price: Price, ...prices: Price[]): Price;
  static min(...prices: Price[]): Price | null;
  static min(...prices: Price[]): Price | null {
    return prices.length ? new Price(Math.min(...prices.map((p) => p.props.value))) : null;
  }

  /** Returns the largest of the provided `Price` objects, or `null` if none were provided. */
  static max(): null;
  static max(price: Price, ...prices: Price[]): Price;
  static max(...prices: Price[]): Price | null;
  static max(...prices: Price[]): Price | null {
    return prices.length ? new Price(Math.max(...prices.map((p) => p.props.value))) : null;
  }

  /** Create a `Price` object from either a raw `number` or another `Price` instance.
   * Throws `RangeError` if the provided argument is either `Infinity`, `-Infinity` or `NaN`.
   * If another `Price` instance already exists wrapping the same number, it is returned instead of a new `Price` object
   * being created (allowing for value object semantics, i.e. `new Price(9.99) === new Price(9.99)`).
   * Despite `0` and `-0` being distinct values in JS, `Price` normalizes `-0` to `0` to avoid issues (i.e.
   * `new Price(-0) === new Price(0)`). This also makes more sense in a price handling context. */
  constructor(value: number | Price) {
    if (value instanceof Price) return value;

    // Reject -Infinity, +Infinity, and NaN
    if (!Number.isFinite(value)) throw new RangeError(`A Price object can only wrap finite values, not ${value}`);

    // Normalize -0 to 0
    const normalizedValue = value || 0;

    super({ value: normalizedValue || 0 }, [normalizedValue]);
  }

  /** Returns the smallest `Price` amongst this instance and the provided `Price` arguments. */
  min(...prices: Price[]): Price {
    return Price.min(this, ...prices);
  }

  /** Returns the largest `Price` amongst this instance and the provided `Price` arguments. */
  max(...prices: Price[]): Price {
    return Price.max(this, ...prices);
  }

  /** Returns a `Price` object that is no smaller than `min` and no larger than `max`.
   * `min` and `max` can both be either `null` or `undefined`.
   * Throws a `RangeError` if `min` wraps a price that is larger than `max`. */
  clamp(min?: Price | null, max?: Price | null): Price {
    if (min && max && min.props.value > max.props.value) throw new RangeError('Price.clamp(): min must be less than or equal to max');
    const tmp = min ? this.max(min) : this;
    return max ? tmp.min(max) : tmp;
  }

  /** Returns whether the price is strictly negative. */
  isNegative(): boolean {
    return this.props.value < 0;
  }

  /** Returns whether the price is zero. */
  isZero(): boolean {
    return this.props.value === 0;
  }

  /** Returns whether the price is strictly positive. */
  isPositive(): boolean {
    return this.props.value > 0;
  }

  /**
   * Format a `Price` object in a format suitable for being displayed in a UI and following the provided locale's
   * standard number format.
   *
   * The locale can either be specified as a string, or as a `t()` translation function from the `i18next` module,
   * provided a translation exists for the `locale` key in the `common` namespace and its value is the locale to use for
   * the current configured language.
   *
   * @see PriceFormat
   */
  format(localeOrTFunction: string | TFunction<Namespace>, format: PriceFormat = PriceFormat.DISPLAY): string {
    const locale = typeof localeOrTFunction === 'string' ? localeOrTFunction : localeOrTFunction('locale', { ns: 'common' });

    switch (format) {
      case PriceFormat.DISPLAY:
        return new Intl.NumberFormat(locale, { minimumFractionDigits: 2, maximumFractionDigits: 20 }).format(this.props.value);
      case PriceFormat.EDIT:
        return new Intl.NumberFormat(locale, { minimumFractionDigits: 2, maximumFractionDigits: 20, useGrouping: false }).format(
          this.props.value,
        );
      default:
        _never(
          format,
          () => new RangeError(`Price.format(): format should be one of ${Object.values(PriceFormat).join(', ')}, not ${format}.`),
        );
    }
  }

  /** Serialize a `Price` object in the format expected by the backend's graphql API. */
  toJSON() {
    return new Intl.NumberFormat(PRICE_API_LOCALE, { minimumFractionDigits: 2, maximumFractionDigits: 20, useGrouping: false }).format(
      this.props.value,
    );
  }

  /** Just a nice string representation in case a `Price` object ends up "stringified" in an exception, etc. */
  toString() {
    return `Price(${this.props.value})`;
  }
}
