/**
 * @generated SignedSource<<f4c36e34b854c2a32ff7bbf73fdcac67>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectBaseFields_ArrivalDateFragment$data = {
  readonly projectBase: {
    readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_ArrivalDateReadFragment">;
  };
  readonly transferredAt?: string | null | undefined;
  readonly " $fragmentType": "ProjectBaseFields_ArrivalDateFragment";
};
export type ProjectBaseFields_ArrivalDateFragment$key = {
  readonly " $data"?: ProjectBaseFields_ArrivalDateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_ArrivalDateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isCopy"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectBaseFields_ArrivalDateFragment",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "condition": "isCopy",
          "kind": "Condition",
          "passingValue": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "transferredAt",
              "storageKey": null
            }
          ]
        }
      ],
      "type": "ServiceCall",
      "abstractKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectInternalBase",
      "kind": "LinkedField",
      "name": "projectBase",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "isCopy",
              "variableName": "isCopy"
            }
          ],
          "kind": "FragmentSpread",
          "name": "ProjectBaseFields_ArrivalDateReadFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "IJobStage",
  "abstractKey": "__isIJobStage"
};

(node as any).hash = "d2b0b6945103593e13fde4a721667a56";

export default node;
