import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { QuoteStatus } from '../__enums__/QuoteStatus';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Chip, ChipProps, SxProps, useTheme } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';

interface Props {
  statuses: readonly QuoteStatus[];
  compact?: boolean;
}

type ChipIconAndColor = Pick<ChipProps, 'icon' | 'color'>;

const getChipIconAndColor = (status: QuoteStatus): ChipIconAndColor => {
  switch (status) {
    case 'inWriting':
      return {
        icon: <EditIcon fontSize='inherit' />,
        color: 'draft',
      };
    case 'inApproval':
      return {
        icon: <ScheduleSendIcon fontSize='inherit' />,
        color: 'advanced',
      };
    case 'awaitingClient':
      return {
        icon: <CheckCircleOutline fontSize='inherit' />,
        color: 'validation',
      };
    case 'revising':
      return {
        icon: <EditIcon fontSize='inherit' />,
        color: 'setback',
      };
    case 'declined':
      return {
        icon: <CloseIcon fontSize='inherit' />,
        color: 'pushback',
      };
    case 'accepted':
      return {
        icon: <CheckCircleIcon fontSize='inherit' />,
        color: 'done',
      };
    case 'canceled':
      return {
        icon: <CancelIcon fontSize='inherit' />,
        color: 'cancel',
      };
    default:
      return {
        icon: undefined,
        color: 'default',
      };
  }
};

const statusPriorities: Record<QuoteStatus, number> = {
  canceled: 1,
  declined: 2,
  accepted: 3,
  inApproval: 4,
  awaitingClient: 5,
  revising: 6,
  inWriting: 7,
  locked: 8,
};

function orderVisibleStatusesByPriority(statuses: readonly QuoteStatus[]): QuoteStatus[] {
  return statuses.filter((s) => s !== 'locked').sort((a, b) => statusPriorities[a] - statusPriorities[b]);
}

function getMainStatus(statuses: readonly QuoteStatus[]): QuoteStatus | undefined {
  return orderVisibleStatusesByPriority(statuses)[0];
}

export function QuoteStatusChip({ statuses, compact, ...rest }: Props & Omit<ChipProps, 'icon' | 'color' | 'label'>) {
  const { t } = useTranslation('quote');
  const status = getMainStatus(statuses);

  if (!status) return null;

  if (compact) return <QuoteStatusIcon status={status} />;

  return <Chip {...getChipIconAndColor(status)} label={t(`status.${status}`)} size='small' {...rest} />;
}

function QuoteStatusIcon({ status }: { status: QuoteStatus }) {
  const theme = useTheme();

  const sx = (bgColor: string, color?: string): SxProps => ({
    backgroundColor: bgColor,
    borderRadius: '1rem',
    border: `0.25rem solid ${bgColor}`,
    color: color,
  });

  switch (status) {
    case 'inWriting':
      return <EditIcon sx={sx(theme.palette.draft.main, theme.palette.draft.contrastText)} />;
    case 'inApproval':
      return <ScheduleSendIcon sx={sx(theme.palette.advanced.main, theme.palette.advanced.contrastText)} />;
    case 'awaitingClient':
      return <CheckCircleOutline sx={sx(theme.palette.validation.main, theme.palette.validation.contrastText)} />;
    case 'revising':
      return <EditIcon sx={sx(theme.palette.setback.main, theme.palette.setback.contrastText)} />;
    case 'declined':
      return <CloseIcon sx={sx(theme.palette.pushback.main, theme.palette.pushback.contrastText)} />;
    case 'accepted':
      return <CheckCircleIcon sx={sx(theme.palette.done.main, theme.palette.done.contrastText)} />;
    case 'canceled':
      return <CancelIcon sx={sx(theme.palette.cancel.main, theme.palette.cancel.contrastText)} />;
    default:
      return null;
  }
}
