import { DetailsLayout, SidebarContentProps } from '../../layout/Layouts';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import { Suspense, useCallback } from 'react';
import { ElementNotFoundErrorBoundary } from '../../layout/ElementNotFoundErrorBoundary';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import { FormSectionSkeleton } from '../../layout/components/loaders/FormSectionSkeleton';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { ElementNotFoundError } from '../../common/exceptions/ElementNotFoundError';
import { RequireAdmin, UnauthorizedFallback } from '../../auth/Authorization';
import { ConfigurationPageQuery$data } from '../__generated__/ConfigurationPageQuery.graphql';
import { NavigationMenu } from '../../layout/SidebarDrawer';
import { ErrorBanner } from '../../common/components/ErrorBanner';
import { FormSectionContent, formSectionContent_gridLayout, FormSectionHeader, FormSectionHeader_Subtitle } from '../../layout/FormLayout';
import { FormProvider } from '../../common/utils/forms';
import { BillingCodeSaveButton } from './BillingCodeSaveButton';
import graphql from 'babel-plugin-relay/macro';
import { _throw } from '../../common/utils/_throw';
import { billingCodeFormContext, useFieldBillingCodeLabelEn, useFieldBillingCodeLabelFr } from './BillingCodeFields';
import { BillingCodeDetails_FormFragment$key } from './__generated__/BillingCodeDetails_FormFragment.graphql';
import { BillingCodeDetails_Query } from './__generated__/BillingCodeDetails_Query.graphql';
import { resolvedLanguage } from '../../i18n';

const flagName = 'app_navigation_configuration';

export function BillingCodeDetails_Page() {
  const { t } = useAmbientTranslation();
  const $data = useOutletContext<ConfigurationPageQuery$data>();

  return (
    <ElementNotFoundErrorBoundary heading={t('billingCodes.edit')} flagName={flagName} sidebar$key={$data} detailsLayout$key={$data}>
      <Suspense fallback={<BillingCodeDetailsSkeleton $data={$data} />}>
        <BillingCodeDetails />
      </Suspense>
    </ElementNotFoundErrorBoundary>
  );
}

function BillingCodeDetailsSkeleton({ $data }: { $data: ConfigurationPageQuery$data }) {
  const { t } = useAmbientTranslation();
  const sidebar = useCallback((props: SidebarContentProps) => <NavigationMenu {...props} $key={$data} />, [$data]);

  return (
    <DetailsLayout heading={t('billingCodes.edit')} flagName={flagName} sidebarProvider={sidebar} $key={$data}>
      <FormSectionSkeleton />
    </DetailsLayout>
  );
}

function BillingCodeDetails() {
  const params = useParams<{ id: string }>();
  const id = params.id ?? _throw('Id must be provided');
  const { t } = useAmbientTranslation();
  const outlet$data = useOutletContext<ConfigurationPageQuery$data>();
  const heading = t('billingCodes.edit');
  const sidebar = useCallback((props: SidebarContentProps) => <NavigationMenu {...props} $key={outlet$data} />, [outlet$data]);
  const navigate = useNavigate();

  const $data = useLazyLoadQuery<BillingCodeDetails_Query>(
    graphql`
      query BillingCodeDetails_Query($id: ID!) {
        node(id: $id) {
          ... on BillingCode {
            ...BillingCodeDetails_FormFragment
          }
        }
      }
    `,
    { id },
    { fetchPolicy: 'store-and-network' },
  );

  if ($data.node == null) {
    throw new ElementNotFoundError('BillingCode', id, '/configuration/billing-codes');
  }

  return (
    <RequireAdmin
      $key={outlet$data}
      fallback={
        <DetailsLayout heading={heading} flagName={flagName} sidebarProvider={sidebar} $key={outlet$data}>
          <UnauthorizedFallback onButtonClick={() => navigate('/')} />
        </DetailsLayout>
      }>
      <FormProvider key={id} context={billingCodeFormContext}>
        <DetailsLayout
          heading={heading}
          flagName={flagName}
          sidebarProvider={sidebar}
          $key={outlet$data}
          actions={<BillingCodeSaveButton id={id} />}>
          <ErrorBanner />
          <BillingCodeForm $key={$data.node} />
        </DetailsLayout>
      </FormProvider>
    </RequireAdmin>
  );
}

function BillingCodeForm({ $key }: { $key: BillingCodeDetails_FormFragment$key }) {
  const { t, i18n } = useAmbientTranslation();
  const lang = resolvedLanguage(i18n);

  const $data = useFragment(
    graphql`
      fragment BillingCodeDetails_FormFragment on BillingCode {
        id
        code
        subCode
        description
        ...BillingCodeFields_LabelFrFragment
        ...BillingCodeFields_LabelEnFragment
      }
    `,
    $key,
  );

  const { renderLabelFr } = useFieldBillingCodeLabelFr($data);
  const { renderLabelEn } = useFieldBillingCodeLabelEn($data);

  return (
    <>
      <FormSectionHeader label={<FormSectionHeader_Subtitle label={t('billingCodes.information')} />} />
      <FormSectionContent sx={formSectionContent_gridLayout}>
        {$data.code}-{$data.subCode} - {$data.description[lang]}
      </FormSectionContent>

      <FormSectionHeader label={<FormSectionHeader_Subtitle label={t('billingCodes.label')} />} />
      <FormSectionContent sx={formSectionContent_gridLayout}>
        {renderLabelFr()}
        {renderLabelEn()}
      </FormSectionContent>
    </>
  );
}
