/**
 * @generated SignedSource<<0a45c8d4fb60434d4ade2b4a5d83583c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddendumTemplateAutocompleteQuery$variables = {
  clientCulture?: string | null | undefined;
  searchTerm?: string | null | undefined;
};
export type AddendumTemplateAutocompleteQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AddendumTemplateAutocompleteListFragment">;
};
export type AddendumTemplateAutocompleteQuery = {
  response: AddendumTemplateAutocompleteQuery$data;
  variables: AddendumTemplateAutocompleteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "clientCulture"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchTerm"
  }
],
v1 = {
  "kind": "Variable",
  "name": "clientCulture",
  "variableName": "clientCulture"
},
v2 = {
  "kind": "Variable",
  "name": "searchTerm",
  "variableName": "searchTerm"
},
v3 = [
  (v1/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 25
  },
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddendumTemplateAutocompleteQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "AddendumTemplateAutocompleteListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddendumTemplateAutocompleteQuery",
    "selections": [
      {
        "alias": "searchResults",
        "args": (v3/*: any*/),
        "concreteType": "SearchAddendumTemplatesConnection",
        "kind": "LinkedField",
        "name": "searchAddendumTemplates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SearchAddendumTemplatesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AddendumTemplate",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "name": "label",
                    "args": null,
                    "fragment": {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "title",
                          "storageKey": null
                        }
                      ],
                      "type": "AddendumTemplate",
                      "abstractKey": null
                    },
                    "kind": "RelayResolver",
                    "storageKey": null,
                    "isOutputType": true
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "kind",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "searchResults",
        "args": (v3/*: any*/),
        "filters": [
          "searchTerm",
          "clientCulture"
        ],
        "handle": "connection",
        "key": "addendumTemplateAutocomplete2ListFragment_searchResults",
        "kind": "LinkedHandle",
        "name": "searchAddendumTemplates"
      }
    ]
  },
  "params": {
    "cacheID": "a8e67ada6ce2ae56d76fe947dbaadaea",
    "id": null,
    "metadata": {},
    "name": "AddendumTemplateAutocompleteQuery",
    "operationKind": "query",
    "text": "query AddendumTemplateAutocompleteQuery(\n  $clientCulture: String\n  $searchTerm: String\n) {\n  ...AddendumTemplateAutocompleteListFragment_YqBJB\n}\n\nfragment AddendumTemplateAutocompleteListFragment_YqBJB on Query {\n  searchResults: searchAddendumTemplates(searchTerm: $searchTerm, clientCulture: $clientCulture, first: 25) {\n    edges {\n      node {\n        id\n        ...AddendumTemplateLabelResolver\n        kind\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment AddendumTemplateLabelResolver on AddendumTemplate {\n  title\n}\n"
  }
};
})();

(node as any).hash = "a314af0e2766ade0008d3ad135cd108e";

export default node;
