/**
 * @generated SignedSource<<462d15eae220fdcac1270ffd76faf367>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TabCompetitors_ListFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TabCompetitors_useFieldCompetitorsCollectionFragment">;
  readonly " $fragmentType": "TabCompetitors_ListFragment";
};
export type TabCompetitors_ListFragment$key = {
  readonly " $data"?: TabCompetitors_ListFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TabCompetitors_ListFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TabCompetitors_ListFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TabCompetitors_useFieldCompetitorsCollectionFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "dc4faa55d227c7f3d0ac280d236bfb98";

export default node;
