/**
 * @generated SignedSource<<c2140ff5d66dd9acad19e9b900980994>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type NatureOfWorkSubCategoryEnum = "accessory" | "airports" | "antennas" | "aqueductsSewer" | "blade" | "bladeChange" | "bridges" | "cages" | "chambers" | "dams" | "demolition" | "distribution" | "docks" | "excavation" | "gearboxes" | "generator" | "gondola" | "hand" | "handling" | "landscaping" | "lifting" | "lines" | "modules" | "newConstruction" | "nonOperated" | "powerPlants" | "pruning" | "pylons" | "refurbishment" | "retentionBasins" | "roadsRails" | "rotor" | "shelters" | "substations" | "towers" | "transformers" | "transport" | "valves" | "ventilationPlumbingElectrical" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NatureOfWorkSubCategoryAutocompleteFragment$data = {
  readonly suggestions?: ReadonlyArray<{
    readonly score: number;
    readonly value: NatureOfWorkSubCategoryEnum;
  } | null | undefined>;
  readonly " $fragmentType": "NatureOfWorkSubCategoryAutocompleteFragment";
};
export type NatureOfWorkSubCategoryAutocompleteFragment$key = {
  readonly " $data"?: NatureOfWorkSubCategoryAutocompleteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NatureOfWorkSubCategoryAutocompleteFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "skipSuggestion"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "suggestionCount"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "suggestionPrompt"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "suggestionScoreThreshold"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "NatureOfWorkSubCategoryAutocompleteFragment",
  "selections": [
    {
      "condition": "skipSuggestion",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": "suggestions",
          "args": [
            {
              "kind": "Variable",
              "name": "count",
              "variableName": "suggestionCount"
            },
            {
              "kind": "Variable",
              "name": "prompt",
              "variableName": "suggestionPrompt"
            },
            {
              "kind": "Variable",
              "name": "scoreThreshold",
              "variableName": "suggestionScoreThreshold"
            }
          ],
          "concreteType": "SuggestionOfNatureOfWorkSubCategoryEnum",
          "kind": "LinkedField",
          "name": "suggestedNatureOfWorkSubCategories",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "score",
              "storageKey": null
            },
            {
              "kind": "RequiredField",
              "field": {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              },
              "action": "LOG"
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "d22d2cbcea3fff526076988b9628b484";

export default node;
