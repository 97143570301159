import { DetailsLayout, SidebarContentProps } from '../../layout/Layouts';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import { Suspense, useCallback, useEffect } from 'react';
import { ElementNotFoundErrorBoundary } from '../../layout/ElementNotFoundErrorBoundary';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import { FormSectionSkeleton } from '../../layout/components/loaders/FormSectionSkeleton';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { ElementNotFoundError } from '../../common/exceptions/ElementNotFoundError';
import { RequireAdmin, UnauthorizedFallback } from '../../auth/Authorization';
import { NavigationMenu } from '../../layout/SidebarDrawer';
import { ErrorBanner } from '../../common/components/ErrorBanner';
import { FormSectionContent, formSectionContent_gridLayout, FormSectionHeader, FormSectionHeader_Subtitle } from '../../layout/FormLayout';
import { FormProvider } from '../../common/utils/forms';
import { NatureOfWorkSaveButton } from './NatureOfWorkSaveButton';
import graphql from 'babel-plugin-relay/macro';
import { _throw } from '../../common/utils/_throw';
import {
  natureOfWorkFormContext,
  useFieldNatureOfWorkCategories,
  useFieldNatureOfWorkDefaultWorkSchedule,
  useFieldNatureOfWorkWorkSchedules,
} from './NatureOfWorkFields';

import { resolvedLanguage } from '../../i18n';
import { ConfigurationPageQuery$data } from '../__generated__/ConfigurationPageQuery.graphql';
import { NatureOfWorkDetails_Query } from './__generated__/NatureOfWorkDetails_Query.graphql';
import { NatureOfWorkDetails_FormFragment$key } from './__generated__/NatureOfWorkDetails_FormFragment.graphql';
import { NatureOfWorkDetails_useSetWorkSchedulesFragment$key } from './__generated__/NatureOfWorkDetails_useSetWorkSchedulesFragment.graphql';

const flagName = 'app_navigation_configuration';

export function NatureOfWorkDetails_Page() {
  const { t } = useAmbientTranslation();
  const $data = useOutletContext<ConfigurationPageQuery$data>();

  return (
    <ElementNotFoundErrorBoundary heading={t('natureOfWork.edit')} flagName={flagName} sidebar$key={$data} detailsLayout$key={$data}>
      <Suspense fallback={<NatureOfWorkDetailsSkeleton $data={$data} />}>
        <NatureOfWorkDetails />
      </Suspense>
    </ElementNotFoundErrorBoundary>
  );
}

function NatureOfWorkDetailsSkeleton({ $data }: { $data: ConfigurationPageQuery$data }) {
  const { t } = useAmbientTranslation();
  const sidebar = useCallback((props: SidebarContentProps) => <NavigationMenu {...props} $key={$data} />, [$data]);

  return (
    <DetailsLayout heading={t('natureOfWork.edit')} flagName={flagName} sidebarProvider={sidebar} $key={$data}>
      <FormSectionSkeleton />
    </DetailsLayout>
  );
}

function NatureOfWorkDetails() {
  const params = useParams<{ id: string }>();
  const id = params.id ?? _throw('Id must be provided');
  const { t } = useAmbientTranslation();
  const outlet$data = useOutletContext<ConfigurationPageQuery$data>();
  const navigate = useNavigate();

  const $data = useLazyLoadQuery<NatureOfWorkDetails_Query>(
    graphql`
      query NatureOfWorkDetails_Query($id: ID!) {
        node(id: $id) {
          ... on NatureOfWorkLookup {
            ...NatureOfWorkDetails_FormFragment
          }
        }
      }
    `,
    { id },
    { fetchPolicy: 'store-and-network' },
  );

  const heading = t('natureOfWork.edit');
  const sidebar = useCallback((props: SidebarContentProps) => <NavigationMenu {...props} $key={outlet$data} />, [outlet$data]);

  if ($data.node == null) {
    throw new ElementNotFoundError('NatureOfWork', id, '/configuration/natures-of-work');
  }

  return (
    <RequireAdmin
      $key={outlet$data}
      fallback={
        <DetailsLayout heading={heading} flagName={flagName} sidebarProvider={sidebar} $key={outlet$data}>
          <UnauthorizedFallback onButtonClick={() => navigate('/')} />
        </DetailsLayout>
      }>
      <FormProvider key={id} context={natureOfWorkFormContext}>
        <DetailsLayout
          heading={heading}
          flagName={flagName}
          sidebarProvider={sidebar}
          $key={outlet$data}
          actions={<NatureOfWorkSaveButton id={id} />}>
          <ErrorBanner />
          <NatureOfWorkForm $key={$data.node} />
        </DetailsLayout>
      </FormProvider>
    </RequireAdmin>
  );
}

function NatureOfWorkForm({ $key }: { $key: NatureOfWorkDetails_FormFragment$key }) {
  const { t, i18n } = useAmbientTranslation();
  const lang = resolvedLanguage(i18n);

  const $data = useFragment(
    graphql`
      fragment NatureOfWorkDetails_FormFragment on NatureOfWorkLookup {
        id
        code
        description
        ...NatureOfWorkFields_WorkSchedulesFragment
        ...NatureOfWorkFields_DefaultWorkScheduleFragment
        ...NatureOfWorkFields_CategoriesFragment
        ...NatureOfWorkDetails_useSetWorkSchedulesFragment
      }
    `,
    $key,
  );

  const { renderWorkSchedules, workSchedules } = useFieldNatureOfWorkWorkSchedules($data);
  const { renderDefaultWorkSchedule } = useFieldNatureOfWorkDefaultWorkSchedule($data);
  const { renderCategories } = useFieldNatureOfWorkCategories($data);

  useSetWorkSchedules($data);

  return (
    <>
      <FormSectionHeader label={<FormSectionHeader_Subtitle label={t('natureOfWork.information')} />} />
      <FormSectionContent sx={formSectionContent_gridLayout}>
        {$data.code} - {$data.description[lang]}
      </FormSectionContent>

      <FormSectionHeader label={<FormSectionHeader_Subtitle label={t('natureOfWork.schedule')} />} />
      <FormSectionContent>
        {renderWorkSchedules()}
        {renderDefaultWorkSchedule([...workSchedules])}
      </FormSectionContent>

      <FormSectionHeader label={<FormSectionHeader_Subtitle label={t('natureOfWork.field.categories')} />} />
      <FormSectionContent>{renderCategories()}</FormSectionContent>
    </>
  );
}

function useSetWorkSchedules($key: NatureOfWorkDetails_useSetWorkSchedulesFragment$key) {
  const $data = useFragment(
    graphql`
      fragment NatureOfWorkDetails_useSetWorkSchedulesFragment on NatureOfWorkLookup {
        ...NatureOfWorkFields_WorkSchedulesFragment
        ...NatureOfWorkFields_DefaultWorkScheduleFragment
      }
    `,
    $key,
  );

  const { workSchedules, workSchedulesAreDirty } = useFieldNatureOfWorkWorkSchedules($data);
  const { workSchedule, setWorkSchedule } = useFieldNatureOfWorkDefaultWorkSchedule($data);

  useEffect(() => {
    if (workSchedulesAreDirty && workSchedule && !workSchedules.includes(workSchedule)) {
      setWorkSchedule(null);
    }
  }, [setWorkSchedule, workSchedule, workSchedules, workSchedulesAreDirty]);
}
