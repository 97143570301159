import { ServiceCallKind } from '../../__enums__/ServiceCallKind';
import { ServiceCallStatus } from '../../__enums__/ServiceCallStatus';
import { LinkedServiceCallTransferStatus } from '../../__enums__/LinkedServiceCallTransferStatus';
import { TransportKind } from '../../__enums__/TransportKind';

const dateTime = {
  sevenDays: '7 days',
  fourteenDays: '14 days',
  thirtyDays: '30 days',
  sixtyDays: '60 days',
};

const field = {
  accessory: {
    accessoryLine_one: '{{ count }} accessory line',
    accessoryLine_other: '{{ count }} accessory lines',
    accessory: 'Accessory',
    billable: 'Billable',
    billed: 'Billed',
    noAccessory: 'No Accessory',
    overline: 'Accessories',
    quantity: 'Quantity',
    qty: 'Qty.',
    rate: 'Rate',
    type: 'Accessory Type',
  },
  addenda: {
    addenda_one: '{{ count }} addendum',
    addenda_other: '{{ count }} addenda',
    title: 'Title',
    kind: 'Kind',
    description: 'Description',
  },
  attachment: {
    attachment_one: '{{ count }} attachment',
    attachment_other: '{{ count }} attachments',
  },
  client: {
    inTheCareOf: 'I/C',
    orderNumber: 'PO #',
    projectManager: 'Project Manager',
    requirement: 'Requirement',
    representative: 'Representative',
  },
  cost: {
    costLine_one: '{{ count }} cost line',
    costLine_other: '{{ count }} cost lines',
    overline: 'Costs',
    billingCode: 'Name of billing code',
    billable: 'Billable',
    crane: 'Crane',
    workForceType: 'Labor',
    noLine: 'No Line',
    quantity: 'Quantity',
    qty: 'Qty.',
    rate: 'Rate',
    rateUpdateReason: 'Rate Change Reason',
  },
  equipment: {
    addConfiguration: 'Add Configuration',
    boom: 'Boom',
    capacity: 'Capacity',
    configuration: 'Configuration',
    boomConfiguration: 'Boom Configuration',
    configurationOption: 'Configuration Option',
    counterWeight: 'Counterweight',
    craneConfiguration: 'Crane Configuration',
    jib: 'Jib',
    kind: 'Equipment Kind',
    maxWeight: 'Load Weight',
    model: 'Model',
    noReplacement: 'No Replacement',
    noSubcontractor: 'No Subcontractor',
    offset: 'Offset',
    otherInformation: 'Other Information',
    radius: 'Radius',
    type: 'Type',
    lifts: 'Lifts',
    configurationList: 'Configurations',
    craneSelectionMode: 'Crane Selection Mode',
    transport: {
      transportKind: 'Transport Type',
    },
  },
  project: {
    branch: {
      dispatchBranch: 'Dispatch Branch',
      overline: 'Branches',
      salesBranch: 'Sales Branch',
    },
    date: {
      arrival: 'Arrival Date',
      date: 'Date',
      departure: 'Departure Date',
      duration: 'Job Duration',
      durationPerDay: 'Duration per Day',
      isSecondServing: '2nd serving',
      includeWeekendsAndHolidays: 'Includes weekends and holidays',
      notes: 'Notes and additional information',
      forbidOvertime: 'No overtime',
      ready: 'Ready Date',
    },
    work: {
      isEstablishedSchedule: 'Established Schedule',
      natureOfWork: 'Nature of Work',
      natureOfWorkSubCategory: 'Category',
      overline: 'Work',
      workDescription: 'Work Description',
      workSchedule: 'Work Schedule',
    },
  },
  questions: {
    hasParticularSituation_one: '{{ count }} particular situation',
    hasParticularSituation_other: '{{ count }} particular situations',
    hasNoParticularSituation: 'No particular situation',
    worksiteVisited: 'Worksite visited',
    worksiteNotVisited: 'Worksite not visited',
  },
};
const details = {
  section: {
    accessories: 'Accessories',
    addenda: 'Addenda',
    client: 'Client',
    costLines: 'Cost lines',
    equipment: 'Equipment',
    project: 'Project',
    questions: 'Questions',
    selectedCrane: 'Selected crane',
    transport: 'Transport',
  },
  subsection: {
    branches: 'Branches',
    dateTime: 'Date & Time',
    otherInformation: 'Other Information',
    work: 'Work',
  },
};

const placeholder = {
  accessoryInfo: 'Select accessories (optional)',
  accessoryOutOfInventory: 'Write accessory',
  addAddenda: 'Add an addendum',
  addendaInfo: 'Enter addenda information (optional)',
  clientInfo: 'Enter client information',
  equipmentInfo: 'Enter equipment information',
  projectInfo: 'Enter project information',
  questionsInfo: 'Answer basic questions',
  costsInfo: 'Enter costs information',
  selectConfiguration: 'Select a configuration',
};

const status: Record<ServiceCallStatus, string> = {
  archived: 'Archived',
  canceled: 'Canceled',
  delayed: 'Delayed',
  dispatched: 'Dispatched',
  inWriting: 'In Writing',
  reserved: 'Reserved',
  inModification: 'In Modification',
  transferred: 'Transferred',
  locked: 'Locked',
};

const list = {
  column: {
    friendlyId: 'ID',
    client: 'Client',
    worksite: 'Worksite',
    kind: 'Type',
    crane: 'Crane',
    status: 'Status',
    date: 'Date',
    poNumber: 'PO #',
  },
};

export const serviceCall = {
  addenda: 'Addenda',
  alert: {
    costLinesAdded_one: '{{count}} cost line have been added',
    costLinesAdded_other: '{{count}} cost lines have been added',
  },
  ariaLabels: {
    main: 'Create operated crane (hourly) service call',
    more: 'Show more options',
    options: {
      accessoriesRental: 'Create accessories rental service call',
      bare: 'Create bare crane service call',
      bareWithOperators: 'Create bare crane with operators service call',
      laborRental: 'Create labor rental service call',
      laborRentalOnBare: 'Create labor rental on bare service call',
      laborRentalOnOperated: 'Create labor rental on operated service call',
      liftingPlan: 'Create lifting plan service call',
      liftingTest: 'Create lifting test service call',
      operatedHourly: 'Create operated crane (hourly) service call',
      operatedMonthly: 'Create operated crane (monthly) service call',
      rollingEquipment: 'Create rolling equipment service call',
      storage: 'Create storage service call',
    } satisfies Record<ServiceCallKind, string>,
  },
  button: {
    createLinkedServiceCall: 'Create Linked Call',
    saveLinked: 'Save And Link',
    linkServiceCall: 'Link Existing Call',
    unlink: 'Unlink',
  },
  costs: 'Costs',
  dateTime,
  details,
  dialog: {
    cancel: {
      title: 'Cancel Service Call',
      confirmButton: 'Cancel Call',
      body: 'Are you sure you want to cancel this service call? Any unsaved changes will be lost. This operation is irreversible.',
    },
    copy: {
      title: 'Copy Service Call',
      confirmButton: 'Copy Call',
    },
    delay: {
      title: 'Delay Service Call',
      confirmButton: 'Delay Call',
      body: 'Are you sure you want to delay this service call?',
    },
    transferPastDate: {
      title: 'Transfer Service Call',
      confirmButton: 'Transfer',
      body: 'The date of the service call is in the past. Are you sure you want to transfer it? If so, please inform dispatch, as it may not be processed.',
    },
    transfer: {
      allTransferred: 'Warning, all linked service calls will be sent to dispatch following the transfer.',
      allTransferredDateInPast:
        'Warning, the date of this service call is in the past. Please inform dispatch, as it may not be processed. All linked service calls will be sent to dispatch following the transfer.',
      confirmTransfer: 'Transfer Linked Calls',
      status: {
        transferable: 'This service call will be transferred',
        nonTransferable: 'This service call is not transferable and prevents the transfer',
        dateInPast: 'This service call has a date in the past. Please inform dispatch, as it may not be processed.',
        ignored: 'This service call will not be transferred',
      } satisfies Record<LinkedServiceCallTransferStatus, string>,
    },
    notLinkedServiceCalls: 'Available to be linked',
    alreadyLinkedServiceCalls: 'Already linked',
    unlinkCurrentServiceCall: 'Are you sure you want to unlink the current service call?',
    unlinkServiceCall: 'Are you sure you want to unlink this service call?',
    unlink: 'Unlink Service Call',
  },
  error: {
    cannotTransfer: 'Unable to transfer',
    clientIsRequired: 'Please select a client',
    errorDuringDelay: 'An error occurred while delaying',
    errorDuringLink: 'An error occurred while linking',
    errorDuringTransfer: 'An error occurred while transferring',
    errorDuringUnlink: 'An error occurred while unlinking',
    fieldCannotBeEmpty: 'This field cannot be empty',
    arrivalDateAfterReadyDate: 'The arrival date must be less than or equal to the ready date',
    valueBetweenMinAndMax: 'Value must be between {{ min }} and {{ max }}',
    worksiteIsRequired: 'Please select a worksite',
  },
  field,
  search: 'Search an Id, client or worksite...',
  info: 'Info',
  pages: {
    accessories: {
      title: 'Accessories',
    },
    addenda: {
      title: 'Addenda',
    },
    addendaAndAttachments: {
      title: 'Addenda and Attachments',
    },
    client: {
      title: 'Client Information',
      requirementDescriptionsTitle: 'Client requirements',
      subsection: {
        management: 'Management',
      },
    },
    costLine: {
      title: 'Cost Lines',
    },
    costs: {
      title: 'Cost Lines',
      subsection: {
        additionalCranes: 'Additional Cranes',
        mainCrane: 'Main Crane',
        emptyCraneConfiguration: 'No configuration selected',
      },
    },
    details: {
      copyTitle: 'Copy Service Call',
      copyTitleShort: 'Copy Call',
      linkTitle: 'New linked call',
      shortTitle: 'Call',
      title: 'Service Call',
    },
    equipment: {
      title: 'Equipment Information',
      crane: {
        title: 'Crane selector',
      },
      subsection: {
        craneSelector: 'Crane Selector',
      },
    },
    linked: {
      title: 'Linked Calls',
    },
    list: {
      tabs: {
        myClients: 'My Clients',
        myJobs: 'My Calls',
        myJobsShort: 'My Calls',
        myRegion: 'My Region',
      },
      title: 'Service Calls',
    },
    project: {
      title: 'Project Information',
      requirementDescriptionsTitle: 'Worksite requirements',
      subsection: {
        branches: 'Branches',
        dateAndTime: 'Dates and time',
        work: 'Work',
        worksite: 'Worksite',
      },
    },
    questions: {
      title: 'Questions',
    },
  },
  section: {
    accessories: 'Accessories',
    addenda: 'Addenda',
    addendaAndAttachments: 'Addendas and attachments',
    client: 'Client',
    costLines: 'Cost lines',
    costs: 'Costs',
    equipment: 'Equipment',
    linked: 'Service Calls',
    project: 'Project',
    questions: 'Questions',
    transport: 'Transport',
    calculator: {
      action: {
        apply: 'Apply',
      },
    },
  },
  kind: {
    accessoriesRental: 'Accessories Rental',
    bare: 'Bare Crane',
    bareWithOperators: 'Bare with Operators',
    laborRental: 'Labor Rental',
    laborRentalOnBare: 'Labor Rental On Bare',
    laborRentalOnOperated: 'Labor Rental On Operated',
    liftingPlan: 'Lifting Plan',
    liftingTest: 'Lifting Test',
    operatedHourly: 'Operated Crane (hourly)',
    operatedMonthly: 'Operated Crane (monthly)',
    rollingEquipment: 'Rolling Equipment',
    storage: 'Storage',
  } satisfies Record<ServiceCallKind, string>,
  kindShort: {
    accessoriesRental: 'Accessories Rental',
    bare: 'Bare',
    bareWithOperators: 'Bare with Operators',
    laborRental: 'Labor Rental',
    laborRentalOnBare: 'Labor on Bare',
    laborRentalOnOperated: 'Labor on Operated',
    liftingPlan: 'Lifting Plan',
    liftingTest: 'Lifting Test',
    operatedHourly: 'Operated (hourly)',
    operatedMonthly: 'Operated (monthly)',
    rollingEquipment: 'Rolling Equipment',
    storage: 'Storage',
  } satisfies Record<ServiceCallKind, string>,
  placeholder,
  status,
  list,
  transportKind: {
    crane: 'Fixed price crane transport',
    jibAndCounterweight: 'Jib and counterweight transport',
    mobilisationAndDemobilisation: 'Mobilisation and demobilisation',
  } satisfies Record<TransportKind, string>,
};
