/**
 * @generated SignedSource<<aeb629e6b74d4e6edec790aefbe7ffcb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddendumTemplateDetailsPageFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"AddendumTemplateFieldsKindFragment" | "AddendumTemplateFieldsNatureOfWorkCodesFragment" | "AddendumTemplateFieldsNatureOfWorkSubcategoryFragment" | "AddendumTemplateFieldsQuestionKindsFragment" | "AddendumTemplateFieldsSaleKindsFragment" | "AddendumTemplateFields_CapacitiesFragment" | "AddendumTemplateFields_EquipmentKindsFragment" | "AddendumTemplateFields_TemplateEnFragment" | "AddendumTemplateFields_TemplateFrFragment" | "AddendumTemplateFields_TitleEnFragment" | "AddendumTemplateFields_TitleFrFragment" | "AddendumTemplateFields_useFieldAddendumTemplateIsAutomaticFragment">;
  readonly " $fragmentType": "AddendumTemplateDetailsPageFragment";
};
export type AddendumTemplateDetailsPageFragment$key = {
  readonly " $data"?: AddendumTemplateDetailsPageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddendumTemplateDetailsPageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddendumTemplateDetailsPageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddendumTemplateFields_TitleFrFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddendumTemplateFields_TitleEnFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddendumTemplateFieldsKindFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddendumTemplateFields_TemplateFrFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddendumTemplateFields_TemplateEnFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddendumTemplateFields_useFieldAddendumTemplateIsAutomaticFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddendumTemplateFieldsSaleKindsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddendumTemplateFields_CapacitiesFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddendumTemplateFields_EquipmentKindsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddendumTemplateFieldsNatureOfWorkCodesFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddendumTemplateFieldsNatureOfWorkSubcategoryFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddendumTemplateFieldsQuestionKindsFragment"
    }
  ],
  "type": "AddendumTemplate",
  "abstractKey": null
};

(node as any).hash = "f4bcafbb70b0262fb54bf1461e1054ae";

export default node;
