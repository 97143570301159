import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { useConstantValue } from '../common/hooks/useConstantValue';
import { DateTime } from 'luxon';
import { WorkPlanningFilters_Representative, WorkPlanningResponsiveGridFilters_Representative } from './WorkPlanningFilters';
import { ArrivalDateFilter, JobStatusFilter, ProjectManagerFilter, TextSearchFilter, useJobFilters } from '../jobs/JobFilters';
import { workPlanningFiltersSharedStateKey } from './WorkPlanningList.Page';
import { WorkPlanningList_RepresentativeFragment$key } from './__generated__/WorkPlanningList_RepresentativeFragment.graphql';
import { ResponsiveGridFilters, ResponsiveGridForwardProps } from '../common/components/ResponsiveGrid';
import { Theme, useMediaQuery } from '@mui/material';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { discriminate, isDefined } from '../common/utils/typeUtils';
import { WorkPlanningStatusChip } from './WorkPlanningStatusChip';
import { DateRange } from '@mui/x-date-pickers-pro';
import { WorkPlanningList_Representative_FiltersFragment$key } from './__generated__/WorkPlanningList_Representative_FiltersFragment.graphql';
import { workPlanningStatuses } from '../__enums__/WorkPlanningStatus';
import { Outlet } from 'react-router';

export type WorkPlanningList_RepresentativeContextValue = {
  filters: WorkPlanningFilters_Representative;
  setFilters: Dispatch<SetStateAction<WorkPlanningFilters_Representative>>;
  listProps: ResponsiveGridForwardProps;
};

export function WorkPlanningList_Representative({
  $key,
  ...listProps
}: {
  $key: WorkPlanningList_RepresentativeFragment$key;
} & ResponsiveGridForwardProps) {
  const $data = useFragment(
    graphql`
      fragment WorkPlanningList_RepresentativeFragment on Employee {
        ...JobFilters_useJobFiltersFragment
      }
    `,
    $key,
  );

  const salesDefaultFilters = useConstantValue(() => {
    const today = DateTime.now().startOf('day');

    return WorkPlanningFilters_Representative.EMPTY.with({
      arrivalDate: { start: today, end: today.plus({ day: 90 }) },
      statuses: ['inWriting', 'worksiteSelected'],
    });
  });

  const [filters, setFilters] = useJobFilters<WorkPlanningFilters_Representative>(
    $data,
    workPlanningFiltersSharedStateKey,
    WorkPlanningFilters_Representative.EMPTY,
    salesDefaultFilters,
    (sp) => WorkPlanningFilters_Representative.fromSearchParams(sp),
  );

  const contextValue: WorkPlanningList_RepresentativeContextValue = {
    filters,
    setFilters,
    listProps,
  };

  return <Outlet context={contextValue} />;
}

export function WorkPlanningList_Representative_Filter({
  $key,
  filters,
  onFiltersChange: handleFiltersChange,
}: {
  $key: WorkPlanningList_Representative_FiltersFragment$key;
  filters: WorkPlanningFilters_Representative;
  onFiltersChange: (filters: WorkPlanningFilters_Representative) => void;
}) {
  const { t } = useAmbientTranslation();
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const $data = useFragment(
    graphql`
      fragment WorkPlanningList_Representative_FiltersFragment on Query @argumentDefinitions(projectManagerIds: { type: "[ID!]!" }) {
        projectManagers: nodes(ids: $projectManagerIds) {
          __typename
          ... on Representative {
            id
            label
            deletedAt
          }
        }
      }
    `,
    $key,
  );

  const responsiveGridFilters: WorkPlanningResponsiveGridFilters_Representative = useMemo(() => {
    // Adding the extra data required by the responsive grid filter components to the filters should only be done once,
    // since this data is only fetched once during list page load. So once the filters have been modified by the user,
    // this extra data might not be up-to-date anymore, and overwriting the filters with it might corrupt the filters.
    // This is safe since the autocompletes which require this data also put it back in the filters on modification.
    if (filters instanceof WorkPlanningResponsiveGridFilters_Representative) return filters;
    return filters.toResponsiveGridFilters({
      projectManagers: $data.projectManagers.filter(isDefined).filter(discriminate('__typename', 'Representative')),
    });
  }, [filters, $data]);

  return (
    <ResponsiveGridFilters<WorkPlanningResponsiveGridFilters_Representative>
      filters={responsiveGridFilters}
      emptyFilters={WorkPlanningResponsiveGridFilters_Representative.EMPTY}
      elements={(mode, state, setState) => [
        mode === 'inline' && (
          <TextSearchFilter
            key='fts'
            value={state.get('searchTerm')}
            placeHolder={t('search', { ns: 'serviceCall' })}
            onChange={(searchTerm) => setState((prev) => prev.with({ searchTerm }))}></TextSearchFilter>
        ),
        mode === 'dialog' && (
          <JobStatusFilter
            key='status'
            value={state.get('statuses')}
            options={workPlanningStatuses.filter((s) => s !== 'locked')}
            onChange={(statuses) => setState((prev) => prev.with({ statuses }))}
            label={t('list.column.status')}
            renderChip={(option, handleDelete) => <WorkPlanningStatusChip key={option} statuses={[option]} onDelete={handleDelete} />}
          />
        ),
        mode === 'dialog' && (
          <ProjectManagerFilter
            key='projectManager'
            value={state.get('projectManagers')}
            onChange={(projectManagers) => setState((prev) => prev.with({ projectManagers }))}
          />
        ),
        mode === 'dialog' && (
          <ArrivalDateFilter
            key='arrivalDate'
            value={state.get('arrivalDate')}
            onChange={([start, end]: DateRange<DateTime>) => {
              setState((prev) => prev.with({ arrivalDate: start?.isValid && end?.isValid ? { start, end } : null }));
            }}
          />
        ),
      ]}
      onFiltersChange={handleFiltersChange}
      compact={compact}
      sx={(theme) => ({ [theme.breakpoints.down('sm')]: { mx: '1rem' }, [theme.breakpoints.up('md')]: { justifyContent: 'flex-start' } })}
    />
  );
}
