import {
  getNodeById,
  ResponsiveGrid,
  ResponsiveGridForwardProps,
  ResponsiveGridProps,
  useElementFactory,
  useSkeletonFactory,
} from '../common/components/ResponsiveGrid';
import { DataID, useLazyLoadQuery, useRefetchableFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { useConstantValue } from '../common/hooks/useConstantValue';
import { WorkPlanningFilters_Representative } from './WorkPlanningFilters';
import { convertToTsQuery } from '../common/utils/stringUtils';
import { WorkPlanningList_Representative_FiltersFragment$key } from './__generated__/WorkPlanningList_Representative_FiltersFragment.graphql';
import { Theme, useMediaQuery } from '@mui/material';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { useCallback } from 'react';
import {
  getWorkPlanningListColumns,
  WorkPlanningList_Item,
  WorkPlanningList_ListSkeleton,
  WorkPlanningList_Row,
  WorkPlanningList_RowSkeleton,
  workPlanningListSx,
} from './WorkPlanningList';
import { useOutletContext } from 'react-router';
import { WorkPlanningList_Representative_Filter, WorkPlanningList_RepresentativeContextValue } from './WorkPlanningList.Representative';
import { useNavigatorOnline } from '../common/hooks/useNavigatorOnline';
import {
  WorkPlanningList_Representative_MyJobsQuery,
  WorkPlanningList_Representative_MyJobsQuery$variables,
} from './__generated__/WorkPlanningList_Representative_MyJobsQuery.graphql';
import { WorkPlanningList_Representative_MyJobs_ListContentFragment$key } from './__generated__/WorkPlanningList_Representative_MyJobs_ListContentFragment.graphql';
import { JobList_Representative_Tabs } from '../jobs/JobList.Representative.Tabs';

export function WorkPlanningList_Representative_MyJobs() {
  const { filters, setFilters: handleSetFilters, listProps } = useOutletContext<WorkPlanningList_RepresentativeContextValue>();
  const online = useNavigatorOnline();

  const queryFilters = useConstantValue<WorkPlanningList_Representative_MyJobsQuery$variables>(() => ({
    first: 25,
    where: filters.toJobRevisionFilter(),
    searchTerm: convertToTsQuery(filters.get('searchTerm'), '|'),
    projectManagerIds: filters.get('projectManagers').map(({ id }) => id),
  }));

  const workPlannings = useLazyLoadQuery<WorkPlanningList_Representative_MyJobsQuery>(
    graphql`
      query WorkPlanningList_Representative_MyJobsQuery(
        $first: Int
        $where: WorkPlanningJobRevisionFilterType
        $projectManagerIds: [ID!]!
        $searchTerm: String
      ) {
        ...WorkPlanningList_Representative_MyJobs_ListContentFragment @arguments(searchTerm: $searchTerm, first: $first, where: $where)
        ...WorkPlanningList_Representative_FiltersFragment @arguments(projectManagerIds: $projectManagerIds)
      }
    `,
    queryFilters,
    { fetchPolicy: online ? 'store-and-network' : 'store-only' }, // store-and-network to make sure the data is up to date following a details page modification
  );

  return (
    <ListContent {...listProps} $key={workPlannings} filters$key={workPlannings} filters={filters} onFiltersChange={handleSetFilters} />
  );
}

function ListContent({
  $key,
  filters$key,
  onItemClick,
  filters,
  onFiltersChange: handleFiltersChange,
  ...gridProps
}: {
  $key: WorkPlanningList_Representative_MyJobs_ListContentFragment$key;
  filters$key: WorkPlanningList_Representative_FiltersFragment$key;
  filters: WorkPlanningFilters_Representative;
  onFiltersChange: (filters: WorkPlanningFilters_Representative) => void;
} & ResponsiveGridForwardProps) {
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { t } = useAmbientTranslation();

  const [$data, refetch] = useRefetchableFragment<
    WorkPlanningList_Representative_MyJobsQuery,
    WorkPlanningList_Representative_MyJobs_ListContentFragment$key
  >(
    graphql`
      fragment WorkPlanningList_Representative_MyJobs_ListContentFragment on Query
      @refetchable(queryName: "WorkPlanningList_Representative_MyJobs_ListContentFragmentQuery")
      @argumentDefinitions(
        searchTerm: { type: "String" }
        after: { type: "String" }
        before: { type: "String" }
        first: { type: "Int" }
        last: { type: "Int" }
        where: { type: "WorkPlanningJobRevisionFilterType" }
      ) {
        searchMyWorkPlannings(
          searchTerm: $searchTerm
          after: $after
          before: $before
          first: $first
          last: $last
          where: $where
          order: [{ snapshot: { statusOrder: ASC } }, { snapshot: { projectBase: { arrivalDate: { date: ASC } } } }, { id: ASC }]
        ) @required(action: THROW) {
          ...ResponsiveGridFragment
          edges {
            node {
              id
              lifeCycleBranchId
              ...WorkPlanningList_RowFragment
              ...WorkPlanningList_ItemFragment
            }
          }
        }
        ...WorkPlanningList_Row_MeFragment
      }
    `,
    $key,
  );

  const edges = $data.searchMyWorkPlannings?.edges;

  const refetchFn = useCallback<ResponsiveGridProps['refetch']>(
    (vars, options) =>
      refetch(
        {
          ...vars,
          searchTerm: convertToTsQuery(filters.get('searchTerm'), '|'),
          where: filters.toJobRevisionFilter(),
        },
        options,
      ),
    [refetch, filters],
  );

  const columns = getWorkPlanningListColumns(t, compact);

  const rowElementFactory = useElementFactory(edges, (node, orderByColumns) => (
    <WorkPlanningList_Row $key={node} me$key={$data} orderByColumns={orderByColumns} />
  ));
  const listElementFactory = useElementFactory(edges, (node) => <WorkPlanningList_Item $key={node} />);
  const rowSkeletonFactory = useSkeletonFactory(() => <WorkPlanningList_RowSkeleton columns={columns} />);
  const listSkeletonFactory = useSkeletonFactory(() => <WorkPlanningList_ListSkeleton />);

  return (
    $data.searchMyWorkPlannings && (
      <>
        <WorkPlanningList_Representative_Filter $key={filters$key} filters={filters} onFiltersChange={handleFiltersChange} />
        <JobList_Representative_Tabs tab='my-jobs' />
        <ResponsiveGrid
          connectionFragmentKey={$data.searchMyWorkPlannings}
          refetch={refetchFn}
          columnDefinitions={columns}
          rowElementFactory={rowElementFactory}
          listElementFactory={listElementFactory}
          rowSkeletonFactory={rowSkeletonFactory}
          listSkeletonFactory={listSkeletonFactory}
          listSx={workPlanningListSx}
          onItemClick={(id: DataID) => onItemClick?.(getNodeById(id, edges).lifeCycleBranchId)}
          {...gridProps}
        />
      </>
    )
  );
}
