import { Suspense, useLayoutEffect } from 'react';
import { createSearchParams, useMatch, useNavigate } from 'react-router';
import { Skeleton, Stack, Theme, useMediaQuery } from '@mui/material';
import { namedPageTitle, usePageTitle } from '../common/hooks/usePageTitle';
import { ServiceCallList_Regular } from './ServiceCallList.Regular';
import graphql from 'babel-plugin-relay/macro';
import { DataID, useLazyLoadQuery } from 'react-relay';
import { EmptyLayout, ListLayout, ListLayoutActions, SidebarContentProps } from '../layout/Layouts';
import { ServiceCallKind } from '../__enums__/ServiceCallKind';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { RequireRead, RequireWrite, UnauthorizedFallback } from '../auth/Authorization';
import { NavigationMenu } from '../layout/SidebarDrawer';
import { EmployeeNotFoundError, EmployeeNotFoundErrorBoundary } from '../auth/EmployeeNotFoundErrorBoundary';
import { ListPageErrorBoundary } from '../layout/ListPageErrorBoundary';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { ListPageRootErrorBoundary } from '../layout/ListPageRootErrorBoundary';
import { useJobFiltersSearchParams } from '../jobs/JobFilters';
import {
  Assignment,
  AssignmentLate,
  CalendarToday,
  EngineeringOutlined,
  HdrAuto,
  Person,
  PersonAddAlt,
  PersonAddAlt1,
  PersonOutline,
  Schedule,
  Warehouse,
} from '@mui/icons-material';
import { createSharedStateKey } from '../common/utils/sharedState';
import { ServiceCallFilters_Regular } from './ServiceCallFilters';
import { ServiceCallList_Representative } from './ServiceCallList.Representative';
import { ServiceCallList_Page_RootQuery } from './__generated__/ServiceCallList_Page_RootQuery.graphql';
import { useShowRepresentativeList } from '../jobs/useShowRepresentativeList';

const flagName = 'app_navigation_service_calls';

export function ServiceCallList_Page() {
  const { t } = useAmbientTranslation();

  return (
    <ListPageErrorBoundary heading={t('pages.list.title')} flagName={flagName}>
      <EmployeeNotFoundErrorBoundary>
        <Suspense fallback={<ListPageSkeleton />}>
          <ListPageRoot />
        </Suspense>
      </EmployeeNotFoundErrorBoundary>
    </ListPageErrorBoundary>
  );
}

function ListPageSkeleton() {
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return <EmptyLayout>{compact ? <ListSkeleton /> : <GridSkeleton />}</EmptyLayout>;
}

function ListPageRoot() {
  const $data = useLazyLoadQuery<ServiceCallList_Page_RootQuery>(
    graphql`
      query ServiceCallList_Page_RootQuery {
        ...AuthorizationReadFragment
        ...AuthorizationWriteFragment
        ...SidebarDrawerFragment
        ...LayoutsListLayoutFragment
        ...ListPageRootErrorBoundaryFragment
        me {
          ...useShowRepresentativeListFragment
          ...ServiceCallList_RegularFragment
          ...ServiceCallList_RepresentativeFragment
        }
      }
    `,
    {},
  );

  if (!$data.me) throw new EmployeeNotFoundError();

  usePageTitle(namedPageTitle('sidebar.serviceCalls'));
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { t } = useAmbientTranslation();
  const navigate = useNavigate();
  const sidebar = (props: SidebarContentProps) => <NavigationMenu {...props} $key={$data} />;
  const handleItemClick = (id: DataID) => navigate(`/service-calls/${id}`);
  const paginationSx = (theme: Theme) => ({ [theme.breakpoints.down('sm')]: { mb: '6rem' } });
  const heading = t('pages.list.title');
  const showRepresentativeList = useShowRepresentativeList($data.me);
  const searchParams = useJobFiltersSearchParams(serviceCallFiltersSharedStateKey);
  const routeMatch = useMatch('*');

  useLayoutEffect(() => {
    if (!routeMatch?.pathname) return;

    const basePath = '/service-calls';

    if (showRepresentativeList && routeMatch.pathname === basePath) {
      navigate(`${basePath}/my-jobs${searchParams}`);
      return;
    }
    if (
      !showRepresentativeList &&
      (routeMatch.pathname === `${basePath}/my-jobs` ||
        routeMatch.pathname === `${basePath}/my-regions` ||
        routeMatch.pathname === `${basePath}/my-clients`)
    ) {
      navigate(basePath);
    }
  }, [navigate, routeMatch?.pathname, searchParams, showRepresentativeList]);

  return (
    <ListPageRootErrorBoundary $key={$data} heading={heading} flagName={flagName}>
      <RequireRead
        $key={$data}
        fallback={
          <ListLayout heading={heading} flagName={flagName} sidebarProvider={sidebar} $key={$data}>
            <UnauthorizedFallback />
          </ListLayout>
        }>
        <ListLayout
          heading={heading}
          flagName={flagName}
          sidebarProvider={sidebar}
          $key={$data}
          actions={
            <RequireWrite $key={$data}>
              <ServiceCallListLayoutActions
                onItemClick={(kind: ServiceCallKind) =>
                  navigate({
                    pathname: '/service-calls/new',
                    search: createSearchParams({ kind }).toString(),
                  })
                }
              />
            </RequireWrite>
          }>
          <Suspense fallback={compact ? <ListSkeleton /> : <GridSkeleton />}>
            {showRepresentativeList ? (
              <ServiceCallList_Representative
                $key={$data.me}
                write$key={$data}
                compact={compact}
                onItemClick={handleItemClick}
                paginationSx={paginationSx}
              />
            ) : (
              <ServiceCallList_Regular
                $key={$data.me}
                write$key={$data}
                compact={compact}
                onItemClick={handleItemClick}
                paginationSx={paginationSx}
              />
            )}
          </Suspense>
        </ListLayout>
      </RequireRead>
    </ListPageRootErrorBoundary>
  );
}

export const serviceCallFiltersSharedStateKey = createSharedStateKey<ServiceCallFilters_Regular | null>(() => null);

export function useServiceCallListPageUrl() {
  const searchParams = useJobFiltersSearchParams(serviceCallFiltersSharedStateKey);
  return `/service-calls${searchParams}`;
}

function GridSkeleton() {
  return (
    <Stack gap='1rem'>
      <Skeleton variant='rounded' height='3rem' />
      <Skeleton variant='rounded' height='40rem' />
    </Stack>
  );
}

function ListSkeleton() {
  return (
    <Stack gap='1rem'>
      <Skeleton variant='rounded' height='3rem' />
      <Stack gap={0.5}>
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
      </Stack>
    </Stack>
  );
}

export function ServiceCallListLayoutActions({ onItemClick: handleItemClick }: { onItemClick: (kind: ServiceCallKind) => void }) {
  const { t } = useAmbientTranslation();
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <ListLayoutActions
      mainAction={{
        label: t('kind.operatedHourly'),
        icon: <Schedule />,
        onClick: () => handleItemClick('operatedHourly'),
      }}
      otherActions={{
        operatedMonthly: {
          label: t('kind.operatedMonthly'),
          icon: <CalendarToday />,
          onClick: () => handleItemClick('operatedMonthly'),
        },
        bare: {
          label: t('kind.bare'),
          icon: <PersonOutline />,
          onClick: () => handleItemClick('bare'),
        },
        bareWithOperators: {
          label: compact ? t('kindShort.bareWithOperators') : t('kind.bareWithOperators'),
          icon: <EngineeringOutlined />,
          onClick: () => handleItemClick('bareWithOperators'),
        },
        accessoriesRental: {
          label: t('kind.accessoriesRental'),
          icon: <HdrAuto />,
          onClick: () => handleItemClick('accessoriesRental'),
        },
        laborRental: {
          label: t('kind.laborRental'),
          icon: <Person />,
          onClick: () => handleItemClick('laborRental'),
        },
        laborRentalOnOperated: {
          label: compact ? t('kindShort.laborRentalOnOperated') : t('kind.laborRentalOnOperated'),
          icon: <PersonAddAlt1 />,
          onClick: () => handleItemClick('laborRentalOnOperated'),
        },
        laborRentalOnBare: {
          label: compact ? t('kindShort.laborRentalOnBare') : t('kind.laborRentalOnBare'),
          icon: <PersonAddAlt />,
          onClick: () => handleItemClick('laborRentalOnBare'),
        },
        rollingEquipment: {
          label: t('kind.rollingEquipment'),
          icon: <LocalShippingIcon />,
          onClick: () => handleItemClick('rollingEquipment'),
        },
        liftingPlan: {
          label: t('kind.liftingPlan'),
          icon: <Assignment />,
          onClick: () => handleItemClick('liftingPlan'),
        },
        liftingTest: {
          label: t('kind.liftingTest'),
          icon: <AssignmentLate />,
          onClick: () => handleItemClick('liftingTest'),
        },
        storage: {
          label: t('kind.storage'),
          icon: <Warehouse />,
          onClick: () => handleItemClick('storage'),
        },
      }}
      componentProps={{
        autoSplitButton: {
          root: { 'aria-label': t('ariaLabels.root') },
          buttonMain: { 'aria-label': t('ariaLabels.main') },
          buttonMenu: { 'aria-label': t('ariaLabels.more') },
          menuItemOption: ([, a]) => ({ 'aria-label': a.label }),
        },
        autoSpeedDial: {
          root: {
            'aria-label': 'arialLabels.more',
            ariaLabel: t('ariaLabels.more'),
          },
          speedDialActionOption: ([, a]) => ({ 'aria-label': a.label }),
          dialog: {
            title: t('selectKind', { ns: 'jobs' }),
          },
        },
      }}
    />
  );
}
