/**
 * @generated SignedSource<<ea91470ccd8cf6903b30dca393209384>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as representativeLabelResolverType } from "../../__resolvers__/RepresentativeLabelResolver";
// Type assertion validating that `representativeLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(representativeLabelResolverType satisfies (
  rootKey: RepresentativeLabelResolver$key,
) => string | null | undefined);
export type WorkPlanningList_Representative_FiltersFragment$data = {
  readonly projectManagers: ReadonlyArray<{
    readonly __typename: "Representative";
    readonly deletedAt: string | null | undefined;
    readonly id: string;
    readonly label: string | null | undefined;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined>;
  readonly " $fragmentType": "WorkPlanningList_Representative_FiltersFragment";
};
export type WorkPlanningList_Representative_FiltersFragment$key = {
  readonly " $data"?: WorkPlanningList_Representative_FiltersFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkPlanningList_Representative_FiltersFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectManagerIds"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkPlanningList_Representative_FiltersFragment",
  "selections": [
    {
      "alias": "projectManagers",
      "args": [
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "projectManagerIds"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "fragment": {
                "args": null,
                "kind": "FragmentSpread",
                "name": "RepresentativeLabelResolver"
              },
              "kind": "RelayResolver",
              "name": "label",
              "resolverModule": require('./../../__resolvers__/RepresentativeLabelResolver').label,
              "path": "projectManagers.label"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "deletedAt",
              "storageKey": null
            }
          ],
          "type": "Representative",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "aaa521eb44eba05297fff0ad545ebd36";

export default node;
