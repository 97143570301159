/**
 * @generated SignedSource<<3b81b156b90d729511aa3241eca10f73>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TabCompetitorsQuery$variables = Record<PropertyKey, never>;
export type TabCompetitorsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TabCompetitors_ListFragment">;
};
export type TabCompetitorsQuery = {
  response: TabCompetitorsQuery$data;
  variables: TabCompetitorsQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TabCompetitorsQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "TabCompetitors_ListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TabCompetitorsQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "order",
            "value": {
              "name": "ASC"
            }
          }
        ],
        "concreteType": "Competitor",
        "kind": "LinkedField",
        "name": "competitors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": "competitors(order:{\"name\":\"ASC\"})"
      }
    ]
  },
  "params": {
    "cacheID": "48e14f46cb722877b4cf0be7924a6dbb",
    "id": null,
    "metadata": {},
    "name": "TabCompetitorsQuery",
    "operationKind": "query",
    "text": "query TabCompetitorsQuery {\n  ...TabCompetitors_ListFragment\n}\n\nfragment TabCompetitors_ListFragment on Query {\n  ...TabCompetitors_useFieldCompetitorsCollectionFragment\n}\n\nfragment TabCompetitors_useFieldCompetitorsCollectionFragment on Query {\n  competitors(order: {name: ASC}) {\n    id\n    name\n  }\n}\n"
  }
};

(node as any).hash = "85ff49a16d7f26cacf6ecc09f1e3f6aa";

export default node;
