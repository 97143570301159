/**
 * @generated SignedSource<<cd436bc649a19c916d1babd20bee5b57>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useSuggestionsFragment$data = {
  readonly client: {
    readonly " $fragmentSpreads": FragmentRefs<"SaleClientFields_InTheCareOfFragment">;
  };
  readonly clientBase: {
    readonly assignedClient: {
      readonly requirements: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"ClientBaseFields_RequirementsFragment">;
      }>;
    } | null | undefined;
    readonly assignedClientInfo: {
      readonly requirements: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"ClientBaseFields_RequirementsFragment">;
      }>;
    };
    readonly " $fragmentSpreads": FragmentRefs<"ClientBaseFields_RepresentativeFragment" | "ClientBaseFields_useFieldAssignedClientFragment">;
  };
  readonly costsBase: {
    readonly " $fragmentSpreads": FragmentRefs<"AccessoryLinesFields_AccessoryLineCollectionFragment">;
  };
  readonly equipmentBase: {
    readonly craneSelector: {
      readonly " $fragmentSpreads": FragmentRefs<"JobEquipment_useCraneSelectorFavoriteFragment">;
    };
  };
  readonly project: {
    readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_DispatchBranchFragment" | "SaleProjectFields_IsSecondServingFragment" | "SaleProjectFields_NatureOfWorkFragment" | "SaleProjectFields_NatureOfWorkSubCategoryFragment" | "SaleProjectFields_SalesBranchFragment" | "SaleProjectFields_WorkDescriptionFragment">;
  };
  readonly projectBase: {
    readonly assignedWorksite: {
      readonly requirements: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_RequirementsFragment">;
      }>;
    } | null | undefined;
    readonly assignedWorksiteInfo: {
      readonly requirements: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_RequirementsFragment">;
      }>;
    };
    readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_AssignedWorksiteFragment" | "ProjectBaseFields_DurationHoursPerDayFragment" | "ProjectBaseFields_DurationLengthInDaysFragment">;
  };
  readonly " $fragmentType": "useSuggestionsFragment";
};
export type useSuggestionsFragment$key = {
  readonly " $data"?: useSuggestionsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useSuggestionsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Requirement",
    "kind": "LinkedField",
    "name": "requirements",
    "plural": true,
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ClientBaseFields_RequirementsFragment"
      }
    ],
    "storageKey": null
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Requirement",
    "kind": "LinkedField",
    "name": "requirements",
    "plural": true,
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ProjectBaseFields_RequirementsFragment"
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "skipAccessories"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useSuggestionsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientInternalBase",
      "kind": "LinkedField",
      "name": "clientBase",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClientBaseFields_useFieldAssignedClientFragment"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Client",
          "kind": "LinkedField",
          "name": "assignedClient",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ClientInfoInternal",
          "kind": "LinkedField",
          "name": "assignedClientInfo",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClientBaseFields_RepresentativeFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "client",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleClientFields_InTheCareOfFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectInternalBase",
      "kind": "LinkedField",
      "name": "projectBase",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectBaseFields_DurationHoursPerDayFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectBaseFields_DurationLengthInDaysFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectBaseFields_AssignedWorksiteFragment"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Worksite",
          "kind": "LinkedField",
          "name": "assignedWorksite",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "WorksiteInfoInternal",
          "kind": "LinkedField",
          "name": "assignedWorksiteInfo",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleProjectFields_DispatchBranchFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleProjectFields_IsSecondServingFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleProjectFields_NatureOfWorkFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleProjectFields_NatureOfWorkSubCategoryFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleProjectFields_SalesBranchFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleProjectFields_WorkDescriptionFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquipmentInternalBase",
      "kind": "LinkedField",
      "name": "equipmentBase",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CraneSelectorInternal",
          "kind": "LinkedField",
          "name": "craneSelector",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "JobEquipment_useCraneSelectorFavoriteFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CostsInternalBase",
      "kind": "LinkedField",
      "name": "costsBase",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "skipAccessories",
              "variableName": "skipAccessories"
            }
          ],
          "kind": "FragmentSpread",
          "name": "AccessoryLinesFields_AccessoryLineCollectionFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ISale",
  "abstractKey": "__isISale"
};
})();

(node as any).hash = "846bc579545368d03a28fe056715e33a";

export default node;
