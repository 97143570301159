import SendIcon from '@mui/icons-material/Send';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Tooltip } from '@mui/material';
import { JobStage } from './jobStage';
import { useTranslation } from 'react-i18next';
import { FieldInputLabel } from '../common/components/FieldInputLabel';
import { _never } from '../common/utils/_never';
import { createContext, ReactNode, useContext, useMemo } from 'react';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';

export type RequiredForStatus = 'transfer' | 'submit' | 'reserve';

/**
 * Intended to be used in the `label` prop of required fields to display the label and the icons where the field is required for
 * @param label
 * @param requiredFor record of jobStages with a list of icons to indicate when an element is required
 * @param disabled
 */
export function RequiredForInputLabel({
  label,
  requiredFor,
  disabled,
}: {
  label: string;
  requiredFor: Partial<Record<JobStage, RequiredForStatus[]>>;
  disabled: boolean;
}) {
  return <FieldInputLabel label={label} adornment={<RequiredForIcons requiredFor={requiredFor} disabled={disabled} />} />;
}

/**
 * Displays icons indicating the element is required to transition to the jobStage.
 * @param requiredFor record of jobStages with a list of icons to indicate when an element is required
 * @param disabled
 */
export function RequiredForIcons({
  requiredFor,
  disabled,
}: {
  requiredFor: Partial<Record<JobStage, RequiredForStatus[]>>;
  disabled: boolean;
}) {
  const { jobStage } = useRequiredForContext();
  return requiredFor[jobStage]?.map((i) => <RequiredForIcon key={i} jobStage={jobStage} icon={i} disabled={disabled} />);
}

function RequiredForIcon({ jobStage, icon, disabled }: { jobStage: JobStage; icon: RequiredForStatus; disabled: boolean }) {
  const { t } = useTranslation('jobs');
  const title = t(`requiredFor.${jobStage}.${icon}`);
  const enterDelay = 500;

  switch (icon) {
    case 'transfer':
      return (
        <Tooltip title={title} enterDelay={enterDelay}>
          <SendIcon color={disabled ? 'disabled' : 'done'} sx={{ fontSize: '1.25em' }} />
        </Tooltip>
      );
    case 'submit':
      return (
        <Tooltip title={title} enterDelay={enterDelay}>
          <ScheduleSendIcon color={disabled ? 'disabled' : 'advanced'} sx={{ fontSize: '1.25em' }} />
        </Tooltip>
      );
    case 'reserve':
      return (
        <Tooltip title={title} enterDelay={enterDelay}>
          <CheckCircleIcon color={disabled ? 'disabled' : 'advanced'} sx={{ fontSize: '1.25em' }} />
        </Tooltip>
      );
    default:
      _never(icon);
  }
}

const RequiredForContext = createContext<
  | {
      jobStage: JobStage;
    }
  | undefined
>(undefined);

export function RequiredForProvider({ children, jobStage }: { children: ReactNode; jobStage: JobStage }) {
  const value = useMemo(() => ({ jobStage }), [jobStage]);

  return <RequiredForContext.Provider value={value}>{children}</RequiredForContext.Provider>;
}

function useRequiredForContext() {
  const context = useContext(RequiredForContext);
  if (!context) {
    throw new Error('useRequiredForContext must be used within a RequiredForProvider');
  }
  return context;
}
