import { useOutletContext } from 'react-router';
import { DataID, useLazyLoadQuery, useRefetchableFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { useConstantValue } from '../common/hooks/useConstantValue';
import { ServiceCallFilters_Representative } from './ServiceCallFilters';
import { convertToTsQuery } from '../common/utils/stringUtils';
import { ServiceCallList_Representative_FiltersFragment$key } from './__generated__/ServiceCallList_Representative_FiltersFragment.graphql';
import { AuthorizationWriteFragment$key } from '../auth/__generated__/AuthorizationWriteFragment.graphql';
import {
  getNodeById,
  ResponsiveGrid,
  ResponsiveGridForwardProps,
  ResponsiveGridImperativeHandle,
  ResponsiveGridProps,
  useElementFactory,
  useSkeletonFactory,
} from '../common/components/ResponsiveGrid';
import { useCallback, useRef } from 'react';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { Theme, useMediaQuery } from '@mui/material';
import {
  getServiceCallListColumns,
  ServiceCallList_Item,
  ServiceCallList_ListSkeleton,
  ServiceCallList_Row,
  ServiceCallList_RowSkeleton,
  serviceCallListSx,
} from './ServiceCallList';
import { useEvent } from '../common/utils/effectUtils';
import { ServiceCallList_Representative_Filters, ServiceCallList_RepresentativeContextValue } from './ServiceCallList.Representative';
import {
  ServiceCallList_Representative_MyClientsQuery,
  ServiceCallList_Representative_MyClientsQuery$variables,
} from './__generated__/ServiceCallList_Representative_MyClientsQuery.graphql';
import { ServiceCallList_Representative_MyClients_ListContentFragment$key } from './__generated__/ServiceCallList_Representative_MyClients_ListContentFragment.graphql';
import { JobList_Representative_Tabs } from '../jobs/JobList.Representative.Tabs';

export function ServiceCallList_Representative_MyClients() {
  const { write$key, filters, setFilters: handleSetFilters, listProps } = useOutletContext<ServiceCallList_RepresentativeContextValue>();

  const queryFilters = useConstantValue<ServiceCallList_Representative_MyClientsQuery$variables>(() => ({
    first: 25,
    where: filters.toJobRevisionFilter(),
    searchTerm: convertToTsQuery(filters.get('searchTerm'), '|'),
    projectManagerIds: filters.get('projectManagers').map(({ id }) => id),
    dispatchBranchIds: filters.get('dispatchBranches').map(({ id }) => id),
    equipmentKindCodes: filters.get('equipmentKinds').map(({ code }) => code),
  }));

  const serviceCalls = useLazyLoadQuery<ServiceCallList_Representative_MyClientsQuery>(
    graphql`
      query ServiceCallList_Representative_MyClientsQuery(
        $searchTerm: String
        $first: Int
        $where: ServiceCallJobRevisionFilterType
        $dispatchBranchIds: [ID!]!
        $equipmentKindCodes: [Int!]!
        $projectManagerIds: [ID!]!
      ) {
        ...ServiceCallList_Representative_MyClients_ListContentFragment @arguments(searchTerm: $searchTerm, first: $first, where: $where)
        ...ServiceCallList_Representative_FiltersFragment
          @arguments(dispatchBranchIds: $dispatchBranchIds, equipmentKindCodes: $equipmentKindCodes, projectManagerIds: $projectManagerIds)
      }
    `,
    queryFilters,
    { fetchPolicy: 'store-and-network' }, // store-and-network to make sure the data is up to date following a details page modification
  );

  return (
    <ListContent
      {...listProps}
      $key={serviceCalls}
      write$key={write$key}
      filters$key={serviceCalls}
      filters={filters}
      onFiltersChange={handleSetFilters}
    />
  );
}

function ListContent({
  $key,
  filters$key,
  write$key,
  filters,
  onFiltersChange: handleFiltersChange,
  onItemClick,
  ...gridProps
}: {
  $key: ServiceCallList_Representative_MyClients_ListContentFragment$key;
  filters$key: ServiceCallList_Representative_FiltersFragment$key;
  write$key: AuthorizationWriteFragment$key;
  filters: ServiceCallFilters_Representative;
  onFiltersChange: (filters: ServiceCallFilters_Representative) => void;
} & ResponsiveGridForwardProps) {
  const gridRef = useRef<ResponsiveGridImperativeHandle | null>(null);

  const { t } = useAmbientTranslation();
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const [$data, refetch] = useRefetchableFragment<
    ServiceCallList_Representative_MyClientsQuery,
    ServiceCallList_Representative_MyClients_ListContentFragment$key
  >(
    graphql`
      fragment ServiceCallList_Representative_MyClients_ListContentFragment on Query
      @refetchable(queryName: "ServiceCallList_Representative_MyClients_ListContentFragmentQuery")
      @argumentDefinitions(
        searchTerm: { type: "String" }
        after: { type: "String" }
        before: { type: "String" }
        first: { type: "Int" }
        last: { type: "Int" }
        where: { type: "ServiceCallJobRevisionFilterType" }
      ) {
        searchServiceCallsWithMyClients(
          searchTerm: $searchTerm
          after: $after
          before: $before
          first: $first
          last: $last
          where: $where
          order: [
            { snapshot: { statusOrder: ASC } }
            { snapshot: { projectBase: { arrivalDate: { date: ASC } } } }
            { snapshot: { equipmentBase: { craneSelector: { favoriteConfiguration: { capacity: DESC } } } } }
            { id: ASC }
          ]
        ) @required(action: THROW) {
          ...ResponsiveGridFragment
          edges {
            node {
              id
              lifeCycleBranchId
              ...ServiceCallList_RowFragment
              ...ServiceCallList_ItemFragment
            }
          }
        }
      }
    `,
    $key,
  );

  const edges = $data.searchServiceCallsWithMyClients?.edges;

  const refetchFn = useCallback<ResponsiveGridProps['refetch']>(
    (vars, options) =>
      refetch(
        {
          ...vars,
          searchTerm: convertToTsQuery(filters.get('searchTerm'), '|'),
          where: filters.toJobRevisionFilter(),
        },
        options,
      ),
    [refetch, filters],
  );

  const columns = getServiceCallListColumns(t, compact);

  const refreshServiceCallList = useEvent(() => gridRef.current?.goToFirstPage());

  const rowElementFactory = useElementFactory(edges, (node, orderByColumns) => (
    <ServiceCallList_Row
      $key={node}
      write$key={write$key}
      orderByColumns={orderByColumns}
      refreshServiceCallList={refreshServiceCallList}
    />
  ));
  const listElementFactory = useElementFactory(edges, (node) => <ServiceCallList_Item $key={node} />);
  const rowSkeletonFactory = useSkeletonFactory(() => <ServiceCallList_RowSkeleton columns={columns} />);
  const listSkeletonFactory = useSkeletonFactory(() => <ServiceCallList_ListSkeleton />);

  return (
    $data.searchServiceCallsWithMyClients && (
      <>
        <ServiceCallList_Representative_Filters $key={filters$key} filters={filters} onFiltersChange={handleFiltersChange} />
        <JobList_Representative_Tabs tab='my-clients' />
        <ResponsiveGrid
          ref={gridRef}
          connectionFragmentKey={$data.searchServiceCallsWithMyClients}
          refetch={refetchFn}
          columnDefinitions={columns}
          rowElementFactory={rowElementFactory}
          listElementFactory={listElementFactory}
          rowSkeletonFactory={rowSkeletonFactory}
          listSkeletonFactory={listSkeletonFactory}
          listSx={serviceCallListSx}
          onItemClick={(id: DataID) => onItemClick?.(getNodeById(id, edges).lifeCycleBranchId)}
          {...gridProps}
        />
      </>
    )
  );
}
