import { Button, IconButton, Theme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmationDialog } from '../dialogs/ConfirmationDialog';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/system';

export function ItemDeleteButton({
  listMode,
  onClick: handleClick,
  showDialog = true,
  disabled,
  sx,
  'data-label-key': dataLabelKey,
}: {
  listMode: boolean;
  onClick: () => void;
  showDialog?: boolean;
  disabled: boolean;
  sx?: SxProps<Theme>;
  'data-label-key'?: string;
}) {
  const { t } = useTranslation('common');
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      {listMode ? (
        <IconButton
          className='delete-button'
          data-label-key={dataLabelKey}
          sx={([() => ({ mx: '0.2rem' }), sx ?? null] satisfies SxProps<Theme>[]).flat()}
          onClick={() => (showDialog ? setDialogOpen(true) : handleClick())}
          disabled={disabled}>
          <DeleteIcon color={disabled ? 'disabled' : 'error'} />
        </IconButton>
      ) : (
        <Button
          onClick={() => setDialogOpen(true)}
          disabled={disabled}
          variant='contained'
          size='toolbar'
          color='error'
          sx={(
            [
              (theme) => ({
                flexShrink: 0,
                mr: '-0.5rem',
                '&.Mui-disabled': {
                  backgroundColor: theme.palette.grey[300],
                },
              }),
              sx ?? null,
            ] satisfies SxProps<Theme>[]
          ).flat()}>
          <DeleteIcon />
        </Button>
      )}
      {showDialog && (
        <ConfirmationDialog
          isOpen={dialogOpen}
          title={t('dialog.deleteTitle')}
          message={t('dialog.deleteConfirmation')}
          confirmText={t('button.delete')}
          confirmColor='secondary'
          onConfirm={() => handleClick()}
          onCancel={() => setDialogOpen(false)}
        />
      )}
    </>
  );
}
