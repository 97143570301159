import { useNavigate } from 'react-router';
import { Tab, Tabs, Theme, useMediaQuery } from '@mui/material';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';

export function JobList_Representative_Tabs({ tab }: { tab: 'my-jobs' | 'my-regions' | 'my-clients' }) {
  const { t } = useAmbientTranslation();
  const navigate = useNavigate();
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Tabs value={tab} onChange={(_, newValue) => navigate(`../${newValue}`)} sx={{ mb: '1rem' }}>
      <Tab value='my-jobs' label={t(`pages.list.tabs.${compact ? 'myJobsShort' : 'myJobs'}`)} />
      <Tab value='my-regions' label={t('pages.list.tabs.myRegion')} />
      <Tab value='my-clients' label={t('pages.list.tabs.myClients')} />
    </Tabs>
  );
}
