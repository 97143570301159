import { Tab, Tabs } from '@mui/material';
import { SyntheticEvent } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

interface Props {
  tab: 'addenda' | 'billing-codes' | 'competitors' | 'enums' | 'natures-of-work' | 'rate-strategies';
}

export function ConfigurationTabs({ tab }: Props) {
  const { t } = useTranslation('configuration');
  const navigate = useNavigate();

  const handleChange = (_: SyntheticEvent, newValue: string) => {
    navigate(`../${newValue}`);
  };

  return (
    <Tabs value={tab} onChange={handleChange} sx={{ mb: '1rem' }}>
      <Tab value='addenda' label={t('section.addenda')} />
      <Tab value='billing-codes' label={t('section.billingCodes')} />
      <Tab value='competitors' label={t('section.competitors')} />
      <Tab value='natures-of-work' label={t('section.naturesOfWork')} />
      <Tab value='rate-strategies' label={t('section.rateStrategies')} />
    </Tabs>
  );
}
