import {
  createFieldKey,
  createFormContext,
  FormProvider,
  SetValueFn,
  useField,
  useFieldErrors,
  useFieldMapper,
  useFieldValidation,
} from '../../common/utils/forms';
import { useCallback, useEffect } from 'react';
import { Box, IconButton, TextField } from '@mui/material';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { CostBillingCodeAutocomplete, ForwardCostBillingCodeAutocompleteProps } from '../../common/components/CostBillingCodeAutocomplete';
import {
  billingCodeRuleAutomaticSubFormContext,
  FieldAutomaticBillingCode,
  FieldAutomaticBillingCodeWorkForceType,
  useAutomaticBillingCodeWorkForceType,
  useFieldAutomaticBillingCode,
} from './BillingCodeRuleAutomaticSubForm';
import { nanoid } from 'nanoid';
import { ResponsiveGridContent, ResponsiveGridHeader, ResponsiveGridItem } from '../../common/components/ResponsiveGrid';
import { arrSetBy } from '../../common/utils/patchable';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import DeleteIcon from '@mui/icons-material/Delete';
import { castNatureOfWorkSubCategoryEnum } from '../../__enums__/NatureOfWorkSubCategoryEnum';
import { castSalesQuestionKind } from '../../__enums__/SalesQuestionKind';
import { isQuoteKind } from '../../__enums__/QuoteKind';
import { isServiceCallKind } from '../../__enums__/ServiceCallKind';
import { BillingCodeRuleFields_DescriptionFragment$key } from './__generated__/BillingCodeRuleFields_DescriptionFragment.graphql';
import { BillingCodeRuleFields_BillingCodeCollectionFragment$key } from './__generated__/BillingCodeRuleFields_BillingCodeCollectionFragment.graphql';
import { UpsertAutomaticEffectInput } from './__generated__/BillingCodeRuleSaveButtonMutation.graphql';
import { BillingCodeRuleFields_AutomaticGridFragment$key } from './__generated__/BillingCodeRuleFields_AutomaticGridFragment.graphql';
import { BillingCodeRuleFields_BillingCodesVisibleFragment$key } from './__generated__/BillingCodeRuleFields_BillingCodesVisibleFragment.graphql';
import {
  BaseQuestionsInput,
  CapacitiesInput,
  ClientsInput,
  EquipmentKindsInput,
  FieldBaseQuestions,
  FieldCapacities,
  FieldClients,
  FieldEquipmentKind,
  FieldNatureOfWorks,
  FieldNatureOfWorkSubCategories,
  FieldSaleKinds,
  FieldSaleStages,
  NatureOfWorksAutocompleteInput,
  NatureOfWorkSubCategoriesInput,
  SaleKindsInput,
  SaleStagesInput,
} from '../RuleFields';
import { BillingCodeRuleFields_BaseQuestions$key } from './__generated__/BillingCodeRuleFields_BaseQuestions.graphql';
import { BillingCodeRuleFields_NatureOfWorkSubCategoriesFragment$key } from './__generated__/BillingCodeRuleFields_NatureOfWorkSubCategoriesFragment.graphql';
import { BillingCodeRuleFields_NatureOfWorksFragment$key } from './__generated__/BillingCodeRuleFields_NatureOfWorksFragment.graphql';
import { BillingCodeRuleFields_ClientsFragment$key } from './__generated__/BillingCodeRuleFields_ClientsFragment.graphql';
import { BillingCodeRuleFields_EquipmentKindsFragment$key } from './__generated__/BillingCodeRuleFields_EquipmentKindsFragment.graphql';
import { BillingCodeRuleFields_CapacitiesFragment$key } from './__generated__/BillingCodeRuleFields_CapacitiesFragment.graphql';
import { BillingCodeRuleFields_SaleKindsFragment$key } from './__generated__/BillingCodeRuleFields_SaleKindsFragment.graphql';
import { BillingCodeRuleFields_SaleStagesFragment$key } from './__generated__/BillingCodeRuleFields_SaleStagesFragment.graphql';
import { isJobStage } from '../../jobs/jobStage';
import { useEffectEvent } from '../../common/utils/effectUtils';

export interface BillingCodeRuleFieldsMappings {}

export const billingCodeRuleDetailsFormContext = createFormContext<BillingCodeRuleFieldsMappings>();

const fieldDescriptionKey = createFieldKey<string>();
export function useFieldDescription($key: BillingCodeRuleFields_DescriptionFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFields_DescriptionFragment on BillingCodeRule {
        description
      }
    `,
    $key,
  );

  const { t } = useAmbientTranslation();
  const [description, setDescription] = useField(billingCodeRuleDetailsFormContext, fieldDescriptionKey, () => $data?.description ?? '');

  const useValidationDescription = useFieldValidation(billingCodeRuleDetailsFormContext, fieldDescriptionKey);
  useValidationDescription((v) => v.trim().length > 0, [], 'save:required');

  const useMapperDescription = useFieldMapper(billingCodeRuleDetailsFormContext, fieldDescriptionKey);
  useMapperDescription((val) => ({ description: val }), [], 'save');

  const errors = useFieldErrors(billingCodeRuleDetailsFormContext, fieldDescriptionKey);

  const renderDescription = useCallback(
    () => (
      <TextField
        label={t('fields.label.description')}
        required
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        onBlur={(e) => setDescription(e.target.value.trim())}
        error={!!errors['required']}
      />
    ),
    [description, errors, setDescription, t],
  );

  return { description, setDescription, renderDescription };
}

const fieldSaleStagesKey = createFieldKey<FieldSaleStages>();
export function useFieldSaleStages($key: BillingCodeRuleFields_SaleStagesFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFields_SaleStagesFragment on BillingCodeRule {
        conditions {
          jobConcreteTypes
        }
      }
    `,
    $key,
  );

  const [saleStages, setSaleStages] = useField(
    billingCodeRuleDetailsFormContext,
    fieldSaleStagesKey,
    () => $data?.conditions?.jobConcreteTypes.filter((k) => isJobStage(k)) ?? [],
  );

  const useMapper = useFieldMapper(billingCodeRuleDetailsFormContext, fieldSaleStagesKey);
  useMapper((val) => ({ upsertConditions: { jobConcreteTypes: val } }), [], 'save');

  const renderSaleStages = useCallback(() => <SaleStagesInput value={saleStages} setValue={setSaleStages} />, [saleStages, setSaleStages]);

  return { saleStages, setSaleStages, renderSaleStages };
}

const fieldSaleKinds = createFieldKey<FieldSaleKinds>();
export function useFieldSaleKinds($key: BillingCodeRuleFields_SaleKindsFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFields_SaleKindsFragment on BillingCodeRule {
        conditions {
          kinds
        }
      }
    `,
    $key,
  );

  const [saleKinds, setSaleKinds] = useField(
    billingCodeRuleDetailsFormContext,
    fieldSaleKinds,
    () => $data?.conditions?.kinds.filter((k) => isQuoteKind(k) || isServiceCallKind(k)) ?? [],
  );

  const useMapperKinds = useFieldMapper(billingCodeRuleDetailsFormContext, fieldSaleKinds);
  useMapperKinds((val) => ({ upsertConditions: { kinds: val } }), [], 'save');

  const renderSaleKinds = useCallback(() => <SaleKindsInput value={saleKinds} setValue={setSaleKinds} />, [saleKinds, setSaleKinds]);

  return { saleKinds, setSaleKinds, renderSaleKinds };
}

const fieldCapacitiesKey = createFieldKey<FieldCapacities>();
export function useFieldCapacities($key: BillingCodeRuleFields_CapacitiesFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFields_CapacitiesFragment on BillingCodeRule {
        conditions {
          capacities {
            capacity
            label
          }
        }
      }
    `,
    $key,
  );

  const [capacities, setCapacities] = useField(
    billingCodeRuleDetailsFormContext,
    fieldCapacitiesKey,
    () => $data?.conditions?.capacities ?? [],
  );

  const useMapperCapacities = useFieldMapper(billingCodeRuleDetailsFormContext, fieldCapacitiesKey);
  useMapperCapacities((val) => ({ upsertConditions: { capacities: val.map((c) => `${c.capacity}`) } }), [], 'save');

  const renderCapacities = useCallback(() => <CapacitiesInput value={capacities} setValue={setCapacities} />, [capacities, setCapacities]);

  return { capacities, setCapacities, renderCapacities };
}

const fieldEquipmentKindsKey = createFieldKey<FieldEquipmentKind>();
export function useFieldEquipmentKinds($key: BillingCodeRuleFields_EquipmentKindsFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFields_EquipmentKindsFragment on BillingCodeRule {
        conditions {
          equipmentKindLookups {
            id
            code
            label
          }
        }
      }
    `,
    $key,
  );

  const [equipmentKinds, setEquipmentKinds] = useField(
    billingCodeRuleDetailsFormContext,
    fieldEquipmentKindsKey,
    () => $data?.conditions?.equipmentKindLookups ?? [],
  );

  const useMapperEquipmentKinds = useFieldMapper(billingCodeRuleDetailsFormContext, fieldEquipmentKindsKey);
  useMapperEquipmentKinds((val) => ({ upsertConditions: { equipmentKinds: val.map((ek) => `${ek.code}`) } }), [], 'save');

  const renderEquipmentKinds = useCallback(
    () => <EquipmentKindsInput value={equipmentKinds} setValue={setEquipmentKinds} />,
    [equipmentKinds, setEquipmentKinds],
  );

  return { equipmentKinds, setEquipmentKinds, renderEquipmentKinds };
}

const fieldClientsKey = createFieldKey<FieldClients>();
export function useFieldClients($key: BillingCodeRuleFields_ClientsFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFields_ClientsFragment on BillingCodeRule {
        conditions {
          clientEntities {
            id
            name
            location {
              address
            }
            category
            number
            phoneNumber
            representative {
              name
            }
            ...CreditAlertIndicatorFragment
            deletedAt
            externalId
          }
        }
      }
    `,
    $key,
  );

  const [clients, setClients] = useField(billingCodeRuleDetailsFormContext, fieldClientsKey, () => $data?.conditions?.clientEntities ?? []);

  const useMapperClients = useFieldMapper(billingCodeRuleDetailsFormContext, fieldClientsKey);
  useMapperClients((val) => ({ upsertConditions: { clients: val.map((client) => `${client.externalId}`) } }), [], 'save');

  const renderClients = useCallback(() => <ClientsInput value={clients} setValue={setClients} />, [clients, setClients]);

  return { clients, setClients, renderClients };
}

const fieldNatureOfWorksKey = createFieldKey<FieldNatureOfWorks>();
export function useFieldNatureOfWorks($key: BillingCodeRuleFields_NatureOfWorksFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFields_NatureOfWorksFragment on BillingCodeRule {
        conditions {
          natureOfWorkLookups {
            id
            code
            label
          }
        }
      }
    `,
    $key,
  );

  const [natureOfWorks, setNatureOfWorks] = useField(
    billingCodeRuleDetailsFormContext,
    fieldNatureOfWorksKey,
    () => $data?.conditions?.natureOfWorkLookups ?? [],
  );

  const useMapperNatureOfWorks = useFieldMapper(billingCodeRuleDetailsFormContext, fieldNatureOfWorksKey);
  useMapperNatureOfWorks((val) => ({ upsertConditions: { natureOfWorks: val.map((v) => `${v.code}`) } }), [], 'save');

  const renderNatureOfWorks = useCallback(
    () => <NatureOfWorksAutocompleteInput value={natureOfWorks} setValue={setNatureOfWorks} />,
    [natureOfWorks, setNatureOfWorks],
  );

  return { natureOfWorks, setNatureOfWorks, renderNatureOfWorks };
}

const fieldNatureOfWorkSubCategories = createFieldKey<FieldNatureOfWorkSubCategories>();
export function useFieldNatureOfWorkSubCategories($key: BillingCodeRuleFields_NatureOfWorkSubCategoriesFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFields_NatureOfWorkSubCategoriesFragment on BillingCodeRule {
        conditions {
          natureOfWorkSubCategories
        }
      }
    `,
    $key,
  );

  const [natureOfWorkSubCategories, setNatureOfWorkSubCategories] = useField(
    billingCodeRuleDetailsFormContext,
    fieldNatureOfWorkSubCategories,
    () => $data?.conditions.natureOfWorkSubCategories?.map(castNatureOfWorkSubCategoryEnum) ?? [],
  );

  const useMapperNatureOfWorkSubCategories = useFieldMapper(billingCodeRuleDetailsFormContext, fieldNatureOfWorkSubCategories);
  useMapperNatureOfWorkSubCategories((val) => ({ upsertConditions: { natureOfWorkSubCategories: val } }), [], 'save');

  const renderNatureOfWorkSubCategories = useCallback(
    () => <NatureOfWorkSubCategoriesInput value={natureOfWorkSubCategories} setValue={setNatureOfWorkSubCategories} />,
    [natureOfWorkSubCategories, setNatureOfWorkSubCategories],
  );

  return { natureOfWorkSubCategories, setNatureOfWorkSubCategories, renderNatureOfWorkSubCategories };
}

const fieldBaseQuestionsKey = createFieldKey<FieldBaseQuestions>();
export function useFieldBaseQuestions($key: BillingCodeRuleFields_BaseQuestions$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFields_BaseQuestions on BillingCodeRule {
        conditions {
          baseQuestions
        }
      }
    `,
    $key,
  );

  const [baseQuestions, setBaseQuestions] = useField(billingCodeRuleDetailsFormContext, fieldBaseQuestionsKey, () =>
    $data?.conditions.baseQuestions ? $data.conditions.baseQuestions.map((q) => castSalesQuestionKind(q)) : [],
  );

  const useMapperQuestionsBase = useFieldMapper(billingCodeRuleDetailsFormContext, fieldBaseQuestionsKey);
  useMapperQuestionsBase((val) => ({ upsertConditions: { baseQuestions: val.map((q) => `${q}`) } }), [], 'save');

  const renderBaseQuestions = useCallback(
    () => <BaseQuestionsInput value={baseQuestions} setValue={setBaseQuestions} />,
    [baseQuestions, setBaseQuestions],
  );

  return { baseQuestions, setBaseQuestions, renderBaseQuestions };
}

export type FieldBillingCodesVisible = NonNullable<ForwardCostBillingCodeAutocompleteProps<true>['value']>;
const fieldBillingCodesVisibleKey = createFieldKey<FieldBillingCodesVisible>();
export function useFieldBillingCodesVisible($key: BillingCodeRuleFields_BillingCodesVisibleFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFields_BillingCodesVisibleFragment on BillingCodeRule {
        effects {
          billingCodes {
            visible {
              code
              id
              label
              billingSection {
                shortDescription
              }
            }
          }
        }
      }
    `,
    $key,
  );

  const codes = $data?.effects?.billingCodes?.visible ?? [];
  const initialValues = codes.filter((v) => v != null);
  if (initialValues.length !== codes.length) {
    throw new Error('Unexpected null value. All billing code should be resolved correctly.');
  }

  const [billingCodesVisible, setBillingCodesVisible] = useField(
    billingCodeRuleDetailsFormContext,
    fieldBillingCodesVisibleKey,
    () => initialValues,
  );

  const useMapperBillingCodesVisible = useFieldMapper(billingCodeRuleDetailsFormContext, fieldBillingCodesVisibleKey);
  useMapperBillingCodesVisible((val) => ({ upsertEffects: { billingCodeIds: { visible: val.map((bv) => bv.id) } } }), [], 'save');

  const renderBillingCodesVisible = useCallback(
    () => <BillingCodeVisibleInput value={billingCodesVisible} setValue={setBillingCodesVisible} />,
    [billingCodesVisible, setBillingCodesVisible],
  );

  return { billingCodesVisible, setBillingCodesVisible, renderBillingCodesVisible };
}

const EMPTY_SEARCH_INPUT = Object.freeze({
  capacity: '',
  equipmentKindCode: '',
  jobKind: '',
  clientExternalId: '',
  natureOfWorkCode: '',
  natureOfWorkSubCategory: '',
  questionsBase: Object.freeze([]),
  saleStage: '',
});

function BillingCodeVisibleInput({ value, setValue }: { value: FieldBillingCodesVisible; setValue: SetValueFn<FieldBillingCodesVisible> }) {
  const { t } = useAmbientTranslation();

  return (
    <CostBillingCodeAutocomplete
      multiple
      value={value}
      onlyVisible={false}
      onChange={(newValue) => setValue(newValue)}
      textFieldProps={() => ({ label: t('fields.label.billingCodesVisible'), placeholder: undefined })}
      searchInput={EMPTY_SEARCH_INPUT}
    />
  );
}

export type BillingCodeRulesAutomatic = {
  key: string;
  billingCode: FieldAutomaticBillingCode | null;
  workForceType: FieldAutomaticBillingCodeWorkForceType | null;
};
const fieldBillingCodesAutomaticKey = createFieldKey<BillingCodeRulesAutomatic[]>();
export function useBillingCodeAutomaticCollection($key: BillingCodeRuleFields_BillingCodeCollectionFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFields_BillingCodeCollectionFragment on BillingCodeRule {
        effects {
          billingCodes {
            automatic {
              billingCode {
                id
                code
                label
                billingSection {
                  shortDescription
                }
              }
              workForceType {
                id
                code
                label
              }
            }
          }
        }
      }
    `,
    $key,
  );

  const [billingCodeAutomaticCollection, setBillingCodeAutomaticCollection] = useField(
    billingCodeRuleDetailsFormContext,
    fieldBillingCodesAutomaticKey,
    () =>
      $data?.effects?.billingCodes?.automatic.map(
        ({ billingCode, workForceType }) =>
          ({
            key: nanoid(),
            billingCode: billingCode ?? null,
            workForceType: workForceType ?? null,
          }) satisfies BillingCodeRulesAutomatic,
      ) ?? [],
  );

  const useMapper = useFieldMapper(billingCodeRuleDetailsFormContext, fieldBillingCodesAutomaticKey);
  useMapper(
    (rows) => ({
      upsertEffects: {
        billingCodeIds: {
          automatic: rows
            .filter(({ billingCode }) => billingCode != null)
            .map(
              ({ billingCode, workForceType }) =>
                ({
                  billingCodeId: billingCode!.id,
                  itemTypeCode: workForceType?.code ?? null,
                }) satisfies UpsertAutomaticEffectInput,
            ),
        },
      },
    }),
    [],
    'save',
  );

  const replaceBillingCodeRuleAutomatic = useCallback(
    (row: BillingCodeRulesAutomatic) => {
      setBillingCodeAutomaticCollection(arrSetBy(row, (x) => x.key === row.key)(billingCodeAutomaticCollection));
    },
    [billingCodeAutomaticCollection, setBillingCodeAutomaticCollection],
  );

  const appendBillingCodeRuleAutomatic = useCallback(
    (row: BillingCodeRulesAutomatic) => {
      setBillingCodeAutomaticCollection([...billingCodeAutomaticCollection, row]);
    },
    [billingCodeAutomaticCollection, setBillingCodeAutomaticCollection],
  );

  const removeBillingCodeRuleAutomatic = useCallback(
    (key: string) => {
      setBillingCodeAutomaticCollection(billingCodeAutomaticCollection.filter((x) => x.key !== key));
    },
    [billingCodeAutomaticCollection, setBillingCodeAutomaticCollection],
  );

  return {
    billingCodeAutomaticCollection,
    setBillingCodeAutomaticCollection,
    replaceBillingCodeRuleAutomatic,
    appendBillingCodeRuleAutomatic,
    removeBillingCodeRuleAutomatic,
  };
}

export function BillingCodesAutomaticGrid({ $key }: { $key: BillingCodeRuleFields_AutomaticGridFragment$key | null | undefined }) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFields_AutomaticGridFragment on BillingCodeRule {
        ...BillingCodeRuleFields_BillingCodesVisibleFragment
        ...BillingCodeRuleFields_BillingCodeCollectionFragment
      }
    `,
    $key,
  );

  const { t } = useAmbientTranslation();
  const { billingCodesVisible } = useFieldBillingCodesVisible($data);
  const {
    billingCodeAutomaticCollection,
    replaceBillingCodeRuleAutomatic,
    appendBillingCodeRuleAutomatic,
    removeBillingCodeRuleAutomatic: handleRemove,
  } = useBillingCodeAutomaticCollection($data);

  const handleRowChange = useCallback(
    (row: BillingCodeRulesAutomatic) => {
      if (billingCodeAutomaticCollection.find((x) => x.key === row.key)) {
        replaceBillingCodeRuleAutomatic(row);
      } else {
        appendBillingCodeRuleAutomatic(row);
      }
    },
    [appendBillingCodeRuleAutomatic, billingCodeAutomaticCollection, replaceBillingCodeRuleAutomatic],
  );

  return (
    <ResponsiveGridContent formMode gridTemplateColumns='1fr 1fr 0.25fr'>
      <ResponsiveGridHeader
        columnsDefinitions={[
          { id: 'billingCode', label: t('fields.label.billingCode') },
          { id: 'labor', label: t('fields.label.labor') },
          { id: 'actions', label: '' },
        ]}
        sx={{ '& > *': { px: '1rem' } }}
      />
      {billingCodeAutomaticCollection.map((automatic) => (
        <FormProvider context={billingCodeRuleAutomaticSubFormContext} key={automatic.key}>
          <BillingCode_AutomaticRow
            id={automatic.key}
            value={automatic}
            billingCodesVisible={billingCodesVisible}
            onRowChange={handleRowChange}
            onRowDelete={() => handleRemove(automatic.key)}
          />
        </FormProvider>
      ))}
      <FormProvider context={billingCodeRuleAutomaticSubFormContext} key={`new-${billingCodeAutomaticCollection.length}`}>
        <BillingCode_AutomaticRow
          billingCodesVisible={billingCodesVisible}
          onRowChange={handleRowChange}
          id={`new-${billingCodeAutomaticCollection.length}`}
          value={null}
        />
      </FormProvider>
    </ResponsiveGridContent>
  );
}

function BillingCode_AutomaticRow({
  id,
  value,
  billingCodesVisible,
  onRowChange,
  onRowDelete: handleRowDelete,
}: {
  id: string;
  value: BillingCodeRulesAutomatic | null;
  billingCodesVisible: FieldBillingCodesVisible;
  onRowChange: (row: BillingCodeRulesAutomatic) => void;
  onRowDelete?: () => void;
}) {
  const { automaticBillingCode, automaticBillingCodeIsDirty, renderAutomaticBillingCode } = useFieldAutomaticBillingCode(
    value?.billingCode,
  );
  const {
    automaticBillingCodeWorkForceType,
    automaticBillingCodeWorkForceTypeIsDirty,
    setAutomaticBillingCodeWorkForceType,
    renderAutomaticBillingCodeWorkForceType,
  } = useAutomaticBillingCodeWorkForceType(value?.workForceType);

  const updateAutomaticBillingCode = useEffectEvent((newCode: typeof automaticBillingCode) => {
    if (!automaticBillingCodeIsDirty) {
      return;
    }

    // Voluntary reset workforce to null
    setAutomaticBillingCodeWorkForceType(null);

    onRowChange({ key: id, billingCode: newCode, workForceType: null });
  });
  useEffect(() => {
    updateAutomaticBillingCode(automaticBillingCode);
  }, [automaticBillingCode, updateAutomaticBillingCode]);

  const updateAutomaticBillingCodeWorkForceType = useEffectEvent((newWorkForceType: typeof automaticBillingCodeWorkForceType) => {
    if (!automaticBillingCodeWorkForceTypeIsDirty) {
      return;
    }

    onRowChange({ key: id, billingCode: automaticBillingCode, workForceType: newWorkForceType });
  });
  useEffect(() => {
    updateAutomaticBillingCodeWorkForceType(automaticBillingCodeWorkForceType);
  }, [automaticBillingCodeWorkForceType, updateAutomaticBillingCodeWorkForceType]);

  return (
    <ResponsiveGridItem id={id}>
      {renderAutomaticBillingCode(billingCodesVisible)}
      {renderAutomaticBillingCodeWorkForceType(automaticBillingCode?.id ?? null)}
      <Box>
        {handleRowDelete && (
          <IconButton onClick={handleRowDelete} size='small' color='error'>
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
    </ResponsiveGridItem>
  );
}
