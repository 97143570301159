import { AuthorizationWriteFragment$key } from '../auth/__generated__/AuthorizationWriteFragment.graphql';
import {
  getNodeById,
  ResponsiveGrid,
  ResponsiveGridForwardProps,
  ResponsiveGridImperativeHandle,
  ResponsiveGridProps,
  useElementFactory,
  useSkeletonFactory,
} from '../common/components/ResponsiveGrid';
import { DataID, useLazyLoadQuery, useRefetchableFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { useConstantValue } from '../common/hooks/useConstantValue';
import { QuoteFilters_Representative } from './QuoteFilters';
import { convertToTsQuery } from '../common/utils/stringUtils';
import { QuoteList_Representative_FiltersFragment$key } from './__generated__/QuoteList_Representative_FiltersFragment.graphql';
import { Theme, useMediaQuery } from '@mui/material';
import { useCallback, useRef } from 'react';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import {
  getQuoteListColumns,
  QuoteList_Item,
  QuoteList_ListSkeleton,
  QuoteList_Row,
  QuoteList_RowSkeleton,
  quoteListSx,
} from './QuoteList';
import { QuoteList_Representative_Filters, QuoteList_RepresentativeContextValue } from './QuoteList.Representative';
import { useOutletContext } from 'react-router';
import {
  QuoteList_Representative_MyClientsQuery,
  QuoteList_Representative_MyClientsQuery$variables,
} from './__generated__/QuoteList_Representative_MyClientsQuery.graphql';
import { QuoteList_Representative_MyClients_ListContentFragment$key } from './__generated__/QuoteList_Representative_MyClients_ListContentFragment.graphql';
import { JobList_Representative_Tabs } from '../jobs/JobList.Representative.Tabs';

export function QuoteList_Representative_MyClients() {
  const { write$key, filters, setFilters: handleSetFilters, listProps } = useOutletContext<QuoteList_RepresentativeContextValue>();

  const queryFilters = useConstantValue<QuoteList_Representative_MyClientsQuery$variables>(() => ({
    first: 25,
    where: filters.toJobRevisionFilter(),
    searchTerm: convertToTsQuery(filters.get('searchTerm'), '|'),
    projectManagerIds: filters.get('projectManagers').map(({ id }) => id),
    dispatchBranchIds: filters.get('dispatchBranches').map(({ id }) => id),
    equipmentKindCodes: filters.get('equipmentKinds').map(({ code }) => code),
  }));

  const quotes = useLazyLoadQuery<QuoteList_Representative_MyClientsQuery>(
    graphql`
      query QuoteList_Representative_MyClientsQuery(
        $first: Int
        $where: QuoteJobRevisionFilterType
        $dispatchBranchIds: [ID!]!
        $equipmentKindCodes: [Int!]!
        $projectManagerIds: [ID!]!
        $searchTerm: String
      ) {
        ...QuoteList_Representative_MyClients_ListContentFragment @arguments(searchTerm: $searchTerm, first: $first, where: $where)
        ...QuoteList_Representative_FiltersFragment
          @arguments(dispatchBranchIds: $dispatchBranchIds, equipmentKindCodes: $equipmentKindCodes, projectManagerIds: $projectManagerIds)
      }
    `,
    queryFilters,
    { fetchPolicy: 'store-and-network' }, // store-and-network to make sure the data is up to date following a details page modification
  );

  return (
    <ListContent
      {...listProps}
      $key={quotes}
      write$key={write$key}
      filters$key={quotes}
      filters={filters}
      onFiltersChange={handleSetFilters}
    />
  );
}

function ListContent({
  $key,
  filters$key,
  write$key,
  filters,
  onFiltersChange: handleFiltersChange,
  onItemClick,
  ...gridProps
}: {
  $key: QuoteList_Representative_MyClients_ListContentFragment$key;
  filters$key: QuoteList_Representative_FiltersFragment$key;
  write$key: AuthorizationWriteFragment$key;
  filters: QuoteFilters_Representative;
  onFiltersChange: (filters: QuoteFilters_Representative) => void;
} & ResponsiveGridForwardProps) {
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const gridRef = useRef<ResponsiveGridImperativeHandle | null>(null);

  const { t } = useAmbientTranslation();

  const [$data, refetch] = useRefetchableFragment<
    QuoteList_Representative_MyClientsQuery,
    QuoteList_Representative_MyClients_ListContentFragment$key
  >(
    graphql`
      fragment QuoteList_Representative_MyClients_ListContentFragment on Query
      @refetchable(queryName: "QuoteList_Representative_MyClients_ListContentFragmentQuery")
      @argumentDefinitions(
        searchTerm: { type: "String" }
        after: { type: "String" }
        before: { type: "String" }
        first: { type: "Int" }
        last: { type: "Int" }
        where: { type: "QuoteJobRevisionFilterType" }
      ) {
        searchQuotesWithMyClients(
          searchTerm: $searchTerm
          after: $after
          before: $before
          first: $first
          last: $last
          where: $where
          order: [{ snapshot: { statusOrder: ASC } }, { snapshot: { projectBase: { arrivalDate: { date: ASC } } } }, { id: ASC }]
        ) @required(action: THROW) {
          ...ResponsiveGridFragment
          edges {
            node {
              id
              lifeCycleBranchId
              ...QuoteList_RowFragment
              ...QuoteList_ItemFragment
            }
          }
        }
      }
    `,
    $key,
  );

  const refetchFn = useCallback<ResponsiveGridProps['refetch']>(
    (vars, options) =>
      refetch(
        {
          ...vars,
          searchTerm: convertToTsQuery(filters.get('searchTerm'), '|'),
          where: filters.toJobRevisionFilter(),
        },
        options,
      ),
    [refetch, filters],
  );

  const edges = $data.searchQuotesWithMyClients?.edges;

  const columns = getQuoteListColumns(t, compact);

  const rowElementFactory = useElementFactory(edges, (node, orderByColumns) => (
    <QuoteList_Row $key={node} write$key={write$key} orderByColumns={orderByColumns} />
  ));
  const listElementFactory = useElementFactory(edges, (node) => <QuoteList_Item $key={node} />);
  const rowSkeletonFactory = useSkeletonFactory(() => <QuoteList_RowSkeleton columns={columns} />);
  const listSkeletonFactory = useSkeletonFactory(() => <QuoteList_ListSkeleton />);

  return (
    $data.searchQuotesWithMyClients && (
      <>
        <QuoteList_Representative_Filters $key={filters$key} filters={filters} onFiltersChange={handleFiltersChange} />
        <JobList_Representative_Tabs tab='my-clients' />
        <ResponsiveGrid
          ref={gridRef}
          connectionFragmentKey={$data.searchQuotesWithMyClients}
          refetch={refetchFn}
          columnDefinitions={columns}
          rowElementFactory={rowElementFactory}
          listElementFactory={listElementFactory}
          rowSkeletonFactory={rowSkeletonFactory}
          listSkeletonFactory={listSkeletonFactory}
          listSx={quoteListSx}
          onItemClick={(id: DataID) => onItemClick?.(getNodeById(id, edges).lifeCycleBranchId)}
          {...gridProps}
        />
      </>
    )
  );
}
