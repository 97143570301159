/**
 * @generated SignedSource<<435bb38c11c2f855d95f3b08cdafbf76>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuoteList_RepresentativeFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"JobFilters_useJobFiltersFragment">;
  readonly " $fragmentType": "QuoteList_RepresentativeFragment";
};
export type QuoteList_RepresentativeFragment$key = {
  readonly " $data"?: QuoteList_RepresentativeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuoteList_RepresentativeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuoteList_RepresentativeFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "JobFilters_useJobFiltersFragment"
    }
  ],
  "type": "Employee",
  "abstractKey": null
};

(node as any).hash = "502a8e70ff0f99bb34cfd5a6e9d55490";

export default node;
