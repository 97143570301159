/**
 * @generated
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck


export const syncableEntities = [

  /**
  * Stand-alone entities
  */
  "accessoryGroup",

  /**
  * Lookups not included in DescriptorType
  */
  "accessoryType",

  /**
  * Depends on BillingSection
  */
  "billing",

  /**
  * Stand-alone entities
  */
  "billingSection",

  /**
  * Depends on CraneConfiguration
  */
  "boomConfiguration",

  /**
  * Depends on BoomConfiguration
  */
  "boomConfigurationAdditional",

  /**
  * Depends on BoomConfiguration
  */
  "boomConfigurationTransport",

  /**
  * Depends on BoomConfiguration
  */
  "boomConfigurationWorkForce",

  /**
  * Stand-alone entities
  */
  "branch",

  /**
  * Depends on Representative, Requirement
  */
  "client",

  /**
  * Lookups not included in DescriptorType
  */
  "configurationType",

  /**
  * Depends on Equipment, ConfigurationType
  */
  "craneConfiguration",

  /**
  * Depends on CraneConfiguration
  */
  "craneConfigurationEscort",

  /**
  * Depends on CraneConfiguration
  */
  "craneConfigurationFlatbed",

  /**
  * Depends on CraneConfiguration
  */
  "craneConfigurationPermit",

  /**
  * Depends on DescriptorType
  */
  "craneMakeModel",

  /**
  * Pseudo-entity used to sync EquipmentGroup, EquipmentType, WorkKind and WorkType
  */
  "descriptorType",

  /**
  * Stand-alone entities
  */
  "employee",

  /**
  * Depends on Branch, DescriptorType, AccessoryType
Also include the emulated Crane entity.
  */
  "equipment",

  /**
  * Lookups not included in DescriptorType
  */
  "escortType",

  /**
  * Lookups not included in DescriptorType
  */
  "flatbedType",

  /**
  * Lookups not included in DescriptorType
  */
  "permitType",

  /**
  * Stand-alone entities
  */
  "region",

  /**
  * Stand-alone entities
  */
  "representative",

  /**
  * Stand-alone entities
  */
  "requirement",

  /**
  * Stand-alone entities
  */
  "statutoryHoliday",

  /**
  * Depends on Region
  */
  "subRegion",

  /**
  * Stand-alone entities
  */
  "surcharge",

  /**
  * Lookups not included in DescriptorType
  */
  "transportType",

  /**
  * Lookups not included in DescriptorType
  */
  "workForceType",

  /**
  * Depends on Requirement
  */
  "workSite"
] as const;
export type SyncableEntity = typeof syncableEntities[number];

export function isSyncableEntity(value: string): value is SyncableEntity {
  return syncableEntities.includes(value)
}

export function asSyncableEntity(value: string): SyncableEntity | null {
  return isSyncableEntity(value) ? value : null;
}

export function castSyncableEntity(value: string): SyncableEntity {
  if(!isSyncableEntity(value)) {
    throw new Error(`Invalid Enum value for type "SyncableEntity": "${value}"`);
  }
  
  return value;
}
