/**
 * @generated SignedSource<<1872a4f816952c631370c3e302d35303>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as representativeLabelResolverType } from "../../__resolvers__/RepresentativeLabelResolver";
// Type assertion validating that `representativeLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(representativeLabelResolverType satisfies (
  rootKey: RepresentativeLabelResolver$key,
) => string | null | undefined);
import { label as subRegionLabelResolverType } from "../../__resolvers__/SubRegionLabelResolver";
// Type assertion validating that `subRegionLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(subRegionLabelResolverType satisfies (
  rootKey: SubRegionLabelResolver$key,
) => Record<string, string> | null | undefined);
export type WorkPlanningList_Regular_FiltersFragment$data = {
  readonly projectManagers: ReadonlyArray<{
    readonly __typename: "Representative";
    readonly deletedAt: string | null | undefined;
    readonly id: string;
    readonly label: string | null | undefined;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined>;
  readonly representatives: ReadonlyArray<{
    readonly __typename: "Representative";
    readonly deletedAt: string | null | undefined;
    readonly id: string;
    readonly label: string | null | undefined;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined>;
  readonly subRegions: ReadonlyArray<{
    readonly __typename: "SubRegion";
    readonly id: string;
    readonly label: Record<string, string> | null | undefined;
    readonly region: {
      readonly description: Record<string, string>;
    } | null | undefined;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined>;
  readonly " $fragmentType": "WorkPlanningList_Regular_FiltersFragment";
};
export type WorkPlanningList_Regular_FiltersFragment$key = {
  readonly " $data"?: WorkPlanningList_Regular_FiltersFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkPlanningList_Regular_FiltersFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "RepresentativeLabelResolver"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectManagerIds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "representativeIds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "subRegionIds"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkPlanningList_Regular_FiltersFragment",
  "selections": [
    {
      "alias": "representatives",
      "args": [
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "representativeIds"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "fragment": (v2/*: any*/),
              "kind": "RelayResolver",
              "name": "label",
              "resolverModule": require('./../../__resolvers__/RepresentativeLabelResolver').label,
              "path": "representatives.label"
            },
            (v3/*: any*/)
          ],
          "type": "Representative",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "projectManagers",
      "args": [
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "projectManagerIds"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "fragment": (v2/*: any*/),
              "kind": "RelayResolver",
              "name": "label",
              "resolverModule": require('./../../__resolvers__/RepresentativeLabelResolver').label,
              "path": "projectManagers.label"
            },
            (v3/*: any*/)
          ],
          "type": "Representative",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "subRegions",
      "args": [
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "subRegionIds"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "fragment": {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SubRegionLabelResolver"
              },
              "kind": "RelayResolver",
              "name": "label",
              "resolverModule": require('./../../__resolvers__/SubRegionLabelResolver').label,
              "path": "subRegions.label"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Region",
              "kind": "LinkedField",
              "name": "region",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "SubRegion",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "c934d9deacec39c55cd8f405df04eab0";

export default node;
