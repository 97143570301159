import {
  createFieldKey,
  createFormContext,
  SetValueFn,
  useField,
  useFieldHasErrors,
  useFieldIsDirty,
  useFieldMapper,
  useFieldValidation,
} from '../../common/utils/forms';
import { CostLine } from './CostLinesFields';
import { NumberInput, NumberInputProps } from '../../common/components/NumberInput';
import { flagDirty } from '../../common/utils/patchable';
import { ReactNode, useCallback, useRef, useState } from 'react';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { PriceInput } from '../../common/components/PriceInput';
import { Button, Checkbox, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  CostBillingCodeAutocomplete,
  ForwardCostBillingCodeAutocompleteProps,
  SearchBillingCodeInput,
} from '../../common/components/CostBillingCodeAutocomplete';
import { ForwardWorkForceTypeAutocompleteProps, WorkForceTypeAutocomplete } from '../../common/components/WorkForceTypeAutocomplete';
import { asBillingCodeCategory, BillingCodeCategory } from '../../__enums__/BillingCodeCategory';
import { SalesRateResult } from '../../common/types/externalResult';
import { SelectPicker } from '../../common/components/SelectPicker';
import { AdditionalCrane } from '../AdditionalCranesFields';
import { CostLineKind } from '../../__enums__/CostLineKind';
import { Price } from '../../common/utils/price';
import { BoxedFormControlLabel } from '../../common/BoxedFormControlLabel';
import { CostLineRateUpdateDialog } from './CostLineRateUpdateDialog';

export const costLineSubFormContext = createFormContext<{ sync: CostLine }>();

const fieldCostLineIdKey = createFieldKey<string>();
export function useFieldCostLineId(initialValue: string) {
  const [value] = useField(costLineSubFormContext, fieldCostLineIdKey, () => initialValue);

  const useMapper = useFieldMapper(costLineSubFormContext, fieldCostLineIdKey);
  useMapper((v) => ({ id: v }), [], 'sync');

  return value;
}

export type FieldCostLineBillingCode = ForwardCostBillingCodeAutocompleteProps<false, true>['value'];
const fieldCostLineBillingCodeKey = createFieldKey<FieldCostLineBillingCode>();

export function useFieldCostLineBillingCode(initialValue: FieldCostLineBillingCode, disabled: boolean) {
  const [billingCode, setValue] = useField(costLineSubFormContext, fieldCostLineBillingCodeKey, () => initialValue);
  const setBillingCode = useCallback<SetValueFn<FieldCostLineBillingCode>>(
    (action) => {
      if (disabled) {
        return;
      }

      setValue(action);
    },
    [disabled, setValue],
  );

  const billingCodeIsDirty = useFieldIsDirty(costLineSubFormContext, fieldCostLineBillingCodeKey);

  const useMapper = useFieldMapper(costLineSubFormContext, fieldCostLineBillingCodeKey);
  useMapper((v, { isDirty }) => flagDirty({ billingCode: v }, { billingCode: isDirty }), [], 'sync');

  const renderBillingCode = useCallback(
    (gridMode: boolean, onlyVisible: boolean, searchInput: SearchBillingCodeInput) => (
      <BillingCodeInput
        value={billingCode}
        onlyVisible={onlyVisible}
        searchInput={searchInput}
        setValue={setBillingCode}
        gridMode={gridMode}
        disabled={disabled}
      />
    ),
    [billingCode, disabled, setBillingCode],
  );

  return { billingCode, setBillingCode, billingCodeIsDirty, renderBillingCode };
}

function BillingCodeInput({
  value,
  setValue,
  gridMode,
  disabled,
  onlyVisible,
  searchInput,
}: {
  value: FieldCostLineBillingCode;
  setValue: SetValueFn<FieldCostLineBillingCode>;
  gridMode: boolean;
  disabled: boolean;
  onlyVisible: boolean;
  searchInput: SearchBillingCodeInput;
}) {
  const { t } = useAmbientTranslation();

  return (
    <CostBillingCodeAutocomplete
      disableClearable
      disabled={disabled}
      value={value}
      onlyVisible={onlyVisible}
      searchInput={searchInput}
      onChange={(v) => setValue(v)}
      textFieldProps={() => ({
        className: gridMode ? 'borderless' : '',
        label: gridMode ? '' : t('field.cost.billingCode'),
        'data-label-key': 'field.cost.billingCode',
      })}
    />
  );
}

export type FieldCostLineCraneIndex = number;
const fieldCostLineCraneIndexKey = createFieldKey<FieldCostLineCraneIndex>();

export function useFieldCostLineCraneIndex(initialValue: FieldCostLineCraneIndex, disabled: boolean) {
  const [craneIndex, setValue] = useField(costLineSubFormContext, fieldCostLineCraneIndexKey, () => initialValue);
  const setCraneIndex = useCallback<SetValueFn<FieldCostLineCraneIndex>>(
    (action) => {
      if (disabled) {
        return;
      }

      setValue(action);
    },
    [disabled, setValue],
  );

  const useMapper = useFieldMapper(costLineSubFormContext, fieldCostLineCraneIndexKey);
  useMapper((v, { isDirty }) => flagDirty({ craneIndex: v }, { craneIndex: isDirty }), [], 'sync');
  const craneIndexIsDirty = useFieldIsDirty(costLineSubFormContext, fieldCostLineCraneIndexKey);
  const renderCraneIndex = useCallback(
    (additionalCranes: AdditionalCrane[], gridMode: boolean) => (
      <CraneIndexInput
        value={craneIndex}
        setValue={setCraneIndex}
        gridMode={gridMode}
        additionalCranes={additionalCranes}
        disabled={disabled}
      />
    ),
    [craneIndex, disabled, setCraneIndex],
  );

  return { craneIndex, setCraneIndex, craneIndexIsDirty, renderCraneIndex };
}

function CraneIndexInput({
  value,
  setValue,
  gridMode,
  disabled,
  additionalCranes,
}: {
  value: FieldCostLineCraneIndex;
  setValue: SetValueFn<FieldCostLineCraneIndex>;
  gridMode: boolean;
  disabled: boolean;
  additionalCranes: AdditionalCrane[];
}) {
  const { t } = useAmbientTranslation();

  const options = [0, ...additionalCranes.filter((a) => !a.deletedAt).map((_, index) => index + 1)];

  return (
    <SelectPicker
      value={value}
      getOptionKey={(o) => o}
      getOptionLabel={(o) =>
        o === 0
          ? t(`crane.${gridMode ? 'mainShort' : 'main'}`, { ns: 'jobs' })
          : `${t(`crane.${gridMode ? 'additionalShort' : 'additional'}`, { ns: 'jobs', index: o })}`
      }
      options={options}
      disableClearable
      disabled={disabled}
      onChange={(_, v) => setValue(v)}
      textFieldProps={() => ({ className: gridMode ? 'borderless' : '', label: gridMode ? '' : t('field.cost.crane') })}
    />
  );
}

export type FieldCostLineKind = CostLineKind;
const fieldCostLineKindKey = createFieldKey<FieldCostLineKind>();

export function useFieldCostLineKind(initialValue: CostLineKind | undefined) {
  const [kind, setKind] = useField(costLineSubFormContext, fieldCostLineKindKey, () => initialValue ?? 'manual');

  const useMapper = useFieldMapper(costLineSubFormContext, fieldCostLineKindKey);
  useMapper((v) => ({ kind: v }), [], 'sync');

  return { kind, setKind };
}

export type FieldCostLineIsFractionAllowed = boolean;
const fieldCostLineFractionAllowedKey = createFieldKey<FieldCostLineIsFractionAllowed>();

export function useFieldCostLineFractionAllowed(initialValue: boolean | undefined) {
  const [costLineIsFractionAllowed, setCostLineIsFractionAllowed] = useField(
    costLineSubFormContext,
    fieldCostLineFractionAllowedKey,
    () => initialValue ?? false,
  );

  const useMapper = useFieldMapper(costLineSubFormContext, fieldCostLineFractionAllowedKey);
  useMapper((v) => ({ isFractionAllowed: v }), [], 'sync');

  return { costLineIsFractionAllowed, setCostLineIsFractionAllowed };
}

export type FieldCostLineBillingCodeCategory = BillingCodeCategory | null;
const fieldCostLineBillingCodeCategoryKey = createFieldKey<FieldCostLineBillingCodeCategory>();

export function useFieldCostLineBillingCodeCategory(initialValue: BillingCodeCategory | null) {
  const [billingCodeCategory, setBillingCodeCategory] = useField(
    costLineSubFormContext,
    fieldCostLineBillingCodeCategoryKey,
    () => initialValue,
  );

  const useMapper = useFieldMapper(costLineSubFormContext, fieldCostLineBillingCodeCategoryKey);
  useMapper((v) => ({ billingCodeCategory: v ? asBillingCodeCategory(v) : null }), [], 'sync');

  return { billingCodeCategory, setBillingCodeCategory };
}

export type FieldCostLineIsFixedQuantity = boolean;
const fieldCostLineIsFixedQuantityKey = createFieldKey<FieldCostLineIsFixedQuantity>();
export function useFieldCostLineIsFixedQuantity(initialValue: boolean | undefined) {
  const [costLineIsFixedQuantity, setCostLineIsFixedQuantity] = useField(
    costLineSubFormContext,
    fieldCostLineIsFixedQuantityKey,
    () => initialValue ?? false,
  );

  const useMapper = useFieldMapper(costLineSubFormContext, fieldCostLineIsFixedQuantityKey);
  useMapper((v) => ({ isFixedQuantity: v }), [], 'sync');

  return { costLineIsFixedQuantity, setCostLineIsFixedQuantity };
}

export type FieldCostLineDefaultQuantity = number | null;
const fieldCostLineDefaultQuantityKey = createFieldKey<FieldCostLineDefaultQuantity>();
export function useFieldCostLineDefaultQuantity(initialValue: number | null | undefined) {
  const [costLineDefaultQuantity, setCostLineDefaultQuantity] = useField(
    costLineSubFormContext,
    fieldCostLineDefaultQuantityKey,
    () => initialValue ?? null,
  );

  const useMapper = useFieldMapper(costLineSubFormContext, fieldCostLineDefaultQuantityKey);
  useMapper((v) => ({ defaultQuantity: v }), [], 'sync');

  return { costLineDefaultQuantity, setCostLineDefaultQuantity };
}

export type FieldCostLineSalesRateResult = SalesRateResult | null;

const fieldCostLineSalesRateResultKey = createFieldKey<FieldCostLineSalesRateResult>();
export function useFieldCostLineSalesRateResult(initialValue: FieldCostLineSalesRateResult | undefined) {
  const [costLineSalesRateResult, setCostLineSalesResult] = useField(
    costLineSubFormContext,
    fieldCostLineSalesRateResultKey,
    () => initialValue ?? null,
  );

  const useMapper = useFieldMapper(costLineSubFormContext, fieldCostLineSalesRateResultKey);
  useMapper((v, { isDirty }) => flagDirty({ salesRateResult: v }, { salesRateResult: isDirty }), [], 'sync');
  const costLineSalesRateResultIsDirty = useFieldIsDirty(costLineSubFormContext, fieldCostLineSalesRateResultKey);
  return { costLineSalesRateResult, setCostLineSalesResult, costLineSalesRateResultIsDirty };
}

export type FieldCostLineRequireWorkForceType = boolean;
const fieldCostLineRequireWorkForceTypeKey = createFieldKey<FieldCostLineRequireWorkForceType>();

export type FieldCostLineWorkForceType = NonNullable<ForwardWorkForceTypeAutocompleteProps['value']> | null;
const fieldCostLineWorkForceTypeKey = createFieldKey<FieldCostLineWorkForceType>();

export function useFieldCostLineWorkForceType(
  initialValue: FieldCostLineWorkForceType | undefined,
  initialRequireWorkForceType: FieldCostLineRequireWorkForceType | undefined,
  disabled: boolean,
) {
  const [workForceType, setWorkForceType] = useField(costLineSubFormContext, fieldCostLineWorkForceTypeKey, () => initialValue ?? null);

  const useMapper = useFieldMapper(costLineSubFormContext, fieldCostLineWorkForceTypeKey);
  useMapper((v, { isDirty }) => flagDirty({ workForceType: v }, { workForceType: isDirty }), [], 'sync');

  const workForceTypeIsDirty = useFieldIsDirty(costLineSubFormContext, fieldCostLineWorkForceTypeKey);

  const [requireWorkForceType, setRequireWorkForceType] = useField(
    costLineSubFormContext,
    fieldCostLineRequireWorkForceTypeKey,
    () => initialRequireWorkForceType ?? false,
  );

  const useMapperRequireWorkForceType = useFieldMapper(costLineSubFormContext, fieldCostLineRequireWorkForceTypeKey);
  useMapperRequireWorkForceType((v, { isDirty }) => flagDirty({ requireWorkForceType: v }, { requireWorkForceType: isDirty }), [], 'sync');

  const resetWorkForceType = useCallback(
    (category: BillingCodeCategory) => {
      if (disabled) {
        return;
      }

      setWorkForceType(null);
      setRequireWorkForceType(category === 'labor');
    },
    [disabled, setRequireWorkForceType, setWorkForceType],
  );

  const renderWorkForceType = useCallback(
    (gridMode: boolean) => (
      <WorkForceTypeInput
        value={workForceType}
        setValue={setWorkForceType}
        gridMode={gridMode}
        disabled={disabled || !requireWorkForceType}
      />
    ),
    [workForceType, setWorkForceType, disabled, requireWorkForceType],
  );

  return {
    workForceType,
    setWorkForceType,
    workForceTypeIsDirty,
    requireWorkForceType,
    setRequireWorkForceType,
    resetWorkForceType,
    renderWorkForceType,
  };
}

function WorkForceTypeInput({
  value,
  setValue,
  gridMode,
  disabled,
}: {
  value: FieldCostLineWorkForceType;
  setValue: SetValueFn<FieldCostLineWorkForceType>;
  gridMode: boolean;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();

  return (
    <WorkForceTypeAutocomplete
      disabled={disabled}
      value={value}
      disableClearable={value !== null}
      onChange={(v) => setValue(v)}
      textFieldProps={() => ({
        className: gridMode ? 'borderless' : '',
        label: gridMode ? '' : t('field.cost.workForceType'),
        placeholder: disabled ? undefined : t('button.select', { ns: 'common' }),
        'data-label-key': 'field.cost.workForceType',
      })}
    />
  );
}

export type FieldCostLineQuantity = NumberInputProps['value'];
const fieldCostLineQuantityKey = createFieldKey<FieldCostLineQuantity>();
export function useFieldCostLineQuantity(initialValue: FieldCostLineQuantity | undefined, disabled: boolean) {
  const [costLineQuantity, setCostLineQuantity] = useField(costLineSubFormContext, fieldCostLineQuantityKey, () => initialValue ?? null);
  const costLineQuantityIsDirty = useFieldIsDirty(costLineSubFormContext, fieldCostLineQuantityKey);

  const useMapper = useFieldMapper(costLineSubFormContext, fieldCostLineQuantityKey);
  useMapper((v, { isDirty }) => flagDirty({ quantity: v }, { quantity: isDirty }), [], 'sync');

  const renderCostLineQuantity = useCallback(
    (
      gridMode: boolean,
      startAdornment: ReactNode,
      isFractionAllowed: boolean,
      billingCodeCategory: BillingCodeCategory | null,
      isFixedQuantity: boolean,
    ) => (
      <CostLineQuantityInput
        value={costLineQuantity}
        setValue={setCostLineQuantity}
        gridMode={gridMode}
        disabled={disabled || isFixedQuantity}
        startAdornment={startAdornment}
        fractionAllowed={isFractionAllowed}
        billingCodeCategory={billingCodeCategory}
      />
    ),
    [costLineQuantity, setCostLineQuantity, disabled],
  );

  return { costLineQuantity, setCostLineQuantity, costLineQuantityIsDirty, renderCostLineQuantity };
}
function CostLineQuantityInput({
  value,
  setValue,
  gridMode,
  disabled,
  startAdornment,
  fractionAllowed,
  billingCodeCategory,
}: {
  value: FieldCostLineQuantity;
  setValue: SetValueFn<FieldCostLineQuantity>;
  gridMode: boolean;
  disabled: boolean;
  startAdornment: ReactNode;
  fractionAllowed: boolean;
  billingCodeCategory: BillingCodeCategory | null;
}) {
  const hasErrors = useFieldHasErrors(costLineSubFormContext, fieldCostLineQuantityKey);
  const { t } = useAmbientTranslation();

  const step = fractionAllowed ? (billingCodeCategory === 'labor' ? 0.25 : 0.01) : 1;
  const min = fractionAllowed ? 0 : 1;
  return (
    <NumberInput
      value={value}
      onChange={(v) => setValue(v)}
      error={hasErrors}
      min={min}
      step={step}
      max={2147483647} // Maximum integer in c#
      borderless={gridMode}
      label={gridMode ? '' : t('field.cost.quantity')}
      InputProps={{
        inputProps: { style: { textAlign: 'right' }, 'data-label-key': 'field.cost.quantity' },
        startAdornment,
      }}
      disabled={disabled}
      required={!disabled}
    />
  );
}

export type FieldCostLineRate = Price | null;
const fieldCostLineRateKey = createFieldKey<FieldCostLineRate>();
export function useFieldCostLineRate(initialValue: FieldCostLineRate | null | undefined, disabled: boolean) {
  const [costLineRate, setCostLineRate] = useField(costLineSubFormContext, fieldCostLineRateKey, () => initialValue ?? null);
  const costLineRateIsDirty = useFieldIsDirty(costLineSubFormContext, fieldCostLineRateKey);

  const useValidation = useFieldValidation(costLineSubFormContext, fieldCostLineRateKey);
  useValidation((v) => v != null, [], 'save:required');

  const useMapper = useFieldMapper(costLineSubFormContext, fieldCostLineRateKey);
  useMapper((v, { isDirty }) => flagDirty({ rate: v }, { rate: isDirty }), [], 'sync');

  const renderCostLineRate = useCallback(
    ({
      gridMode,
      startAdornment,
      salesRateResultInitialValue,
      rateUpdateReasonInitialValue,
      inRateUpdateDialog = false,
    }: {
      gridMode: boolean;
      startAdornment: (rate: FieldCostLineRate, rateUpdateReason: FieldCostLineRateUpdateReason) => ReactNode;
      salesRateResultInitialValue: FieldCostLineSalesRateResult | undefined;
      rateUpdateReasonInitialValue: FieldCostLineRateUpdateReason | undefined;
      inRateUpdateDialog?: boolean;
    }) => (
      <CostLineRateInput
        value={costLineRate}
        setValue={setCostLineRate}
        disabled={disabled}
        gridMode={gridMode}
        startAdornment={startAdornment}
        salesRateResultInitialValue={salesRateResultInitialValue}
        rateUpdateReasonInitialValue={rateUpdateReasonInitialValue}
        inRateUpdateDialog={inRateUpdateDialog}
      />
    ),
    [costLineRate, setCostLineRate, disabled],
  );

  return { costLineRate, setCostLineRate, costLineRateIsDirty, renderCostLineRate };
}
function CostLineRateInput({
  value,
  setValue,
  disabled,
  gridMode,
  startAdornment,
  salesRateResultInitialValue,
  rateUpdateReasonInitialValue,
  inRateUpdateDialog,
}: {
  value: FieldCostLineRate;
  setValue: SetValueFn<FieldCostLineRate>;
  disabled: boolean;
  gridMode: boolean;
  startAdornment: (rate: FieldCostLineRate, rateUpdateReason: FieldCostLineRateUpdateReason) => ReactNode;
  salesRateResultInitialValue: FieldCostLineSalesRateResult | undefined;
  rateUpdateReasonInitialValue: FieldCostLineRateUpdateReason | undefined;
  inRateUpdateDialog: boolean;
}) {
  const hasErrors = useFieldHasErrors(costLineSubFormContext, fieldCostLineRateKey);
  const { t } = useAmbientTranslation();

  const [open, setOpen] = useState(false);
  const { costLineSalesRateResult } = useFieldCostLineSalesRateResult(salesRateResultInitialValue);
  const {
    costLineRateUpdateReason,
    costLineRateUpdateReasonIsRequired,
    costLineRateUpdateReasonIsRequiredOnRateChange,
    setCostLineRateUpdateReason,
  } = useFieldCostLineRateUpdateReason(rateUpdateReasonInitialValue, value, costLineSalesRateResult);

  const salesRateResultPrice = costLineSalesRateResult?.value?.price ?? null;
  const inputRef = useRef<HTMLInputElement>();

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const priceInput = (
    <PriceInput
      value={value}
      onChange={(rate) => setValue(rate)}
      error={hasErrors}
      label={gridMode ? '' : t('field.cost.rate')}
      className={gridMode ? 'borderless' : undefined}
      helperText={
        !gridMode &&
        !inRateUpdateDialog &&
        costLineRateUpdateReasonIsRequired && (
          <Typography component='span' sx={{ color: theme.palette.warning.main }}>
            {t('salesRate.salesRatePrice', { price: salesRateResultPrice?.format(t), ns: 'jobs' })}
          </Typography>
        )
      }
      InputProps={{
        startAdornment: startAdornment(value, costLineRateUpdateReason),
        inputProps: { 'data-label-key': 'field.cost.rate' },
      }}
      disabled={disabled}
      required
      inputRef={inputRef}
      onFocus={() => {
        if (gridMode && !inRateUpdateDialog && !disabled && costLineRateUpdateReasonIsRequiredOnRateChange) {
          // prevent the input from gaining back focus when the dialog is closed, which would open the dialog again
          inputRef.current?.blur();
          setOpen(true);
        }
      }}
    />
  );

  return (
    <>
      {smallScreen ? (
        <Stack direction='row' spacing={1}>
          {priceInput}
          <Button
            onClick={() => {
              setValue(salesRateResultPrice);
              setCostLineRateUpdateReason(null);
            }}
            variant='text'
            color='error'
            sx={{ flexShrink: 0 }}>
            {t('salesRate.rateUpdateDialog.buttonLabels.reset', { ns: 'jobs' })}
          </Button>
        </Stack>
      ) : (
        priceInput
      )}
      {!inRateUpdateDialog && (
        <CostLineRateUpdateDialog
          open={open}
          onClose={() => setOpen(false)}
          rate={value}
          setRate={setValue}
          rateUpdateReason={costLineRateUpdateReason}
          setRateUpdateReason={setCostLineRateUpdateReason}
          salesRateResult={costLineSalesRateResult}
        />
      )}
    </>
  );
}

export type FieldCostLineRateUpdateReason = string | null;
const fieldCostLineRateUpdateReasonKey = createFieldKey<FieldCostLineRateUpdateReason>();
export function useFieldCostLineRateUpdateReason(
  initialValue: FieldCostLineRateUpdateReason | null | undefined,
  costLineRate: FieldCostLineRate,
  costLineSalesRateResult: FieldCostLineSalesRateResult,
) {
  const [costLineRateUpdateReason, setCostLineRateUpdateReason] = useField(
    costLineSubFormContext,
    fieldCostLineRateUpdateReasonKey,
    () => initialValue ?? null,
  );

  const salesRateResultPrice = costLineSalesRateResult?.value?.price ?? null;
  const costLineRateUpdateReasonIsRequiredOnRateChange = !!costLineSalesRateResult?.error || !!salesRateResultPrice?.isPositive();
  const costLineRateUpdateReasonIsRequired = costLineRateUpdateReasonIsRequiredOnRateChange && costLineRate !== salesRateResultPrice;

  const useValidation = useFieldValidation(costLineSubFormContext, fieldCostLineRateUpdateReasonKey);
  useValidation((v) => !costLineRateUpdateReasonIsRequired || !!v?.trim(), [costLineRateUpdateReasonIsRequired], 'save:required');

  const useMapper = useFieldMapper(costLineSubFormContext, fieldCostLineRateUpdateReasonKey);
  useMapper((v, { isDirty }) => flagDirty({ rateUpdateReason: v }, { rateUpdateReason: isDirty }), [], 'sync');

  const renderCostLineRateUpdateReason = useCallback(
    () => <RateUpdateReasonInput value={costLineRateUpdateReason} setValue={setCostLineRateUpdateReason} />,
    [costLineRateUpdateReason, setCostLineRateUpdateReason],
  );

  return {
    costLineRateUpdateReason,
    costLineRateUpdateReasonIsRequired,
    costLineRateUpdateReasonIsRequiredOnRateChange,
    setCostLineRateUpdateReason,
    renderCostLineRateUpdateReason,
  };
}
function RateUpdateReasonInput({
  value,
  setValue,
}: {
  value: FieldCostLineRateUpdateReason;
  setValue: SetValueFn<FieldCostLineRateUpdateReason>;
}) {
  const { t } = useAmbientTranslation();
  const hasErrors = useFieldHasErrors(costLineSubFormContext, fieldCostLineRateUpdateReasonKey);
  return (
    <TextField
      value={value ?? ''}
      onChange={(event) => setValue(event.target.value)}
      onBlur={(event) => setValue(event.target.value.trim())}
      label={t('field.cost.rateUpdateReason')}
      error={hasErrors}
      required
    />
  );
}

export type FieldCostLineBillable = boolean | null;
const fieldCostLineBillableKey = createFieldKey<FieldCostLineBillable>();
export function useFieldCostLineBillable(initialValue: FieldCostLineBillable | undefined, disabled: boolean) {
  const [costLineBillable, setCostLineBillable] = useField(costLineSubFormContext, fieldCostLineBillableKey, () => initialValue ?? false);
  const costLineBillableIsDirty = useFieldIsDirty(costLineSubFormContext, fieldCostLineBillableKey);

  const useMapper = useFieldMapper(costLineSubFormContext, fieldCostLineBillableKey);
  useMapper((v, { isDirty }) => flagDirty({ billable: v }, { billable: isDirty }), [], 'sync');

  const renderCostLineBillable = useCallback(
    (gridMode: boolean) => (
      <BillableInput value={costLineBillable} setValue={setCostLineBillable} gridMode={gridMode} disabled={disabled} />
    ),
    [costLineBillable, disabled, setCostLineBillable],
  );

  return { costLineBillable, setCostLineBillable, costLineBillableIsDirty, renderCostLineBillable };
}
function BillableInput({
  value,
  setValue,
  gridMode,
  disabled,
}: {
  value: FieldCostLineBillable;
  setValue: SetValueFn<FieldCostLineBillable>;
  gridMode: boolean;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();

  return gridMode ? (
    <Checkbox checked={value ?? false} onChange={(_, v) => setValue(v)} disabled={disabled} />
  ) : (
    <BoxedFormControlLabel
      checked={value ?? false}
      onChange={(_, v) => setValue(v)}
      control={<Checkbox />}
      label={t('field.cost.billable')}
      disabled={disabled}
    />
  );
}
