/**
 * @generated
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck


export const creditCategories = ["A", "AA", "AAA", "B", "C", "D", "E", "F", "NONE"] as const;
export type CreditCategory = typeof creditCategories[number];

export function isCreditCategory(value: string): value is CreditCategory {
  return creditCategories.includes(value)
}

export function asCreditCategory(value: string): CreditCategory | null {
  return isCreditCategory(value) ? value : null;
}

export function castCreditCategory(value: string): CreditCategory {
  if(!isCreditCategory(value)) {
    throw new Error(`Invalid Enum value for type "CreditCategory": "${value}"`);
  }
  
  return value;
}
