/**
 * @generated SignedSource<<f9724c8f13950bc86315d09400aecb19>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PatchOperationAction = "delete" | "insert" | "skip" | "update" | "%future added value";
export type UpdateCompetitorListInput = {
  competitors: ReadonlyArray<PatchOperationOfSaveCompetitorInput | null | undefined>;
};
export type PatchOperationOfSaveCompetitorInput = {
  action: PatchOperationAction;
  id?: string | null | undefined;
  value?: SaveCompetitorInput | null | undefined;
};
export type SaveCompetitorInput = {
  name: string;
};
export type TabCompetitors_SaveButtonMutation$variables = {
  data: UpdateCompetitorListInput;
};
export type TabCompetitors_SaveButtonMutation$data = {
  readonly updateCompetitors: {
    readonly errors: ReadonlyArray<{
      readonly __typename: string;
    }> | null | undefined;
  };
};
export type TabCompetitors_SaveButtonMutation = {
  response: TabCompetitors_SaveButtonMutation$data;
  variables: TabCompetitors_SaveButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "data"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "updateCompetitorList",
            "variableName": "data"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "UpdateCompetitorsPayload",
    "kind": "LinkedField",
    "name": "updateCompetitors",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TabCompetitors_SaveButtonMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TabCompetitors_SaveButtonMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "69c7d86fc237a27c7441da4d14a645fa",
    "id": null,
    "metadata": {},
    "name": "TabCompetitors_SaveButtonMutation",
    "operationKind": "mutation",
    "text": "mutation TabCompetitors_SaveButtonMutation(\n  $data: UpdateCompetitorListInput!\n) {\n  updateCompetitors(input: {updateCompetitorList: $data}) {\n    errors {\n      __typename\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "999cb9de9d1c7c69c74a0b6f3d499cb3";

export default node;
