/**
 * @generated SignedSource<<ffaff4a9fbb713e9568b68bd0d925255>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useShowRepresentativeListFragment$data = {
  readonly roles: ReadonlyArray<string>;
  readonly " $fragmentType": "useShowRepresentativeListFragment";
};
export type useShowRepresentativeListFragment$key = {
  readonly " $data"?: useShowRepresentativeListFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useShowRepresentativeListFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useShowRepresentativeListFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roles",
      "storageKey": null
    }
  ],
  "type": "Employee",
  "abstractKey": null
};

(node as any).hash = "53444ac54fe5372328ff86c28143f8aa";

export default node;
