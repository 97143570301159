import { Tooltip } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { useTranslation } from 'react-i18next';
import { SalesRateResult } from '../../common/types/externalResult';
import { useMemo } from 'react';
import { Price } from '../../common/utils/price';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';

export function IssueIndicatorSalesQuantity({
  quantity,
  salesRateResult,
  isFixedQuantity,
}: {
  quantity: number | null;
  salesRateResult: SalesRateResult | null | undefined;
  isFixedQuantity?: boolean;
}) {
  const { t } = useTranslation('jobs');
  const quantityTooLow = useMemo(() => {
    return (
      salesRateResult?.value?.minimumQuantity != null &&
      salesRateResult?.value?.minimumQuantity > 0 &&
      quantity != null &&
      quantity < salesRateResult.value.minimumQuantity
    );
  }, [quantity, salesRateResult?.value?.minimumQuantity]);

  return (
    quantityTooLow &&
    !isFixedQuantity && (
      <IssueIndicator description={t('salesRate.minimumQuantity', { quantity: salesRateResult?.value?.minimumQuantity })} severity='warn' />
    )
  );
}

export type Severity = 'info' | 'warn' | 'error';
export function IssueIndicatorSalesRate({
  salesRateResult,
  rate,
  rateUpdateReason,
}: {
  salesRateResult: SalesRateResult | null | undefined;
  rate?: Price | null;
  rateUpdateReason?: string | null;
}) {
  const { t } = useTranslation('jobs');

  const salesRateResultPrice = salesRateResult?.value?.price ?? null;

  const issue: { description: string; severity: Severity } | null = useMemo(() => {
    // the sales rate returned an error - print it as is so the user knows what's uo
    if (salesRateResult?.error != null) {
      return { description: `${salesRateResult.error.code} - ${salesRateResult.error.description}`, severity: 'error' };
    }

    // The sales rate has no value and not error - we assume this is a guay-facing issue and mention the missing price
    if (salesRateResult?.value == null) {
      return { description: t('salesRate.missingPrice'), severity: 'info' };
    }

    // The user manually modified the rate coming from the sales rate - warn them about this
    if (rate && salesRateResultPrice !== rate) {
      return {
        description: salesRateResultPrice?.isPositive()
          ? t('salesRate.manuallyEditedRate', { rate: salesRateResultPrice?.format(t), reason: rateUpdateReason })
          : t('salesRate.manuallyEditedRateNoReason', { rate: salesRateResultPrice?.format(t) }),
        severity: 'warn',
      };
    }

    // No issues, we're in the clear
    return null;
  }, [rate, rateUpdateReason, salesRateResult, salesRateResultPrice, t]);

  return issue && <IssueIndicator description={issue.description} severity={issue.severity} />;
}

// Note: Tooltip does not support ReactNode :facepalm:
const icons: Record<Severity, JSX.Element> = {
  info: <InfoIcon color='info' />,
  warn: <WarningIcon color='warning' />,
  error: <ErrorIcon color='error' />,
};

function IssueIndicator({ description, severity }: { description: string; severity: Severity }) {
  return (
    <Tooltip title={description} placement='top'>
      {icons[severity]}
    </Tooltip>
  );
}
