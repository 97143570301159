import { usePromiseMutation } from '../common/hooks/usePromiseMutation';
import graphql from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay';
import { IconButton } from '@mui/material';
import { MouseEvent, useCallback, useRef } from 'react';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { ServiceCallDelayDialog, ServiceCallDelayDialogRef } from './ServiceCallDelayDialog';
import { ServiceCallListDelayButtonMutation } from './__generated__/ServiceCallListDelayButtonMutation.graphql';
import { ServiceCallListDelayButtonFragment$key } from './__generated__/ServiceCallListDelayButtonFragment.graphql';
import { EventBoundary } from '../common/components/EventBoundary';

export function ServiceCallListDelayButton({
  fragmentKey,
  disabled,
  refreshServiceCallList,
}: {
  fragmentKey: ServiceCallListDelayButtonFragment$key | null | undefined;
  disabled?: boolean;
  refreshServiceCallList: () => void;
}) {
  const delayServiceCallRef = useRef<ServiceCallDelayDialogRef>(null);

  const jobRevision = useFragment(
    graphql`
      fragment ServiceCallListDelayButtonFragment on ServiceCallJobRevision {
        id
        snapshot {
          transferredAt
        }
      }
    `,
    fragmentKey,
  );
  const revisionId = jobRevision?.id;

  const [commit, isDelaying] = usePromiseMutation<ServiceCallListDelayButtonMutation>(graphql`
    mutation ServiceCallListDelayButtonMutation($revisionId: ID!, $snapshot: SaveServiceCallInput!) {
      delayServiceCall(input: { revisionId: $revisionId, snapshot: $snapshot }) {
        lifeCycleBranch {
          id
        }
      }
    }
  `);

  const handleConfirm = useCallback(async () => {
    if (!revisionId) return;

    try {
      await commit({ variables: { revisionId: revisionId, snapshot: {} } });

      // TODO: Add error handling when GG-6315 is merged
    } finally {
      delayServiceCallRef.current?.close();
      refreshServiceCallList();
    }
  }, [commit, refreshServiceCallList, revisionId]);

  const handleOpen = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      delayServiceCallRef.current?.open(handleConfirm);
    },
    [handleConfirm],
  );

  return (
    <EventBoundary>
      <IconButton onClick={handleOpen} disabled={disabled || !jobRevision?.snapshot?.transferredAt}>
        <SkipNextIcon />
      </IconButton>
      <ServiceCallDelayDialog ref={delayServiceCallRef} loading={isDelaying} />
    </EventBoundary>
  );
}
