export const craneCharts = {
  craneCharts: 'Crane Charts',
  dialog: {
    title: 'Deactivate Chart',
    body: 'All the data related to this crane chart will be deleted',
    confirmButton: 'Deactivate',
  },
  error: {
    unexpectedError: 'An unexpected error occurred. Please try again.',
    duringCraneChartDeactivation: 'An error occurred while deactivating the craneChart',
    errorDuringUpload: 'An error occurred while uploading files',
  },
  importErrorMessage: 'An error occurred while uploading files.',
  importSuccessMessage_one: '{{count}} file uploaded and treated.',
  importSuccessMessage_other: '{{count}} files uploaded and treated.',
  list: {
    column: {
      name: 'Name',
      make: 'Make',
      model: 'Model',
      equipmentKind: 'Kind',
      capacity: 'Capacity',
      updatedAt: 'Updated at',
      isActive: 'Active',
      status: 'Status',
    },
  },
};
