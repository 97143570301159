import { SalesRateUpdateDialog, SalesRateUpdateDialogFormProvider } from '../salesRate/SalesRateUpdateDialog';
import {
  costLineSubFormContext,
  FieldCostLineRate,
  FieldCostLineRateUpdateReason,
  FieldCostLineSalesRateResult,
  useFieldCostLineRate,
  useFieldCostLineRateUpdateReason,
} from './CostLineSubFormFields';
import { SetValueFn, useFormValidate } from '../../common/utils/forms';

export interface CostLineRateUpdateDialogProps {
  open: boolean;
  onClose: () => void;
  rate: FieldCostLineRate;
  setRate: SetValueFn<FieldCostLineRate>;
  rateUpdateReason: FieldCostLineRateUpdateReason;
  setRateUpdateReason: SetValueFn<FieldCostLineRateUpdateReason>;
  salesRateResult: FieldCostLineSalesRateResult;
}

export function CostLineRateUpdateDialog({
  open,
  onClose: handleClose,
  rate,
  setRate,
  rateUpdateReason,
  setRateUpdateReason,
  salesRateResult,
}: CostLineRateUpdateDialogProps) {
  return (
    <SalesRateUpdateDialogFormProvider context={costLineSubFormContext} open={open}>
      <CostLineRateUpdateDialogInner
        open={open}
        onClose={handleClose}
        rate={rate}
        setRate={setRate}
        rateUpdateReason={rateUpdateReason}
        setRateUpdateReason={setRateUpdateReason}
        salesRateResult={salesRateResult}
      />
    </SalesRateUpdateDialogFormProvider>
  );
}

function CostLineRateUpdateDialogInner({
  open,
  onClose: handleClose,
  rate,
  setRate,
  rateUpdateReason,
  setRateUpdateReason,
  salesRateResult,
}: {
  open: boolean;
  onClose: () => void;
  rate: FieldCostLineRate;
  setRate: SetValueFn<FieldCostLineRate>;
  rateUpdateReason: FieldCostLineRateUpdateReason;
  setRateUpdateReason: SetValueFn<FieldCostLineRateUpdateReason>;
  salesRateResult: FieldCostLineSalesRateResult;
}) {
  const { costLineRate, renderCostLineRate } = useFieldCostLineRate(rate, false);
  const { costLineRateUpdateReason, renderCostLineRateUpdateReason } = useFieldCostLineRateUpdateReason(
    rateUpdateReason,
    costLineRate,
    salesRateResult,
  );
  const validate = useFormValidate(costLineSubFormContext);

  return (
    <SalesRateUpdateDialog
      open={open}
      onClose={handleClose}
      innerRate={costLineRate}
      setOuterRate={setRate}
      innerRateUpdateReason={costLineRateUpdateReason}
      setOuterRateUpdateReason={setRateUpdateReason}
      salesRateResult={salesRateResult}
      renderRate={() =>
        renderCostLineRate({
          gridMode: false,
          startAdornment: () => null,
          salesRateResultInitialValue: salesRateResult,
          rateUpdateReasonInitialValue: rateUpdateReason,
          inRateUpdateDialog: true,
        })
      }
      renderRateUpdateReason={renderCostLineRateUpdateReason}
      validate={() => validate('save')}
    />
  );
}
