/**
 * @generated SignedSource<<e2570d09013ce32019cd51692bc1b7e3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkPlanningList_RepresentativeFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"JobFilters_useJobFiltersFragment">;
  readonly " $fragmentType": "WorkPlanningList_RepresentativeFragment";
};
export type WorkPlanningList_RepresentativeFragment$key = {
  readonly " $data"?: WorkPlanningList_RepresentativeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkPlanningList_RepresentativeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkPlanningList_RepresentativeFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "JobFilters_useJobFiltersFragment"
    }
  ],
  "type": "Employee",
  "abstractKey": null
};

(node as any).hash = "bb6c789454f369fe21c87573f8693160";

export default node;
