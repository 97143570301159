/**
 * @generated SignedSource<<bb843d684e1b6a443a2491de49cc9ff8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaveBillingCodeInput = {
  enMicroDescription?: string | null | undefined;
  frMicroDescription?: string | null | undefined;
};
export type BillingCodeSaveButtonMutation$variables = {
  id: string;
  saveBillingCode: SaveBillingCodeInput;
};
export type BillingCodeSaveButtonMutation$data = {
  readonly saveBillingCode: {
    readonly billingCode: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"BillingCodeDetails_FormFragment">;
    } | null | undefined;
    readonly errors: ReadonlyArray<{
      readonly __typename: "SalesApiValidationError";
      readonly " $fragmentSpreads": FragmentRefs<"ErrorBannerValidationErrorFragment">;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    }> | null | undefined;
  };
};
export type BillingCodeSaveButtonMutation = {
  response: BillingCodeSaveButtonMutation$data;
  variables: BillingCodeSaveButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "saveBillingCode"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "saveBillingCode",
    "variableName": "saveBillingCode"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BillingCodeSaveButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SaveBillingCodePayload",
        "kind": "LinkedField",
        "name": "saveBillingCode",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingCode",
            "kind": "LinkedField",
            "name": "billingCode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "BillingCodeDetails_FormFragment"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ErrorBannerValidationErrorFragment"
                  }
                ],
                "type": "SalesApiValidationError",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BillingCodeSaveButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SaveBillingCodePayload",
        "kind": "LinkedField",
        "name": "saveBillingCode",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingCode",
            "kind": "LinkedField",
            "name": "billingCode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "subCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "microDescription",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SalesValidationError",
                    "kind": "LinkedField",
                    "name": "errors",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "propertyName",
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "index",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "comparisonValue",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "comparisonPropertyName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "actualValue",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "SalesApiValidationError",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fe9e15dedb733692a2dd96d3b9c75bca",
    "id": null,
    "metadata": {},
    "name": "BillingCodeSaveButtonMutation",
    "operationKind": "mutation",
    "text": "mutation BillingCodeSaveButtonMutation(\n  $id: ID!\n  $saveBillingCode: SaveBillingCodeInput!\n) {\n  saveBillingCode(id: $id, saveBillingCode: $saveBillingCode) {\n    billingCode {\n      id\n      ...BillingCodeDetails_FormFragment\n    }\n    errors {\n      __typename\n      ... on SalesApiValidationError {\n        ...ErrorBannerValidationErrorFragment\n      }\n    }\n  }\n}\n\nfragment BillingCodeDetails_FormFragment on BillingCode {\n  id\n  code\n  subCode\n  description\n  ...BillingCodeFields_LabelFrFragment\n  ...BillingCodeFields_LabelEnFragment\n}\n\nfragment BillingCodeFields_LabelEnFragment on BillingCode {\n  microDescription\n}\n\nfragment BillingCodeFields_LabelFrFragment on BillingCode {\n  microDescription\n}\n\nfragment ErrorBannerValidationErrorFragment on SalesApiValidationError {\n  errors {\n    propertyName\n    code\n    index\n    comparisonValue\n    comparisonPropertyName\n    actualValue\n  }\n}\n"
  }
};
})();

(node as any).hash = "9679b906734179522db1d275b92bafe1";

export default node;
