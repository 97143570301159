export const jobStages = ['workPlanning', 'quote', 'serviceCall'] as const;
export type JobStage = (typeof jobStages)[number];

export function isJobStage(value: string): value is JobStage {
  return jobStages.includes(value as JobStage);
}

export const flagNameMap: Record<JobStage, string> = {
  workPlanning: 'app_navigation_work_plannings',
  quote: 'app_navigation_quote',
  serviceCall: 'app_navigation_service_calls',
};
