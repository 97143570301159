import { DateTime, Duration } from 'luxon';
import TimeAgo from 'react-timeago';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import frenchStrings from 'react-timeago/lib/language-strings/fr';
import englishStrings from 'react-timeago/lib/language-strings/en';

export const defaultTimezone = 'America/Montreal';

export const timeFormat = 'HH:mm';

export const dateTimeFormat = 'dd/MM/yyyy HH:mm';

export const dateFormat = 'dd/MM/yyyy';

export const roundDateTimeToMinuteStep = (dateTime: DateTime, minuteStep: number): DateTime => {
  if (minuteStep <= 0) throw new Error('minuteStep must be a positive number');
  const minute = Math.round(dateTime.minute / minuteStep) * minuteStep;
  const rounded = dateTime.set({ minute, second: 0, millisecond: 0 });
  return rounded.hasSame(dateTime, 'day') ? rounded : rounded.minus({ minute: minuteStep }); // prevent overflow to next day
};

export const isValidDate = (dateString: string | null): boolean => {
  if (!dateString) return false;

  const parsedDate = DateTime.fromISO(dateString);
  return parsedDate.isValid;
};

/**
 * Returns a TimeAgo formatter for the specified language.
 * Since a missing translation here is low-value, we fall back to the default formatter to avoid crashing the
 * app in the event that a new language is added.
 * @param language - Language code
 */
export const timeAgoFormatter = (language: string): TimeAgo.Formatter | undefined => {
  const languageTwoLetterCode = language.substring(0, 2);
  switch (languageTwoLetterCode) {
    case 'fr':
      return buildFormatter(frenchStrings);
    case 'en':
      return buildFormatter(englishStrings);
    default:
      return buildFormatter(englishStrings);
  }
};

/**
 * Parses a date time given its string raw value
 * @param rawValue - Date in string value
 */
export function parseDateTime(rawValue: string | null | undefined): DateTime | null {
  if (rawValue) {
    const dateTime = DateTime.fromISO(rawValue);
    if (dateTime.isValid) return dateTime;
  }
  return null;
}

/**
 * Parses a string in format 'PT0S' (00:00), 'PT1H' (01:00), 'PT12H' (12:00), 'PT1H5M' (01:05), 'PT1H30M' (01:30), 'PT30M' (00:30)
 * @param rawValue - TimeOnly in string value
 */
export function parseTimeOnly(rawValue: string | null | undefined): DateTime | null {
  if (!rawValue) {
    return null;
  }
  const { hours, minutes } = Duration.fromISO(rawValue).toObject();
  return DateTime.fromObject({ hour: hours, minute: minutes });
}
