/**
 * @generated SignedSource<<506cff5b3eba1a5b97a7f242ce39475d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NatureOfWorkSubCategoryEnum = "accessory" | "airports" | "antennas" | "aqueductsSewer" | "blade" | "bladeChange" | "bridges" | "cages" | "chambers" | "dams" | "demolition" | "distribution" | "docks" | "excavation" | "gearboxes" | "generator" | "gondola" | "hand" | "handling" | "landscaping" | "lifting" | "lines" | "modules" | "newConstruction" | "nonOperated" | "powerPlants" | "pruning" | "pylons" | "refurbishment" | "retentionBasins" | "roadsRails" | "rotor" | "shelters" | "substations" | "towers" | "transformers" | "transport" | "valves" | "ventilationPlumbingElectrical" | "%future added value";
export type WorkScheduleEnum = "fiveDaysEightHours" | "fiveDaysNineHours" | "fiveDaysTenHours" | "fourDaysTenHours" | "fourDaysTenHoursOneDayFiveHours" | "%future added value";
export type SaveNatureOfWorkInput = {
  defaultWorkSchedule: WorkScheduleEnum;
  natureOfWorkSubCategories: ReadonlyArray<NatureOfWorkSubCategoryEnum>;
  workSchedules: ReadonlyArray<WorkScheduleEnum>;
};
export type NatureOfWorkSaveButtonMutation$variables = {
  id: string;
  saveNatureOfWork: SaveNatureOfWorkInput;
};
export type NatureOfWorkSaveButtonMutation$data = {
  readonly saveNatureOfWork: {
    readonly errors: ReadonlyArray<{
      readonly __typename: "SalesApiValidationError";
      readonly " $fragmentSpreads": FragmentRefs<"ErrorBannerValidationErrorFragment">;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    }> | null | undefined;
    readonly natureOfWorkLookup: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"NatureOfWorkDetails_FormFragment">;
    } | null | undefined;
  };
};
export type NatureOfWorkSaveButtonMutation = {
  response: NatureOfWorkSaveButtonMutation$data;
  variables: NatureOfWorkSaveButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "saveNatureOfWork"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "saveNatureOfWork",
    "variableName": "saveNatureOfWork"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NatureOfWorkSaveButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SaveNatureOfWorkPayload",
        "kind": "LinkedField",
        "name": "saveNatureOfWork",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NatureOfWorkLookup",
            "kind": "LinkedField",
            "name": "natureOfWorkLookup",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "NatureOfWorkDetails_FormFragment"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ErrorBannerValidationErrorFragment"
                  }
                ],
                "type": "SalesApiValidationError",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NatureOfWorkSaveButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SaveNatureOfWorkPayload",
        "kind": "LinkedField",
        "name": "saveNatureOfWork",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NatureOfWorkLookup",
            "kind": "LinkedField",
            "name": "natureOfWorkLookup",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "workSchedules",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "defaultWorkSchedule",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "natureOfWorkSubCategories",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SalesValidationError",
                    "kind": "LinkedField",
                    "name": "errors",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "propertyName",
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "index",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "comparisonValue",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "comparisonPropertyName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "actualValue",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "SalesApiValidationError",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "82c8e354ed9f5e69bd37cbb70b00b464",
    "id": null,
    "metadata": {},
    "name": "NatureOfWorkSaveButtonMutation",
    "operationKind": "mutation",
    "text": "mutation NatureOfWorkSaveButtonMutation(\n  $id: ID!\n  $saveNatureOfWork: SaveNatureOfWorkInput!\n) {\n  saveNatureOfWork(id: $id, saveNatureOfWork: $saveNatureOfWork) {\n    natureOfWorkLookup {\n      id\n      ...NatureOfWorkDetails_FormFragment\n    }\n    errors {\n      __typename\n      ... on SalesApiValidationError {\n        ...ErrorBannerValidationErrorFragment\n      }\n    }\n  }\n}\n\nfragment ErrorBannerValidationErrorFragment on SalesApiValidationError {\n  errors {\n    propertyName\n    code\n    index\n    comparisonValue\n    comparisonPropertyName\n    actualValue\n  }\n}\n\nfragment NatureOfWorkDetails_FormFragment on NatureOfWorkLookup {\n  id\n  code\n  description\n  ...NatureOfWorkFields_WorkSchedulesFragment\n  ...NatureOfWorkFields_DefaultWorkScheduleFragment\n  ...NatureOfWorkFields_CategoriesFragment\n  ...NatureOfWorkDetails_useSetWorkSchedulesFragment\n}\n\nfragment NatureOfWorkDetails_useSetWorkSchedulesFragment on NatureOfWorkLookup {\n  ...NatureOfWorkFields_WorkSchedulesFragment\n  ...NatureOfWorkFields_DefaultWorkScheduleFragment\n}\n\nfragment NatureOfWorkFields_CategoriesFragment on NatureOfWorkLookup {\n  natureOfWorkSubCategories\n}\n\nfragment NatureOfWorkFields_DefaultWorkScheduleFragment on NatureOfWorkLookup {\n  defaultWorkSchedule\n}\n\nfragment NatureOfWorkFields_WorkSchedulesFragment on NatureOfWorkLookup {\n  workSchedules\n}\n"
  }
};
})();

(node as any).hash = "a7907afbd6a8b1d9537e92c8f6b1f1fd";

export default node;
