import { createFieldKey, createFormContext, SetValueFn, useField, useFieldIsDirty } from '../../common/utils/forms';
import { ForwardCostBillingCodeAutocompleteProps } from '../../common/components/CostBillingCodeAutocomplete';
import { DataID, useLazyLoadQuery } from 'react-relay';
import { SelectPicker } from '../../common/components/SelectPicker';
import { ForwardWorkForceTypeAutocompleteProps, WorkForceTypeAutocomplete } from '../../common/components/WorkForceTypeAutocomplete';
import graphql from 'babel-plugin-relay/macro';
import { Suspense } from 'react';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { useTranslation } from 'react-i18next';
import { BillingCodeRuleAutomaticSubForm_WorkForceTypeInputQuery } from './__generated__/BillingCodeRuleAutomaticSubForm_WorkForceTypeInputQuery.graphql';
import { resolvedLanguage } from '../../i18n';
import { FieldBillingCodesVisible } from './BillingCodeRuleFields';

export const billingCodeRuleAutomaticSubFormContext = createFormContext<object>();

export type FieldAutomaticBillingCode = NonNullable<ForwardCostBillingCodeAutocompleteProps<false, boolean>['value']> | null;
const fieldAutomaticBillingCodeKey = createFieldKey<FieldAutomaticBillingCode>();
export function useFieldAutomaticBillingCode(initialValue: FieldAutomaticBillingCode | undefined) {
  const [automaticBillingCode, setAutomaticBillingCode] = useField(
    billingCodeRuleAutomaticSubFormContext,
    fieldAutomaticBillingCodeKey,
    () => initialValue ?? null,
  );

  const automaticBillingCodeIsDirty = useFieldIsDirty(billingCodeRuleAutomaticSubFormContext, fieldAutomaticBillingCodeKey);

  const renderAutomaticBillingCode = (billingCodesVisible: FieldBillingCodesVisible) => (
    <AutomaticBillingCodeInput billingCodesVisible={billingCodesVisible} value={automaticBillingCode} setValue={setAutomaticBillingCode} />
  );

  return { automaticBillingCode, setAutomaticBillingCode, automaticBillingCodeIsDirty, renderAutomaticBillingCode };
}

function AutomaticBillingCodeInput({
  billingCodesVisible,
  value,
  setValue,
}: {
  billingCodesVisible: FieldBillingCodesVisible;
  value: FieldAutomaticBillingCode;
  setValue: SetValueFn<FieldAutomaticBillingCode>;
}) {
  const { t, i18n } = useAmbientTranslation();

  return (
    <SelectPicker
      value={value ?? null}
      disableClearable={value != null}
      options={billingCodesVisible}
      onChange={(_, newValue) => setValue(newValue)}
      groupBy={(option) => option.billingSection?.shortDescription[resolvedLanguage(i18n)] ?? ''}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      getOptionKey={(o) => o.id}
      textFieldProps={() => ({
        placeholder: t('button.select', { ns: 'common' }),
        className: 'borderless',
      })}
    />
  );
}

export type FieldAutomaticBillingCodeWorkForceType = NonNullable<ForwardWorkForceTypeAutocompleteProps['value']> | null;
const fieldAutomaticBillingCodeWorkForceTypeKey = createFieldKey<FieldAutomaticBillingCodeWorkForceType>();
export function useAutomaticBillingCodeWorkForceType(initialValue: FieldAutomaticBillingCodeWorkForceType | undefined) {
  const [automaticBillingCodeWorkForceType, setAutomaticBillingCodeWorkForceType] = useField(
    billingCodeRuleAutomaticSubFormContext,
    fieldAutomaticBillingCodeWorkForceTypeKey,
    () => initialValue ?? null,
  );

  const automaticBillingCodeWorkForceTypeIsDirty = useFieldIsDirty(
    billingCodeRuleAutomaticSubFormContext,
    fieldAutomaticBillingCodeWorkForceTypeKey,
  );

  const renderAutomaticBillingCodeWorkForceType = (billingCodeId: DataID | null) => (
    <AutomaticBillingCodeWorkForceTypeInput
      billingCodeId={billingCodeId}
      value={automaticBillingCodeWorkForceType}
      setValue={setAutomaticBillingCodeWorkForceType}
    />
  );

  return {
    automaticBillingCodeWorkForceType,
    setAutomaticBillingCodeWorkForceType,
    automaticBillingCodeWorkForceTypeIsDirty,
    renderAutomaticBillingCodeWorkForceType,
  };
}

export function AutomaticBillingCodeWorkForceTypeInput({
  billingCodeId,
  value,
  setValue,
}: {
  billingCodeId: DataID | null;
  value: FieldAutomaticBillingCodeWorkForceType;
  setValue: SetValueFn<FieldAutomaticBillingCodeWorkForceType>;
}) {
  if (billingCodeId) {
    return (
      <Suspense fallback={<AutomaticBillingCodeWorkForceTypeInput_Content value={value} setValue={setValue} disabled loading={true} />}>
        <AutomaticBillingCodeWorkForceTypeInput_Queried value={value} setValue={setValue} billingCodeId={billingCodeId} />
      </Suspense>
    );
  }

  return <AutomaticBillingCodeWorkForceTypeInput_Content value={value} setValue={setValue} disabled />;
}

function AutomaticBillingCodeWorkForceTypeInput_Queried({
  billingCodeId,
  value,
  setValue,
}: {
  billingCodeId: DataID;
  value: FieldAutomaticBillingCodeWorkForceType;
  setValue: SetValueFn<FieldAutomaticBillingCodeWorkForceType>;
}) {
  const $data = useLazyLoadQuery<BillingCodeRuleAutomaticSubForm_WorkForceTypeInputQuery>(
    graphql`
      query BillingCodeRuleAutomaticSubForm_WorkForceTypeInputQuery($billingCodeId: ID!) {
        node(id: $billingCodeId) {
          ... on BillingCode {
            billingCodeCategory
          }
        }
      }
    `,
    { billingCodeId },
  );

  return (
    <AutomaticBillingCodeWorkForceTypeInput_Content
      value={value}
      setValue={setValue}
      disabled={$data.node?.billingCodeCategory !== 'labor'}
    />
  );
}

function AutomaticBillingCodeWorkForceTypeInput_Content({
  value,
  setValue,
  disabled,
  loading,
}: {
  value: FieldAutomaticBillingCodeWorkForceType;
  setValue: SetValueFn<FieldAutomaticBillingCodeWorkForceType>;
  disabled: boolean;
  loading?: boolean;
}) {
  const { t } = useTranslation('common');

  return (
    <WorkForceTypeAutocomplete
      value={value}
      onChange={(newValue) => setValue(newValue)}
      textFieldProps={() => ({ className: 'borderless', placeholder: !disabled ? t('button.select') : undefined })}
      disabled={disabled || loading}
    />
  );
}
