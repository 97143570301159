import graphql from 'babel-plugin-relay/macro';
import { DataID, useFragment } from 'react-relay';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { resolvedLanguage } from '../../i18n';
import {
  ResponsiveGridColumnDefinition,
  ResponsiveGridContent,
  ResponsiveGridHeader,
  ResponsiveGridItem,
} from '../../common/components/ResponsiveGrid';
import { EmptyList } from '../../layout/components/form/FormList';
import { EllipsisedTypography } from '../../common/components/EllipsisedTypography';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { BillingCodesListItemFragment$key } from './__generated__/BillingCodesListItemFragment.graphql';
import { BillingCodesListFragment$key } from './__generated__/BillingCodesListFragment.graphql';

export function BillingCodesList({ $key }: { $key: BillingCodesListFragment$key | null | undefined }) {
  const { t } = useAmbientTranslation();
  const $data = useFragment(
    graphql`
      fragment BillingCodesListFragment on Query {
        billingCodes(where: { billingCodeCategory: { eq: accessories } }) {
          id
          ...BillingCodesListItemFragment
        }
      }
    `,
    $key,
  );

  const columns: ResponsiveGridColumnDefinition[] = [
    { id: 'code', label: t('billingCodes.code'), size: 'auto' },
    { id: 'description', label: t('billingCodes.description'), size: 'minmax(1rem, 1fr)' },
    { id: 'labelFr', label: t('billingCodes.field.labelFr'), size: 'minmax(1rem, 0.5fr)' },
    { id: 'labelEn', label: t('billingCodes.field.labelEn'), size: 'minmax(1rem, 0.5fr)' },
  ];
  return $data?.billingCodes?.length ? (
    <ResponsiveGridContent
      gridMode
      gridTemplateColumns='auto minmax(1rem, 1fr) minmax(1rem, 0.5fr) minmax(1rem, 0.5fr)'
      listSx={{
        // Adds a padding in cells to improve readability, specially when elipsing.
        'li.responsive-grid__header  > *': {
          px: '1rem',
        },
        'li:not(.responsive-grid__header)  > *': {
          px: '0.5rem',
        },
      }}>
      <ResponsiveGridHeader columnsDefinitions={columns} />
      {$data?.billingCodes.map((bc) => <BillingCodesListRow key={bc.id} $key={bc} />)}
    </ResponsiveGridContent>
  ) : (
    <EmptyList />
  );
}

function BillingCodesListRow({ $key }: { $key: BillingCodesListItemFragment$key }) {
  const { i18n } = useTranslation();
  const lang = resolvedLanguage(i18n);
  const navigate = useNavigate();

  const $data = useFragment(
    graphql`
      fragment BillingCodesListItemFragment on BillingCode {
        id
        code
        subCode
        description
        microDescription
      }
    `,
    $key,
  );

  const handleItemClick = useCallback((id: DataID) => navigate(`/configuration/billing-codes/${id}`), [navigate]);

  return (
    <ResponsiveGridItem id={$data.id} onItemClick={handleItemClick}>
      <EllipsisedTypography key='code' variant='body2' component='div'>
        {$data.code} - {$data.subCode}
      </EllipsisedTypography>
      <EllipsisedTypography key='description' variant='body2' component='div'>
        {$data.description[lang]}
      </EllipsisedTypography>
      <EllipsisedTypography key='labelFr' variant='body2' component='div'>
        {$data.microDescription?.fr ?? '---'}
      </EllipsisedTypography>
      <EllipsisedTypography key='labelEn' variant='body2' component='div'>
        {$data.microDescription?.en ?? '---'}
      </EllipsisedTypography>
    </ResponsiveGridItem>
  );
}
