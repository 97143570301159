import {
  createFieldKey,
  createFormContext,
  FieldKey,
  SetValueFn,
  useField,
  useFieldErrors,
  useFieldMapper,
  useFieldValidation,
} from '../../common/utils/forms';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, TextFieldProps } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { AddendumKind, isAddendumKind } from '../../__enums__/AddendumKind';
import { SelectPicker, SelectPickerProps } from '../../common/components/SelectPicker';
import { AddendumTemplateFieldsKindFragment$key } from './__generated__/AddendumTemplateFieldsKindFragment.graphql';
import { AddendumTemplateFields_TitleFrFragment$key } from './__generated__/AddendumTemplateFields_TitleFrFragment.graphql';
import { AddendumTemplateFields_TitleEnFragment$key } from './__generated__/AddendumTemplateFields_TitleEnFragment.graphql';
import { AddendumTemplateFields_TemplateFrFragment$key } from './__generated__/AddendumTemplateFields_TemplateFrFragment.graphql';
import { AddendumTemplateFields_TemplateEnFragment$key } from './__generated__/AddendumTemplateFields_TemplateEnFragment.graphql';
import { AddendumTemplateFieldsSaleKindsFragment$key } from './__generated__/AddendumTemplateFieldsSaleKindsFragment.graphql';
import { ForwardSaleKindAutocompleteProps, SaleKindAutocomplete } from '../../common/components/SaleKindAutocomplete';
import { ForwardNatureOfWorkAutocompleteProps, NatureOfWorkAutocomplete } from '../../common/components/NatureOfWorkAutocomplete';
import { AddendumTemplateFieldsNatureOfWorkCodesFragment$key } from './__generated__/AddendumTemplateFieldsNatureOfWorkCodesFragment.graphql';
import {
  ForwardNatureOfWorkSubCategoryAutocompleteProps,
  NatureOfWorkSubCategoryAutocomplete,
} from '../../common/components/NatureOfWorkSubCategoryAutocomplete';
import { castNatureOfWorkSubCategoryEnum, natureOfWorkSubCategoryEnums } from '../../__enums__/NatureOfWorkSubCategoryEnum';
import { AddendumTemplateFieldsNatureOfWorkSubcategoryFragment$key } from './__generated__/AddendumTemplateFieldsNatureOfWorkSubcategoryFragment.graphql';
import { castSalesQuestionKind, SalesQuestionKind } from '../../__enums__/SalesQuestionKind';
import { specialProjects } from '../../jobs/fields/QuestionsBaseFields';
import { AddendumTemplateFieldsQuestionKindsFragment$key } from './__generated__/AddendumTemplateFieldsQuestionKindsFragment.graphql';
import { QuestionKindAutocomplete } from '../../common/components/QuestionKindAutocomplete';
import { isQuoteKind } from '../../__enums__/QuoteKind';
import { isServiceCallKind } from '../../__enums__/ServiceCallKind';
import { _throw } from '../../common/utils/_throw';
import { useTranslation } from 'react-i18next';
import { AddendumTemplateFields_useFieldAddendumTemplateIsAutomaticFragment$key } from './__generated__/AddendumTemplateFields_useFieldAddendumTemplateIsAutomaticFragment.graphql';
import { CraneCapacityAutocomplete, ForwardCraneCapacityAutocompleteProps } from '../../common/components/CraneCapacityAutocomplete';
import { EquipmentKindAutocomplete, ForwardEquipmentKindAutocompleteProps } from '../../common/components/EquipmentKindAutocomplete';
import { AddendumTemplateFields_EquipmentKindsFragment$key } from './__generated__/AddendumTemplateFields_EquipmentKindsFragment.graphql';
import { AddendumTemplateFields_CapacitiesFragment$key } from './__generated__/AddendumTemplateFields_CapacitiesFragment.graphql';
import { SELECTABLE_ADDENDUMKINDS_BY_PRIORITY } from '../../jobs/addenda/AddendumSubFormFields';

/** Maximum length of an addendum template's description. */
export const MAXIMUM_TEMPLATE_LENGTH = 3000;

export interface AddendumTemplateFieldsMappings {}

export const addendumTemplateFormContext = createFormContext<AddendumTemplateFieldsMappings>();

export type FieldCapacities = NonNullable<ForwardCraneCapacityAutocompleteProps<true>['value']>;
const fieldCapacitiesKey = createFieldKey<FieldCapacities>();
export function useFieldCapacities($key: AddendumTemplateFields_CapacitiesFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AddendumTemplateFields_CapacitiesFragment on AddendumTemplate {
        conditions {
          capacities {
            capacity
            label
          }
        }
      }
    `,
    $key,
  );

  const [capacities, setCapacities] = useField(addendumTemplateFormContext, fieldCapacitiesKey, () => $data?.conditions?.capacities ?? []);

  const useMapperCapacities = useFieldMapper(addendumTemplateFormContext, fieldCapacitiesKey);
  useMapperCapacities((val) => ({ conditions: { capacities: val.map((c) => `${c.capacity}`) } }), [], 'save');

  const renderCapacities = useCallback(() => {
    return <CapacitiesInput value={capacities} setValue={setCapacities} />;
  }, [capacities, setCapacities]);

  return { capacities, setCapacities, renderCapacities };
}

export function CapacitiesInput({ value, setValue }: { value: FieldCapacities; setValue: SetValueFn<FieldCapacities> }) {
  const { t } = useAmbientTranslation();

  return (
    <CraneCapacityAutocomplete
      multiple
      vehicleIds={null}
      equipmentKindCode={null}
      configurationKindCode={null}
      value={value}
      onChange={(v) => setValue(v)}
      textFieldProps={() => ({ label: t('addenda.field.capacities') })}
    />
  );
}

export type FieldEquipmentKinds = NonNullable<ForwardEquipmentKindAutocompleteProps<true>['value']>;
const fieldEquipmentKindsKey = createFieldKey<FieldEquipmentKinds>();
export function useFieldEquipmentKinds($key: AddendumTemplateFields_EquipmentKindsFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AddendumTemplateFields_EquipmentKindsFragment on AddendumTemplate {
        conditions {
          equipmentKinds {
            id
            code
            label
          }
        }
      }
    `,
    $key,
  );

  const [equipmentKinds, setEquipmentKinds] = useField(
    addendumTemplateFormContext,
    fieldEquipmentKindsKey,
    () => $data?.conditions?.equipmentKinds.filter((v) => !!v) ?? [],
  );

  const useMapperEquipmentKinds = useFieldMapper(addendumTemplateFormContext, fieldEquipmentKindsKey);
  useMapperEquipmentKinds((val) => ({ conditions: { equipmentKindCodes: val.map((ek) => `${ek.code}`) } }), [], 'save');

  const renderEquipmentKinds = useCallback(
    () => <EquipmentKindsInput value={equipmentKinds} setValue={setEquipmentKinds} />,
    [equipmentKinds, setEquipmentKinds],
  );

  return { equipmentKinds, setEquipmentKinds, renderEquipmentKinds };
}

export function EquipmentKindsInput({ value, setValue }: { value: FieldEquipmentKinds; setValue: SetValueFn<FieldEquipmentKinds> }) {
  const { t } = useAmbientTranslation();

  return (
    <EquipmentKindAutocomplete
      multiple
      capacity={null}
      vehicleIds={null}
      configurationKindCode={null}
      value={value}
      onChange={(v) => setValue(v)}
      textFieldProps={() => ({ label: t('addenda.field.equipmentKinds') })}
    />
  );
}

const fieldTitleFrKey = createFieldKey<string>();
export function useAddendumTemplateTitleFr($key: AddendumTemplateFields_TitleFrFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AddendumTemplateFields_TitleFrFragment on AddendumTemplate {
        title
      }
    `,
    $key,
  );

  const [title, setTitle] = useField(addendumTemplateFormContext, fieldTitleFrKey, () => $data?.title.fr ?? '');
  const useValidation = useFieldValidation(addendumTemplateFormContext, fieldTitleFrKey);
  useValidation((v) => v.trim().length > 0, [], 'save:required');

  const useMapper = useFieldMapper(addendumTemplateFormContext, fieldTitleFrKey);
  useMapper((val) => ({ titleFr: val }), [], 'save');

  const renderAddendumTemplateTitleFr = useCallback(
    () => (
      <TitleInput
        fieldKey={fieldTitleFrKey}
        onChange={(e) => setTitle(e.target.value)}
        onBlur={(e) => setTitle(e.target.value.trim())}
        value={title}
        labelKey='addenda.field.titleFr'
      />
    ),
    [setTitle, title],
  );

  return { titleFr: title, setTitleFr: setTitle, renderAddendumTemplateTitleFr };
}

const fieldTitleEnKey = createFieldKey<string>();
export function useAddendumTemplateTitleEn($key: AddendumTemplateFields_TitleEnFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AddendumTemplateFields_TitleEnFragment on AddendumTemplate {
        title
      }
    `,
    $key,
  );

  const [title, setTitle] = useField(addendumTemplateFormContext, fieldTitleEnKey, () => $data?.title.en ?? '');
  const useValidation = useFieldValidation(addendumTemplateFormContext, fieldTitleEnKey);
  useValidation((v) => v.trim().length > 0, [], 'save:required');

  const useMapper = useFieldMapper(addendumTemplateFormContext, fieldTitleEnKey);
  useMapper((val) => ({ titleEn: val }), [], 'save');

  const renderAddendumTemplateTitleEn = useCallback(
    () => (
      <TitleInput
        fieldKey={fieldTitleEnKey}
        onChange={(e) => setTitle(e.target.value)}
        onBlur={(e) => setTitle(e.target.value.trim())}
        value={title}
        labelKey='addenda.field.titleEn'
      />
    ),
    [setTitle, title],
  );

  return { titleEn: title, setTitleEn: setTitle, renderAddendumTemplateTitleEn };
}
function TitleInput({
  fieldKey,
  onChange: handleChange,
  onBlur: handleBlur,
  value,
  labelKey,
}: {
  fieldKey: FieldKey<string>;
  onChange: TextFieldProps['onChange'];
  onBlur: TextFieldProps['onBlur'];
  value: string;
  labelKey: string;
}) {
  const { t } = useAmbientTranslation();
  const errors = useFieldErrors(addendumTemplateFormContext, fieldKey);

  return <TextField label={t(labelKey)} required value={value} onChange={handleChange} onBlur={handleBlur} error={!!errors['required']} />;
}

const fieldTemplateFrKey = createFieldKey<string>();
export function useAddendumTemplateTemplateFr($key: AddendumTemplateFields_TemplateFrFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AddendumTemplateFields_TemplateFrFragment on AddendumTemplate {
        template
      }
    `,
    $key,
  );

  const [template, setTemplate] = useField(addendumTemplateFormContext, fieldTemplateFrKey, () => $data?.template.fr ?? '');
  const useValidation = useFieldValidation(addendumTemplateFormContext, fieldTemplateFrKey);
  useValidation((v) => v.trim().length > 0, [], 'save:required');

  const useMapper = useFieldMapper(addendumTemplateFormContext, fieldTemplateFrKey);
  useMapper((val) => ({ templateFr: val }), [], 'save');

  const renderAddendumTemplateTemplateFr = useCallback(
    () => (
      <TemplateInput
        fieldKey={fieldTemplateFrKey}
        value={template}
        onChange={(e) => setTemplate(e.target.value)}
        onBlur={(e) => setTemplate(e.target.value.trim())}
        labelKey='addenda.field.templateFr'
      />
    ),
    [setTemplate, template],
  );

  return { templateFr: template, setTemplateFr: setTemplate, renderAddendumTemplateTemplateFr };
}

const fieldTemplateEnKey = createFieldKey<string>();
export function useAddendumTemplateTemplateEn($key: AddendumTemplateFields_TemplateEnFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AddendumTemplateFields_TemplateEnFragment on AddendumTemplate {
        template
      }
    `,
    $key,
  );

  const [template, setTemplate] = useField(addendumTemplateFormContext, fieldTemplateEnKey, () => $data?.template.en ?? '');
  const useValidation = useFieldValidation(addendumTemplateFormContext, fieldTemplateEnKey);
  useValidation((v) => v.trim().length > 0, [], 'save:required');

  const useMapper = useFieldMapper(addendumTemplateFormContext, fieldTemplateEnKey);
  useMapper((val) => ({ templateEn: val }), [], 'save');

  const renderAddendumTemplateTemplateEn = useCallback(
    () => (
      <TemplateInput
        fieldKey={fieldTemplateEnKey}
        value={template}
        onChange={(e) => setTemplate(e.target.value)}
        onBlur={(e) => setTemplate(e.target.value.trim())}
        labelKey='addenda.field.templateEn'
      />
    ),
    [setTemplate, template],
  );

  return { templateEn: template, setTemplateEn: setTemplate, renderAddendumTemplateTemplateEn };
}

function TemplateInput({
  fieldKey,
  onChange: handleChange,
  onBlur: handleBlur,
  value,
  labelKey,
}: {
  fieldKey: FieldKey<string>;
  onChange: TextFieldProps['onChange'];
  onBlur: TextFieldProps['onBlur'];
  value: string;
  labelKey: string;
}) {
  const { t } = useAmbientTranslation();
  const errors = useFieldErrors(addendumTemplateFormContext, fieldKey);

  return (
    <TextField
      onChange={handleChange}
      onBlur={handleBlur}
      required
      multiline
      minRows={3}
      maxRows={10}
      value={value}
      inputProps={{ maxLength: MAXIMUM_TEMPLATE_LENGTH }}
      error={!!errors['required']}
      label={t(labelKey)}
    />
  );
}

export type FieldKind = AddendumKind | null;
const fieldKindKey = createFieldKey<FieldKind>();

export function useAddendumTemplateKind($key: AddendumTemplateFieldsKindFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AddendumTemplateFieldsKindFragment on AddendumTemplate {
        kind
      }
    `,
    $key,
  );

  const [kind, setKind] = useField(addendumTemplateFormContext, fieldKindKey, () =>
    $data?.kind ? (isAddendumKind($data.kind) ? $data.kind : null) : null,
  );
  const useValidation = useFieldValidation(addendumTemplateFormContext, fieldKindKey);
  useValidation((v) => !!v, [], 'save:required');

  const useMapper = useFieldMapper(addendumTemplateFormContext, fieldKindKey);
  useMapper((val) => ({ kind: val ?? _throw(new Error('AddendumTemplateKind should not be null')) }), [], 'save');

  const renderAddendumTemplateKind = useCallback(
    () => (
      <KindInput
        value={kind}
        onChange={(_, v) => {
          setKind(v);
        }}
      />
    ),
    [kind, setKind],
  );

  return { kind, setKind, renderAddendumTemplateKind };
}

function KindInput({
  value,
  onChange: handleChange,
}: {
  value: AddendumKind | null;
  onChange: SelectPickerProps<AddendumKind>['onChange'];
}) {
  const { t } = useAmbientTranslation();
  const errors = useFieldErrors(addendumTemplateFormContext, fieldKindKey);

  return (
    <SelectPicker
      value={value}
      onChange={handleChange}
      options={SELECTABLE_ADDENDUMKINDS_BY_PRIORITY}
      getOptionKey={(o) => o}
      getOptionLabel={(option) => t(`addendumKind.${option}`, { ns: 'jobs' })}
      textFieldProps={(params) => ({
        ...params,
        error: !!errors['required'],
        required: true,
        label: t('addenda.field.kind'),
      })}
    />
  );
}

const fieldIsAutomaticKey = createFieldKey<boolean>();
export function useFieldAddendumTemplateIsAutomatic(
  $key: AddendumTemplateFields_useFieldAddendumTemplateIsAutomaticFragment$key | null | undefined,
) {
  const $data = useFragment(
    graphql`
      fragment AddendumTemplateFields_useFieldAddendumTemplateIsAutomaticFragment on AddendumTemplate {
        isAutomatic
      }
    `,
    $key,
  );

  const [isAutomatic, setIsAutomatic] = useField(addendumTemplateFormContext, fieldIsAutomaticKey, () => $data?.isAutomatic ?? false);

  const useMapper = useFieldMapper(addendumTemplateFormContext, fieldIsAutomaticKey);
  useMapper((val) => ({ isAutomatic: val }), [], 'save');

  const renderIsAutomatic = useCallback(
    () => <AutomaticInput value={isAutomatic} setValue={setIsAutomatic} />,
    [isAutomatic, setIsAutomatic],
  );

  return { isAutomatic, setIsAutomatic, renderIsAutomatic };
}
function AutomaticInput({ value, setValue }: { value: boolean; setValue: SetValueFn<boolean> }) {
  const { t } = useTranslation('configuration');

  return (
    <FormControl component='fieldset'>
      <FormLabel component='legend'>{t('addenda.field.automatic')}</FormLabel>
      <RadioGroup value={value} onChange={(_, v) => setValue(v === 'true')} row>
        <FormControlLabel value={true} control={<Radio />} label={t('yesNo.yes', { ns: 'common' })} />
        <FormControlLabel value={false} control={<Radio />} label={t('yesNo.no', { ns: 'common' })} />
      </RadioGroup>
    </FormControl>
  );
}

export type FieldSaleKinds = NonNullable<ForwardSaleKindAutocompleteProps<true>['value']>;
const fieldSaleKindsKey = createFieldKey<FieldSaleKinds>();
export function useAddendumTemplateSaleKinds($key: AddendumTemplateFieldsSaleKindsFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AddendumTemplateFieldsSaleKindsFragment on AddendumTemplate {
        conditions {
          saleKinds
        }
      }
    `,
    $key,
  );

  const [saleKinds, setSaleKinds] = useField(
    addendumTemplateFormContext,
    fieldSaleKindsKey,
    () => $data?.conditions.saleKinds.filter((k) => isQuoteKind(k) || isServiceCallKind(k)) ?? [],
  );

  const useMapper = useFieldMapper(addendumTemplateFormContext, fieldSaleKindsKey);
  useMapper((val) => ({ conditions: { saleKinds: val } }), [], 'save');

  const renderAddendumTemplateSaleKinds = useCallback(
    () => <SaleKindsInput value={saleKinds} setValue={setSaleKinds} />,
    [saleKinds, setSaleKinds],
  );

  return { saleKinds, setSaleKinds, renderAddendumTemplateSaleKinds };
}

function SaleKindsInput({ value, setValue }: { value: FieldSaleKinds; setValue: SetValueFn<FieldSaleKinds> }) {
  const { t } = useAmbientTranslation();

  return (
    <SaleKindAutocomplete
      multiple
      value={value}
      onChange={(_, v) => setValue([...v])}
      textFieldProps={() => ({ label: t('addenda.field.kinds') })}
    />
  );
}

export type FieldNatureOfWorks = NonNullable<ForwardNatureOfWorkAutocompleteProps<true>['value']>;
const fieldNatureOfWorkCodesKey = createFieldKey<FieldNatureOfWorks>();
export function useFieldNatureOfWorkCodes($key: AddendumTemplateFieldsNatureOfWorkCodesFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AddendumTemplateFieldsNatureOfWorkCodesFragment on AddendumTemplate {
        conditions {
          natureOfWorks {
            id
            code
            label
          }
        }
      }
    `,
    $key,
  );

  const [natureOfWorkCodes, setNatureOfWorkCodes] = useField(
    addendumTemplateFormContext,
    fieldNatureOfWorkCodesKey,
    () => $data?.conditions?.natureOfWorks.filter((v) => !!v) ?? [],
  );

  const useMapperNatureOfWorks = useFieldMapper(addendumTemplateFormContext, fieldNatureOfWorkCodesKey);
  useMapperNatureOfWorks((val) => ({ conditions: { natureOfWorkCodes: val.map((v) => `${v.code}`) } }), [], 'save');

  const renderNatureOfWorkCodes = useCallback(
    () => <NatureOfWorkAutocompleteInput value={natureOfWorkCodes} setValue={setNatureOfWorkCodes} />,
    [natureOfWorkCodes, setNatureOfWorkCodes],
  );

  return { natureOfWorkCodes, setNatureOfWorkCodes, renderNatureOfWorkCodes };
}

function NatureOfWorkAutocompleteInput({ value, setValue }: { value: FieldNatureOfWorks; setValue: SetValueFn<FieldNatureOfWorks> }) {
  const { t } = useAmbientTranslation();
  return (
    <NatureOfWorkAutocomplete
      multiple
      value={value}
      onChange={(v) => setValue(v)}
      textFieldProps={() => ({ label: t('addenda.field.natureOfWorks') })}
    />
  );
}

export type FieldNatureOfWorkSubCategories = NonNullable<ForwardNatureOfWorkSubCategoryAutocompleteProps<true>['value']>;
const fieldNatureOfWorkSubCategoriesKey = createFieldKey<FieldNatureOfWorkSubCategories>();
export function useFieldNatureOfWorkSubCategories($key: AddendumTemplateFieldsNatureOfWorkSubcategoryFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AddendumTemplateFieldsNatureOfWorkSubcategoryFragment on AddendumTemplate {
        conditions {
          natureOfWorkSubCategories
        }
      }
    `,
    $key,
  );

  const [natureOfWorkSubCategories, setNatureOfWorkSubCategories] = useField(
    addendumTemplateFormContext,
    fieldNatureOfWorkSubCategoriesKey,
    () =>
      $data?.conditions.natureOfWorkSubCategories
        ? $data.conditions.natureOfWorkSubCategories.map((sc) => castNatureOfWorkSubCategoryEnum(sc))
        : [],
  );

  const useMapperNatureOfWorkSubCategories = useFieldMapper(addendumTemplateFormContext, fieldNatureOfWorkSubCategoriesKey);
  useMapperNatureOfWorkSubCategories((val) => ({ conditions: { natureOfWorkSubCategories: val } }), [], 'save');

  const renderNatureOfWorkSubCategories = useCallback(
    () => <NatureOfWorkSubCategoryInput value={natureOfWorkSubCategories} setValue={setNatureOfWorkSubCategories} />,
    [natureOfWorkSubCategories, setNatureOfWorkSubCategories],
  );

  return { natureOfWorkSubCategories, setNatureOfWorkSubCategories, renderNatureOfWorkSubCategories };
}

function NatureOfWorkSubCategoryInput({
  value,
  setValue,
}: {
  value: FieldNatureOfWorkSubCategories;
  setValue: SetValueFn<FieldNatureOfWorkSubCategories>;
}) {
  const { t } = useAmbientTranslation();
  const sortedCategories = useMemo(
    () =>
      natureOfWorkSubCategoryEnums
        .map((c) => ({ value: c, label: t(`natureOfWorkSubCategories.${c}`, { ns: 'common' }) }))
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((item) => item.value),
    [t],
  );

  return (
    <NatureOfWorkSubCategoryAutocomplete
      multiple
      value={value}
      onChange={(v) => setValue(v)}
      options={sortedCategories}
      getOptionKey={(o) => o}
      getOptionLabel={(o) => t(`natureOfWorkSubCategories.${o}`, { ns: 'common' })}
      textFieldProps={() => ({
        label: t('addenda.field.natureOfWorkSubCategory'),
      })}
    />
  );
}

export type FieldQuestionKinds = NonNullable<SelectPickerProps<SalesQuestionKind, true>['value']>;
const fieldQuestionKindsKey = createFieldKey<FieldQuestionKinds>();
export function useFieldQuestionKinds($key: AddendumTemplateFieldsQuestionKindsFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AddendumTemplateFieldsQuestionKindsFragment on AddendumTemplate {
        conditions {
          questionKinds
        }
      }
    `,
    $key,
  );

  const [questionKinds, setQuestionKinds] = useField(addendumTemplateFormContext, fieldQuestionKindsKey, () =>
    $data?.conditions.questionKinds ? $data.conditions.questionKinds.map((q) => castSalesQuestionKind(q)) : [],
  );

  const useMapperQuestionKinds = useFieldMapper(addendumTemplateFormContext, fieldQuestionKindsKey);
  useMapperQuestionKinds((val) => ({ conditions: { questionKinds: val } }), [], 'save');

  const renderQuestionKinds = useCallback(
    () => <QuestionKindsInput value={questionKinds} setValue={setQuestionKinds} />,
    [questionKinds, setQuestionKinds],
  );

  return { questionKinds, setQuestionKinds, renderQuestionKinds };
}

function QuestionKindsInput({ value, setValue }: { value: FieldQuestionKinds; setValue: SetValueFn<FieldQuestionKinds> }) {
  const { t } = useAmbientTranslation();
  return (
    <QuestionKindAutocomplete
      multiple
      value={value}
      onChange={(_, v) => setValue(v)}
      groupBy={(o) => (specialProjects.includes(o) ? t('addenda.field.specialProjects') : '')}
      textFieldProps={() => ({
        label: t('addenda.field.specialSituation'),
      })}
    />
  );
}
