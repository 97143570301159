import { Box, FormControlLabel, FormControlLabelProps } from '@mui/material';

export function BoxedFormControlLabel(props: FormControlLabelProps) {
  return (
    // Wrap FormControlLabel in a Box to prevent the clickable area from growing larger than the control and label.
    <Box>
      <FormControlLabel {...props} />
    </Box>
  );
}
