/**
 * @generated SignedSource<<b158b05356ac1603645e817dc65f86fa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type WorkScheduleEnum = "fiveDaysEightHours" | "fiveDaysNineHours" | "fiveDaysTenHours" | "fourDaysTenHours" | "fourDaysTenHoursOneDayFiveHours" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NatureOfWorkFields_DefaultWorkScheduleFragment$data = {
  readonly defaultWorkSchedule: WorkScheduleEnum;
  readonly " $fragmentType": "NatureOfWorkFields_DefaultWorkScheduleFragment";
};
export type NatureOfWorkFields_DefaultWorkScheduleFragment$key = {
  readonly " $data"?: NatureOfWorkFields_DefaultWorkScheduleFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NatureOfWorkFields_DefaultWorkScheduleFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NatureOfWorkFields_DefaultWorkScheduleFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultWorkSchedule",
      "storageKey": null
    }
  ],
  "type": "NatureOfWorkLookup",
  "abstractKey": null
};

(node as any).hash = "b3ca6605a6bfaba2719d1cb5d66314b6";

export default node;
