import { Route } from 'react-router';
import { TranslationNamespaceContext } from '../common/hooks/useAmbientTranslation';
import { QuoteList_Page } from './QuoteList.Page';
import { QuoteList_Representative_MyJobs } from './QuoteList.Representative.MyJobs';
import { QuoteList_Representative_MyRegions } from './QuoteList.Representative.MyRegions';
import { QuoteList_Representative_MyClients } from './QuoteList.Representative.MyClients';

export const quoteRoutes = (
  <>
    <Route path='quotes/:id' lazy={() => import('./QuoteDetails.Page')}>
      <Route index lazy={() => import('./QuoteDetails.Mobile.Index')} />
      <Route path='client' lazy={() => import('../jobs/SaleDetails.Mobile.Client')} />
      <Route path='client/pick-client-f' lazy={() => import('../jobs/SaleDetails.Mobile.ClientPicker')} />
      <Route path='project' lazy={() => import('../jobs/SaleDetails.Mobile.Project')} />
      <Route path='project/pick-worksite-f' lazy={() => import('../jobs/SaleDetails.Mobile.WorksitePicker')} />
      <Route path='questions' lazy={() => import('../jobs/SaleDetails.Mobile.Questions')} />
      <Route path='equipment' lazy={() => import('../jobs/SaleDetails.Mobile.Equipment')} />
      <Route path='documents' lazy={() => import('../jobs/JobDetails.Mobile.JobDocumentsProgression')} />
      <Route path='equipment/lift-f/:liftId' lazy={() => import('../jobs/JobDetails.Mobile.Lift.Edit')} />
      <Route path='equipment/configurations' lazy={() => import('../jobs/JobDetails.Mobile.Equipment.Configuration')} />
      <Route path='equipment/configurations/:eTag' lazy={() => import('../jobs/JobDetails.Mobile.Equipment.Configuration.Item')} />
      <Route path='info' lazy={() => import('./QuoteDetails.Desktop.TabInfo')}>
        <Route path='pick-client' lazy={() => import('../jobs/SaleDetails.Desktop.ClientPicker')} />
        <Route path='pick-worksite' lazy={() => import('../jobs/SaleDetails.Desktop.WorksitePicker')} />
        <Route path='lift/:liftId' lazy={() => import('../jobs/JobDetails.Desktop.Lift.Edit')} />
      </Route>
      <Route path='costs' lazy={() => import('./QuoteDetails.Costs')} />
      <Route path='costs/accessories/:accessoryLineId' lazy={() => import('../jobs/SaleDetails.Mobile.AccessoryLine.Edit')} />
      <Route path='costs/costLines/:costLineId' lazy={() => import('../jobs/SaleDetails.Mobile.CostLine.Edit')} />
      <Route path='addenda' lazy={() => import('./QuoteDetails.Addenda')} />
      <Route path='addenda/addenda/:addendumId' lazy={() => import('../jobs/SaleDetails.Mobile.Addendum.Edit')} />
      <Route path='addenda/attachment/:attachmentId' lazy={() => import('../jobs/JobDetails.Mobile.Attachment.Edit')} />
      <Route path='comment' lazy={() => import('./QuoteDetails.Comment')} />
    </Route>
    <Route
      path='quotes'
      element={
        <TranslationNamespaceContext.Provider value='quote'>
          <QuoteList_Page />
        </TranslationNamespaceContext.Provider>
      }>
      <Route path='my-jobs' element={<QuoteList_Representative_MyJobs />} />
      <Route path='my-regions' element={<QuoteList_Representative_MyRegions />} />
      <Route path='my-clients' element={<QuoteList_Representative_MyClients />} />
    </Route>
  </>
);
