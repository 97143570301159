import { ForwardedRef, forwardRef } from 'react';
import { DimensionInput, DimensionInputProps } from './DimensionInput';
import { Angle, angleUnits, isAngleUnit } from '../utils/dimensions/angle';
import { defaultLogger, Logger } from '../utils/logging';

const logger = new Logger(defaultLogger, 'AngleInput', () => new Date().toISOString());

export type AngleInputProps = DimensionInputProps<Angle>;

export const AngleInput = forwardRef(function AngleInput(
  props: AngleInputProps,
  ref: ForwardedRef<HTMLTextAreaElement | HTMLInputElement>,
) {
  return (
    <DimensionInput<Angle>
      {...props}
      enabledUnits={props.enabledUnits ?? angleUnits}
      ref={ref}
      factory={(scalar, unit) => new Angle(scalar, unit)}
      isValidUnit={isAngleUnit}
      logger={logger}
      unitKey='angle'
    />
  );
});
