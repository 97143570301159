import graphql from 'babel-plugin-relay/macro';
import { fetchQuery, useRelayEnvironment } from 'react-relay';
import { ElementType, forwardRef, ReactElement, Ref, RefAttributes, useCallback } from 'react';
import { ChipTypeMap } from '@mui/material';
import {
  AutocompleteMetadata,
  ConnectionNode,
  ConnectionPaginatedAutocomplete,
  ForwardPaginatedAutocompleteProps,
} from './PaginatedAutocomplete';
import { useTranslation } from 'react-i18next';
import { convertToTsQuery } from '../utils/stringUtils';
import { resolvedLanguage } from '../../i18n';
import { SubRegionAutocompleteListFragment$data } from './__generated__/SubRegionAutocompleteListFragment.graphql';
import { SubRegionAutocompleteQuery } from './__generated__/SubRegionAutocompleteQuery.graphql';

export type ForwardSubRegionAutocompleteProps<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = ForwardPaginatedAutocompleteProps<
  ConnectionNode<SubRegionAutocompleteListFragment$data>,
  'fragment' | 'onQuery' | 'getOptionLabel',
  Multiple,
  DisableClearable,
  ChipComponent
>;

export const SubRegionAutocomplete = forwardRef<
  HTMLInputElement,
  ForwardPaginatedAutocompleteProps<
    ConnectionNode<SubRegionAutocompleteListFragment$data> & AutocompleteMetadata,
    'fragment' | 'onQuery' | 'getOptionLabel',
    boolean,
    boolean,
    ElementType
  >
>(function SubRegionAutocomplete<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  {
    multiple,
    ...paginatedAutocompleteProps
  }: ForwardPaginatedAutocompleteProps<
    ConnectionNode<SubRegionAutocompleteListFragment$data> & AutocompleteMetadata,
    'fragment' | 'onQuery' | 'getOptionLabel',
    Multiple,
    DisableClearable,
    ChipComponent
  >,
  ref: Ref<HTMLInputElement>,
) {
  const { i18n } = useTranslation();
  const env = useRelayEnvironment();

  const handleQuery = useCallback(
    (searchTerm: string | null) =>
      fetchQuery<SubRegionAutocompleteQuery>(
        env,
        graphql`
          query SubRegionAutocompleteQuery($searchTerm: String) {
            ...SubRegionAutocompleteListFragment @arguments(searchTerm: $searchTerm)
          }
        `,
        {
          searchTerm: convertToTsQuery(searchTerm),
        },
      ),
    [env],
  );

  return (
    <ConnectionPaginatedAutocomplete<
      ConnectionNode<SubRegionAutocompleteListFragment$data> & AutocompleteMetadata,
      Multiple,
      DisableClearable,
      ChipComponent
    >
      {...paginatedAutocompleteProps}
      ref={ref}
      multiple={multiple}
      fragment={graphql`
        fragment SubRegionAutocompleteListFragment on Query
        @refetchable(queryName: "SubRegionAutocompleteListFragmentQuery")
        @argumentDefinitions(searchTerm: { type: "String" }, cursor: { type: "String" }, count: { type: "Int", defaultValue: 25 }) {
          searchResults: searchSubRegions(searchTerm: $searchTerm, after: $cursor, first: $count)
            @connection(key: "SubRegionAutocompleteListFragment_searchResults") {
            edges {
              node {
                id
                label
                region {
                  description
                }
              }
            }
          }
        }
      `}
      onQuery={handleQuery}
      groupBy={(option) => option.region?.description[resolvedLanguage(i18n)] ?? ''}
    />
  );
}) as <
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  props: ForwardPaginatedAutocompleteProps<
    ConnectionNode<SubRegionAutocompleteListFragment$data>,
    'fragment' | 'onQuery' | 'getOptionLabel',
    Multiple,
    DisableClearable,
    ChipComponent
  > &
    RefAttributes<HTMLInputElement>,
) => ReactElement;
