import {
  createFieldKey,
  createFormContext,
  SetValueFn,
  useField,
  useFieldErrors,
  useFieldHasErrors,
  useFieldIsDirty,
  useFieldMapper,
  useFieldValidation,
} from '../../common/utils/forms';
import { AccessoryTypeAutocomplete, ForwardAccessoryTypeAutocompleteProps } from '../../common/components/AccessoryTypeAutocomplete';
import { flagDirty } from '../../common/utils/patchable';
import { ReactNode, SyntheticEvent, useCallback, useRef, useState } from 'react';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { DataID, useFragment } from 'react-relay';
import { Button, Checkbox, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  AccessoryBillingCodeAutocomplete,
  ForwardAccessoryBillingCodeAutocompleteProps,
} from '../../common/components/AccessoryBillingCodeAutocomplete';
import { NumberInput, NumberInputProps } from '../../common/components/NumberInput';
import { PriceInput } from '../../common/components/PriceInput';
import { AccessoryLine } from './AccessoryLinesFields';
import graphql from 'babel-plugin-relay/macro';
import { useSuggestions } from '../useSuggestions';
import { ServiceCallKind } from '../../__enums__/ServiceCallKind';
import { AccessoryLineSubFormFields_InputAccessoryType_SuggestionsFragment$key } from './__generated__/AccessoryLineSubFormFields_InputAccessoryType_SuggestionsFragment.graphql';
import { AccessoryGroupAutocomplete, ForwardAccessoryGroupAutocompleteProps } from '../../common/components/AccessoryGroupAutocomplete';
import { AccessoryLineSubFormFields_AccessoryGroupOrOutOfInventoryRulesFragment$key } from './__generated__/AccessoryLineSubFormFields_AccessoryGroupOrOutOfInventoryRulesFragment.graphql';
import { SalesRateResult } from '../../common/types/externalResult';
import { AccessoryLineKind } from '../../__enums__/AccessoryLineKind';
import { Price } from '../../common/utils/price';
import { BoxedFormControlLabel } from '../../common/BoxedFormControlLabel';
import { AccessoryLineRateUpdateDialog } from './AccessoryLineRateUpdateDialog';

export const accessoryLineSubFormContext = createFormContext<{ sync: AccessoryLine }>();

const fieldAccessoryIdKey = createFieldKey<string>();
export function useFieldAccessoryId(initialValue: string) {
  const [value] = useField(accessoryLineSubFormContext, fieldAccessoryIdKey, () => initialValue);

  const useMapper = useFieldMapper(accessoryLineSubFormContext, fieldAccessoryIdKey);
  useMapper((v) => ({ id: v }), [], 'sync');

  return value;
}

export type FieldAccessoryType = ForwardAccessoryTypeAutocompleteProps<false, true>['value'];
const fieldAccessoryTypeKey = createFieldKey<FieldAccessoryType>();
export function useFieldAccessoryType(initialValue: FieldAccessoryType, disabled: boolean) {
  const [accessoryType, setAccessoryType] = useField(accessoryLineSubFormContext, fieldAccessoryTypeKey, () => initialValue);

  const accessoryTypeIsDirty = useFieldIsDirty(accessoryLineSubFormContext, fieldAccessoryTypeKey);

  const useMapper = useFieldMapper(accessoryLineSubFormContext, fieldAccessoryTypeKey);
  useMapper((type, { isDirty }) => flagDirty({ accessoryType: type }, { accessoryType: isDirty }), [], 'sync');

  const renderAccessoryType = useCallback(
    (
      $key: AccessoryLineSubFormFields_InputAccessoryType_SuggestionsFragment$key | null | undefined,
      saleKind: ServiceCallKind,
      gridMode: boolean,
    ) => (
      <InputAccessoryType
        $key={$key}
        saleKind={saleKind}
        value={accessoryType}
        setValue={setAccessoryType}
        gridMode={gridMode}
        disabled={disabled}
      />
    ),
    [accessoryType, disabled, setAccessoryType],
  );

  return { accessoryType, setAccessoryType, accessoryTypeIsDirty, renderAccessoryType };
}
function InputAccessoryType({
  $key,
  value,
  setValue,
  gridMode,
  saleKind,
  disabled,
}: {
  $key: AccessoryLineSubFormFields_InputAccessoryType_SuggestionsFragment$key | null | undefined;
  value: FieldAccessoryType;
  setValue: SetValueFn<FieldAccessoryType>;
  gridMode: boolean;
  saleKind: ServiceCallKind;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();
  const errors = useFieldErrors(accessoryLineSubFormContext, fieldAccessoryTypeKey);

  const $data = useFragment(
    graphql`
      fragment AccessoryLineSubFormFields_InputAccessoryType_SuggestionsFragment on ISale
      @argumentDefinitions(skipAccessories: { type: "Boolean!" }) {
        ...useSuggestionsFragment @arguments(skipAccessories: $skipAccessories)
      }
    `,
    $key,
  );

  const suggestionPromptInput = useSuggestions($data, saleKind);

  return (
    <AccessoryTypeAutocomplete
      disableClearable
      value={value}
      suggestible
      suggestionPromptInput={suggestionPromptInput}
      onChange={(v) => setValue(v)}
      disabled={disabled}
      textFieldProps={() => ({
        label: gridMode ? '' : t('field.accessory.type'),
        className: gridMode ? 'borderless' : '',
        required: !gridMode,
        error: Object.keys(errors).length > 0,
      })}
    />
  );
}

export type FieldAccessoryLineSalesRateResult = SalesRateResult | null;

const fieldAccessoryLineSalesRateResultKey = createFieldKey<FieldAccessoryLineSalesRateResult>();
export function useFieldAccessoryLineSalesRateResult(initialValue: FieldAccessoryLineSalesRateResult | undefined) {
  const [accessoryLineSalesRateResult, setAccessoryLineSalesResult] = useField(
    accessoryLineSubFormContext,
    fieldAccessoryLineSalesRateResultKey,
    () => initialValue ?? null,
  );

  const useMapper = useFieldMapper(accessoryLineSubFormContext, fieldAccessoryLineSalesRateResultKey);
  useMapper((v, { isDirty }) => flagDirty({ salesRateResult: v }, { salesRateResult: isDirty }), [], 'sync');
  const accessoryLineSalesRateResultIsDirty = useFieldIsDirty(accessoryLineSubFormContext, fieldAccessoryLineSalesRateResultKey);
  return { accessoryLineSalesRateResult, setAccessoryLineSalesResult, accessoryLineSalesRateResultIsDirty };
}

export type FieldAccessoryLineIsFixedQuantity = boolean;
const fieldAccessoryLineIsFixedQuantityKey = createFieldKey<FieldAccessoryLineIsFixedQuantity>();
export function useFieldAccessoryLineIsFixedQuantity(initialValue: boolean | undefined) {
  const [accessoryLineIsFixedQuantity, setAccessoryLineIsFixedQuantity] = useField(
    accessoryLineSubFormContext,
    fieldAccessoryLineIsFixedQuantityKey,
    () => initialValue ?? false,
  );

  const useMapper = useFieldMapper(accessoryLineSubFormContext, fieldAccessoryLineIsFixedQuantityKey);
  useMapper((v) => ({ isFixedQuantity: v }), [], 'sync');

  return { accessoryLineIsFixedQuantity, setAccessoryLineIsFixedQuantity };
}

export type FieldAccessoryLineDefaultQuantity = number | null;
const fieldAccessoryLineDefaultQuantityKey = createFieldKey<FieldAccessoryLineDefaultQuantity>();
export function useFieldAccessoryLineDefaultQuantity(initialValue: number | null | undefined) {
  const [accessoryLineDefaultQuantity, setAccessoryLineDefaultQuantity] = useField(
    accessoryLineSubFormContext,
    fieldAccessoryLineDefaultQuantityKey,
    () => initialValue ?? null,
  );

  const useMapper = useFieldMapper(accessoryLineSubFormContext, fieldAccessoryLineDefaultQuantityKey);
  useMapper((v) => ({ defaultQuantity: v }), [], 'sync');

  return { accessoryLineDefaultQuantity, setAccessoryLineDefaultQuantity };
}

export type FieldAccessoryGroup = NonNullable<ForwardAccessoryGroupAutocompleteProps['value']> | null;
const fieldAccessoryGroupKey = createFieldKey<FieldAccessoryGroup>();
export function useFieldAccessoryGroup(
  initialValue: FieldAccessoryGroup | undefined,
  accessoryTypeCode: number | null,
  dispatchBranchId: DataID | null,
  disabled: boolean,
) {
  const [accessoryGroup, setAccessoryGroup] = useField(accessoryLineSubFormContext, fieldAccessoryGroupKey, () => initialValue ?? null);
  const accessoryGroupIsDirty = useFieldIsDirty(accessoryLineSubFormContext, fieldAccessoryGroupKey);

  const useMapper = useFieldMapper(accessoryLineSubFormContext, fieldAccessoryGroupKey);
  useMapper((v, { isDirty }) => flagDirty({ accessoryGroup: v }, { accessoryGroup: isDirty }), [], 'sync');

  const renderAccessoryGroup = useCallback(
    (gridMode: boolean) => (
      <InputAccessoryGroup
        value={accessoryGroup}
        setValue={setAccessoryGroup}
        accessoryTypeCode={accessoryTypeCode}
        dispatchBranchId={dispatchBranchId}
        gridMode={gridMode}
        disabled={disabled}
      />
    ),
    [accessoryGroup, accessoryTypeCode, disabled, dispatchBranchId, setAccessoryGroup],
  );

  return {
    accessoryGroup: accessoryGroup,
    setAccessoryGroup: setAccessoryGroup,
    accessoryIsDirty: accessoryGroupIsDirty,
    renderAccessory: renderAccessoryGroup,
  };
}
function InputAccessoryGroup({
  value,
  setValue,
  accessoryTypeCode,
  dispatchBranchId,
  gridMode,
  disabled,
}: {
  value: FieldAccessoryGroup;
  setValue: SetValueFn<FieldAccessoryGroup>;
  accessoryTypeCode: number | null;
  dispatchBranchId: DataID | null;
  gridMode: boolean;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();
  const errors = useFieldErrors(accessoryLineSubFormContext, fieldAccessoryBillingCodeKey);

  return (
    <AccessoryGroupAutocomplete
      value={value}
      onChange={(v) => setValue(v)}
      disabled={disabled}
      textFieldProps={() => ({
        label: gridMode ? '' : t('field.accessory.accessory'),
        'data-label-key': 'field.accessory.accessoryGroup',
        className: gridMode ? 'borderless' : '',
        required: !gridMode,
        error: Object.keys(errors).length > 0,
      })}
      accessoryTypeCode={accessoryTypeCode}
      dispatchBranchId={dispatchBranchId}
    />
  );
}

export type FieldAccessoryOutOfInventory = string | null;
const fieldAccessoryOutOfInventoryKey = createFieldKey<FieldAccessoryOutOfInventory>();
export function useFieldAccessoryOutOfInventory(initialValue: FieldAccessoryOutOfInventory, disabled: boolean) {
  const [accessoryOutOfInventory, setAccessoryOutOfInventory] = useField(
    accessoryLineSubFormContext,
    fieldAccessoryOutOfInventoryKey,
    () => initialValue ?? '',
  );
  const accessoryOutOfInventoryIsDirty = useFieldIsDirty(accessoryLineSubFormContext, fieldAccessoryOutOfInventoryKey);

  const useMapper = useFieldMapper(accessoryLineSubFormContext, fieldAccessoryOutOfInventoryKey);
  useMapper((v, { isDirty }) => flagDirty({ accessoryOutOfInventory: v }, { accessoryOutOfInventory: isDirty }), [], 'sync');

  const renderAccessoryOutOfInventory = useCallback(
    (gridMode: boolean) => (
      <AccessoryOutOfInventoryInput
        value={accessoryOutOfInventory}
        setValue={setAccessoryOutOfInventory}
        gridMode={gridMode}
        disabled={disabled}
      />
    ),
    [accessoryOutOfInventory, disabled, setAccessoryOutOfInventory],
  );

  return { accessoryOutOfInventory, setAccessoryOutOfInventory, accessoryOutOfInventoryIsDirty, renderAccessoryOutOfInventory };
}
function AccessoryOutOfInventoryInput({
  value,
  setValue,
  gridMode,
  disabled,
}: {
  value: FieldAccessoryOutOfInventory;
  setValue: SetValueFn<FieldAccessoryOutOfInventory>;
  gridMode: boolean;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();
  const errors = useFieldErrors(accessoryLineSubFormContext, fieldAccessoryBillingCodeKey);

  return (
    <TextField
      value={value}
      onChange={({ target: { value: v } }) => setValue(v)}
      error={Object.keys(errors).length > 0}
      className={gridMode ? 'borderless' : ''}
      label={gridMode ? '' : t('field.accessory.accessory')}
      data-label-key='field.accessory.accessoryOutOfInventory'
      placeholder={t('placeholder.accessoryOutOfInventory')}
      disabled={disabled}
    />
  );
}

export type FieldRequireAccessory = boolean;
const fieldAccessoryLineRequireAccessoryKey = createFieldKey<FieldRequireAccessory>();

export function useFieldAccessoryGroupOrOutOfInventory(
  rules$key: AccessoryLineSubFormFields_AccessoryGroupOrOutOfInventoryRulesFragment$key | null | undefined,
  initialRequireAccessory: FieldRequireAccessory | undefined,
  initialAccessory: FieldAccessoryGroup | undefined,
  initialOutOfInventory: FieldAccessoryOutOfInventory | undefined,
  accessoryTypeCode: number | null,
  dispatchBranchId: DataID | null,
  disabled: boolean,
) {
  const rules$data = useFragment(
    graphql`
      fragment AccessoryLineSubFormFields_AccessoryGroupOrOutOfInventoryRulesFragment on AccessoryTypeLookup {
        isNumberMandatory
      }
    `,
    rules$key,
  );
  const isOutOfInventory = rules$data?.isNumberMandatory === false;

  const [requireAccessory, setRequireAccessory] = useField(
    accessoryLineSubFormContext,
    fieldAccessoryLineRequireAccessoryKey,
    () => initialRequireAccessory ?? !isOutOfInventory,
  );
  const useMapperRequireAccessory = useFieldMapper(accessoryLineSubFormContext, fieldAccessoryLineRequireAccessoryKey);
  useMapperRequireAccessory((v, { isDirty }) => flagDirty({ requireAccessory: v }, { requireAccessory: isDirty }), [], 'sync');

  const { accessoryGroup, setAccessoryGroup, renderAccessory } = useFieldAccessoryGroup(
    initialAccessory,
    accessoryTypeCode,
    dispatchBranchId,
    disabled,
  );
  const { accessoryOutOfInventory, setAccessoryOutOfInventory, renderAccessoryOutOfInventory } = useFieldAccessoryOutOfInventory(
    initialOutOfInventory ?? '',
    disabled,
  );

  const resetAccessoryGroupAndOutOfInventory = useCallback(
    (newRequireAccessory: boolean) => {
      if (disabled) {
        return;
      }

      setRequireAccessory(newRequireAccessory);

      setAccessoryGroup(null);
      setAccessoryOutOfInventory('');
    },
    [disabled, setAccessoryGroup, setAccessoryOutOfInventory, setRequireAccessory],
  );

  const renderAccessoryOrOutOfInventory = useCallback(
    (gridMode: boolean) => (requireAccessory ? renderAccessory(gridMode) : renderAccessoryOutOfInventory(gridMode)),
    [requireAccessory, renderAccessory, renderAccessoryOutOfInventory],
  );

  return {
    accessoryGroup,
    setAccessoryGroup,
    accessoryOutOfInventory,
    setAccessoryOutOfInventory,
    resetAccessoryAndOutOfInventory: resetAccessoryGroupAndOutOfInventory,
    renderAccessoryOrOutOfInventory,
  };
}

export type FieldAccessoryBillingCode = NonNullable<ForwardAccessoryBillingCodeAutocompleteProps['value']> | null;
const fieldAccessoryBillingCodeKey = createFieldKey<FieldAccessoryBillingCode>();
export function useFieldAccessoryBillingCode(initialValue: FieldAccessoryBillingCode | undefined, disabled: boolean) {
  const [accessoryBillingCode, setAccessoryBillingCode] = useField(
    accessoryLineSubFormContext,
    fieldAccessoryBillingCodeKey,
    () => initialValue ?? null,
  );

  const useMapper = useFieldMapper(accessoryLineSubFormContext, fieldAccessoryBillingCodeKey);
  useMapper((v, { isDirty }) => flagDirty({ billingCode: v }, { billingCode: isDirty }), [], 'sync');
  const accessoryBillingCodeIsDirty = useFieldIsDirty(accessoryLineSubFormContext, fieldAccessoryBillingCodeKey);
  const renderAccessoryBillingCode = useCallback(
    (gridMode: boolean) => (
      <AccessoryBillingCodeInput value={accessoryBillingCode} setValue={setAccessoryBillingCode} gridMode={gridMode} disabled={disabled} />
    ),
    [accessoryBillingCode, disabled, setAccessoryBillingCode],
  );

  return { accessoryBillingCode, setAccessoryBillingCode, accessoryBillingCodeIsDirty, renderAccessoryBillingCode };
}
function AccessoryBillingCodeInput({
  value,
  setValue,
  gridMode,
  disabled,
}: {
  value: FieldAccessoryBillingCode;
  setValue: SetValueFn<FieldAccessoryBillingCode>;
  gridMode: boolean;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();
  const errors = useFieldErrors(accessoryLineSubFormContext, fieldAccessoryBillingCodeKey);

  return (
    <AccessoryBillingCodeAutocomplete
      value={value}
      onChange={(v) => setValue(v)}
      disabled={disabled}
      textFieldProps={() => ({
        label: gridMode ? '' : t('field.accessory.billed'),
        className: gridMode ? 'borderless' : '',
        required: !gridMode,
        error: Object.keys(errors).length > 0,
      })}
    />
  );
}

export type FieldAccessoryQuantity = NumberInputProps['value'];
const fieldAccessoryQuantityKey = createFieldKey<FieldAccessoryQuantity>();
export function useFieldAccessoryQuantity(initialValue: FieldAccessoryQuantity | undefined, disabled: boolean) {
  const [accessoryQuantity, setAccessoryQuantity] = useField(
    accessoryLineSubFormContext,
    fieldAccessoryQuantityKey,
    () => initialValue ?? null,
  );

  const useMapper = useFieldMapper(accessoryLineSubFormContext, fieldAccessoryQuantityKey);
  useMapper((v, { isDirty }) => flagDirty({ quantity: v }, { quantity: isDirty }), [], 'sync');

  const renderAccessoryQuantity = useCallback(
    (gridMode: boolean, startAdornment: ReactNode, isFixedQuantity: boolean) => (
      <QuantityInput
        value={accessoryQuantity}
        setValue={setAccessoryQuantity}
        gridMode={gridMode}
        disabled={disabled || isFixedQuantity}
        startAdornment={startAdornment}
      />
    ),
    [disabled, accessoryQuantity, setAccessoryQuantity],
  );

  return { accessoryQuantity, setAccessoryQuantity, renderAccessoryQuantity };
}
function QuantityInput({
  value,
  setValue,
  gridMode,
  disabled,
  startAdornment,
}: {
  value: FieldAccessoryQuantity;
  setValue: SetValueFn<FieldAccessoryQuantity>;
  gridMode: boolean;
  disabled: boolean;
  startAdornment: ReactNode;
}) {
  const errors = useFieldErrors(accessoryLineSubFormContext, fieldAccessoryQuantityKey);
  const { t } = useAmbientTranslation();

  return (
    <NumberInput
      value={value}
      onChange={(v) => setValue(v)}
      error={Object.keys(errors).length > 0}
      min={1}
      step={1}
      borderless={gridMode}
      label={gridMode ? '' : t('field.accessory.quantity')}
      InputProps={{
        inputProps: { style: { textAlign: 'right' }, 'data-label-key': 'field.accessory.quantity' },
        startAdornment,
      }}
      disabled={disabled}
      required={!disabled}
    />
  );
}

export type FieldAccessoryRate = Price | null;
const fieldAccessoryRateKey = createFieldKey<FieldAccessoryRate>();
export function useFieldAccessoryRate(initialValue: FieldAccessoryRate | undefined, disabled: boolean) {
  const [accessoryRate, setAccessoryRate] = useField(accessoryLineSubFormContext, fieldAccessoryRateKey, () => initialValue ?? null);

  const useValidation = useFieldValidation(accessoryLineSubFormContext, fieldAccessoryRateKey);
  useValidation((v) => v != null, [], 'save:required');

  const useMapper = useFieldMapper(accessoryLineSubFormContext, fieldAccessoryRateKey);
  useMapper((v, { isDirty }) => flagDirty({ rate: v }, { rate: isDirty }), [], 'sync');

  const renderAccessoryRate = useCallback(
    ({
      gridMode,
      startAdornment,
      salesRateResultInitialValue,
      rateUpdateReasonInitialValue,
      inRateUpdateDialog = false,
    }: {
      gridMode: boolean;
      startAdornment: (rate: FieldAccessoryRate, rateUpdateReason: FieldAccessoryRateUpdateReason) => ReactNode;
      salesRateResultInitialValue: FieldAccessoryLineSalesRateResult | undefined;
      rateUpdateReasonInitialValue: FieldAccessoryRateUpdateReason | undefined;
      inRateUpdateDialog?: boolean;
    }) => (
      <RateInput
        value={accessoryRate}
        setValue={setAccessoryRate}
        disabled={disabled}
        gridMode={gridMode}
        startAdornment={startAdornment}
        salesRateResultInitialValue={salesRateResultInitialValue}
        rateUpdateReasonInitialValue={rateUpdateReasonInitialValue}
        inRateUpdateDialog={inRateUpdateDialog}
      />
    ),
    [accessoryRate, setAccessoryRate, disabled],
  );

  return { accessoryRate, setAccessoryRate, renderAccessoryRate };
}
function RateInput({
  value,
  setValue,
  disabled,
  gridMode,
  startAdornment,
  salesRateResultInitialValue,
  rateUpdateReasonInitialValue,
  inRateUpdateDialog,
}: {
  value: FieldAccessoryRate;
  setValue: SetValueFn<FieldAccessoryRate>;
  disabled: boolean;
  gridMode: boolean;
  startAdornment: (rate: FieldAccessoryRate, rateUpdateReason: FieldAccessoryRateUpdateReason) => ReactNode;
  salesRateResultInitialValue: FieldAccessoryLineSalesRateResult | undefined;
  rateUpdateReasonInitialValue: FieldAccessoryRateUpdateReason | undefined;
  inRateUpdateDialog: boolean;
}) {
  const hasErrors = useFieldHasErrors(accessoryLineSubFormContext, fieldAccessoryRateKey);
  const { t } = useAmbientTranslation();

  const [open, setOpen] = useState(false);
  const { accessoryLineSalesRateResult } = useFieldAccessoryLineSalesRateResult(salesRateResultInitialValue);
  const {
    accessoryRateUpdateReason,
    accessoryRateUpdateReasonIsRequired,
    accessoryRateUpdateReasonIsRequiredOnRateChange,
    setAccessoryRateUpdateReason,
  } = useFieldAccessoryRateUpdateReason(rateUpdateReasonInitialValue, value, accessoryLineSalesRateResult);

  const salesRateResultPrice = accessoryLineSalesRateResult?.value?.price ?? null;
  const inputRef = useRef<HTMLInputElement>();

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const priceInput = (
    <PriceInput
      value={value}
      onChange={(rate) => setValue(rate)}
      error={hasErrors}
      label={gridMode ? '' : t('field.accessory.rate')}
      className={gridMode ? 'borderless' : undefined}
      helperText={
        !gridMode &&
        !inRateUpdateDialog &&
        accessoryRateUpdateReasonIsRequired && (
          <Typography component='span' sx={{ color: theme.palette.warning.main }}>
            {t('salesRate.salesRatePrice', { price: salesRateResultPrice?.format(t), ns: 'jobs' })}
          </Typography>
        )
      }
      InputProps={{
        startAdornment: startAdornment(value, accessoryRateUpdateReason),
        inputProps: { 'data-label-key': 'field.cost.rate' },
      }}
      disabled={disabled}
      required
      inputRef={inputRef}
      onFocus={() => {
        if (gridMode && !inRateUpdateDialog && !disabled && accessoryRateUpdateReasonIsRequiredOnRateChange) {
          // prevent the input from gaining back focus when the dialog is closed, which would open the dialog again
          inputRef.current?.blur();
          setOpen(true);
        }
      }}
    />
  );

  return (
    <>
      {smallScreen ? (
        <Stack direction='row' spacing={1}>
          {priceInput}
          <Button
            onClick={() => {
              setValue(salesRateResultPrice);
              setAccessoryRateUpdateReason(null);
            }}
            variant='text'
            color='error'
            sx={{ flexShrink: 0 }}>
            {t('salesRate.rateUpdateDialog.buttonLabels.reset', { ns: 'jobs' })}
          </Button>
        </Stack>
      ) : (
        priceInput
      )}
      {!inRateUpdateDialog && (
        <AccessoryLineRateUpdateDialog
          open={open}
          onClose={() => setOpen(false)}
          rate={value}
          setRate={setValue}
          rateUpdateReason={accessoryRateUpdateReason}
          setRateUpdateReason={setAccessoryRateUpdateReason}
          salesRateResult={accessoryLineSalesRateResult}
        />
      )}
    </>
  );
}

export type FieldAccessoryRateUpdateReason = string | null;
const fieldAccessoryRateUpdateReasonKey = createFieldKey<FieldAccessoryRateUpdateReason>();
export function useFieldAccessoryRateUpdateReason(
  initialValue: FieldAccessoryRateUpdateReason | null | undefined,
  accessoryRate: FieldAccessoryRate,
  accessoryLineSalesRateResult: FieldAccessoryLineSalesRateResult,
) {
  const [accessoryRateUpdateReason, setAccessoryRateUpdateReason] = useField(
    accessoryLineSubFormContext,
    fieldAccessoryRateUpdateReasonKey,
    () => initialValue ?? null,
  );

  const salesRateResultPrice = accessoryLineSalesRateResult?.value?.price ?? null;
  const accessoryRateUpdateReasonIsRequiredOnRateChange = !!accessoryLineSalesRateResult?.error || !!salesRateResultPrice?.isPositive();
  const accessoryRateUpdateReasonIsRequired = accessoryRateUpdateReasonIsRequiredOnRateChange && accessoryRate !== salesRateResultPrice;

  const useValidation = useFieldValidation(accessoryLineSubFormContext, fieldAccessoryRateUpdateReasonKey);
  useValidation((v) => !accessoryRateUpdateReasonIsRequired || !!v?.trim(), [accessoryRateUpdateReasonIsRequired], 'save:required');

  const useMapper = useFieldMapper(accessoryLineSubFormContext, fieldAccessoryRateUpdateReasonKey);
  useMapper((v, { isDirty }) => flagDirty({ rateUpdateReason: v }, { rateUpdateReason: isDirty }), [], 'sync');

  const renderAccessoryRateUpdateReason = useCallback(
    () => <RateUpdateReasonInput value={accessoryRateUpdateReason} setValue={setAccessoryRateUpdateReason} />,
    [accessoryRateUpdateReason, setAccessoryRateUpdateReason],
  );

  return {
    accessoryRateUpdateReason,
    accessoryRateUpdateReasonIsRequired,
    accessoryRateUpdateReasonIsRequiredOnRateChange,
    setAccessoryRateUpdateReason,
    renderAccessoryRateUpdateReason,
  };
}
function RateUpdateReasonInput({
  value,
  setValue,
}: {
  value: FieldAccessoryRateUpdateReason;
  setValue: SetValueFn<FieldAccessoryRateUpdateReason>;
}) {
  const { t } = useAmbientTranslation();
  const hasErrors = useFieldHasErrors(accessoryLineSubFormContext, fieldAccessoryRateUpdateReasonKey);
  return (
    <TextField
      value={value ?? ''}
      onChange={(event) => setValue(event.target.value)}
      onBlur={(event) => setValue(event.target.value.trim())}
      label={t('field.cost.rateUpdateReason')}
      error={hasErrors}
      required
    />
  );
}

export type FieldAccessoryBillable = boolean | null;
const fieldAccessoryBillableKey = createFieldKey<FieldAccessoryBillable>();
export function useFieldAccessoryBillable(initialValue: FieldAccessoryBillable | undefined, disabled: boolean) {
  const [accessoryBillable, setValue] = useField(accessoryLineSubFormContext, fieldAccessoryBillableKey, () => initialValue ?? false);
  const setAccessoryBillable = useCallback<SetValueFn<boolean | null>>(
    (action) => {
      if (disabled) {
        return;
      }

      setValue(action);
    },
    [disabled, setValue],
  );

  const useMapper = useFieldMapper(accessoryLineSubFormContext, fieldAccessoryBillableKey);
  useMapper((v, { isDirty }) => flagDirty({ billable: v }, { billable: isDirty }), [], 'sync');

  const renderAccessoryBillable = useCallback(
    (gridMode: boolean) => (
      <BillableInput value={accessoryBillable} setValue={setAccessoryBillable} gridMode={gridMode} disabled={disabled} />
    ),
    [accessoryBillable, disabled, setAccessoryBillable],
  );

  return { accessoryBillable, setAccessoryBillable, renderAccessoryBillable };
}
function BillableInput({
  value,
  setValue,
  gridMode,
  disabled,
}: {
  value: FieldAccessoryBillable;
  setValue: SetValueFn<FieldAccessoryBillable>;
  gridMode: boolean;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();

  const handleChange = (_: SyntheticEvent, v: boolean) => setValue(v);

  return gridMode ? (
    <Checkbox checked={value ?? false} onChange={handleChange} disabled={disabled} />
  ) : (
    <BoxedFormControlLabel
      checked={value ?? false}
      onChange={handleChange}
      control={<Checkbox />}
      label={t('field.accessory.billable')}
      disabled={disabled}
    />
  );
}

const fieldAccessoryDefaultBillableKey = createFieldKey<FieldAccessoryBillable>();
export function useFieldAccessoryDefaultBillable(initialValue: FieldAccessoryBillable | undefined) {
  const [accessoryDefaultBillable, setAccessoryDefaultBillable] = useField(
    accessoryLineSubFormContext,
    fieldAccessoryDefaultBillableKey,
    () => initialValue ?? false, //< Note, same default as billable when value is null
  );

  const useMapper = useFieldMapper(accessoryLineSubFormContext, fieldAccessoryDefaultBillableKey);
  useMapper((v, { isDirty }) => flagDirty({ defaultBillable: v }, { defaultBillable: isDirty }), [], 'sync');

  return { accessoryDefaultBillable, setAccessoryDefaultBillable };
}

export type FieldAccessoryLineKind = AccessoryLineKind;
const fieldAccessoryLineKindKey = createFieldKey<FieldAccessoryLineKind>();

export function useFieldAccessoryLineKind(initialValue: AccessoryLineKind | undefined) {
  const [kind, setKind] = useField(accessoryLineSubFormContext, fieldAccessoryLineKindKey, () => initialValue ?? 'manual');

  const useMapper = useFieldMapper(accessoryLineSubFormContext, fieldAccessoryLineKindKey);
  useMapper((v) => ({ kind: v }), [], 'sync');

  return { kind, setKind };
}
