/**
 * @generated SignedSource<<56f71cbda9f4c33549b1c9a028842054>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientBaseFields_ClientRepresentativeSuggestedFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useSuggestionsFragment">;
  readonly " $fragmentType": "ClientBaseFields_ClientRepresentativeSuggestedFragment";
};
export type ClientBaseFields_ClientRepresentativeSuggestedFragment$key = {
  readonly " $data"?: ClientBaseFields_ClientRepresentativeSuggestedFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientBaseFields_ClientRepresentativeSuggestedFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "skipAccessories"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientBaseFields_ClientRepresentativeSuggestedFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "skipAccessories",
          "variableName": "skipAccessories"
        }
      ],
      "kind": "FragmentSpread",
      "name": "useSuggestionsFragment"
    }
  ],
  "type": "ISale",
  "abstractKey": "__isISale"
};

(node as any).hash = "47ff88179c9e8d08c0868d07a3ebf6b4";

export default node;
