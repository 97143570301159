/**
 * @generated SignedSource<<2714c0683069e2c1613db2dbc372fa34>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ServiceCallList_Representative_MyClients_ListContentFragment$data = {
  readonly searchServiceCallsWithMyClients: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly lifeCycleBranchId: string;
        readonly " $fragmentSpreads": FragmentRefs<"ServiceCallList_ItemFragment" | "ServiceCallList_RowFragment">;
      };
    }> | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"ResponsiveGridFragment">;
  };
  readonly " $fragmentType": "ServiceCallList_Representative_MyClients_ListContentFragment";
};
export type ServiceCallList_Representative_MyClients_ListContentFragment$key = {
  readonly " $data"?: ServiceCallList_Representative_MyClients_ListContentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ServiceCallList_Representative_MyClients_ListContentFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "before"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "last"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchTerm"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "where"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./ServiceCallList_Representative_MyClients_ListContentFragmentQuery.graphql')
    }
  },
  "name": "ServiceCallList_Representative_MyClients_ListContentFragment",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "after",
            "variableName": "after"
          },
          {
            "kind": "Variable",
            "name": "before",
            "variableName": "before"
          },
          {
            "kind": "Variable",
            "name": "first",
            "variableName": "first"
          },
          {
            "kind": "Variable",
            "name": "last",
            "variableName": "last"
          },
          {
            "kind": "Literal",
            "name": "order",
            "value": [
              {
                "snapshot": {
                  "statusOrder": "ASC"
                }
              },
              {
                "snapshot": {
                  "projectBase": {
                    "arrivalDate": {
                      "date": "ASC"
                    }
                  }
                }
              },
              {
                "snapshot": {
                  "equipmentBase": {
                    "craneSelector": {
                      "favoriteConfiguration": {
                        "capacity": "DESC"
                      }
                    }
                  }
                }
              },
              {
                "id": "ASC"
              }
            ]
          },
          {
            "kind": "Variable",
            "name": "searchTerm",
            "variableName": "searchTerm"
          },
          {
            "kind": "Variable",
            "name": "where",
            "variableName": "where"
          }
        ],
        "concreteType": "SearchServiceCallsWithMyClientsConnection",
        "kind": "LinkedField",
        "name": "searchServiceCallsWithMyClients",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ResponsiveGridFragment"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SearchServiceCallsWithMyClientsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ServiceCallJobRevision",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lifeCycleBranchId",
                    "storageKey": null
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ServiceCallList_RowFragment"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ServiceCallList_ItemFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "65783fabedbe1508f933cc5d3faf5949";

export default node;
