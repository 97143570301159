import { ReactNode } from 'react';
import { Box } from '@mui/material';

/**
 * Intended to be used in the `label` prop of MUI Input components to display a label with some trailing adornment
 * @param label
 * @param adornment
 */
export function FieldInputLabel({ label, adornment }: { label: string; adornment: ReactNode }) {
  return (
    <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: '0.25rem' }}>
      {label}
      {adornment}
    </Box>
  );
}
