import { SetValueFn } from '../common/utils/forms';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { ForwardSaleKindAutocompleteProps, SaleKindAutocomplete } from '../common/components/SaleKindAutocomplete';
import { Chip } from '@mui/material';
import { CraneCapacityAutocomplete, ForwardCraneCapacityAutocompleteProps } from '../common/components/CraneCapacityAutocomplete';
import { EquipmentKindAutocomplete, ForwardEquipmentKindAutocompleteProps } from '../common/components/EquipmentKindAutocomplete';
import { ClientAutocomplete, ForwardClientAutocompleteProps } from '../common/components/ClientAutocomplete';
import { ForwardNatureOfWorkAutocompleteProps, NatureOfWorkAutocomplete } from '../common/components/NatureOfWorkAutocomplete';
import {
  ForwardNatureOfWorkSubCategoryAutocompleteProps,
  NatureOfWorkSubCategoryAutocomplete,
} from '../common/components/NatureOfWorkSubCategoryAutocomplete';
import { ForwardQuestionKindAutocompleteProps, QuestionKindAutocomplete } from '../common/components/QuestionKindAutocomplete';
import { specialProjects } from '../jobs/fields/QuestionsBaseFields';
import { ForwardJobStageAutocompleteProps, JobStageAutocomplete } from '../common/components/JobStageAutocomplete';

const ALL_BILLABLE_JOBSTAGES = ['quote', 'serviceCall'] as const;

export type FieldSaleStages = NonNullable<ForwardJobStageAutocompleteProps<true>['value']>;
export function SaleStagesInput({ value, setValue }: { value: FieldSaleStages; setValue: SetValueFn<FieldSaleStages> }) {
  const { t } = useAmbientTranslation();

  return (
    <JobStageAutocomplete
      options={ALL_BILLABLE_JOBSTAGES}
      multiple
      value={value}
      onChange={(_, v) => setValue([...v])}
      textFieldProps={() => ({ label: t('fields.label.formKind') })}
    />
  );
}

export type FieldSaleKinds = NonNullable<ForwardSaleKindAutocompleteProps<true>['value']>;
export function SaleKindsInput({ value, setValue }: { value: FieldSaleKinds; setValue: SetValueFn<FieldSaleKinds> }) {
  const { t } = useAmbientTranslation();

  return (
    <SaleKindAutocomplete
      multiple
      value={value}
      onChange={(_, v) => setValue([...v])}
      textFieldProps={() => ({ label: t('fields.label.kinds') })}
    />
  );
}

export type FieldCapacities = NonNullable<ForwardCraneCapacityAutocompleteProps<true>['value']>;
export function CapacitiesInput({ value, setValue }: { value: FieldCapacities; setValue: SetValueFn<FieldCapacities> }) {
  const { t } = useAmbientTranslation();

  return (
    <CraneCapacityAutocomplete
      multiple
      vehicleIds={null}
      equipmentKindCode={null}
      configurationKindCode={null}
      value={value}
      onChange={(v) => setValue(v)}
      textFieldProps={() => ({ label: t('fields.label.capacities') })}
    />
  );
}

export type FieldEquipmentKind = NonNullable<ForwardEquipmentKindAutocompleteProps<true>['value']>;
export function EquipmentKindsInput({ value, setValue }: { value: FieldEquipmentKind; setValue: SetValueFn<FieldEquipmentKind> }) {
  const { t } = useAmbientTranslation();

  return (
    <EquipmentKindAutocomplete
      multiple
      capacity={null}
      vehicleIds={null}
      configurationKindCode={null}
      value={value}
      onChange={(v) => setValue(v)}
      textFieldProps={() => ({ label: t('fields.label.equipmentKinds') })}
    />
  );
}

export type FieldClients = NonNullable<ForwardClientAutocompleteProps<true>['value']>;
export function ClientsInput({ value, setValue }: { value: FieldClients; setValue: SetValueFn<FieldClients> }) {
  const { t } = useAmbientTranslation();

  return (
    <ClientAutocomplete
      multiple
      value={value}
      onChange={(v) => setValue(v)}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const handleDelete = getTagProps({ index }).onDelete;
          return <Chip key={option.id} onDelete={handleDelete} label={`${option.number}-${option.name}`} size='small' />;
        })
      }
      textFieldProps={() => ({ label: t('fields.label.clients') })}
    />
  );
}

export type FieldNatureOfWorks = NonNullable<ForwardNatureOfWorkAutocompleteProps<true>['value']>;
export function NatureOfWorksAutocompleteInput({
  value,
  setValue,
}: {
  value: FieldNatureOfWorks;
  setValue: SetValueFn<FieldNatureOfWorks>;
}) {
  const { t } = useAmbientTranslation();

  return (
    <NatureOfWorkAutocomplete
      multiple
      value={value}
      onChange={(v) => setValue(v)}
      textFieldProps={() => ({ label: t('fields.label.natureOfWorks') })}
    />
  );
}

export type FieldNatureOfWorkSubCategories = NonNullable<ForwardNatureOfWorkSubCategoryAutocompleteProps<true>['value']>;
export function NatureOfWorkSubCategoriesInput({
  value,
  setValue,
}: {
  value: FieldNatureOfWorkSubCategories;
  setValue: SetValueFn<FieldNatureOfWorkSubCategories>;
}) {
  const { t } = useAmbientTranslation();

  return (
    <NatureOfWorkSubCategoryAutocomplete
      multiple
      value={value}
      onChange={(v) => setValue(v)}
      getOptionKey={(o) => o}
      getOptionLabel={(o) => t(`natureOfWorkSubCategories.${o}`, { ns: 'common' })}
      textFieldProps={() => ({
        label: t('fields.label.natureOfWorkSubCategory'),
      })}
    />
  );
}

export type FieldBaseQuestions = NonNullable<ForwardQuestionKindAutocompleteProps<true>['value']>;
export function BaseQuestionsInput({ value, setValue }: { value: FieldBaseQuestions; setValue: SetValueFn<FieldBaseQuestions> }) {
  const { t } = useAmbientTranslation();

  return (
    <QuestionKindAutocomplete
      multiple
      value={value}
      onChange={(_, v) => setValue(v)}
      groupBy={(o) => (specialProjects.includes(o) ? t('fields.specialProjects') : '')}
      textFieldProps={() => ({
        label: t('fields.label.specialSituation'),
      })}
    />
  );
}
