import {
  ChangeEvent,
  ChangeEventHandler,
  FocusEvent,
  FocusEventHandler,
  ForwardedRef,
  forwardRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { clamp, NumberParser } from '../utils/numberUtils';
import { TextField, TextFieldProps } from '@mui/material';
import { usePreviousValue } from '../hooks/usePreviousValue';
import { BorderlessTextField } from './BorderlessTextField';

export type NumberInputProps = Omit<TextFieldProps, 'value' | 'type' | 'onFocus' | 'onChange' | 'onBlur' | 'InputProps'> & {
  value: number | null;
  min?: number;
  max?: number;
  step?: number;
  onFocus?: (event: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onChange?: (value: number | null, event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  InputProps?: TextFieldProps['InputProps'];
  numberFormatOptions?: Intl.NumberFormatOptions;
  borderless?: boolean;
};

export const NumberInput = forwardRef(function NumberInput(
  {
    value: inputValue,
    min,
    max,
    step,
    onFocus,
    onChange,
    onBlur,
    InputProps,
    numberFormatOptions,
    error: inputError,
    borderless,
    ...rest
  }: NumberInputProps,
  ref: ForwardedRef<HTMLTextAreaElement | HTMLInputElement>,
) {
  const { t } = useTranslation('common');
  const locale = t('locale');

  const formatters = useMemo(() => {
    const blurredFormatter = new Intl.NumberFormat(locale, {
      // Make maximumFractionDigits default to its maximum value to try to avoid loss of precision on blur.
      maximumFractionDigits: 20,
      ...numberFormatOptions,
    });

    const focusedFormatter = new Intl.NumberFormat(locale, {
      ...numberFormatOptions,
      // The default minimumFractionDigits when in currency mode is currency-dependent (2 for most currencies). But we
      // switch to decimal mode when focused, and the default minimumFractionDigits in decimal mode is 0, so we risk
      // losing the non-significant fractional part of the number on focus. Explicitly setting minimumFractionDigits
      // to the same value as the blurred formatter prevents this.
      minimumFractionDigits: blurredFormatter.resolvedOptions().minimumFractionDigits,
      // Set maximumFractionDigits to its maximum value while focused to avoid loss of precision
      maximumFractionDigits: 20,
      // Non-decimal styles aren't parseable by NumberParser, so temporarily switch to decimal mode while focused.
      style: 'decimal',
      // Numbers formatted in compact notation aren't parseable by NumberParser, so temporarily switch to standard
      // notation while focused.
      notation: numberFormatOptions?.notation === 'compact' ? 'standard' : numberFormatOptions?.notation,
      // Force the sign to be displayed while focused to prevent parsing a negative number as positive
      signDisplay: numberFormatOptions?.signDisplay === 'never' ? 'auto' : numberFormatOptions?.signDisplay,
      // Disable grouping while focused unless option was provided explicitly
      useGrouping: numberFormatOptions?.useGrouping ?? false,
    });

    return {
      blurred: blurredFormatter,
      focused: focusedFormatter,
    };
  }, [locale, numberFormatOptions]);

  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [forceNumberReformat, setForceNumberReformat] = useState<boolean>(false);

  const formatNumber = useCallback(
    (value: number | null) =>
      value == null ? '' : Number.isNaN(value) ? '?' : (isFocused ? formatters.focused : formatters.blurred).format(value),
    [formatters.blurred, formatters.focused, isFocused],
  );

  const parseNumber = useMemo(() => {
    const numberParser = NumberParser.forLocale(locale);
    return (value: string) => (!value.trim() ? null : numberParser.parseFuzzy(value));
  }, [locale]);

  const lastInputValue = usePreviousValue(inputValue, inputValue);
  const [numberValue, setNumberValue] = useState<number | null>(inputValue);
  const [stringValue, setStringValue] = useState<string>(() => formatNumber(inputValue));
  const isInvalid = numberValue == null && stringValue.trim() !== '';

  // Reset component state whenever inputValue changes to a value different from numberValue.
  // This is the idiomatic way to reset state on props change according to:
  // https://github.com/facebook/react/issues/14920#issuecomment-471070149
  // Note: we use !Object.is() here because !== would fail with NaN
  if (!Object.is(inputValue, lastInputValue)) {
    // If inputValue is different from numberValue, it means the change is coming from "outside" (i.e. not in response
    // to the user interacting with the component) so we force a reformat even while focused to make sure the user
    // sees the new value.
    if (!Object.is(inputValue, numberValue)) {
      setNumberValue(inputValue);
      setForceNumberReformat(true);
    }
  }

  // Reformat the string value whenever needed, using useLayoutEffect() instead of useEffect() to avoid flickering.
  useLayoutEffect(() => {
    if ((!isFocused && !isInvalid) || forceNumberReformat) {
      setStringValue(formatNumber(numberValue));
      setForceNumberReformat(false);
    }
  }, [forceNumberReformat, formatNumber, isFocused, isInvalid, numberValue]);

  const handleFocus: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
    (event) => {
      setIsFocused(true);
      if (!isInvalid) {
        setForceNumberReformat(true);
      }
      onFocus?.(event);
    },
    [isInvalid, onFocus],
  );

  const handleChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = useCallback(
    (event) => {
      setStringValue(event.target.value);
      const parsed = parseNumber(event.target.value);
      const newNumberValue = Number.isNaN(parsed) ? null : parsed;
      setNumberValue(newNumberValue);
      onChange?.(newNumberValue, event);
    },
    [onChange, parseNumber],
  );

  const handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
    (event) => {
      setIsFocused(false);
      // seems to fix some rounding issues.. best way would be to replicate the pattern of the price input
      const rounded = numberValue == null ? null : step && step > 0 ? Math.round(numberValue * (1 / step)) / (1 / step) : numberValue;
      const clamped = rounded == null ? null : clamp(rounded, min, max);
      // Only call onChange() if there was an actual value change to avoid marking the field as dirty just because it
      // was focused then blurred.
      if (!Object.is(clamped, numberValue)) {
        const newNumberValue = Number.isNaN(clamped) ? null : clamped;
        setNumberValue(newNumberValue);
        onChange?.(newNumberValue, event);
      }
      onBlur?.(event);
    },
    [max, min, numberValue, onBlur, onChange, step],
  );

  const inputProps: TextFieldProps['InputProps'] = useMemo(
    () => ({
      // disable autocomplete by default, overridable through InputProps.autoComplete
      autoComplete: 'off',
      ...InputProps,
      inputProps: {
        // default to numeric (integer) keyboard when step is an integer, decimal (fractional) keyboard otherwise,
        // overridable through InputProps.inputProps.inputMode
        inputMode: Number.isInteger(step) ? 'numeric' : 'decimal',
        ...InputProps?.inputProps,
      },
    }),
    [InputProps, step],
  );

  const error = inputError || isInvalid;
  const TextFieldComponent = borderless ? BorderlessTextField : TextField;

  return (
    <TextFieldComponent
      type='text'
      value={stringValue}
      onFocus={handleFocus}
      onChange={handleChange}
      onBlur={handleBlur}
      inputRef={ref}
      InputProps={inputProps}
      error={error}
      {...rest}
    />
  );
});
