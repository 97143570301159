/**
 * @generated SignedSource<<198a7961b7256cbb11f94cc0290cf231>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SubRegionAutocompleteQuery$variables = {
  searchTerm?: string | null | undefined;
};
export type SubRegionAutocompleteQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SubRegionAutocompleteListFragment">;
};
export type SubRegionAutocompleteQuery = {
  response: SubRegionAutocompleteQuery$data;
  variables: SubRegionAutocompleteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchTerm"
  }
],
v1 = {
  "kind": "Variable",
  "name": "searchTerm",
  "variableName": "searchTerm"
},
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 25
  },
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SubRegionAutocompleteQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "SubRegionAutocompleteListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SubRegionAutocompleteQuery",
    "selections": [
      {
        "alias": "searchResults",
        "args": (v2/*: any*/),
        "concreteType": "SearchSubRegionsConnection",
        "kind": "LinkedField",
        "name": "searchSubRegions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SearchSubRegionsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SubRegion",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "name": "label",
                    "args": null,
                    "fragment": {
                      "kind": "InlineFragment",
                      "selections": [
                        (v4/*: any*/)
                      ],
                      "type": "SubRegion",
                      "abstractKey": null
                    },
                    "kind": "RelayResolver",
                    "storageKey": null,
                    "isOutputType": true
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Region",
                    "kind": "LinkedField",
                    "name": "region",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "searchResults",
        "args": (v2/*: any*/),
        "filters": [
          "searchTerm"
        ],
        "handle": "connection",
        "key": "SubRegionAutocompleteListFragment_searchResults",
        "kind": "LinkedHandle",
        "name": "searchSubRegions"
      }
    ]
  },
  "params": {
    "cacheID": "d5d5ceeef97f79b7694bdc7030a6a5b8",
    "id": null,
    "metadata": {},
    "name": "SubRegionAutocompleteQuery",
    "operationKind": "query",
    "text": "query SubRegionAutocompleteQuery(\n  $searchTerm: String\n) {\n  ...SubRegionAutocompleteListFragment_1CW4ID\n}\n\nfragment SubRegionAutocompleteListFragment_1CW4ID on Query {\n  searchResults: searchSubRegions(searchTerm: $searchTerm, first: 25) {\n    edges {\n      node {\n        id\n        ...SubRegionLabelResolver\n        region {\n          description\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment SubRegionLabelResolver on SubRegion {\n  description\n}\n"
  }
};
})();

(node as any).hash = "558fafea18e263f92e7a1b48c8ab6f95";

export default node;
