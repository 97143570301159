/** The public API of a function wrapped with {@link debounce}. */
export type Debounced<T extends (...args: never[]) => unknown> = ((...args: Parameters<T>) => void) & {
  /** Cancel any pending debounced calls. */
  cancel: () => void;
  /** Cancel any pending debounced calls and synchronously call the wrapped function with the provided arguments. */
  sync: (...args: Parameters<T>) => void;
};

export function debounce<T extends (...args: never[]) => unknown>(func: T, delay: number): Debounced<T> {
  let timeoutId: number | undefined = undefined;

  const debounced = (...args: Parameters<T>): void => {
    cancel();
    timeoutId = setTimeout(func, delay, ...args);
  };

  const cancel: Debounced<T>['cancel'] = () => {
    clearTimeout(timeoutId);
  };

  const sync: Debounced<T>['sync'] = (...args) => {
    cancel();
    func(...args);
  };

  return Object.assign(debounced, { cancel, sync });
}
