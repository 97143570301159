/**
 * @generated SignedSource<<498da02080d0c6aeca2e354d006c13a5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type WorkScheduleEnum = "fiveDaysEightHours" | "fiveDaysNineHours" | "fiveDaysTenHours" | "fourDaysTenHours" | "fourDaysTenHoursOneDayFiveHours" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NatureOfWorkFields_WorkSchedulesFragment$data = {
  readonly workSchedules: ReadonlyArray<WorkScheduleEnum>;
  readonly " $fragmentType": "NatureOfWorkFields_WorkSchedulesFragment";
};
export type NatureOfWorkFields_WorkSchedulesFragment$key = {
  readonly " $data"?: NatureOfWorkFields_WorkSchedulesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NatureOfWorkFields_WorkSchedulesFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NatureOfWorkFields_WorkSchedulesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workSchedules",
      "storageKey": null
    }
  ],
  "type": "NatureOfWorkLookup",
  "abstractKey": null
};

(node as any).hash = "394f4b10f96c796dd9ed386b0172bebb";

export default node;
