import { Box, ListItemIcon, ListItemText, Skeleton, Theme, Typography, useMediaQuery } from '@mui/material';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { visuallyHidden } from '@mui/utils';
import { EllipsisedTypography } from '../common/components/EllipsisedTypography';
import { ResponsiveGridColumnDefinition, ResponsiveGridColumnOrderer } from '../common/components/ResponsiveGrid';
import { NullableCell } from '../common/components/NullableCell';
import { DateTime } from 'luxon';
import { dateFormat } from '../common/utils/dateTimeUtils';
import { WorkPlanningStatusChip } from './WorkPlanningStatusChip';
import { isWorkPlanningStatus } from '../__enums__/WorkPlanningStatus';
import { useTranslation } from 'react-i18next';
import { WorkPlanningList_ItemFragment$key } from './__generated__/WorkPlanningList_ItemFragment.graphql';
import { WorkPlanningList_RowFragment$key } from './__generated__/WorkPlanningList_RowFragment.graphql';
import { RequireWrite } from '../auth/Authorization';
import { WorkPlanningList_ActionsFragment$key } from './__generated__/WorkPlanningList_ActionsFragment.graphql';
import { WorkPlanningList_Actions_MeFragment$key } from './__generated__/WorkPlanningList_Actions_MeFragment.graphql';
import { WorkPlanningList_Row_MeFragment$key } from './__generated__/WorkPlanningList_Row_MeFragment.graphql';
import { WorkPlanningList_CopyButton } from './WorkPlanningList.CopyButton';
import { CreditCategoryChip } from '../jobs/CreditCategoryChip';
import { TFunction } from 'i18next';

export function getWorkPlanningListColumns(t: TFunction, compact: boolean): ResponsiveGridColumnDefinition[] {
  return [
    { id: 'friendlyId', label: t('list.column.friendlyId'), size: '7rem' },
    { id: 'client', label: t('list.column.client'), size: 'minmax(6rem, 1fr)' },
    { id: 'worksite', label: t('list.column.worksite'), size: 'minmax(6rem, 1fr)' },
    { id: 'status', label: compact ? '' : t('list.column.status'), size: 'auto' },
    { id: 'date', label: t('list.column.date'), size: 'auto' },
    { id: 'actions', label: '', size: 'minmax(3rem, auto)' },
  ];
}

export const workPlanningListSx = {
  "&[data-mode='grid']": {
    // Adds a padding in cells to improve readability, specially when elipsing.
    'li.responsive-grid__header  > *': {
      px: '1rem',
    },
    'li:not(.responsive-grid__header)  > *': {
      px: '0.5rem',
    },
  },

  // Controls the gap between the content of list items and the status chip.
  "&[data-mode='list'] > li": {
    gap: '0.5rem',
  },
};

export function WorkPlanningList_Row({
  $key,
  me$key,
  orderByColumns,
}: {
  $key: WorkPlanningList_RowFragment$key;
  me$key: WorkPlanningList_Row_MeFragment$key;
  orderByColumns: ResponsiveGridColumnOrderer;
}) {
  const { t } = useTranslation();
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const $data = useFragment(
    graphql`
      fragment WorkPlanningList_RowFragment on WorkPlanningJobRevision {
        friendlyId
        snapshot {
          statuses
          clientBase {
            assignedClient {
              name
              isDirectSales
              ...CreditCategoryChipFragment
            }
            assignedClientInfo {
              name
            }
          }
          projectBase {
            assignedWorksiteInfo {
              name
            }
            arrivalDate {
              rawValue
            }
          }
        }
        ...WorkPlanningList_ActionsFragment
      }
    `,
    $key,
  );
  const { snapshot } = $data;

  const me$data = useFragment(
    graphql`
      fragment WorkPlanningList_Row_MeFragment on Query {
        ...WorkPlanningList_Actions_MeFragment
      }
    `,
    me$key,
  );

  const clientName = snapshot?.clientBase.assignedClient?.isDirectSales
    ? snapshot.clientBase.assignedClientInfo.name
    : snapshot?.clientBase.assignedClient?.name;
  const statuses = snapshot?.statuses.every(isWorkPlanningStatus) ? (snapshot?.statuses ?? []) : [];

  return (
    <>
      <h3 style={visuallyHidden}>
        <NullableCell value={$data.friendlyId?.toString()} />
        <br />
        <NullableCell value={$data.snapshot?.clientBase.assignedClient?.name} />
        <br />
        <NullableCell value={$data.snapshot?.projectBase.assignedWorksiteInfo?.name} />
      </h3>

      {orderByColumns([
        <Typography key='friendlyId' variant='body2'>
          <NullableCell value={$data.friendlyId?.toString()} />
        </Typography>,

        <Box key='client' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '0.25rem' }}>
          <EllipsisedTypography variant='body2' component='span'>
            <NullableCell value={clientName} />
          </EllipsisedTypography>
          {$data.snapshot?.clientBase.assignedClient && <CreditCategoryChip $key={$data.snapshot.clientBase.assignedClient} />}
        </Box>,

        <EllipsisedTypography key='worksite' variant='body2' component='span'>
          <NullableCell value={$data.snapshot?.projectBase.assignedWorksiteInfo?.name} />
        </EllipsisedTypography>,

        <Box key='status' display='flex' alignItems='center'>
          <WorkPlanningStatusChip statuses={statuses} compact={compact} />
        </Box>,

        <Typography
          key='date'
          variant='body2'
          title={DateTime.fromISO($data.snapshot?.projectBase.arrivalDate?.rawValue ?? '')
            .setLocale(t('locale', { ns: 'common' }))
            .toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' })}>
          <NullableCell
            formatter={(v: string) =>
              DateTime.fromISO(v)
                .setLocale(t('locale', { ns: 'common' }))
                .toLocaleString({
                  month: 'short',
                  day: 'numeric',
                })
            }
            value={$data.snapshot?.projectBase.arrivalDate?.rawValue}
          />
        </Typography>,
        <WorkPlanningList_Actions key='actions' $key={$data} me$key={me$data} />,
      ])}
    </>
  );
}

function WorkPlanningList_Actions({
  $key,
  me$key,
}: {
  $key: WorkPlanningList_ActionsFragment$key | null | undefined;
  me$key: WorkPlanningList_Actions_MeFragment$key;
}) {
  const $data = useFragment(
    graphql`
      fragment WorkPlanningList_ActionsFragment on WorkPlanningJobRevision {
        ...WorkPlanningList_CopyButtonFragment
      }
    `,
    $key,
  );

  const me$data = useFragment(
    graphql`
      fragment WorkPlanningList_Actions_MeFragment on Query {
        ...AuthorizationWriteFragment
      }
    `,
    me$key,
  );

  return (
    // need to fallback on an empty element to keep grid columns from displaying correctly
    <RequireWrite $key={me$data} fallback={<div />}>
      <Box>
        <Box sx={{ opacity: 'var(--hover-highlight)' }}>
          <WorkPlanningList_CopyButton $key={$data} />
        </Box>
      </Box>
    </RequireWrite>
  );
}

export function WorkPlanningList_Item({ $key }: { $key: WorkPlanningList_ItemFragment$key }) {
  const $data = useFragment(
    graphql`
      fragment WorkPlanningList_ItemFragment on WorkPlanningJobRevision {
        friendlyId
        snapshot {
          statuses
          clientBase {
            assignedClient {
              name
              isDirectSales
            }
            assignedClientInfo {
              name
            }
          }
          projectBase {
            assignedWorksiteInfo {
              name
            }
            arrivalDate {
              rawValue
            }
          }
        }
      }
    `,
    $key,
  );
  const { snapshot } = $data;
  const clientName = snapshot?.clientBase.assignedClient?.isDirectSales
    ? snapshot.clientBase.assignedClientInfo.name
    : snapshot?.clientBase.assignedClient?.name;
  const statuses = snapshot?.statuses.every(isWorkPlanningStatus) ? (snapshot?.statuses ?? []) : [];
  return (
    <>
      <h3 style={visuallyHidden}>
        <NullableCell value={$data.friendlyId?.toString()} />
        <br />
        <NullableCell value={$data.snapshot?.clientBase.assignedClient?.name} />
        <br />
        <NullableCell value={$data.snapshot?.projectBase.assignedWorksiteInfo?.name} />
      </h3>

      <ListItemText disableTypography={true}>
        <Typography color='text.secondary' fontSize='0.75rem'>
          <NullableCell value={$data.friendlyId?.toString()} />
          <span> | </span>
          <NullableCell
            formatter={(v: string) => DateTime.fromISO(v).toFormat(dateFormat)}
            value={$data.snapshot?.projectBase.arrivalDate?.rawValue}
          />
        </Typography>
        <EllipsisedTypography variant='subtitle2' component='p' color='text.primary'>
          <NullableCell value={clientName} />
        </EllipsisedTypography>
        <EllipsisedTypography variant='body2' component='p' color='text.secondary'>
          <NullableCell value={$data.snapshot?.projectBase.assignedWorksiteInfo?.name} />
        </EllipsisedTypography>
      </ListItemText>
      <ListItemIcon>
        <WorkPlanningStatusChip statuses={statuses} />
      </ListItemIcon>
    </>
  );
}

export function WorkPlanningList_RowSkeleton({ columns }: { columns: ResponsiveGridColumnDefinition[] }) {
  return (
    <span style={{ gridColumn: `1 / span ${columns.length}` }}>
      <Skeleton variant='rounded' height='1.5rem' sx={{ my: '0.875rem' }} />
    </span>
  );
}

export function WorkPlanningList_ListSkeleton() {
  return (
    <ListItemText>
      <Skeleton variant='rounded' width='6rem' height='1rem' sx={{ mb: '0.25rem' }} />
      <Skeleton variant='rounded' width='14rem' height='1.25rem' sx={{ mb: '0.25rem' }} />
      <Skeleton variant='rounded' width='10rem' height='1.25rem' />
    </ListItemText>
  );
}
