/**
 * @generated SignedSource<<3a6ae54f1db91ef80c4f04a33819edb4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type QuoteKind = "accessoriesRental" | "bare" | "bareWithOperators" | "laborRental" | "liftingPlan" | "liftingTest" | "operatedHourly" | "operatedMonthly" | "rollingEquipment" | "storage" | "%future added value";
export type QuoteStatus = "accepted" | "awaitingClient" | "canceled" | "declined" | "inApproval" | "inWriting" | "locked" | "revising" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type QuoteList_RowFragment$data = {
  readonly friendlyId: string | null | undefined;
  readonly snapshot: {
    readonly clientBase: {
      readonly assignedClient: {
        readonly isDirectSales: boolean;
        readonly name: string;
        readonly number: string;
        readonly " $fragmentSpreads": FragmentRefs<"CreditCategoryChipFragment">;
      } | null | undefined;
      readonly assignedClientInfo: {
        readonly name: string;
      };
    };
    readonly equipmentBase: {
      readonly craneSelector: {
        readonly favoriteConfiguration: {
          readonly capacity: {
            readonly capacity: number;
          } | null | undefined;
          readonly equipmentKind: {
            readonly abbreviation: string;
          } | null | undefined;
        } | null | undefined;
      };
    };
    readonly kind: QuoteKind;
    readonly projectBase: {
      readonly arrivalDate: {
        readonly rawValue: string;
      } | null | undefined;
      readonly assignedWorksiteInfo: {
        readonly name: string | null | undefined;
      };
    };
    readonly statuses: ReadonlyArray<QuoteStatus>;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"QuoteList_ActionsFragment">;
  readonly " $fragmentType": "QuoteList_RowFragment";
};
export type QuoteList_RowFragment$key = {
  readonly " $data"?: QuoteList_RowFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuoteList_RowFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuoteList_RowFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "friendlyId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Quote",
      "kind": "LinkedField",
      "name": "snapshot",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "statuses",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ClientInternalBase",
          "kind": "LinkedField",
          "name": "clientBase",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Client",
              "kind": "LinkedField",
              "name": "assignedClient",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "number",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isDirectSales",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "CreditCategoryChipFragment"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ClientInfoInternal",
              "kind": "LinkedField",
              "name": "assignedClientInfo",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EquipmentInternalBase",
          "kind": "LinkedField",
          "name": "equipmentBase",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CraneSelectorInternal",
              "kind": "LinkedField",
              "name": "craneSelector",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "FavoriteConfigurationInfo",
                  "kind": "LinkedField",
                  "name": "favoriteConfiguration",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CraneCapacity",
                      "kind": "LinkedField",
                      "name": "capacity",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "capacity",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "EquipmentKindLookup",
                      "kind": "LinkedField",
                      "name": "equipmentKind",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "abbreviation",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectInternalBase",
          "kind": "LinkedField",
          "name": "projectBase",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "WorksiteInfoInternal",
              "kind": "LinkedField",
              "name": "assignedWorksiteInfo",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "DateTimeInTimeZone",
              "kind": "LinkedField",
              "name": "arrivalDate",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rawValue",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "QuoteList_ActionsFragment"
    }
  ],
  "type": "QuoteJobRevision",
  "abstractKey": null
};
})();

(node as any).hash = "bf8a93e8ec7811aec1029c603c7e82fb";

export default node;
