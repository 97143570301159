import { Box, FormControlLabel, FormGroup, Stack, Switch, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-relay';
import { ListLayout, SidebarContentProps } from '../layout/Layouts';
import { ConfirmationButton } from '../common/components/ConfirmationButton';
import graphql from 'babel-plugin-relay/macro';
import { syncableEntities, SyncableEntity } from '../__enums__/SyncableEntity';
import { RequireAdmin, UnauthorizedFallback } from '../auth/Authorization';
import { useCallback, useState } from 'react';
import { NavigationMenu } from '../layout/SidebarDrawer';
import { ErrorBanner } from '../common/components/ErrorBanner';
import { MaintenanceTabs } from './MaintenanceTabs';
import { MaintenancePageQuery$data } from './__generated__/MaintenancePageQuery.graphql';
import { TabDataSyncDeleteAllMutation } from './__generated__/TabDataSyncDeleteAllMutation.graphql';
import { TabDataSyncSeedAllMutation } from './__generated__/TabDataSyncSeedAllMutation.graphql';
import { TabDataSyncSeedGeneratedMutation } from './__generated__/TabDataSyncSeedGeneratedMutation.graphql';
import { TabDataSyncSeedImportedMutation } from './__generated__/TabDataSyncSeedImportedMutation.graphql';
import { ResyncOptionsInput, TabDataSyncResyncMutation } from './__generated__/TabDataSyncResyncMutation.graphql';
import { useOutletContext } from 'react-router';

function DeleteAllButton({ sx }: { sx?: SxProps }) {
  const { t } = useTranslation('maintenance');
  const [commitDeleteAll] = useMutation<TabDataSyncDeleteAllMutation>(graphql`
    mutation TabDataSyncDeleteAllMutation {
      deleteAllData(input: { safety: true }) {
        boolean
      }
    }
  `);
  const handleDeleteAll = () => {
    return new Promise<void>((resolve, reject) => {
      commitDeleteAll({
        variables: {},
        onCompleted: () => {
          alert(t('dataSyncSection.deleting.success'));
          resolve();
        },
        onError: (error) => {
          alert(t('dataSyncSection.deleting.fail'));
          reject(error);
        },
      });
    });
  };

  return (
    <ConfirmationButton
      buttonColor='error'
      buttonText={t('dataSyncSection.deleting.button')}
      confirmationTitle={t('dataSyncSection.deleting.title')}
      confirmationMessage={t('dataSyncSection.deleting.confirmationText')}
      confirmationButton={t('dataSyncSection.deleting.confirmButton')}
      onConfirm={handleDeleteAll}
      sx={sx}
    />
  );
}

function SeedAllButton({ sx }: { sx?: SxProps }) {
  const { t } = useTranslation('maintenance');
  const [commitSeedAll] = useMutation<TabDataSyncSeedAllMutation>(graphql`
    mutation TabDataSyncSeedAllMutation {
      seedAllData(input: { safety: true }) {
        boolean
      }
    }
  `);
  const handleSeedAll = () => {
    return new Promise<void>((resolve, reject) => {
      commitSeedAll({
        variables: {},
        onCompleted: () => {
          alert(t('dataSyncSection.seeding.success'));
          resolve();
        },
        onError: (error) => {
          alert(t('dataSyncSection.seeding.fail'));
          reject(error);
        },
      });
    });
  };

  return (
    <ConfirmationButton
      buttonColor='success'
      buttonText={t('dataSyncSection.seeding.button')}
      confirmationTitle={t('dataSyncSection.seeding.title')}
      confirmationMessage={t('dataSyncSection.seeding.confirmationText')}
      confirmationButton={t('dataSyncSection.seeding.confirmationButton')}
      onConfirm={handleSeedAll}
      sx={sx}
    />
  );
}

function SeedGeneratedDataButton({ sx }: { sx?: SxProps }) {
  const { t } = useTranslation('maintenance');
  const [commitSeedGenerated] = useMutation<TabDataSyncSeedGeneratedMutation>(graphql`
    mutation TabDataSyncSeedGeneratedMutation {
      seedGeneratedData(input: { safety: true }) {
        boolean
      }
    }
  `);
  const handleSeedGenerated = () => {
    return new Promise<void>((resolve, reject) => {
      commitSeedGenerated({
        variables: {},
        onCompleted: () => {
          alert(t('dataSyncSection.seeding.success'));
          resolve();
        },
        onError: (error) => {
          alert(t('dataSyncSection.seeding.fail'));
          reject(error);
        },
      });
    });
  };

  return (
    <ConfirmationButton
      buttonText={t('dataSyncSection.seeding.buttonGenerated')}
      confirmationTitle={t('dataSyncSection.seeding.title')}
      confirmationMessage={t('dataSyncSection.seeding.confirmationText')}
      confirmationButton={t('dataSyncSection.seeding.confirmationButton')}
      onConfirm={handleSeedGenerated}
      sx={sx}
    />
  );
}

const tabDataSyncSeedImportedMutation = graphql`
  mutation TabDataSyncSeedImportedMutation {
    seedImportedData(input: { safety: true }) {
      boolean
    }
  }
`;
function SeedImportedDataButton({ sx }: { sx?: SxProps }) {
  const { t } = useTranslation('maintenance');
  const [commitSeedImportedData] = useMutation<TabDataSyncSeedImportedMutation>(tabDataSyncSeedImportedMutation);
  const handleSeedImported = () => {
    return new Promise<void>((resolve, reject) => {
      commitSeedImportedData({
        variables: {},
        onCompleted: () => {
          alert(t('dataSyncSection.seeding.success'));
          resolve();
        },
        onError: (error) => {
          alert(t('dataSyncSection.seeding.fail'));
          reject(error);
        },
      });
    });
  };

  return (
    <ConfirmationButton
      buttonText={t('dataSyncSection.seeding.buttonImported')}
      confirmationTitle={t('dataSyncSection.seeding.title')}
      confirmationMessage={t('dataSyncSection.seeding.confirmationText')}
      confirmationButton={t('dataSyncSection.seeding.confirmationButton')}
      onConfirm={handleSeedImported}
      sx={sx}
    />
  );
}

export function ResyncButton({ entityName, options, sx }: { entityName: SyncableEntity; options: ResyncOptionsInput; sx?: SxProps }) {
  const { t } = useTranslation('maintenance');
  const [commitResync] = useMutation<TabDataSyncResyncMutation>(graphql`
    mutation TabDataSyncResyncMutation($entity: SyncableEntity!, $options: ResyncOptionsInput!) {
      resyncEntity(input: { entity: $entity, options: $options }) {
        boolean
      }
    }
  `);

  const handleResync = () => {
    return new Promise<void>((resolve, reject) => {
      commitResync({
        variables: { entity: entityName, options },
        onCompleted: () => {
          alert(t('dataSyncSection.synchronization.success'));
          resolve();
        },
        onError: (error) => {
          alert(t('dataSyncSection.synchronization.fail', { entity: entityName }));
          reject(error);
        },
      });
    });
  };

  return (
    <ConfirmationButton
      buttonColor='info'
      buttonText={entityName}
      confirmationTitle={t('dataSyncSection.synchronization.title')}
      confirmationMessage={t('dataSyncSection.synchronization.confirmationText', { entity: entityName })}
      confirmationButton={t('dataSyncSection.synchronization.confirmationButton')}
      onConfirm={handleResync}
      sx={sx}
    />
  );
}

interface DataSyncSectionProps {
  deleteEnabled: boolean;
  seedEnabled: boolean;
  syncEnabled: boolean;
}

const initialResyncOptions = { readAs400: false, withDeleted: false, withRelation: false };
export function TabDataSync({ deleteEnabled, seedEnabled, syncEnabled }: DataSyncSectionProps) {
  const { t } = useTranslation('maintenance');
  const $data = useOutletContext<MaintenancePageQuery$data>();

  const [resyncOptions, setResyncOptions] = useState<ResyncOptionsInput>(initialResyncOptions);
  const resyncButtons = syncableEntities.map((entity: SyncableEntity) => {
    return <ResyncButton key={entity} entityName={entity} options={resyncOptions} sx={{ gridColumn: '1' }} />;
  });

  const sidebar = useCallback((props: SidebarContentProps) => <NavigationMenu {...props} $key={$data} />, [$data]);
  const flagName = 'app_navigation_maintenance';

  return (
    <RequireAdmin
      $key={$data}
      fallback={
        <ListLayout heading={t('maintenance')} flagName={flagName} sidebarProvider={sidebar} $key={$data}>
          <UnauthorizedFallback />
        </ListLayout>
      }>
      <ListLayout heading={t('maintenance')} flagName={flagName} sidebarProvider={sidebar} $key={$data}>
        <ErrorBanner />
        <MaintenanceTabs tab='data-sync'></MaintenanceTabs>

        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridAutoFlow: 'dense', gap: '0.5rem' }}>
          {syncEnabled && (
            <>
              <Typography variant='subtitle1' component='h4' sx={{ gridColumn: '1' }}>
                {t('dataSyncSection.syncSectionTitle')}
              </Typography>
              <FormGroup sx={{ gridColumn: '1' }}>
                <FormControlLabel
                  control={<Switch />}
                  onChange={(_, checked) => setResyncOptions((prev) => ({ ...prev, readAs400: checked }))}
                  label='readAs400'
                />
                <FormControlLabel
                  control={<Switch />}
                  onChange={(_, checked) => setResyncOptions((prev) => ({ ...prev, withDeleted: checked }))}
                  label='withDeleted'
                />
                <FormControlLabel
                  control={<Switch />}
                  onChange={(_, checked) => setResyncOptions((prev) => ({ ...prev, withRelation: checked }))}
                  label='withRelation'
                />
              </FormGroup>
              {resyncButtons}
            </>
          )}
          {deleteEnabled && (
            <>
              <Typography variant='subtitle1' component='h4' sx={{ gridColumn: '2' }}>
                {t('dataSyncSection.deleteSectionTitle')}
              </Typography>
              <DeleteAllButton sx={{ gridColumn: '2' }} />
            </>
          )}
          {seedEnabled && (
            <>
              <Typography variant='subtitle1' component='h4' sx={{ gridColumn: '3' }}>
                {t('dataSyncSection.seedSectionTitle')}
              </Typography>
              <SeedAllButton sx={{ gridColumn: '3' }} />
              <SeedGeneratedDataButton sx={{ gridColumn: '3' }} />
              <SeedImportedDataButton sx={{ gridColumn: '3' }} />
            </>
          )}
        </Box>
        <Stack gap={2} maxWidth='32rem'></Stack>
        <Stack maxWidth='32rem' gap={2}></Stack>
        <Stack maxWidth='32rem' gap={2}></Stack>
      </ListLayout>
    </RequireAdmin>
  );
}
