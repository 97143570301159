/**
 * @generated SignedSource<<7fcf4e879321c55dea8db45892bebc61>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ServiceCallList_RegularFragment$data = {
  readonly roles: ReadonlyArray<string>;
  readonly supervisedRegions: ReadonlyArray<{
    readonly subRegions: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"JobFilters_useJobFiltersFragment">;
  readonly " $fragmentType": "ServiceCallList_RegularFragment";
};
export type ServiceCallList_RegularFragment$key = {
  readonly " $data"?: ServiceCallList_RegularFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ServiceCallList_RegularFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ServiceCallList_RegularFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "JobFilters_useJobFiltersFragment"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roles",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Region",
      "kind": "LinkedField",
      "name": "supervisedRegions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SubRegion",
          "kind": "LinkedField",
          "name": "subRegions",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Employee",
  "abstractKey": null
};

(node as any).hash = "66d5d93409ebbbcaad6d6d7898e9bf67";

export default node;
