import { Navigate, Route } from 'react-router';
import { TabAddendumTemplates } from './TabAddendumTemplates';
import { TabCompetitors } from './TabCompetitors';
import { AddendumTemplateDetails_Page } from './addendumTemplate/AddendumTemplateDetails.Page';
import { EmptyLayout } from '../layout/Layouts';
import { TabRateStrategies } from './TabRateStrategies';
import { RateStrategyDetails_Page } from './rateStrategies/RateStrategyDetails.Page';
import { TabBillingCodes } from './TabBillingCodes';
import { BillingCodeDetails_Page } from './billingCodes/BillingCodeDetails.Page';
import { TabNaturesOfWork } from './TabNaturesOfWork';
import { NatureOfWorkDetails_Page } from './naturesOfWork/NatureOfWorkDetails.Page';

export const configurationRoutes = (
  <Route path='configuration' lazy={() => import('./ConfigurationPage')}>
    <Route
      index
      element={
        <EmptyLayout>
          <Navigate to='addenda' replace />
        </EmptyLayout>
      }
    />
    <Route path='addenda' element={<TabAddendumTemplates />} />
    <Route path='addenda/:id' element={<AddendumTemplateDetails_Page />} />
    <Route path='billing-codes' element={<TabBillingCodes />} />
    <Route path='billing-codes/:id' element={<BillingCodeDetails_Page />} />
    <Route path='competitors' element={<TabCompetitors />} />
    <Route path='natures-of-work' element={<TabNaturesOfWork />} />
    <Route path='natures-of-work/:id' element={<NatureOfWorkDetails_Page />} />
    <Route path='rate-strategies' element={<TabRateStrategies />} />
    <Route path='rate-strategies/:id' element={<RateStrategyDetails_Page />} />
  </Route>
);
