/**
 * @generated SignedSource<<70b6f324340f0e76d9bb3cd0b0670e39>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NatureOfWorkSubCategoryEnum = "accessory" | "airports" | "antennas" | "aqueductsSewer" | "blade" | "bladeChange" | "bridges" | "cages" | "chambers" | "dams" | "demolition" | "distribution" | "docks" | "excavation" | "gearboxes" | "generator" | "gondola" | "hand" | "handling" | "landscaping" | "lifting" | "lines" | "modules" | "newConstruction" | "nonOperated" | "powerPlants" | "pruning" | "pylons" | "refurbishment" | "retentionBasins" | "roadsRails" | "rotor" | "shelters" | "substations" | "towers" | "transformers" | "transport" | "valves" | "ventilationPlumbingElectrical" | "%future added value";
export type ServiceCallKind = "accessoriesRental" | "bare" | "bareWithOperators" | "laborRental" | "laborRentalOnBare" | "laborRentalOnOperated" | "liftingPlan" | "liftingTest" | "operatedHourly" | "operatedMonthly" | "rollingEquipment" | "storage" | "%future added value";
export type SuggestionPromptInput = {
  accessoryTypes: ReadonlyArray<string>;
  capacity?: number | null | undefined;
  clientId?: string | null | undefined;
  clientRequirements: ReadonlyArray<string>;
  dispatchBranchId?: string | null | undefined;
  equipmentKindCode?: string | null | undefined;
  hoursPerDay?: number | null | undefined;
  inTheCareOf?: string | null | undefined;
  isSecondServing?: boolean | null | undefined;
  lengthInDays?: number | null | undefined;
  natureOfWorkCode?: string | null | undefined;
  natureOfWorkSubCategory?: NatureOfWorkSubCategoryEnum | null | undefined;
  representativeId?: string | null | undefined;
  salesBranchId?: string | null | undefined;
  serviceCallKind?: ServiceCallKind | null | undefined;
  vehicleIds: ReadonlyArray<string>;
  workDescription?: string | null | undefined;
  worksiteId?: string | null | undefined;
  worksiteRequirements: ReadonlyArray<string>;
};
export type DispatchBranchAutocompleteQuery$variables = {
  isDispatchBranch?: boolean | null | undefined;
  searchTerm?: string | null | undefined;
  skipSuggestion: boolean;
  suggestionCount: number;
  suggestionPrompt: SuggestionPromptInput;
  suggestionScoreThreshold: number;
};
export type DispatchBranchAutocompleteQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DispatchBranchAutocompleteListFragment">;
};
export type DispatchBranchAutocompleteQuery = {
  response: DispatchBranchAutocompleteQuery$data;
  variables: DispatchBranchAutocompleteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isDispatchBranch"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchTerm"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skipSuggestion"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "suggestionCount"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "suggestionPrompt"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "suggestionScoreThreshold"
},
v6 = {
  "kind": "Variable",
  "name": "isDispatchBranch",
  "variableName": "isDispatchBranch"
},
v7 = {
  "kind": "Variable",
  "name": "searchTerm",
  "variableName": "searchTerm"
},
v8 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 25
  },
  (v6/*: any*/),
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "name": "label",
  "args": null,
  "fragment": {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "number",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "type": "Branch",
    "abstractKey": null
  },
  "kind": "RelayResolver",
  "storageKey": null,
  "isOutputType": true
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DispatchBranchAutocompleteQuery",
    "selections": [
      {
        "args": [
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "Variable",
            "name": "skipSuggestion",
            "variableName": "skipSuggestion"
          },
          {
            "kind": "Variable",
            "name": "suggestionCount",
            "variableName": "suggestionCount"
          },
          {
            "kind": "Variable",
            "name": "suggestionPrompt",
            "variableName": "suggestionPrompt"
          },
          {
            "kind": "Variable",
            "name": "suggestionScoreThreshold",
            "variableName": "suggestionScoreThreshold"
          }
        ],
        "kind": "FragmentSpread",
        "name": "DispatchBranchAutocompleteListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "DispatchBranchAutocompleteQuery",
    "selections": [
      {
        "alias": "searchResults",
        "args": (v8/*: any*/),
        "concreteType": "SearchBranchesConnection",
        "kind": "LinkedField",
        "name": "searchBranches",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SearchBranchesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Branch",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "searchResults",
        "args": (v8/*: any*/),
        "filters": [
          "searchTerm",
          "isDispatchBranch"
        ],
        "handle": "connection",
        "key": "dispatchBranchAutocomplete2ListFragment_searchResults",
        "kind": "LinkedHandle",
        "name": "searchBranches"
      },
      {
        "condition": "skipSuggestion",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "suggestions",
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "suggestionCount"
              },
              {
                "kind": "Variable",
                "name": "prompt",
                "variableName": "suggestionPrompt"
              },
              {
                "kind": "Variable",
                "name": "scoreThreshold",
                "variableName": "suggestionScoreThreshold"
              }
            ],
            "concreteType": "SuggestionOfBranch",
            "kind": "LinkedField",
            "name": "suggestedDispatchBranches",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "score",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Branch",
                "kind": "LinkedField",
                "name": "value",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "8520d3a2f1a27ebf6ad5b1c7596a6918",
    "id": null,
    "metadata": {},
    "name": "DispatchBranchAutocompleteQuery",
    "operationKind": "query",
    "text": "query DispatchBranchAutocompleteQuery(\n  $searchTerm: String\n  $isDispatchBranch: Boolean\n  $suggestionPrompt: SuggestionPromptInput!\n  $suggestionCount: Int!\n  $suggestionScoreThreshold: Float!\n  $skipSuggestion: Boolean!\n) {\n  ...DispatchBranchAutocompleteListFragment_3YN6Id\n}\n\nfragment BranchLabelResolver on Branch {\n  number\n  name\n}\n\nfragment DispatchBranchAutocompleteListFragment_3YN6Id on Query {\n  searchResults: searchBranches(searchTerm: $searchTerm, isDispatchBranch: $isDispatchBranch, first: 25) {\n    edges {\n      node {\n        id\n        ...BranchLabelResolver\n        deletedAt\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  suggestions: suggestedDispatchBranches(prompt: $suggestionPrompt, count: $suggestionCount, scoreThreshold: $suggestionScoreThreshold) @skip(if: $skipSuggestion) {\n    score\n    value {\n      id\n      ...BranchLabelResolver\n      deletedAt\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b9dbbc5721a29f7a88e57e6f4f7ddfc6";

export default node;
