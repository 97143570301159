import { useEffect } from 'react';

/**
 * An interface that maps custom event types to custom event data types.
 *
 * This interface is empty by default because it's meant to be augmented from other modules to define new custom event
 * types.
 *
 * Data-less events should be mapped to the `void` data type, since this allows calling {@link dispatchCustomEvent} with
 * the event type as the only parameter, while an additional `eventData` parameter is required for non-`void` data
 * types.
 *
 * @example
 * ```ts
 * declare module 'path/to/customEvents' {
 *   interface CustomEvents {
 *     okButtonClicked: void;
 *     inputTextChanged: string;
 *   }
 * }
 * ```
 */
// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface CustomEvents {}

/** A module-private {@link EventTarget} used to dispatch and listen to custom events. */
const target: EventTarget = document.createElement('div');

/**
 * Dispatch a custom event with the provided event type and, if the event type has a non-`void` data type, the provided
 * event data.
 *
 * The event dispatching process is not tied to the DOM or to React's component tree, so `dispatchCustomEvent()` will
 * always trigger all event listeners for the provided event type regardless of the components that registered them
 * (i.e. there is no event bubbling or capturing).
 *
 * @example
 * ```ts
 * function DispatcherComponent() {
 *   return (
 *     <>
 *       <input type='text' onChange={(e) => dispatchCustomEvent('inputTextChanged', e.target.value)} />
 *       <button type='button' onClick={() => dispatchCustomEvent('okButtonClicked')}>
 *         OK
 *       </button>
 *     </>
 *   );
 * }
 * ```*/
export function dispatchCustomEvent<K extends keyof CustomEvents>(
  type: K,
  // Omit eventData argument when void
  ...rest: [CustomEvents[K]] extends [void] ? [] : [eventData: CustomEvents[K]]
): void {
  target.dispatchEvent(new CustomEvent(type, { detail: rest[0] }));
}

/**
 * Register an event listener that will be called whenever an event of the provided type is dispatched.
 *
 * Any time the type or listener changes, the old listener is unregistered and the new one is registered.
 *
 * If the provided event type has a `void` data type, then the listener doesn't take any arguments, otherwise the
 * listener takes the event data as its only argument.
 *
 * @example
 * ```ts
 * function ListenerComponent() {
 *   useCustomEventListener('okButtonClicked', () => {
 *     console.log(`OK button clicked`);
 *   });
 *   useCustomEventListener('inputTextChanged', (inputText) => {
 *     console.log(`Input text changed: ${inputText}`);
 *   });
 *   return <></>;
 * }
 * ```
 */
export function useCustomEventListener<K extends keyof CustomEvents>(
  type: K,
  // Omit eventData argument when void
  listener: [CustomEvents[K]] extends [void] ? () => void : (eventData: CustomEvents[K]) => void,
): void {
  useEffect(() => {
    const domListener = (event: Event) => {
      if (event instanceof CustomEvent) listener(event.detail);
    };

    target.addEventListener(type, domListener);

    return () => {
      target.removeEventListener(type, domListener);
    };
  }, [type, listener]);
}
