import { apiNumberFormatter, Dimension, DimensionMinMax } from '.';
import { defaultLogger, Logger } from '../logging';
import Qty from 'js-quantities';
import { Namespace, TFunction } from 'i18next';

export const lengthUnits = ['ft', 'in', 'ftin', 'mm', 'cm', 'm', 'km'] as const;
export type LengthUnit = (typeof lengthUnits)[number];

export function isLengthUnit(val: unknown): val is LengthUnit {
  return lengthUnits.includes(val as LengthUnit);
}

export type LengthProps =
  | {
      scalar: number;
      unit: 'ftin';
      inchesScalar: number;
    }
  | {
      scalar: number;
      unit: Exclude<LengthUnit, 'ftin'>;
      inchesScalar: undefined;
    };

const inchesToFeetAndInches = (inches: number) => ({
  feet: Math.trunc(inches / 12),
  inches: inches % 12,
});

export class Length extends Dimension<LengthUnit, LengthProps> {
  protected static readonly logger = new Logger(defaultLogger, 'Length', new Date().toISOString());

  static parse(serializedLength: string | null | undefined): Length | null {
    if (serializedLength == null) return null;

    const [feetStr, ft, inchesStr, in_] = serializedLength.split(' ');
    if (feetStr && ft === 'ft' && inchesStr && in_ === 'in') {
      const feet = +feetStr;
      const inches = +inchesStr;
      if (!Number.isFinite(feet) || !Number.isFinite(inches)) {
        this.logger.error(
          `Failed to parse value ${JSON.stringify(serializedLength)}: 'ftin' length contains invalid scalars ${JSON.stringify(feetStr)} and ${JSON.stringify(inchesStr)}`,
        );
        return null;
      }
      return new Length(feet, 'ftin', inches);
    }

    return this.baseParse(serializedLength, isLengthUnit, ({ scalar, unit }) => {
      if (unit === 'ftin') throw new Error("Unexpected unit 'ftin'"); // Should never happen
      return new Length(scalar, unit);
    });
  }

  get inchesScalar() {
    return this.props.inchesScalar;
  }

  constructor(scalar: number, unit: 'ftin', inchesScalar: number);
  constructor(scalar: number, unit: Exclude<LengthUnit, 'ftin'>);
  constructor(scalar: number, unit: LengthUnit, inchesScalar: number | undefined);
  constructor(scalar: number, unit: LengthUnit, inchesScalar?: number | undefined) {
    if (unit === 'ftin') {
      if (typeof inchesScalar !== 'number') {
        throw new TypeError("Length: inchesScalar argument must be a number when unit is 'ftin'");
      }
      // Normalize feet and inches, i.e. make sure Math.abs(inchesScalar) < 12
      const { feet, inches } = inchesToFeetAndInches(scalar * 12 + inchesScalar);
      super({ scalar: feet, unit, inchesScalar: inches }, [feet, unit, inches]);
    } else {
      if (inchesScalar !== undefined) {
        throw new TypeError("Length: inchesScalar argument must be undefined when unit isn't 'ftin'");
      }
      super({ scalar, unit, inchesScalar }, [scalar, unit]);
    }
  }

  protected toQty(): Qty {
    const { scalar, unit, inchesScalar } = this.props;
    if (unit === 'ftin') {
      // Convert Length objects in 'ftin' unit to inches
      return new Qty(scalar * 12 + inchesScalar, 'in');
    }
    return super.toQty();
  }

  /** Return a `Length` object with the scalar rounded to one of the closest multiples of `step`.
   *
   * - If `step` is negative, a {@link RangeError} exception is thrown.
   * - If `step` is zero, the current object is returned.
   * - If `step` is positive, a new `Length` object is returned with the scalar rounded to one of the closest
   *   multiples of `step` according to the selected rounding mode (see below).
   *
   * The rounding mode can be altered by passing either `'round'`, `'trunc`', `'floor'` or `'ceil'` as the second
   * argument. The default is `'round'`.
   *
   * When `unit` is `'ftin'`, the step is applied to the length converted to inches then converted back to `'ftin'`. */
  toStep(step: number, mode: 'round' | 'trunc' | 'floor' | 'ceil' = 'round'): this {
    if (this.unit === 'ftin') {
      return this.toUnit('in').toStep(step, mode).toUnit('ftin');
    }
    return super.toStep(step, mode);
  }

  toUnit(unit: LengthUnit): this {
    if (unit === 'ftin' && unit !== this.unit) {
      // Assume any Length subclass constructor has the same signature as Length's constructor.
      // If it doesn't, the subclass will have to override toUnit() to correctly invoke the constructor.
      // The Length class isn't expected to be extended, so this shouldn't be necessary.
      return new (this.constructor as typeof Length)(0, 'ftin', this.toQty().to('in').scalar) as this;
    }
    return super.toUnit(unit);
  }

  protected getMinMax(minMax: DimensionMinMax<this>, unit: this['unit']): this | null {
    if (minMax == null) return null;
    // Assume any Length subclass constructor has the same signature as Length's constructor.
    // If it doesn't, the subclass will have to override getMinMax() to correctly invoke the constructor.
    // The Length class isn't expected to be extended, so this shouldn't be necessary.
    const constructor = this.constructor as typeof Length;
    if (minMax instanceof constructor) return minMax;
    return new constructor(
      typeof minMax === 'number' ? minMax : (minMax as Record<this['unit'], number>)[unit],
      unit,
      unit === 'ftin' ? 0 : undefined,
    ) as this;
  }

  normalize(min: DimensionMinMax<this>, max: DimensionMinMax<this>, step: number | undefined): this {
    return super.normalize(min, max, this.unit === 'ftin' ? 1 : step); // In 'ftin' mode the backend can only parse integers, so force step to 1
  }

  format(t: TFunction<Namespace>): string {
    const { formattedString, formatter } = this.baseFormat(t, 'length');
    const { scalar, unit, inchesScalar } = this.props;
    if (unit === 'ftin') {
      // https://unicode-explorer.com/c/2032 https://unicode-explorer.com/c/00A0 https://unicode-explorer.com/c/2033
      return `${formatter.format(scalar)}\u2032\u00a0${formatter.format(inchesScalar)}\u2033`;
    }
    return formattedString;
  }

  toJSON(): string {
    const { scalar, unit, inchesScalar } = this.props;
    if (unit === 'ftin') {
      return `${apiNumberFormatter.format(scalar)} ft ${apiNumberFormatter.format(inchesScalar)} in`;
    }
    return super.toJSON();
  }
}
