/**
 * @generated SignedSource<<a502f652c42a50605aea2b89d7b5e22c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NatureOfWorkDetails_Query$variables = {
  id: string;
};
export type NatureOfWorkDetails_Query$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"NatureOfWorkDetails_FormFragment">;
  } | null | undefined;
};
export type NatureOfWorkDetails_Query = {
  response: NatureOfWorkDetails_Query$data;
  variables: NatureOfWorkDetails_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NatureOfWorkDetails_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "NatureOfWorkDetails_FormFragment"
              }
            ],
            "type": "NatureOfWorkLookup",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NatureOfWorkDetails_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "workSchedules",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "defaultWorkSchedule",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "natureOfWorkSubCategories",
                "storageKey": null
              }
            ],
            "type": "NatureOfWorkLookup",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a99782d0e46cee3a9292e84273057280",
    "id": null,
    "metadata": {},
    "name": "NatureOfWorkDetails_Query",
    "operationKind": "query",
    "text": "query NatureOfWorkDetails_Query(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on NatureOfWorkLookup {\n      ...NatureOfWorkDetails_FormFragment\n    }\n    id\n  }\n}\n\nfragment NatureOfWorkDetails_FormFragment on NatureOfWorkLookup {\n  id\n  code\n  description\n  ...NatureOfWorkFields_WorkSchedulesFragment\n  ...NatureOfWorkFields_DefaultWorkScheduleFragment\n  ...NatureOfWorkFields_CategoriesFragment\n  ...NatureOfWorkDetails_useSetWorkSchedulesFragment\n}\n\nfragment NatureOfWorkDetails_useSetWorkSchedulesFragment on NatureOfWorkLookup {\n  ...NatureOfWorkFields_WorkSchedulesFragment\n  ...NatureOfWorkFields_DefaultWorkScheduleFragment\n}\n\nfragment NatureOfWorkFields_CategoriesFragment on NatureOfWorkLookup {\n  natureOfWorkSubCategories\n}\n\nfragment NatureOfWorkFields_DefaultWorkScheduleFragment on NatureOfWorkLookup {\n  defaultWorkSchedule\n}\n\nfragment NatureOfWorkFields_WorkSchedulesFragment on NatureOfWorkLookup {\n  workSchedules\n}\n"
  }
};
})();

(node as any).hash = "55b2720bf214b0dfcd724b033f685b32";

export default node;
