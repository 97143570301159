import { Suspense, useLayoutEffect } from 'react';
import { createSearchParams, useMatch, useNavigate } from 'react-router';
import { Skeleton, Stack, Theme, useMediaQuery } from '@mui/material';
import { namedPageTitle, usePageTitle } from '../common/hooks/usePageTitle';
import graphql from 'babel-plugin-relay/macro';
import { DataID, useLazyLoadQuery } from 'react-relay';
import { EmptyLayout, ListLayout, ListLayoutActions, SidebarContentProps } from '../layout/Layouts';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { RequireRead, RequireWrite, UnauthorizedFallback } from '../auth/Authorization';
import { NavigationMenu } from '../layout/SidebarDrawer';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { QuoteKind } from '../__enums__/QuoteKind';
import { ListPageErrorBoundary } from '../layout/ListPageErrorBoundary';
import { ListPageRootErrorBoundary } from '../layout/ListPageRootErrorBoundary';
import { EmployeeNotFoundError, EmployeeNotFoundErrorBoundary } from '../auth/EmployeeNotFoundErrorBoundary';
import { useJobFiltersSearchParams } from '../jobs/JobFilters';
import {
  Assignment,
  AssignmentLate,
  CalendarToday,
  EngineeringOutlined,
  HdrAuto,
  Person,
  PersonOutline,
  Schedule,
  Warehouse,
} from '@mui/icons-material';
import { createSharedStateKey } from '../common/utils/sharedState';
import { QuoteFilters_Regular } from './QuoteFilters';
import { QuoteList_Regular } from './QuoteList.Regular';
import { QuoteList_Page_RootQuery } from './__generated__/QuoteList_Page_RootQuery.graphql';
import { useShowRepresentativeList } from '../jobs/useShowRepresentativeList';
import { QuoteList_Representative } from './QuoteList.Representative';

const flagName = 'app_navigation_quote';
export function QuoteList_Page() {
  const { t } = useAmbientTranslation();

  return (
    <ListPageErrorBoundary heading={t('quotes')} flagName={flagName}>
      <EmployeeNotFoundErrorBoundary>
        <Suspense fallback={<ListPageSkeleton />}>
          <ListPageRoot />
        </Suspense>
      </EmployeeNotFoundErrorBoundary>
    </ListPageErrorBoundary>
  );
}

function ListPageSkeleton() {
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return <EmptyLayout>{compact ? <ListSkeleton /> : <GridSkeleton />}</EmptyLayout>;
}

function ListPageRoot() {
  const $data = useLazyLoadQuery<QuoteList_Page_RootQuery>(
    graphql`
      query QuoteList_Page_RootQuery {
        ...AuthorizationReadFragment
        ...AuthorizationWriteFragment
        ...SidebarDrawerFragment
        ...LayoutsListLayoutFragment
        ...ListPageRootErrorBoundaryFragment
        me {
          ...useShowRepresentativeListFragment
          ...QuoteList_RepresentativeFragment
          ...QuoteList_RegularFragment
        }
      }
    `,
    {},
  );

  if (!$data.me) throw new EmployeeNotFoundError();

  usePageTitle(namedPageTitle('quotes'));
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { t } = useAmbientTranslation();
  const navigate = useNavigate();
  const handleItemCreate = (kind: QuoteKind) =>
    navigate({
      pathname: `/quotes/new`,
      search: createSearchParams({ kind }).toString(),
    });
  const sidebar = (props: SidebarContentProps) => <NavigationMenu {...props} $key={$data} />;
  const handleItemClick = (id: DataID) => navigate(`/quotes/${id}`);
  const heading = t('quotes');
  const showRepresentativeList = useShowRepresentativeList($data.me);
  const searchParams = useJobFiltersSearchParams(quoteFiltersSharedStateKey);
  const routeMatch = useMatch('*');

  useLayoutEffect(() => {
    if (!routeMatch?.pathname) return;

    const basePath = '/quotes';

    if (showRepresentativeList && routeMatch.pathname === basePath) {
      navigate(`${basePath}/my-jobs${searchParams}`);
      return;
    }
    if (
      !showRepresentativeList &&
      (routeMatch.pathname === `${basePath}/my-jobs` ||
        routeMatch.pathname === `${basePath}/my-regions` ||
        routeMatch.pathname === `${basePath}/my-clients`)
    ) {
      navigate(basePath);
    }
  }, [navigate, routeMatch?.pathname, searchParams, showRepresentativeList]);

  return (
    <ListPageRootErrorBoundary $key={$data} heading={heading} flagName={flagName}>
      <RequireRead
        $key={$data}
        fallback={
          <ListLayout heading={heading} flagName={flagName} sidebarProvider={sidebar} $key={$data}>
            <UnauthorizedFallback />
          </ListLayout>
        }>
        <ListLayout
          heading={heading}
          flagName={flagName}
          sidebarProvider={sidebar}
          $key={$data}
          actions={
            <RequireWrite $key={$data}>
              <ListLayoutActions
                mainAction={{
                  label: t('kind.operatedHourly'),
                  icon: <Schedule />,
                  onClick: () => handleItemCreate('operatedHourly'),
                }}
                otherActions={{
                  operatedMonthly: {
                    label: t('kind.operatedMonthly'),
                    icon: <CalendarToday />,
                    onClick: () => handleItemCreate('operatedMonthly'),
                  },
                  bare: {
                    label: t('kind.bare'),
                    icon: <PersonOutline />,
                    onClick: () => handleItemCreate('bare'),
                  },
                  bareWithOperators: {
                    label: compact ? t('kindShort.bareWithOperators') : t('kind.bareWithOperators'),
                    icon: <EngineeringOutlined />,
                    onClick: () => handleItemCreate('bareWithOperators'),
                  },
                  accessoriesRental: {
                    label: t('kind.accessoriesRental'),
                    icon: <HdrAuto />,
                    onClick: () => handleItemCreate('accessoriesRental'),
                  },
                  laborRental: {
                    label: t('kind.laborRental'),
                    icon: <Person />,
                    onClick: () => handleItemCreate('laborRental'),
                  },
                  rollingEquipment: {
                    label: t('kind.rollingEquipment'),
                    icon: <LocalShippingIcon />,
                    onClick: () => handleItemCreate('rollingEquipment'),
                  },
                  liftingPlan: {
                    label: t('kind.liftingPlan'),
                    icon: <Assignment />,
                    onClick: () => handleItemCreate('liftingPlan'),
                  },
                  liftingTest: {
                    label: t('kind.liftingTest'),
                    icon: <AssignmentLate />,
                    onClick: () => handleItemCreate('liftingTest'),
                  },
                  storage: {
                    label: t('kind.storage'),
                    icon: <Warehouse />,
                    onClick: () => handleItemCreate('storage'),
                  },
                }}
                componentProps={{
                  autoSplitButton: {
                    root: { 'aria-label': t('ariaLabels.root') },
                    buttonMain: { 'aria-label': t('ariaLabels.main') },
                    buttonMenu: { 'aria-label': t('ariaLabels.more') },
                    menuItemOption: ([, a]) => ({ 'aria-label': a.label }),
                  },
                  autoSpeedDial: {
                    root: {
                      'aria-label': 'ariaLabels.more',
                      ariaLabel: t('ariaLabels.more'),
                    },
                    speedDialActionOption: ([, a]) => ({ 'aria-label': a.label }),
                    dialog: {
                      title: t('selectKind', { ns: 'jobs' }),
                    },
                  },
                }}
              />
            </RequireWrite>
          }>
          <Suspense fallback={compact ? <ListSkeleton /> : <GridSkeleton />}>
            {showRepresentativeList ? (
              <QuoteList_Representative
                $key={$data.me}
                write$key={$data}
                compact={compact}
                paginationSx={(theme) => ({ [theme.breakpoints.down('sm')]: { mb: '6rem' } })}
                onItemClick={handleItemClick}
              />
            ) : (
              <QuoteList_Regular
                $key={$data.me}
                write$key={$data}
                compact={compact}
                paginationSx={(theme) => ({ [theme.breakpoints.down('sm')]: { mb: '6rem' } })}
                onItemClick={handleItemClick}
              />
            )}
          </Suspense>
        </ListLayout>
      </RequireRead>
    </ListPageRootErrorBoundary>
  );
}

export const quoteFiltersSharedStateKey = createSharedStateKey<QuoteFilters_Regular | null>(() => null);

export function useQuoteListPageUrl() {
  const searchParams = useJobFiltersSearchParams(quoteFiltersSharedStateKey);
  return `/quotes${searchParams}`;
}

function GridSkeleton() {
  return (
    <Stack gap='1rem'>
      <Skeleton variant='rounded' height='3rem' />
      <Skeleton variant='rounded' height='40rem' />
    </Stack>
  );
}

function ListSkeleton() {
  return (
    <Stack gap='1rem'>
      <Skeleton variant='rounded' height='3rem' />
      <Stack gap={0.5}>
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
        <Skeleton variant='rounded' height='7rem' />
      </Stack>
    </Stack>
  );
}
