/**
 * @generated
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck


export const natureOfWorkSubCategoryEnums = ["accessory", "airports", "antennas", "aqueductsSewer", "blade", "bladeChange", "bridges", "cages", "chambers", "dams", "demolition", "distribution", "docks", "excavation", "gearboxes", "generator", "gondola", "hand", "handling", "landscaping", "lifting", "lines", "modules", "newConstruction", "nonOperated", "powerPlants", "pruning", "pylons", "refurbishment", "retentionBasins", "roadsRails", "rotor", "shelters", "substations", "towers", "transformers", "transport", "valves", "ventilationPlumbingElectrical"] as const;
export type NatureOfWorkSubCategoryEnum = typeof natureOfWorkSubCategoryEnums[number];

export function isNatureOfWorkSubCategoryEnum(value: string): value is NatureOfWorkSubCategoryEnum {
  return natureOfWorkSubCategoryEnums.includes(value)
}

export function asNatureOfWorkSubCategoryEnum(value: string): NatureOfWorkSubCategoryEnum | null {
  return isNatureOfWorkSubCategoryEnum(value) ? value : null;
}

export function castNatureOfWorkSubCategoryEnum(value: string): NatureOfWorkSubCategoryEnum {
  if(!isNatureOfWorkSubCategoryEnum(value)) {
    throw new Error(`Invalid Enum value for type "NatureOfWorkSubCategoryEnum": "${value}"`);
  }
  
  return value;
}
