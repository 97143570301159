import { readFragment } from 'relay-runtime/lib/store/ResolverFragments';
import graphql from 'babel-plugin-relay/macro';
import { SubRegionLabelResolver$key } from './__generated__/SubRegionLabelResolver.graphql';

/**
 * @RelayResolver SubRegion.label: Localized
 * @rootFragment SubRegionLabelResolver
 *
 * A label for SubRegion which includes description.
 * Used dynamically by Autocomplete component to resolve the option label
 */
export function label(subRegionLabel$key: SubRegionLabelResolver$key): Record<string, string> {
  const subRegion$data = readFragment(
    graphql`
      fragment SubRegionLabelResolver on SubRegion {
        description
      }
    `,
    subRegionLabel$key,
  );

  return subRegion$data.description;
}
