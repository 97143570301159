/**
 * @generated SignedSource<<07f13917f244d956bad7919f468dbce0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BillingCodeDetails_FormFragment$data = {
  readonly code: number;
  readonly description: Record<string, string>;
  readonly id: string;
  readonly subCode: number;
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodeFields_LabelEnFragment" | "BillingCodeFields_LabelFrFragment">;
  readonly " $fragmentType": "BillingCodeDetails_FormFragment";
};
export type BillingCodeDetails_FormFragment$key = {
  readonly " $data"?: BillingCodeDetails_FormFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodeDetails_FormFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillingCodeDetails_FormFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingCodeFields_LabelFrFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingCodeFields_LabelEnFragment"
    }
  ],
  "type": "BillingCode",
  "abstractKey": null
};

(node as any).hash = "c66952ffa1f5b7dd67c08ecd7888ddc9";

export default node;
