/**
 * @generated SignedSource<<878390c5f1ca8adce1afd7621ac9be98>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaleProjectFields_ReadyDateFragment$data = {
  readonly project: {
    readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_ReadyDateReadFragment">;
  };
  readonly transferredAt?: string | null | undefined;
  readonly " $fragmentType": "SaleProjectFields_ReadyDateFragment";
};
export type SaleProjectFields_ReadyDateFragment$key = {
  readonly " $data"?: SaleProjectFields_ReadyDateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_ReadyDateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isCopy"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleProjectFields_ReadyDateFragment",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "condition": "isCopy",
          "kind": "Condition",
          "passingValue": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "transferredAt",
              "storageKey": null
            }
          ]
        }
      ],
      "type": "ServiceCall",
      "abstractKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "isCopy",
              "variableName": "isCopy"
            }
          ],
          "kind": "FragmentSpread",
          "name": "SaleProjectFields_ReadyDateReadFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ISale",
  "abstractKey": "__isISale"
};

(node as any).hash = "65e6289beb8bd925e2219560630a4627";

export default node;
