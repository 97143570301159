import { readFragment } from 'relay-runtime/lib/store/ResolverFragments';
import graphql from 'babel-plugin-relay/macro';
import { AddendumTemplateLabelResolver$key } from './__generated__/AddendumTemplateLabelResolver.graphql';

/**
 * @RelayResolver AddendumTemplate.label: Localized
 * @rootFragment AddendumTemplateLabelResolver
 *
 * An object that contains the label for every language for AddendumTemplate which includes title.
 * Used dynamically by Autocomplete component to resolve the option label
 */
export function label(addendumTemplate$key: AddendumTemplateLabelResolver$key): Record<string, string> {
  const addendumTemplate$data = readFragment(
    graphql`
      fragment AddendumTemplateLabelResolver on AddendumTemplate {
        title
      }
    `,
    addendumTemplate$key,
  );
  return addendumTemplate$data.title;
}
