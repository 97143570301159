import { castCraneSelectorMode, CraneSelectorMode } from '../../__enums__/CraneSelectorMode';
import {
  createFieldKey,
  FieldKey,
  SetValueFn,
  useField,
  useFieldErrorsFirstMessage,
  useFieldHasErrors,
  useFieldIsDirty,
  useFieldMapper,
  useFieldSetter,
  useFieldValidation,
  useFieldValue,
} from '../../common/utils/forms';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { jobStageBaseFormContext } from '../JobStageBaseFields';
import { FormControlLabel, Radio, SxProps, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { LengthInput } from '../../common/components/LengthInput';
import { Length, LengthUnit } from '../../common/utils/dimensions/length';
import { AngleInput } from '../../common/components/AngleInput';
import { Angle } from '../../common/utils/dimensions/angle';
import { MassInput } from '../../common/components/MassInput';
import { Mass } from '../../common/utils/dimensions/mass';
import { EquipmentKindAutocomplete, ForwardEquipmentKindAutocompleteProps } from '../../common/components/EquipmentKindAutocomplete';
import { CraneVehicleIdAutocomplete, ForwardCraneVehicleIdAutocompleteProps } from '../../common/components/CraneVehicleIdAutocomplete';
import { CraneCapacityAutocomplete, ForwardCraneCapacityAutocompleteProps } from '../../common/components/CraneCapacityAutocomplete';
import {
  CraneConfigurationKindAutocomplete,
  ForwardCraneConfigurationKindAutocompleteProps,
} from '../../common/components/CraneConfigurationKindAutocomplete';
import { CraneConfiguration_ConfigurationListItemFragment$key } from '../__generated__/CraneConfiguration_ConfigurationListItemFragment.graphql';
import { CraneConfiguration_ConfigurationListItemSummaryFragment$key } from '../__generated__/CraneConfiguration_ConfigurationListItemSummaryFragment.graphql';
import { CraneConfiguration_SelectedConfigurationCard_AutomaticInformationFragment$key } from '../__generated__/CraneConfiguration_SelectedConfigurationCard_AutomaticInformationFragment.graphql';
import { SaleCraneSelectorFields_CraneConfigurationsFragment$key } from './__generated__/SaleCraneSelectorFields_CraneConfigurationsFragment.graphql';
import { SaleCraneSelectorFields_Manual_BoomLengthFragment$key } from './__generated__/SaleCraneSelectorFields_Manual_BoomLengthFragment.graphql';
import { SaleCraneSelectorFields_Manual_JibLengthFragment$key } from './__generated__/SaleCraneSelectorFields_Manual_JibLengthFragment.graphql';
import { SaleCraneSelectorFields_Manual_OffsetAngleFragment$key } from './__generated__/SaleCraneSelectorFields_Manual_OffsetAngleFragment.graphql';
import { SaleCraneSelectorFields_Manual_RadiusFragment$key } from './__generated__/SaleCraneSelectorFields_Manual_RadiusFragment.graphql';
import { SaleCraneSelectorFields_Manual_MaxWeightFragment$key } from './__generated__/SaleCraneSelectorFields_Manual_MaxWeightFragment.graphql';
import { SaleCraneSelectorFields_Manual_CounterweightFragment$key } from './__generated__/SaleCraneSelectorFields_Manual_CounterweightFragment.graphql';
import { SaleCraneSelectorFields_EquipmentKindFragment$key } from './__generated__/SaleCraneSelectorFields_EquipmentKindFragment.graphql';
import { SaleCraneSelectorFields_CraneCapacityFragment$key } from './__generated__/SaleCraneSelectorFields_CraneCapacityFragment.graphql';
import { SaleCraneSelectorFields_CraneConfigurationKindFragment$key } from './__generated__/SaleCraneSelectorFields_CraneConfigurationKindFragment.graphql';
import { SaleCraneSelectorFields_FavoriteConfigurationFragment$key } from './__generated__/SaleCraneSelectorFields_FavoriteConfigurationFragment.graphql';
import { SaleCraneSelectorFields_CraneSelectorModeFragment$key } from './__generated__/SaleCraneSelectorFields_CraneSelectorModeFragment.graphql';
import {
  BoomConfigurationAutocomplete,
  ForwardBoomConfigurationAutocompleteProps,
} from '../../common/components/BoomConfigurationAutocomplete';
import { SaleCraneSelectorFields_Manual_BoomConfigurationFragment$key } from './__generated__/SaleCraneSelectorFields_Manual_BoomConfigurationFragment.graphql';
import { JobEquipment_AutomaticFavoriteFragment$key } from '../__generated__/JobEquipment_AutomaticFavoriteFragment.graphql';
import { SaleCraneSelectorFields_CraneConfigurationCollection_FavoriteFragment$key } from './__generated__/SaleCraneSelectorFields_CraneConfigurationCollection_FavoriteFragment.graphql';
import { JobEquipment_useCraneSelectorAutomaticFavorite_BoomConfigurationFragment$key } from '../__generated__/JobEquipment_useCraneSelectorAutomaticFavorite_BoomConfigurationFragment.graphql';
import { SaleCraneSelectorFields_CraneConfigurationSnapshotFragment$key } from './__generated__/SaleCraneSelectorFields_CraneConfigurationSnapshotFragment.graphql';
import { SaleCraneSelectorFields_VehicleIdsFragment$key } from './__generated__/SaleCraneSelectorFields_VehicleIdsFragment.graphql';
import { JobStage } from '../jobStage';
import { RequiredForInputLabel, RequiredForStatus } from '../RequiredForJobStatus';

/**
 * TODO: Rename field to JobCraneSelectorFields...
 */
const fieldCraneSelectorModeKey = createFieldKey<CraneSelectorMode>();

export function useFieldCraneSelectorModeRead($key: SaleCraneSelectorFields_CraneSelectorModeFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment SaleCraneSelectorFields_CraneSelectorModeFragment on CraneSelectorInternal {
        mode
      }
    `,
    $key,
  );
  const craneSelectorMode = useFieldValue(jobStageBaseFormContext, fieldCraneSelectorModeKey, () =>
    $data?.mode ? castCraneSelectorMode($data.mode) : 'lifts',
  );
  const craneSelectorModeIsDirty = useFieldIsDirty(jobStageBaseFormContext, fieldCraneSelectorModeKey);

  const mapper = useFieldMapper(jobStageBaseFormContext, fieldCraneSelectorModeKey);
  mapper((v) => ({ equipmentBase: { craneSelector: { mode: v } } }), [], 'save');

  return { craneSelectorMode, craneSelectorModeIsDirty };
}
export function useFieldCraneSelectorMode(
  $key: SaleCraneSelectorFields_CraneSelectorModeFragment$key | null | undefined,
  disabled: boolean,
) {
  const { craneSelectorMode, ...rest } = useFieldCraneSelectorModeRead($key);
  const setCraneSelectorMode = useFieldSetter(jobStageBaseFormContext, fieldCraneSelectorModeKey);

  const renderCraneSelectorMode = useCallback(
    () => (
      <>
        <SaleCraneSelectorModeInput kind='lifts' value={craneSelectorMode} setValue={setCraneSelectorMode} disabled={disabled} />
        <SaleCraneSelectorModeInput kind='manual' value={craneSelectorMode} setValue={setCraneSelectorMode} disabled={disabled} />
      </>
    ),
    [craneSelectorMode, disabled, setCraneSelectorMode],
  );

  return {
    craneSelectorMode,
    setCraneSelectorMode,
    renderCraneSelectorMode,
    ...rest,
  };
}

function SaleCraneSelectorModeInput({
  kind,
  value,
  setValue,
  disabled,
}: {
  kind: CraneSelectorMode;
  value: CraneSelectorMode;
  setValue: SetValueFn<CraneSelectorMode>;
  disabled: boolean;
}) {
  const theme = useTheme();
  const { t } = useTranslation('craneSelector');

  const baseButtonSx = useMemo(
    (): SxProps => ({
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '0.25rem',
      width: '100%',
      ml: 0,
      mr: 0,
      backgroundColor: 'transparent',
      ':not(.Mui-disabled):hover': {
        border: '1px solid black',
      },
    }),
    [theme.palette.divider],
  );

  const isCheckedButtonSx = useMemo(
    (): SxProps => ({
      ...baseButtonSx,
      ':not(.Mui-disabled)': {
        border: '1px solid black',
      },
      backgroundColor: theme.palette.background.default,
    }),
    [baseButtonSx, theme.palette.background.default],
  );

  return (
    <FormControlLabel
      control={<Radio onChange={(e) => setValue(castCraneSelectorMode(e.target.value))} value={kind} checked={value === kind} />}
      label={t(`field.mode.${kind}`)}
      sx={value === kind ? isCheckedButtonSx : baseButtonSx}
      disabled={disabled}
      data-label-key={`field.mode.${kind}`}
    />
  );
}

type FieldBoomConfiguration = NonNullable<ForwardBoomConfigurationAutocompleteProps['value']> | null;
const fieldBoomConfigurationKey = createFieldKey<FieldBoomConfiguration>();

export function useFieldManualBoomConfigurationRead($key: SaleCraneSelectorFields_Manual_BoomConfigurationFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment SaleCraneSelectorFields_Manual_BoomConfigurationFragment on ManualConfigurationInfo {
        boomConfiguration {
          id
          label
          preparationHours
        }
      }
    `,
    $key,
  );
  const boomConfiguration = useFieldValue(jobStageBaseFormContext, fieldBoomConfigurationKey, () => $data?.boomConfiguration ?? null);
  const boomConfigurationIsDirty = useFieldIsDirty(jobStageBaseFormContext, fieldBoomConfigurationKey);

  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldBoomConfigurationKey);
  useMapper(
    (v) => ({ equipmentBase: { craneSelector: { manualConfiguration: { configurationInfo: { boomConfigurationId: v?.id ?? null } } } } }),
    [],
    'save',
  );

  return { boomConfiguration, boomConfigurationIsDirty };
}
export function useFieldManualBoomConfiguration(
  $key: SaleCraneSelectorFields_Manual_BoomConfigurationFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const { boomConfiguration, ...rest } = useFieldManualBoomConfigurationRead($key);
  const setBoomConfiguration = useFieldSetter(jobStageBaseFormContext, fieldBoomConfigurationKey);

  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldBoomConfigurationKey);

  const renderBoomConfiguration = useCallback(
    (capacity: number, configurationKindCode: number, equipmentKindCode: number) => (
      <BoomConfigurationInput
        value={boomConfiguration}
        setValue={setBoomConfiguration}
        capacity={capacity}
        configurationKindCode={configurationKindCode}
        equipmentKindCode={equipmentKindCode}
        disabled={disabled}
        required={required}
      />
    ),
    [boomConfiguration, setBoomConfiguration, disabled, required],
  );

  return {
    boomConfiguration,
    setBoomConfiguration,
    renderBoomConfiguration,
    useValidationBoomConfiguration: useValidation,
    ...rest,
  };
}

function BoomConfigurationInput({
  value,
  setValue,
  capacity,
  configurationKindCode,
  equipmentKindCode,
  disabled,
  required,
}: {
  value: FieldBoomConfiguration;
  setValue: SetValueFn<FieldBoomConfiguration>;
  capacity: number;
  configurationKindCode: number;
  equipmentKindCode: number;
  disabled: boolean;
  required: boolean;
}) {
  const { t } = useTranslation('craneSelector');
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldBoomConfigurationKey);

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = required
    ? {
        quote: ['submit'],
        serviceCall: ['reserve', 'transfer'],
      }
    : {};

  return (
    <BoomConfigurationAutocomplete
      capacity={capacity}
      configurationKindCode={configurationKindCode}
      equipmentKindCode={equipmentKindCode}
      value={value}
      onChange={(v) => setValue(v)}
      disabled={disabled}
      textFieldProps={(params) => ({
        label: <RequiredForInputLabel label={t('field.equipment.boomConfiguration')} requiredFor={requiredFor} disabled={disabled} />,
        'data-label-key': 'field.equipment.boomConfiguration',
        error: hasErrors && !disabled,
        InputProps: {
          ...params.InputProps,
          startAdornment: <>{params.InputProps.startAdornment}</>,
        },
      })}
    />
  );
}

const fieldBoomLengthKey = createFieldKey<Length | null>();
export function useFieldBoomLengthRead($key: SaleCraneSelectorFields_Manual_BoomLengthFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment SaleCraneSelectorFields_Manual_BoomLengthFragment on ManualConfigurationInfo {
        boomLength
      }
    `,
    $key,
  );

  const boomLength = useFieldValue(jobStageBaseFormContext, fieldBoomLengthKey, () => Length.parse($data?.boomLength));
  const boomLengthIsDirty = useFieldIsDirty(jobStageBaseFormContext, fieldBoomLengthKey);

  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldBoomLengthKey);
  useMapper(
    (v) => ({ equipmentBase: { craneSelector: { manualConfiguration: { configurationInfo: { boomLength: v?.toJSON() ?? null } } } } }),
    [],
    'save',
  );

  return { boomLength, boomLengthIsDirty };
}
export function useFieldBoomLength(
  $key: SaleCraneSelectorFields_Manual_BoomLengthFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const { boomLength, ...rest } = useFieldBoomLengthRead($key);
  const setBoomLength = useFieldSetter(jobStageBaseFormContext, fieldBoomLengthKey);
  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldBoomLengthKey);
  const renderBoomLength = useCallback(
    () => (
      <CraneSelectorLengthInput
        labelKey='field.equipment.boom'
        value={boomLength}
        setValue={setBoomLength}
        disabled={disabled}
        requiredFor={
          required
            ? {
                quote: ['submit'],
                serviceCall: ['transfer'],
              }
            : {}
        }
        fieldKey={fieldBoomLengthKey}
      />
    ),
    [boomLength, disabled, required, setBoomLength],
  );

  return { boomLength, setBoomLength, renderBoomLength, useValidationBoomLength: useValidation, ...rest };
}

const fieldJibLengthKey = createFieldKey<Length | null>();
export function useFieldJibLengthRead($key: SaleCraneSelectorFields_Manual_JibLengthFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment SaleCraneSelectorFields_Manual_JibLengthFragment on ManualConfigurationInfo {
        jibLength
      }
    `,
    $key,
  );

  const jibLength = useFieldValue(jobStageBaseFormContext, fieldJibLengthKey, () => Length.parse($data?.jibLength));
  const jibLengthIsDirty = useFieldIsDirty(jobStageBaseFormContext, fieldJibLengthKey);

  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldJibLengthKey);
  useMapper(
    (v) => ({ equipmentBase: { craneSelector: { manualConfiguration: { configurationInfo: { jibLength: v?.toJSON() ?? null } } } } }),
    [],
    'save',
  );

  return { jibLength, jibLengthIsDirty };
}
export function useFieldJibLength(
  $key: SaleCraneSelectorFields_Manual_JibLengthFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const { jibLength, ...rest } = useFieldJibLengthRead($key);
  const setJibLength = useFieldSetter(jobStageBaseFormContext, fieldJibLengthKey);
  const renderJibLength = useCallback(
    () => (
      <CraneSelectorLengthInput
        labelKey='field.equipment.jib'
        value={jibLength}
        setValue={setJibLength}
        disabled={disabled}
        requiredFor={
          required
            ? {
                quote: ['submit'],
                serviceCall: ['transfer'],
              }
            : {}
        }
        fieldKey={fieldJibLengthKey}
      />
    ),
    [jibLength, setJibLength, disabled, required],
  );

  return { jibLength, setJibLength, renderJibLength, ...rest };
}

const fieldOffsetAngleKey = createFieldKey<Angle | null>();
export function useFieldOffsetAngleRead($key: SaleCraneSelectorFields_Manual_OffsetAngleFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment SaleCraneSelectorFields_Manual_OffsetAngleFragment on ManualConfigurationInfo {
        offsetAngle
      }
    `,
    $key,
  );

  const offsetAngle = useFieldValue(jobStageBaseFormContext, fieldOffsetAngleKey, () => Angle.parse($data?.offsetAngle));
  const offsetAngleIsDirty = useFieldIsDirty(jobStageBaseFormContext, fieldOffsetAngleKey);

  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldOffsetAngleKey);
  useMapper(
    (v) => ({ equipmentBase: { craneSelector: { manualConfiguration: { configurationInfo: { offsetAngle: v?.toJSON() ?? null } } } } }),
    [],
    'save',
  );

  return { offsetAngle, offsetAngleIsDirty };
}
export function useFieldOffsetAngle($key: SaleCraneSelectorFields_Manual_OffsetAngleFragment$key | null | undefined, disabled: boolean) {
  const { offsetAngle, ...rest } = useFieldOffsetAngleRead($key);

  const setOffsetAngle = useFieldSetter(jobStageBaseFormContext, fieldOffsetAngleKey);
  const renderOffsetAngle = useCallback(
    () => <CraneSelectorAngleInput labelKey='field.equipment.offset' value={offsetAngle} setValue={setOffsetAngle} disabled={disabled} />,
    [offsetAngle, setOffsetAngle, disabled],
  );

  return { offsetAngle, setOffsetAngle, renderOffsetAngle, ...rest };
}
function CraneSelectorAngleInput({
  labelKey,
  value,
  setValue,
  disabled,
}: {
  labelKey: string;
  value: Angle | null;
  setValue: SetValueFn<Angle | null>;
  disabled: boolean;
}) {
  const { t } = useTranslation('craneSelector');
  return (
    <AngleInput
      value={value}
      min={0}
      max={180}
      step={1}
      onChange={(val) => setValue(val)}
      label={t(labelKey)}
      data-label-key={labelKey}
      disabled={disabled}
    />
  );
}

const fieldRadiusKey = createFieldKey<Length | null>();
export function useFieldRadiusRead($key: SaleCraneSelectorFields_Manual_RadiusFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment SaleCraneSelectorFields_Manual_RadiusFragment on ManualConfigurationInfo {
        radius
      }
    `,
    $key,
  );

  const radius = useFieldValue(jobStageBaseFormContext, fieldRadiusKey, () => Length.parse($data?.radius));
  const radiusIsDirty = useFieldIsDirty(jobStageBaseFormContext, fieldRadiusKey);

  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldRadiusKey);
  useMapper(
    (v) => ({ equipmentBase: { craneSelector: { manualConfiguration: { configurationInfo: { radius: v?.toJSON() ?? null } } } } }),
    [],
    'save',
  );

  return { radius, radiusIsDirty };
}
export function useFieldRadius(
  $key: SaleCraneSelectorFields_Manual_RadiusFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const { radius, ...rest } = useFieldRadiusRead($key);
  const setRadius = useFieldSetter(jobStageBaseFormContext, fieldRadiusKey);
  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldRadiusKey);
  const renderRadius = useCallback(
    () => (
      <CraneSelectorLengthInput
        labelKey='field.equipment.radius'
        value={radius}
        setValue={setRadius}
        disabled={disabled}
        requiredFor={
          required
            ? {
                quote: ['submit'],
                serviceCall: ['transfer'],
              }
            : {}
        }
        fieldKey={fieldRadiusKey}
      />
    ),
    [radius, setRadius, disabled, required],
  );

  return { radius, setRadius, renderRadius, useValidationRadius: useValidation, ...rest };
}

const fieldMaxWeightKey = createFieldKey<Mass | null>();
export function useFieldMaxWeightRead($key: SaleCraneSelectorFields_Manual_MaxWeightFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment SaleCraneSelectorFields_Manual_MaxWeightFragment on ManualConfigurationInfo {
        maxWeight
      }
    `,
    $key,
  );

  const maxWeight = useFieldValue(jobStageBaseFormContext, fieldMaxWeightKey, () => Mass.parse($data?.maxWeight));
  const maxWeightIsDirty = useFieldIsDirty(jobStageBaseFormContext, fieldMaxWeightKey);

  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldMaxWeightKey);
  useMapper(
    (v) => ({ equipmentBase: { craneSelector: { manualConfiguration: { configurationInfo: { maxWeight: v?.toJSON() ?? null } } } } }),
    [],
    'save',
  );

  return { maxWeight, maxWeightIsDirty };
}
export function useFieldMaxWeight(
  $key: SaleCraneSelectorFields_Manual_MaxWeightFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const { maxWeight, ...rest } = useFieldMaxWeightRead($key);
  const setMaxWeight = useFieldSetter(jobStageBaseFormContext, fieldMaxWeightKey);
  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldMaxWeightKey);
  const renderMaxWeight = useCallback(
    () => (
      <CraneSelectorMassInput
        labelKey='field.equipment.maxWeight'
        value={maxWeight}
        setValue={setMaxWeight}
        disabled={disabled}
        requiredFor={
          required
            ? {
                quote: ['submit'],
                serviceCall: ['transfer'],
              }
            : {}
        }
      />
    ),
    [maxWeight, setMaxWeight, disabled, required],
  );

  return { maxWeight, setMaxWeight, renderMaxWeight, useValidationMaxWeight: useValidation, ...rest };
}

const fieldCounterweightKey = createFieldKey<Mass | null>('counterweightKey');
export function useFieldCounterweightRead($key: SaleCraneSelectorFields_Manual_CounterweightFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment SaleCraneSelectorFields_Manual_CounterweightFragment on ManualConfigurationInfo {
        counterweight
      }
    `,
    $key,
  );

  const counterweight = useFieldValue(jobStageBaseFormContext, fieldCounterweightKey, () => Mass.parse($data?.counterweight));
  const counterweightIsDirty = useFieldIsDirty(jobStageBaseFormContext, fieldCounterweightKey);

  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldCounterweightKey);
  useMapper(
    (v) => ({ equipmentBase: { craneSelector: { manualConfiguration: { configurationInfo: { counterweight: v?.toJSON() ?? null } } } } }),
    [],
    'save',
  );

  return { counterweight, counterweightIsDirty };
}
export function useFieldCounterweight(
  $key: SaleCraneSelectorFields_Manual_CounterweightFragment$key | null | undefined,
  disabled: boolean,
) {
  const { counterweight, ...rest } = useFieldCounterweightRead($key);

  const setCounterweight = useFieldSetter(jobStageBaseFormContext, fieldCounterweightKey);
  const renderCounterweight = useCallback(
    () => (
      <CraneSelectorMassInput
        labelKey='field.equipment.counterWeight'
        value={counterweight}
        setValue={setCounterweight}
        disabled={disabled}
        requiredFor={{}}
      />
    ),
    [counterweight, disabled, setCounterweight],
  );

  return { counterweight, setCounterweight, renderCounterweight, ...rest };
}

type FieldEquipmentKind = NonNullable<ForwardEquipmentKindAutocompleteProps['value']> | null;
const fieldEquipmentKindKey = createFieldKey<FieldEquipmentKind>();
export function useFieldManualEquipmentKindRead($key: SaleCraneSelectorFields_EquipmentKindFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment SaleCraneSelectorFields_EquipmentKindFragment on ManualConfigurationInfo {
        equipmentKind {
          id
          code
          label
        }
      }
    `,
    $key,
  );
  const equipmentKind = useFieldValue(jobStageBaseFormContext, fieldEquipmentKindKey, () => $data?.equipmentKind ?? null);
  const equipmentKindIsDirty = useFieldIsDirty(jobStageBaseFormContext, fieldEquipmentKindKey);

  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldEquipmentKindKey);
  useMapper(
    (v) => ({
      equipmentBase: {
        craneSelector: { manualConfiguration: { configurationInfo: { equipmentKindCode: v?.code ?? null } } },
      },
    }),
    [],
    'save',
  );

  return { equipmentKind, equipmentKindIsDirty };
}
export function useFieldManualEquipmentKind(
  $key: SaleCraneSelectorFields_EquipmentKindFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const { equipmentKind, ...rest } = useFieldManualEquipmentKindRead($key);
  const setEquipmentKind = useFieldSetter(jobStageBaseFormContext, fieldEquipmentKindKey);

  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldEquipmentKindKey);

  const renderEquipmentKind = useCallback(
    (capacity: number | null, configurationKindCode: number | null, vehicleIds: FieldVehicleIds) => (
      <EquipmentKindInput
        value={equipmentKind}
        setValue={setEquipmentKind}
        capacity={capacity}
        configurationKindCode={configurationKindCode}
        vehicleIds={vehicleIds}
        disabled={disabled}
        required={required}
      />
    ),
    [equipmentKind, setEquipmentKind, disabled, required],
  );

  return { equipmentKind, setEquipmentKind, renderEquipmentKind, useValidationEquipmentKind: useValidation, ...rest };
}

function EquipmentKindInput({
  value,
  setValue,
  capacity,
  configurationKindCode,
  vehicleIds,
  disabled,
  required,
}: {
  value: FieldEquipmentKind;
  setValue: SetValueFn<FieldEquipmentKind>;
  capacity: number | null;
  configurationKindCode: number | null;
  vehicleIds: FieldVehicleIds;
  disabled: boolean;
  required: boolean;
}) {
  const { t } = useTranslation('craneSelector');
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldEquipmentKindKey);

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = required
    ? {
        quote: ['submit'],
        serviceCall: ['reserve', 'transfer'],
      }
    : {};

  return (
    <EquipmentKindAutocomplete
      capacity={capacity}
      configurationKindCode={configurationKindCode}
      vehicleIds={vehicleIds.map((v) => v.key)}
      value={value}
      onChange={(v) => setValue(v)}
      disabled={disabled}
      textFieldProps={(params) => ({
        label: <RequiredForInputLabel label={t('field.equipment.type')} requiredFor={requiredFor} disabled={disabled} />,
        'data-label-key': 'field.equipment.type',
        error: hasErrors,
        InputProps: {
          ...params.InputProps,
          startAdornment: <>{params.InputProps.startAdornment}</>,
        },
      })}
    />
  );
}

type FieldCapacity = NonNullable<ForwardCraneCapacityAutocompleteProps['value']> | null;
const fieldCapacityKey = createFieldKey<FieldCapacity>();
export function useFieldManualCapacityRead($key: SaleCraneSelectorFields_CraneCapacityFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment SaleCraneSelectorFields_CraneCapacityFragment on ManualConfigurationInfo {
        capacity {
          capacity
          label
        }
      }
    `,
    $key,
  );
  const capacity = useFieldValue(jobStageBaseFormContext, fieldCapacityKey, () => $data?.capacity ?? null);
  const capacityIsDirty = useFieldIsDirty(jobStageBaseFormContext, fieldCapacityKey);
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldCapacityKey);
  useMapper(
    (v) => ({
      equipmentBase: {
        craneSelector: { manualConfiguration: { configurationInfo: { capacity: v?.capacity ?? null } } },
      },
    }),
    [],
    'save',
  );

  return { capacity, capacityIsDirty };
}
export function useFieldManualCapacity(
  $key: SaleCraneSelectorFields_CraneCapacityFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const { capacity, ...rest } = useFieldManualCapacityRead($key);
  const setCapacity = useFieldSetter(jobStageBaseFormContext, fieldCapacityKey);

  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldCapacityKey);

  const renderCapacity = useCallback(
    (configurationKindCode: number | null, equipmentKindCode: number | null, vehicleIds: FieldVehicleIds) => (
      <CapacityInput
        value={capacity}
        setValue={setCapacity}
        configurationKindCode={configurationKindCode}
        equipmentKindCode={equipmentKindCode}
        vehicleIds={vehicleIds}
        disabled={disabled}
        required={required}
      />
    ),
    [capacity, setCapacity, disabled, required],
  );

  return { capacity, setCapacity, renderCapacity, useValidationCapacity: useValidation, ...rest };
}
function CapacityInput({
  value,
  setValue,
  configurationKindCode,
  equipmentKindCode,
  vehicleIds,
  disabled,
  required,
}: {
  value: FieldCapacity;
  setValue: SetValueFn<FieldCapacity>;
  configurationKindCode: number | null;
  equipmentKindCode: number | null;
  vehicleIds: FieldVehicleIds;
  disabled: boolean;
  required: boolean;
}) {
  const { t } = useTranslation('craneSelector');
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldCapacityKey);

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = required
    ? {
        quote: ['submit'],
        serviceCall: ['reserve', 'transfer'],
      }
    : {};

  return (
    <CraneCapacityAutocomplete
      configurationKindCode={configurationKindCode}
      equipmentKindCode={equipmentKindCode}
      vehicleIds={vehicleIds.map((v) => v.key)}
      value={value}
      onChange={(v) => setValue(v)}
      disabled={disabled}
      textFieldProps={(params) => ({
        label: <RequiredForInputLabel label={t('field.equipment.capacity')} requiredFor={requiredFor} disabled={disabled} />,
        'data-label-key': 'field.equipment.capacity',
        error: hasErrors,
        InputProps: {
          ...params.InputProps,
          startAdornment: <>{params.InputProps.startAdornment}</>,
        },
      })}
    />
  );
}

export type FieldVehicleIds = NonNullable<ForwardCraneVehicleIdAutocompleteProps<true>['value']>;
const fieldVehicleIdsKey = createFieldKey<FieldVehicleIds>();
export function useFieldManualVehicleIdsRead($key: SaleCraneSelectorFields_VehicleIdsFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment SaleCraneSelectorFields_VehicleIdsFragment on ManualConfigurationInfo {
        vehicleIds {
          key
          label
          make
          model
        }
      }
    `,
    $key,
  );

  const vehicleIds = useFieldValue(jobStageBaseFormContext, fieldVehicleIdsKey, () => $data?.vehicleIds ?? []);
  const vehicleIdsIsDirty = useFieldIsDirty(jobStageBaseFormContext, fieldVehicleIdsKey);

  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldVehicleIdsKey);
  useMapper(
    (v) => ({
      equipmentBase: {
        craneSelector: {
          manualConfiguration: {
            configurationInfo: {
              vehicleIds: v.map((c) => c.key),
            },
          },
        },
      },
    }),
    [],
    'save',
  );

  return { vehicleIds, vehicleIdsIsDirty };
}
export function useFieldManualVehicleIds(
  $key: SaleCraneSelectorFields_VehicleIdsFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const { vehicleIds, ...rest } = useFieldManualVehicleIdsRead($key);
  const setVehicleIds = useFieldSetter(jobStageBaseFormContext, fieldVehicleIdsKey);

  const useValidationVehicleIds = useFieldValidation(jobStageBaseFormContext, fieldVehicleIdsKey);

  const renderVehicleIds = useCallback(
    (capacity: number | null, configurationKindCode: number | null, equipmentKindCode: number | null) => (
      <VehicleIdsInput
        value={vehicleIds}
        setValue={setVehicleIds}
        capacity={capacity}
        configurationKindCode={configurationKindCode}
        equipmentKindCode={equipmentKindCode}
        disabled={disabled}
        required={required}
      />
    ),
    [disabled, required, setVehicleIds, vehicleIds],
  );

  return { vehicleIds, setVehicleIds, renderVehicleIds, useValidationVehicleIds, ...rest };
}

function VehicleIdsInput({
  value,
  setValue,
  capacity,
  configurationKindCode,
  equipmentKindCode,
  disabled,
  required,
}: {
  value: FieldVehicleIds;
  setValue: SetValueFn<FieldVehicleIds>;
  capacity: number | null;
  configurationKindCode: number | null;
  equipmentKindCode: number | null;
  disabled: boolean;
  required: boolean;
}) {
  const { t } = useTranslation('craneSelector');
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldVehicleIdsKey);
  const title = useMemo(() => value.map((v) => v.label).join('\n'), [value]);

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = required
    ? {
        quote: ['submit'],
        serviceCall: ['transfer'],
      }
    : {};

  return (
    <CraneVehicleIdAutocomplete
      multiple
      limitTags={1}
      capacity={capacity}
      configurationKindCode={configurationKindCode}
      equipmentKindCode={equipmentKindCode}
      value={value}
      onChange={(v) => setValue(v)}
      disabled={disabled}
      textFieldProps={(params) => ({
        label: <RequiredForInputLabel label={t('field.equipment.model')} requiredFor={requiredFor} disabled={disabled} />,
        'data-label-key': 'field.equipment.model',
        error: hasErrors && !disabled,
        InputProps: {
          ...params.InputProps,
          startAdornment: <>{params.InputProps.startAdornment}</>,
          title,
        },
      })}
    />
  );
}

type FieldConfigurationKind = NonNullable<ForwardCraneConfigurationKindAutocompleteProps['value']> | null;
const fieldConfigurationKindKey = createFieldKey<FieldConfigurationKind>();
export function useFieldManualConfigurationKindRead($key: SaleCraneSelectorFields_CraneConfigurationKindFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment SaleCraneSelectorFields_CraneConfigurationKindFragment on ManualConfigurationInfo {
        configurationKind {
          id
          code
          label
        }
      }
    `,
    $key,
  );
  const configurationKind = useFieldValue(jobStageBaseFormContext, fieldConfigurationKindKey, () => $data?.configurationKind ?? null);
  const configurationKindIsDirty = useFieldIsDirty(jobStageBaseFormContext, fieldConfigurationKindKey);

  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldConfigurationKindKey);
  useMapper(
    (v) => ({
      equipmentBase: {
        craneSelector: { manualConfiguration: { configurationInfo: { configurationKindCode: v?.code ?? null } } },
      },
    }),
    [],
    'save',
  );

  return { configurationKind, configurationKindIsDirty };
}
export function useFieldManualConfigurationKind(
  $key: SaleCraneSelectorFields_CraneConfigurationKindFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const { configurationKind, ...rest } = useFieldManualConfigurationKindRead($key);
  const setConfigurationKind = useFieldSetter(jobStageBaseFormContext, fieldConfigurationKindKey);

  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldConfigurationKindKey);

  const renderConfigurationKind = useCallback(
    (capacity: number | null, equipmentKindCode: number | null, vehicleIds: FieldVehicleIds) => (
      <ConfigurationKindInput
        value={configurationKind}
        setValue={setConfigurationKind}
        capacity={capacity}
        equipmentKindCode={equipmentKindCode}
        vehicleIds={vehicleIds}
        disabled={disabled}
        required={required}
      />
    ),
    [configurationKind, setConfigurationKind, disabled, required],
  );

  return {
    configurationKind,
    setConfigurationKind,
    renderConfigurationKind,
    fieldConfigurationKindKey,
    useValidationConfigurationKind: useValidation,
    ...rest,
  };
}

function ConfigurationKindInput({
  value,
  setValue,
  capacity,
  equipmentKindCode,
  vehicleIds,
  disabled,
  required,
}: {
  value: FieldConfigurationKind;
  setValue: SetValueFn<FieldConfigurationKind>;
  capacity: number | null;
  equipmentKindCode: number | null;
  vehicleIds: FieldVehicleIds;
  disabled: boolean;
  required: boolean;
}) {
  const { t } = useTranslation('craneSelector');
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldConfigurationKindKey);

  const requiredFor: Partial<Record<JobStage, RequiredForStatus[]>> = required
    ? {
        quote: ['submit'],
        serviceCall: ['reserve', 'transfer'],
      }
    : {};

  return (
    <CraneConfigurationKindAutocomplete
      value={value}
      onChange={(v) => setValue(v)}
      disabled={disabled}
      textFieldProps={(params) => ({
        label: <RequiredForInputLabel label={t('field.equipment.configurationOption')} requiredFor={requiredFor} disabled={disabled} />,
        'data-label-key': 'field.equipment.configurationOption',
        error: hasErrors,
        InputProps: {
          ...params.InputProps,
          startAdornment: <>{params.InputProps.startAdornment}</>,
        },
      })}
      equipmentKindCode={equipmentKindCode}
      vehicleIds={vehicleIds.map((v) => v.key)}
      capacity={capacity}
    />
  );
}

const fieldFavoriteConfigurationETagKey = createFieldKey<string | null>();
export function useFieldFavoriteConfigurationETag($key: SaleCraneSelectorFields_FavoriteConfigurationFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment SaleCraneSelectorFields_FavoriteConfigurationFragment on CraneSelectorInternal {
        automaticConfiguration {
          matchingConfigurations {
            isFavorite
            eTag
          }
        }
      }
    `,
    $key,
  );

  const [favoriteConfigurationETag, setFavoriteConfigurationETag] = useField(
    jobStageBaseFormContext,
    fieldFavoriteConfigurationETagKey,
    () => $data?.automaticConfiguration.matchingConfigurations.find((m) => m.isFavorite)?.eTag ?? null,
  );
  const favoriteConfigurationETagIsDirty = useFieldIsDirty(jobStageBaseFormContext, fieldFavoriteConfigurationETagKey);

  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldFavoriteConfigurationETagKey);
  useMapper((v) => ({ equipmentBase: { craneSelector: { automaticConfiguration: { favoriteETag: v } } } }), [], 'save');

  return { favoriteConfigurationETag, setFavoriteConfigurationETag, favoriteConfigurationETagIsDirty };
}

const fieldCraneConfigurationSnapshotKey = createFieldKey<string | null>();
export function useFieldCraneConfigurationSnapshot(
  $key: SaleCraneSelectorFields_CraneConfigurationSnapshotFragment$key | null | undefined,
) {
  const $data = useFragment(
    graphql`
      fragment SaleCraneSelectorFields_CraneConfigurationSnapshotFragment on AutomaticConfigurationInternal {
        snapshotId
      }
    `,
    $key,
  );

  const [craneConfigurationSnapshot, setCraneConfigurationSnapshot] = useField(
    jobStageBaseFormContext,
    fieldCraneConfigurationSnapshotKey,
    () => $data?.snapshotId ?? null,
  );
  const craneConfigurationSnapshotIsDirty = useFieldIsDirty(jobStageBaseFormContext, fieldCraneConfigurationSnapshotKey);

  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldCraneConfigurationSnapshotKey);
  useMapper((v) => ({ equipmentBase: { craneSelector: { automaticConfiguration: { snapshotId: v } } } }), [], 'save');

  return { craneConfigurationSnapshot, setCraneConfigurationSnapshot, craneConfigurationSnapshotIsDirty };
}

export type MatchingCraneConfiguration = {
  eTag: string;
  matchingConfiguration$key: CraneConfiguration_ConfigurationListItemFragment$key;
  matchingConfigurationSummary$key: CraneConfiguration_ConfigurationListItemSummaryFragment$key;
  matchingConfigurationFullSummary$key: CraneConfiguration_SelectedConfigurationCard_AutomaticInformationFragment$key;
  matchingConfigurationBoomConfiguration$key: JobEquipment_useCraneSelectorAutomaticFavorite_BoomConfigurationFragment$key;
  matchingCostLineAutomaticRulesFavorite$key: JobEquipment_AutomaticFavoriteFragment$key;
};

const fieldCraneConfigurationCollectionKey = createFieldKey<MatchingCraneConfiguration[]>();
export function useFieldCraneConfigurationCollection($key: SaleCraneSelectorFields_CraneConfigurationsFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment SaleCraneSelectorFields_CraneConfigurationsFragment on IMatchingConfigurations {
        matchingConfigurations {
          eTag
          ...CraneConfiguration_ConfigurationListItemFragment
          ...CraneConfiguration_ConfigurationListItemSummaryFragment
          ...CraneConfiguration_SelectedConfigurationCard_AutomaticInformationFragment
          ...JobEquipment_useCraneSelectorAutomaticFavorite_BoomConfigurationFragment
          ...JobEquipment_AutomaticFavoriteFragment
        }
      }
    `,
    $key,
  );

  const [craneConfigurationCollection, setCraneConfigurationCollection] = useField(
    jobStageBaseFormContext,
    fieldCraneConfigurationCollectionKey,
    () =>
      $data?.matchingConfigurations?.map((c) => ({
        eTag: c.eTag,
        matchingConfiguration$key: c,
        matchingConfigurationSummary$key: c,
        matchingConfigurationFullSummary$key: c,
        matchingConfigurationBoomConfiguration$key: c,
        matchingCostLineAutomaticRulesFavorite$key: c,
      })) ?? [],
  );

  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldCraneConfigurationCollectionKey);

  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldCraneConfigurationCollectionKey);
  useMapper(
    (v) => ({
      equipmentBase: {
        craneSelector: {
          automaticConfiguration: {
            configurationETags: v?.flatMap((c) => (c.eTag ? [c.eTag] : [])) ?? [],
          },
        },
      },
    }),
    [],
    'save',
  );

  return { craneConfigurationCollection, setCraneConfigurationCollection, useCraneConfigurationCollectionValidation: useValidation };
}

export function useFieldCraneConfigurationCollectionErrors() {
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldCraneConfigurationCollectionKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(jobStageBaseFormContext, fieldCraneConfigurationCollectionKey);

  return { craneConfigurationErrorMessage: message, craneConfigurationErrorParams: errorParams, craneConfigurationHasError: hasErrors };
}

/**
 * TODO: Rename to useFieldCraneSelectorAutomaticFavoriteConfiguration and associated fields accordingly
 * @param $key
 * @param required
 */
export function useFieldCraneConfigurationCollectionFavorite(
  $key: SaleCraneSelectorFields_CraneConfigurationCollection_FavoriteFragment$key | null | undefined,
  required: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment SaleCraneSelectorFields_CraneConfigurationCollection_FavoriteFragment on CraneSelectorInternal {
        ...SaleCraneSelectorFields_CraneSelectorModeFragment
        ...SaleCraneSelectorFields_FavoriteConfigurationFragment
        automaticConfiguration {
          ...SaleCraneSelectorFields_CraneConfigurationsFragment
        }
      }
    `,
    $key,
  );

  const { favoriteConfigurationETag, favoriteConfigurationETagIsDirty } = useFieldFavoriteConfigurationETag($data);
  const { craneConfigurationCollection, useCraneConfigurationCollectionValidation } = useFieldCraneConfigurationCollection(
    $data?.automaticConfiguration,
  );
  const { craneSelectorMode } = useFieldCraneSelectorModeRead($data);
  const isLiftsMode = craneSelectorMode === 'lifts';

  useCraneConfigurationCollectionValidation(
    () => (required && isLiftsMode && !favoriteConfigurationETag ? 'errorMessages.requiredField' : true),
    [isLiftsMode, favoriteConfigurationETag],
    'transferable,submittable:required',
  );

  return useMemo(
    () => ({
      craneConfigurationCollectionFavorite: craneConfigurationCollection.find((c) => c.eTag === favoriteConfigurationETag),
      craneConfigurationCollectionFavoriteIsDirty: favoriteConfigurationETagIsDirty,
    }),
    [craneConfigurationCollection, favoriteConfigurationETag, favoriteConfigurationETagIsDirty],
  );
}

function CraneSelectorMassInput({
  labelKey,
  value,
  setValue,
  disabled,
  requiredFor,
}: {
  labelKey: string;
  value: Mass | null;
  setValue: SetValueFn<Mass | null>;
  disabled: boolean;
  requiredFor: Partial<Record<JobStage, RequiredForStatus[]>>;
}) {
  const { t } = useTranslation('craneSelector');
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldMaxWeightKey);

  return (
    <MassInput
      value={value}
      onChange={(val) => setValue(val)}
      label={<RequiredForInputLabel label={t(labelKey)} requiredFor={requiredFor} disabled={disabled} />}
      data-label-key={labelKey}
      disabled={disabled}
      error={hasErrors && requiredFor && !disabled}
    />
  );
}

const craneSelectorEnabledLengthUnits = ['ft', 'in', 'ftin', 'mm', 'cm', 'm'] as const satisfies LengthUnit[];
function CraneSelectorLengthInput({
  labelKey,
  value,
  setValue,
  disabled,
  requiredFor,
  fieldKey,
}: {
  labelKey: string;
  value: Length | null;
  setValue: SetValueFn<Length | null>;
  disabled: boolean;
  requiredFor: Partial<Record<JobStage, RequiredForStatus[]>>;
  fieldKey: FieldKey<Length | null>;
}) {
  const { t } = useTranslation('craneSelector');
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldKey);

  return (
    <LengthInput
      enabledUnits={craneSelectorEnabledLengthUnits}
      value={value}
      onChange={(val) => setValue(val)}
      label={<RequiredForInputLabel label={t(labelKey)} requiredFor={requiredFor} disabled={disabled} />}
      data-label-key={labelKey}
      disabled={disabled}
      error={hasErrors && !disabled}
    />
  );
}
