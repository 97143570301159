import { AuthorizationWriteFragment$key } from '../auth/__generated__/AuthorizationWriteFragment.graphql';
import { ResponsiveGridColumnDefinition, ResponsiveGridColumnOrderer } from '../common/components/ResponsiveGrid';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Box, ListItemIcon, ListItemText, Skeleton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { isServiceCallStatus } from '../__enums__/ServiceCallStatus';
import { visuallyHidden } from '@mui/utils';
import { NullableCell } from '../common/components/NullableCell';
import { EllipsisedTypography } from '../common/components/EllipsisedTypography';
import { CreditCategoryChip } from '../jobs/CreditCategoryChip';
import { ServiceCallStatusChip } from './ServiceCallStatusChip';
import { DateTime } from 'luxon';
import { useHideAccessorySection } from '../jobs/accessoryLines/AccessoryLinesFields';
import { castServiceCallKind } from '../__enums__/ServiceCallKind';
import { RequireWrite } from '../auth/Authorization';
import { ServiceCallListDelayButton } from './ServiceCallListDelayButton';
import { SaleList_CopyButton } from '../jobs/SaleList.CopyButton';
import { dateFormat } from '../common/utils/dateTimeUtils';
import { ServiceCallList_ActionsFragment$key } from './__generated__/ServiceCallList_ActionsFragment.graphql';
import { ServiceCallList_ItemFragment$key } from './__generated__/ServiceCallList_ItemFragment.graphql';
import { ServiceCallList_RowFragment$key } from './__generated__/ServiceCallList_RowFragment.graphql';
import { TFunction } from 'i18next';

export function getServiceCallListColumns(t: TFunction, compact: boolean) {
  const mainColumns: ResponsiveGridColumnDefinition[] = [
    { id: 'friendlyId', label: t('list.column.friendlyId'), size: '7rem' },
    { id: 'client', label: t('list.column.client'), size: 'minmax(6rem, 2fr)' },
    { id: 'worksite', label: t('list.column.worksite'), size: 'minmax(6rem, 2fr)' },
    { id: 'kind', label: t('list.column.kind'), size: 'minmax(6rem, 1fr)' },
    { id: 'crane', label: t('list.column.crane'), size: 'auto', sx: { textAlign: 'center' } },
    { id: 'status', label: compact ? '' : t('list.column.status'), size: 'auto' },
    { id: 'date', label: t('list.column.date'), size: 'auto' },
  ];

  const allColumns: ResponsiveGridColumnDefinition[] = [
    ...mainColumns,
    {
      id: 'po',
      label: t('list.column.poNumber'),
      size: '8rem',
    },
    { id: 'actions', label: '', size: 'minmax(6rem, auto)' },
  ];

  return compact ? mainColumns : allColumns;
}

export const serviceCallListSx = {
  "&[data-mode='grid']": {
    // Adds a padding in cells to improve readability, specially when elipsing.
    'li.responsive-grid__header  > *': {
      px: '1rem',
    },
    'li:not(.responsive-grid__header)  > *': {
      px: '0.5rem',
    },
  },

  // Controls the gap between the content of list items and the status chip.
  "&[data-mode='list'] > li": {
    gap: '0.5rem',
  },
};

export function ServiceCallList_Row({
  $key,
  write$key,
  orderByColumns,
  refreshServiceCallList,
}: {
  $key: ServiceCallList_RowFragment$key;
  write$key: AuthorizationWriteFragment$key;
  orderByColumns: ResponsiveGridColumnOrderer;
  refreshServiceCallList: () => void;
}) {
  const { t } = useAmbientTranslation();

  const $data = useFragment(
    graphql`
      fragment ServiceCallList_RowFragment on ServiceCallJobRevision {
        ...ServiceCallList_ActionsFragment
        friendlyId
        snapshot {
          kind
          statuses
          clientBase {
            assignedClient {
              name
              isDirectSales
              ...CreditCategoryChipFragment
            }
            assignedClientInfo {
              name
            }
          }
          client {
            orderNumber
          }
          equipmentBase {
            craneSelector {
              favoriteConfiguration {
                capacity {
                  capacity
                }
                equipmentKind {
                  abbreviation
                }
              }
            }
          }
          projectBase {
            assignedWorksiteInfo {
              name
            }
            arrivalDate {
              rawValue
              date
            }
          }
        }
      }
    `,
    $key,
  );
  const { snapshot } = $data;
  const theme = useTheme();
  const compact = useMediaQuery(theme.breakpoints.down('lg'));
  const clientName = snapshot?.clientBase.assignedClient?.isDirectSales
    ? snapshot.clientBase.assignedClientInfo.name
    : snapshot?.clientBase.assignedClient?.name;
  const favouriteConfiguration = snapshot?.equipmentBase.craneSelector.favoriteConfiguration;
  const statuses = snapshot?.statuses.every(isServiceCallStatus) ? (snapshot?.statuses ?? []) : [];

  return (
    <>
      <h3 style={visuallyHidden}>
        <NullableCell value={$data.friendlyId?.toString()} />
        <br />
        <NullableCell value={clientName} />
        <br />
        <NullableCell value={snapshot?.projectBase.assignedWorksiteInfo?.name} />
      </h3>

      {orderByColumns([
        <Typography key='friendlyId' variant='body2'>
          <NullableCell value={$data.friendlyId?.toString()} />
        </Typography>,

        <Box key='client' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '0.25rem' }}>
          <EllipsisedTypography variant='body2' component='span' title={clientName}>
            <NullableCell value={clientName} />
          </EllipsisedTypography>
          {snapshot?.clientBase.assignedClient && <CreditCategoryChip $key={snapshot.clientBase.assignedClient} />}
        </Box>,

        <EllipsisedTypography
          key='worksite'
          variant='body2'
          component='span'
          title={snapshot?.projectBase.assignedWorksiteInfo?.name ?? ''}>
          <NullableCell value={snapshot?.projectBase.assignedWorksiteInfo?.name} />
        </EllipsisedTypography>,

        <EllipsisedTypography key='kind' variant='body2' component='span' title={t(`kind.${snapshot?.kind}`)}>
          {t(`kindShort.${snapshot?.kind}`)}
        </EllipsisedTypography>,

        <Typography key='crane' variant='body2' textAlign='center'>
          <NullableCell
            value={
              favouriteConfiguration
                ? {
                    capacity: favouriteConfiguration.capacity?.capacity,
                    equipmentKind: favouriteConfiguration.equipmentKind?.abbreviation,
                  }
                : null
            }
            formatter={({ capacity, equipmentKind }) => (
              <>
                {capacity}
                {capacity && equipmentKind && <span>&ndash;</span>}
                {equipmentKind}
              </>
            )}
          />
        </Typography>,

        <Box key='status' display='flex' alignItems='center'>
          <ServiceCallStatusChip statuses={statuses} compact={compact} />
        </Box>,

        <Typography
          key='date'
          variant='body2'
          title={DateTime.fromISO(snapshot?.projectBase.arrivalDate?.rawValue ?? '')
            .setLocale(t('locale', { ns: 'common' }))
            .toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' })}>
          <NullableCell
            formatter={(v: string) =>
              DateTime.fromISO(v)
                .setLocale(t('locale', { ns: 'common' }))
                .toLocaleString({
                  month: 'short',
                  day: 'numeric',
                })
            }
            value={snapshot?.projectBase.arrivalDate?.rawValue}
          />
        </Typography>,

        <EllipsisedTypography key='po' variant='body2' title={snapshot?.client.orderNumber || ''}>
          <NullableCell value={snapshot?.client.orderNumber} />
        </EllipsisedTypography>,

        <ServiceCallList_Actions $key={$data} write$key={write$key} refreshServiceCallList={refreshServiceCallList} key='actions' />,
      ])}
    </>
  );
}

function ServiceCallList_Actions({
  $key,
  write$key,
  refreshServiceCallList,
}: {
  $key: ServiceCallList_ActionsFragment$key | null | undefined;
  write$key: AuthorizationWriteFragment$key;
  refreshServiceCallList: () => void;
}) {
  const $data = useFragment(
    graphql`
      fragment ServiceCallList_ActionsFragment on ServiceCallJobRevision {
        ...ServiceCallListDelayButtonFragment
        ...SaleList_CopyButtonFragment
        snapshot {
          kind
        }
      }
    `,
    $key,
  );

  const hideAccessories = useHideAccessorySection(castServiceCallKind($data?.snapshot?.kind ?? ''));

  return (
    // need to fallback on an empty element to keep grid columns from displaying correctly
    <RequireWrite $key={write$key} fallback={<div />}>
      <Box>
        <Stack direction='row' sx={{ opacity: 'var(--hover-highlight)' }}>
          <ServiceCallListDelayButton fragmentKey={$data} refreshServiceCallList={refreshServiceCallList} />
          <SaleList_CopyButton $key={$data} hideAccessories={hideAccessories} />
        </Stack>
      </Box>
    </RequireWrite>
  );
}

export function ServiceCallList_Item({ $key }: { $key: ServiceCallList_ItemFragment$key }) {
  const { t } = useAmbientTranslation();

  const serviceCall = useFragment(
    graphql`
      fragment ServiceCallList_ItemFragment on ServiceCallJobRevision {
        friendlyId
        snapshot {
          statuses
          clientBase {
            assignedClient {
              name
              isDirectSales
            }
            assignedClientInfo {
              name
            }
          }
          equipmentBase {
            craneSelector {
              favoriteConfiguration {
                capacity {
                  capacity
                }
                equipmentKind {
                  abbreviation
                }
              }
            }
          }
          projectBase {
            assignedWorksiteInfo {
              name
            }
            arrivalDate {
              rawValue
            }
          }
        }
      }
    `,
    $key,
  );
  const { snapshot } = serviceCall;

  const clientName = snapshot?.clientBase.assignedClient?.isDirectSales
    ? snapshot.clientBase.assignedClientInfo.name
    : snapshot?.clientBase.assignedClient?.name;
  const capacityInTons = snapshot?.equipmentBase.craneSelector.favoriteConfiguration?.capacity?.capacity ?? 0;
  const statuses = snapshot?.statuses.every(isServiceCallStatus) ? (snapshot?.statuses ?? []) : [];
  return (
    <>
      <h3 style={visuallyHidden}>
        <NullableCell value={serviceCall.friendlyId?.toString()} />
        <br />
        <NullableCell value={snapshot?.clientBase.assignedClient?.name} />
        <br />
        <NullableCell value={snapshot?.projectBase.assignedWorksiteInfo?.name} />
      </h3>

      <ListItemText disableTypography={true}>
        <Typography color='text.secondary' fontSize='0.75rem'>
          <NullableCell value={serviceCall.friendlyId?.toString()} />
          <span> | </span>
          <NullableCell
            formatter={(v: string) => DateTime.fromISO(v).toFormat(dateFormat)}
            value={snapshot?.projectBase.arrivalDate?.rawValue}
          />
        </Typography>
        <EllipsisedTypography variant='subtitle2' component='p' color='text.primary'>
          <NullableCell value={clientName} />
        </EllipsisedTypography>
        <EllipsisedTypography variant='subtitle2' component='p' color='text.primary'>
          <NullableCell value={snapshot?.projectBase.assignedWorksiteInfo?.name} />
        </EllipsisedTypography>
        <Typography variant='body2' component='span' color='text.secondary'>
          {t('unit.full.ton', { ns: 'common', count: capacityInTons })}
          &nbsp;
          {snapshot?.equipmentBase.craneSelector.favoriteConfiguration?.equipmentKind?.abbreviation ?? ''}
        </Typography>
      </ListItemText>
      <ListItemIcon>
        <ServiceCallStatusChip statuses={statuses} />
      </ListItemIcon>
    </>
  );
}

export function ServiceCallList_RowSkeleton({ columns }: { columns: ResponsiveGridColumnDefinition[] }) {
  return (
    <span style={{ gridColumn: `1 / span ${columns.length}` }}>
      <Skeleton variant='rounded' height='1.5rem' sx={{ my: '0.875rem' }} />
    </span>
  );
}

export function ServiceCallList_ListSkeleton() {
  return (
    <>
      <ListItemText>
        <Skeleton variant='rounded' width='6rem' height='1rem' sx={{ mb: '0.25rem' }} />
        <Skeleton variant='rounded' width='14rem' height='1.25rem' sx={{ mb: '0.25rem' }} />
        <Skeleton variant='rounded' width='10rem' height='1.25rem' sx={{ mb: '0.25rem' }} />
        <Skeleton variant='rounded' width='6rem' height='1rem' />
      </ListItemText>
      <ListItemIcon>
        <Skeleton variant='rounded' width='4.5rem' height='1.5rem' sx={{ borderRadius: '1rem' }} />
      </ListItemIcon>
    </>
  );
}
