/**
 * @generated SignedSource<<24c90be8e5b9495101fb4aa9bbbf3fce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as subRegionLabelResolverType } from "../../../__resolvers__/SubRegionLabelResolver";
// Type assertion validating that `subRegionLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(subRegionLabelResolverType satisfies (
  rootKey: SubRegionLabelResolver$key,
) => Record<string, string> | null | undefined);
export type SubRegionAutocompleteListFragment$data = {
  readonly searchResults: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly label: Record<string, string> | null | undefined;
        readonly region: {
          readonly description: Record<string, string>;
        } | null | undefined;
      };
    }> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "SubRegionAutocompleteListFragment";
};
export type SubRegionAutocompleteListFragment$key = {
  readonly " $data"?: SubRegionAutocompleteListFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SubRegionAutocompleteListFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "searchResults"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": 25,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchTerm"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./SubRegionAutocompleteListFragmentQuery.graphql')
    }
  },
  "name": "SubRegionAutocompleteListFragment",
  "selections": [
    {
      "alias": "searchResults",
      "args": [
        {
          "kind": "Variable",
          "name": "searchTerm",
          "variableName": "searchTerm"
        }
      ],
      "concreteType": "SearchSubRegionsConnection",
      "kind": "LinkedField",
      "name": "__SubRegionAutocompleteListFragment_searchResults_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchSubRegionsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SubRegion",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "fragment": {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SubRegionLabelResolver"
                  },
                  "kind": "RelayResolver",
                  "name": "label",
                  "resolverModule": require('./../../../__resolvers__/SubRegionLabelResolver').label,
                  "path": "searchResults.edges.node.label"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Region",
                  "kind": "LinkedField",
                  "name": "region",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "description",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "1a900f5ebba619390e0f688439ecd087";

export default node;
