import { Suspense, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataID, useFragment, useLazyLoadQuery } from 'react-relay';
import { ListLayout, SidebarContentProps } from '../layout/Layouts';
import { ConfigurationTabs } from './ConfigurationTabs';
import graphql from 'babel-plugin-relay/macro';
import { TabCompetitorsQuery } from './__generated__/TabCompetitorsQuery.graphql';
import { Box, Skeleton, TextField } from '@mui/material';
import { usePromiseMutation } from '../common/hooks/usePromiseMutation';
import { RequireAdmin, UnauthorizedFallback } from '../auth/Authorization';
import { useOutletContext } from 'react-router';
import { ConfigurationPageQuery$data } from './__generated__/ConfigurationPageQuery.graphql';
import { NavigationMenu } from '../layout/SidebarDrawer';
import { ErrorBanner, useErrorBanner } from '../common/components/ErrorBanner';
import { LoadingButton } from '@mui/lab';
import {
  createFieldKey,
  createFormContext,
  FormProvider,
  SetValueFn,
  useField,
  useFieldMapper,
  useFormMappings,
} from '../common/utils/forms';
import {
  flagDirty,
  hasChanged,
  isPatchableEditProps,
  Patchable,
  PatchableEditProps,
  PatchableList_RenderFn,
  toPatchOperation,
  usePatchable,
} from '../common/utils/patchable';
import { TabCompetitors_useFieldCompetitorsCollectionFragment$key } from './__generated__/TabCompetitors_useFieldCompetitorsCollectionFragment.graphql';
import { TabCompetitors_ListFragment$key } from './__generated__/TabCompetitors_ListFragment.graphql';
import { TabCompetitors_SaveButtonMutation, UpdateCompetitorListInput } from './__generated__/TabCompetitors_SaveButtonMutation.graphql';
import { PatchableForm_RenderNewFn, PatchableNewProps, useRenderNewItem, useRenderSubFormCollection } from '../common/utils/patchableForm';
import { nanoid } from 'nanoid';
import { ItemDeleteButton } from '../common/components/ItemDeleteButton';
import {
  ResponsiveGridContent,
  ResponsiveGridHeader,
  ResponsiveGridHeaderColumn,
  ResponsiveGridItem,
} from '../common/components/ResponsiveGrid';
import { visuallyHidden } from '@mui/utils';
import { NullableCell } from '../common/components/NullableCell';

export interface CompetitorsFieldMappings {
  save: UpdateCompetitorListInput;
}

const competitorsFormContext = createFormContext<CompetitorsFieldMappings>();

type Competitor = Patchable<{
  id: DataID;
  name: string;
}>;
function keySelector(v: Competitor) {
  return v.id;
}

const competitorSubFormContext = createFormContext<{ sync: Competitor }>();

const flagName = 'app_navigation_configuration';
export function TabCompetitors() {
  const { t } = useTranslation('configuration');

  const $data = useOutletContext<ConfigurationPageQuery$data>();

  const sidebar = (props: SidebarContentProps) => <NavigationMenu {...props} $key={$data} />;

  const [formKey, setFormKey] = useState<string>(nanoid());
  const handleSaveSuccess = () => setFormKey(nanoid());

  return (
    <FormProvider key={formKey} context={competitorsFormContext}>
      <RequireAdmin
        $key={$data}
        fallback={
          <ListLayout heading={t('configuration')} flagName={flagName} sidebarProvider={sidebar} $key={$data}>
            <UnauthorizedFallback />
          </ListLayout>
        }>
        <ListLayout
          heading={t('configuration')}
          flagName={flagName}
          sidebarProvider={sidebar}
          $key={$data}
          actions={<TabCompetitors_SaveButton onSuccess={handleSaveSuccess} />}>
          <ErrorBanner />
          <ConfigurationTabs tab='competitors'></ConfigurationTabs>

          <Suspense fallback={<TabCompetitors_Skeleton />}>
            <TabCompetitors_Content formKey={formKey} />
          </Suspense>
        </ListLayout>
      </RequireAdmin>
    </FormProvider>
  );
}

function TabCompetitors_Content({ formKey }: { formKey: string }) {
  const $data = useLazyLoadQuery<TabCompetitorsQuery>(
    graphql`
      query TabCompetitorsQuery {
        ...TabCompetitors_ListFragment
      }
    `,
    {},
    {
      // HACK: Normally, Relay should be able to notice that the TabCompetitors_SaveButtonMutation came back with a
      //  new set of Competitors and automatically re-render all queries/fragments that depends on them. However,
      //  because Competitors are returned straight up as an unpaginated array instead of through a paginated Connection,
      //  while Competitors themselves get updated, the array itself isn't. This happens because even if the two arrays
      //  contains the same values/types, the array from `query { competitors { ... } }` and
      //  `mutation { updateCompetitors(...) { query { competitors } } }` aren't actually treated as the same collection.
      //  To workaround this issue, instead of re-querying the data through the mutation, we forcefully reload the state
      //  of the list using a fetchKey and network-only mode. This will cause the fresh FormProvider to get
      //  re-initialized with data from the backend, ignoring the old invalid local cache.
      //  The real fix would be to upgrade competitors to use a connection pagination mechanic. Considering the impacts
      //  and complexity of this change throughout the application, we opted to keep it as is for now.
      fetchPolicy: 'network-only',
      fetchKey: formKey,
    },
  );

  return <TabCompetitors_List $key={$data} />;
}

function TabCompetitors_Skeleton() {
  return (
    <>
      <Skeleton sx={{ fontSize: '2rem' }} />
      <Skeleton sx={{ fontSize: '2rem' }} />
      <Skeleton sx={{ fontSize: '2rem' }} />
      <Skeleton sx={{ fontSize: '2rem' }} />
      <Skeleton sx={{ fontSize: '2rem' }} />
    </>
  );
}

const competitorsCollectionFieldKey = createFieldKey<Competitor[]>();

function useFieldCompetitorsCollection($key: TabCompetitors_useFieldCompetitorsCollectionFragment$key) {
  const $data = useFragment(
    graphql`
      fragment TabCompetitors_useFieldCompetitorsCollectionFragment on Query {
        competitors(order: { name: ASC }) {
          id
          name
        }
      }
    `,
    $key,
  );

  const [competitors, setCompetitors] = useField(competitorsFormContext, competitorsCollectionFieldKey, () =>
    $data.competitors.map((c) => ({ ...c })),
  );

  const { append, remove: removeCompetitor, patch: patchCompetitor } = usePatchable(setCompetitors, keySelector);
  const appendCompetitor = useCallback((value: Competitor) => append({ ...value, id: nanoid() }), [append]);

  const useMapper = useFieldMapper(competitorsFormContext, competitorsCollectionFieldKey);
  useMapper(
    (rows) => ({
      competitors: rows.filter(hasChanged).map((v) => toPatchOperation(v, keySelector, ({ name }) => ({ name }))),
    }),
    [],
    'save',
  );

  const renderCompetitorsCollection = useRenderSubFormCollection(competitors, setCompetitors, keySelector, competitorSubFormContext);
  const renderCompetitorsNewItem = useRenderNewItem(competitors, appendCompetitor, competitorSubFormContext);

  return {
    competitorsCollection: competitors,
    setCompetitorsCollection: setCompetitors,
    appendCompetitor,
    removeCompetitor,
    patchCompetitor,
    renderCompetitorsCollection,
    renderCompetitorsNewItem,
  };
}

function TabCompetitors_List({ $key }: { $key: TabCompetitors_ListFragment$key }) {
  const { t } = useTranslation('configuration');

  const $data = useFragment(
    graphql`
      fragment TabCompetitors_ListFragment on Query {
        ...TabCompetitors_useFieldCompetitorsCollectionFragment
      }
    `,
    $key,
  );

  const { renderCompetitorsCollection, renderCompetitorsNewItem } = useFieldCompetitorsCollection($data);

  const renderCompetitor = useCallback<PatchableList_RenderFn<Competitor>>((params) => <TabCompetitors_Item {...params} />, []);
  const renderNewCompetitor = useCallback<PatchableForm_RenderNewFn<Competitor>>((params) => <TabCompetitors_Item {...params} />, []);

  const columnDefinitions: ResponsiveGridHeaderColumn[] = [
    { id: 'name', label: t('competitors.name') },
    { id: 'actions', label: '' },
  ];

  return (
    <ResponsiveGridContent
      gridTemplateColumns='1fr auto'
      gridMode
      formMode
      listSx={{
        "&[data-mode='grid']": {
          // Adds a padding in cells to improve readability, specially when elipsing.
          'li.responsive-grid__header  > *': {
            px: '1rem',
          },
        },
      }}>
      <ResponsiveGridHeader columnsDefinitions={columnDefinitions} />
      {renderCompetitorsCollection(renderCompetitor)}
      {renderCompetitorsNewItem(renderNewCompetitor)}
    </ResponsiveGridContent>
  );
}

const competitorNameFieldKey = createFieldKey<string>();

function useFieldCompetitorName(initialValue: string | null | undefined) {
  const [competitorName, setCompetitorName] = useField(competitorSubFormContext, competitorNameFieldKey, () => initialValue ?? '');

  const useMapper = useFieldMapper(competitorSubFormContext, competitorNameFieldKey);
  useMapper((name, { isDirty }) => flagDirty({ name }, { name: isDirty }), [], 'sync');

  const renderCompetitorName = useCallback(
    (handleBlur: () => void) => <CompetitorNameInput value={competitorName} setValue={setCompetitorName} onBlur={handleBlur} />,
    [competitorName, setCompetitorName],
  );

  return { competitorName, setCompetitorName, renderCompetitorName };
}

function CompetitorNameInput({ value, setValue, onBlur }: { value: string; setValue: SetValueFn<string>; onBlur: () => void }) {
  const { t } = useTranslation('configuration');

  return (
    <TextField
      className='borderless'
      placeholder={t('competitors.placeholder')}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={() => {
        setValue(value.trim());
        onBlur();
      }}
    />
  );
}

function TabCompetitors_Item({ ...patchableProps }: PatchableEditProps<Competitor> | PatchableNewProps<Competitor>) {
  const { value, onDelete: handleDelete } = isPatchableEditProps<Competitor>(patchableProps)
    ? patchableProps
    : { value: null, onDelete: null };
  const { id, onChange } = patchableProps;

  const { mapDirty } = useFormMappings(competitorSubFormContext);

  const { competitorName, renderCompetitorName } = useFieldCompetitorName(value?.name);

  const handleBlurName = () => {
    const data = mapDirty('sync');

    if (value) {
      if (data.name === '') {
        // Handle removing empty items
        handleDelete?.();
      } else if (data.name) {
        // Handle changing existing items
        onChange({ ...value, ...data });
      }
    } else {
      if (data.name) {
        // Handle new values from ghost row
        onChange(data);
      }
    }
  };

  return (
    <ResponsiveGridItem
      id={id}
      sx={{
        '&:hover .delete-button': {
          opacity: 1,
        },
      }}>
      <h3 style={visuallyHidden}>
        <NullableCell value={competitorName} />
      </h3>

      {renderCompetitorName(handleBlurName)}

      <Box sx={{ display: 'grid', placeItems: 'center' }}>
        {handleDelete && <ItemDeleteButton listMode onClick={handleDelete} disabled={false} />}
      </Box>
    </ResponsiveGridItem>
  );
}

function TabCompetitors_SaveButton({ onSuccess }: { onSuccess: () => void }) {
  const { t } = useTranslation('configuration');

  const [commitSaveCompetitors, isCurrentlySaving] = usePromiseMutation<TabCompetitors_SaveButtonMutation>(graphql`
    mutation TabCompetitors_SaveButtonMutation($data: UpdateCompetitorListInput!) {
      updateCompetitors(input: { updateCompetitorList: $data }) {
        errors {
          __typename
        }
      }
    }
  `);

  const { reportUnexpectedError, reportHandledError, dismissError } = useErrorBanner();

  const { mapDirty } = useFormMappings(competitorsFormContext);

  const handleSave = async () => {
    dismissError();

    const data = mapDirty('save');

    if (!data.competitors?.length) {
      return;
    }

    try {
      const { response } = await commitSaveCompetitors({ variables: { data } });

      if (response?.updateCompetitors?.errors?.length) {
        reportHandledError(response.updateCompetitors.errors, () => t('error.errorDuringSaveCompetitor'));
        return;
      }
    } catch {
      reportUnexpectedError(() => t('error.errorDuringSaveCompetitor'));
    }

    onSuccess();
  };

  return (
    <LoadingButton type='submit' variant='contained' loadingPosition='start' loading={isCurrentlySaving} onClick={handleSave}>
      {t('button.save', { ns: 'common' })}
    </LoadingButton>
  );
}
