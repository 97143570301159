import { createSearchParam, createSearchParamsClass, SearchParamsProps } from '../common/utils/searchParams';
import {
  ARRIVAL_DATE_FORMAT,
  baseJobFilterParams,
  JobFiltersSearchParams,
  projectManagersResponsiveGridSearchParam,
  projectManagersSearchParam,
  representativesResponsiveGridSearchParam,
  representativesSearchParam,
  subRegionsResponsiveGridSearchParam,
  subRegionsSearchParam,
} from '../jobs/JobFilters';
import { isWorkPlanningStatus, WorkPlanningStatus } from '../__enums__/WorkPlanningStatus';
import { WorkPlanningJobRevisionFilterType } from './__generated__/WorkPlanningList_RegularQuery.graphql';

const workPlanningStatusesSearchParam = createSearchParam<readonly WorkPlanningStatus[]>(
  (searchParams) => searchParams.getAll(JobFiltersSearchParams.STATUS).filter(isWorkPlanningStatus),
  (statuses, searchParams) => {
    for (const status of statuses) searchParams.append(JobFiltersSearchParams.STATUS, status);
  },
);

const baseWorkPlanningFiltersParams = {
  ...baseJobFilterParams,
  statuses: workPlanningStatusesSearchParam,
  projectManagers: projectManagersSearchParam,
};

const workPlanningFiltersParams_regular = {
  ...baseWorkPlanningFiltersParams,
  subRegions: subRegionsSearchParam,
  representatives: representativesSearchParam,
};

export class WorkPlanningFilters_Regular extends createSearchParamsClass(workPlanningFiltersParams_regular) {
  static readonly EMPTY = WorkPlanningFilters_Regular.fromSearchParams(new URLSearchParams());

  static fromSearchParams(searchParams: URLSearchParams): WorkPlanningFilters_Regular {
    return new WorkPlanningFilters_Regular(this.searchParamsToProps(searchParams));
  }

  toResponsiveGridFilters(props: WorkPlanningToResponsiveGridFiltersProps_Regular): WorkPlanningResponsiveGridFilters_Regular {
    return new WorkPlanningResponsiveGridFilters_Regular({ ...this.props, ...props });
  }

  toJobRevisionFilter(): WorkPlanningJobRevisionFilterType {
    const simplify = (op: 'and' | 'or', fs: WorkPlanningJobRevisionFilterType[]): WorkPlanningJobRevisionFilterType => {
      const activeFilters = fs.filter((f) => Object.keys(f).length); //ignore empty filters
      return activeFilters.length > 1 ? { [op]: activeFilters } : activeFilters[0] || {};
    };
    const and = (fs: WorkPlanningJobRevisionFilterType[]) => simplify('and', fs);
    const or = (fs: WorkPlanningJobRevisionFilterType[]) => simplify('or', fs);

    return and([
      this.props.arrivalDate
        ? {
            snapshot: {
              projectBase: {
                arrivalDate: {
                  date: {
                    gte: this.props.arrivalDate.start.toFormat(ARRIVAL_DATE_FORMAT),
                    lte: this.props.arrivalDate.end.toFormat(ARRIVAL_DATE_FORMAT),
                  },
                },
              },
            },
          }
        : {},
      this.props.projectManagers.length
        ? { snapshot: { clientBase: { projectManagerId: { in: this.props.projectManagers.map(({ id }) => id) } } } }
        : {},
      or(this.props.statuses.map((s) => ({ snapshot: { [`${s}At`]: { neq: null } } }))),
    ]);
  }
}

const workPlanningResponsiveGridFiltersParams_regular = {
  ...workPlanningFiltersParams_regular,
  representatives: representativesResponsiveGridSearchParam,
  projectManagers: projectManagersResponsiveGridSearchParam,
  subRegions: subRegionsResponsiveGridSearchParam,
};

export type WorkPlanningToResponsiveGridFiltersProps_Regular = Pick<
  SearchParamsProps<typeof workPlanningResponsiveGridFiltersParams_regular>,
  'representatives' | 'projectManagers' | 'subRegions'
>;

export class WorkPlanningResponsiveGridFilters_Regular
  extends createSearchParamsClass(workPlanningResponsiveGridFiltersParams_regular)
  implements WorkPlanningFilters_Regular
{
  static readonly EMPTY = WorkPlanningFilters_Regular.EMPTY.toResponsiveGridFilters({
    projectManagers: [],
    representatives: [],
    subRegions: [],
  });

  toResponsiveGridFilters(props: WorkPlanningToResponsiveGridFiltersProps_Regular): WorkPlanningResponsiveGridFilters_Regular {
    return new WorkPlanningResponsiveGridFilters_Regular({ ...this.props, ...props });
  }

  toJobRevisionFilter(): WorkPlanningJobRevisionFilterType {
    return WorkPlanningFilters_Regular.prototype.toJobRevisionFilter.call(this);
  }
}

export class WorkPlanningFilters_Representative extends createSearchParamsClass(baseWorkPlanningFiltersParams) {
  static readonly EMPTY = WorkPlanningFilters_Representative.fromSearchParams(new URLSearchParams());

  static fromSearchParams(searchParams: URLSearchParams): WorkPlanningFilters_Representative {
    return new WorkPlanningFilters_Representative(this.searchParamsToProps(searchParams));
  }

  toResponsiveGridFilters(
    props: WorkPlanningToResponsiveGridFiltersProps_Representative,
  ): WorkPlanningResponsiveGridFilters_Representative {
    return new WorkPlanningResponsiveGridFilters_Representative({ ...this.props, ...props });
  }

  toJobRevisionFilter(): WorkPlanningJobRevisionFilterType {
    const simplify = (op: 'and' | 'or', fs: WorkPlanningJobRevisionFilterType[]): WorkPlanningJobRevisionFilterType => {
      const activeFilters = fs.filter((f) => Object.keys(f).length); //ignore empty filters
      return activeFilters.length > 1 ? { [op]: activeFilters } : activeFilters[0] || {};
    };
    const and = (fs: WorkPlanningJobRevisionFilterType[]) => simplify('and', fs);
    const or = (fs: WorkPlanningJobRevisionFilterType[]) => simplify('or', fs);

    return and([
      this.props.arrivalDate
        ? {
            snapshot: {
              projectBase: {
                arrivalDate: {
                  date: {
                    gte: this.props.arrivalDate.start.toFormat(ARRIVAL_DATE_FORMAT),
                    lte: this.props.arrivalDate.end.toFormat(ARRIVAL_DATE_FORMAT),
                  },
                },
              },
            },
          }
        : {},
      this.props.projectManagers.length
        ? { snapshot: { clientBase: { projectManagerId: { in: this.props.projectManagers.map(({ id }) => id) } } } }
        : {},
      or(this.props.statuses.map((s) => ({ snapshot: { [`${s}At`]: { neq: null } } }))),
    ]);
  }
}

const workPlanningResponsiveGridFiltersParams_representative = {
  ...baseWorkPlanningFiltersParams,
  projectManagers: projectManagersResponsiveGridSearchParam,
};

export type WorkPlanningToResponsiveGridFiltersProps_Representative = Pick<
  SearchParamsProps<typeof workPlanningResponsiveGridFiltersParams_representative>,
  'projectManagers'
>;

export class WorkPlanningResponsiveGridFilters_Representative
  extends createSearchParamsClass(workPlanningResponsiveGridFiltersParams_representative)
  implements WorkPlanningFilters_Representative
{
  static readonly EMPTY = WorkPlanningFilters_Representative.EMPTY.toResponsiveGridFilters({
    projectManagers: [],
  });

  toResponsiveGridFilters(
    props: WorkPlanningToResponsiveGridFiltersProps_Representative,
  ): WorkPlanningResponsiveGridFilters_Representative {
    return new WorkPlanningResponsiveGridFilters_Representative({ ...this.props, ...props });
  }

  toJobRevisionFilter(): WorkPlanningJobRevisionFilterType {
    return WorkPlanningFilters_Representative.prototype.toJobRevisionFilter.call(this);
  }
}
