export const clamp = (val: number, min: number | null | undefined, max: number | null | undefined): number =>
  Math.max(min ?? -Infinity, Math.min(max ?? Infinity, val));

const PLUS_SIGN = /\+/;
const MINUS_SIGN = /-\u2212/u; // https://unicode-explorer.com/c/2212
const DECIMAL_SEPARATOR = /[,.\u066B\u2396]/u; // https://unicode-explorer.com/c/066B https://unicode-explorer.com/c/2396

/**
 * Parse numbers formatted according to a given locale's number formatting rules. Parsing equivalent of {@link Intl.NumberFormat}.
 *
 * Adapted to TS and modified from: https://observablehq.com/@mbostock/localized-number-parsing#NumberParser
 */
export class NumberParser {
  private readonly _plusSign: RegExp;
  private readonly _minusSign: RegExp;
  private readonly _group: RegExp;
  private readonly _decimal: RegExp;
  private readonly _numeral: RegExp;
  private readonly _index: (d: string) => string;

  /** A cache of {@link NumberParser} instances indexed by locale. For simplicity and performance reasons, the cache
   * keeps its instances alive forever, since there will never be more than 3 (fr-CA and en-CA for the UI, en-US for the
   * API). */
  private static _instances = new Map<string, NumberParser>();

  /** Return a (potentially cached) {@link NumberParser} instance for the provided locale, creating a new instance
   * on-the-fly if no instance exists in the cache for the specified locale. */
  static forLocale(locale: string): NumberParser {
    let instance = this._instances.get(locale);
    if (!instance) {
      instance = new NumberParser(locale);
      this._instances.set(locale, instance);
    }
    return instance;
  }

  private constructor(locale: string) {
    const parts = [
      ...new Intl.NumberFormat(locale, { signDisplay: 'always' }).formatToParts(12345.6),
      ...new Intl.NumberFormat(locale, { signDisplay: 'always' }).formatToParts(-1),
    ];
    const findPart = (type: Intl.NumberFormatPartTypes) => parts.find((d) => d.type === type)?.value ?? '';

    this._plusSign = new RegExp(`[${findPart('plusSign')}]`);
    this._minusSign = new RegExp(`[${findPart('minusSign')}]`);
    this._group = new RegExp(`[${findPart('group')}]`, 'g');
    this._decimal = new RegExp(`[${findPart('decimal')}]`);

    const numerals = [...new Intl.NumberFormat(locale, { useGrouping: false }).format(9876543210)].reverse();
    const index = new Map(numerals.map((d, i) => [d, i]));

    this._numeral = new RegExp(`[${numerals.join('')}]`, 'g');
    this._index = (d) => {
      const idx = index.get(d);
      return idx == null ? '' : `${idx}`;
    };
  }

  /** Parse a string that strictly matches the configured locale's number format into a number. */
  parse(string: string): number {
    const parsed = string
      .trim()
      .replace(this._minusSign, '-')
      .replace(this._plusSign, '+')
      .replace(this._group, '')
      .replace(this._decimal, '.')
      .replace(this._numeral, this._index);
    return parsed ? +parsed : NaN;
  }

  /** Try to parse a string that loosely resembles a number according to either the configured locale's or the en-US
   * locale's number format into a number. */
  parseFuzzy(string: string): number | null {
    let sign = '';
    let integer = '';
    let fraction = '';

    let state: 'signOrInteger' | 'fraction' = 'signOrInteger';

    for (const char of [...string]) {
      switch (state) {
        case 'signOrInteger': {
          if (!sign && !integer) {
            if (char.match(this._minusSign) || char.match(MINUS_SIGN)) {
              sign = '-';
              break;
            } else if (char.match(this._plusSign) || char.match(PLUS_SIGN)) {
              sign = '+';
              break;
            }
          }
          if (char.match(this._numeral)) {
            integer += this._index(char);
          } else if (char.match(/\d/)) {
            integer += char;
          } else if (char.match(this._decimal) || char.match(DECIMAL_SEPARATOR)) {
            state = 'fraction';
          }
          break;
        }
        case 'fraction': {
          if (char.match(this._numeral)) {
            fraction += this._index(char);
          } else if (char.match(/\d/)) {
            fraction += char;
          }
          break;
        }
      }
    }

    const num = +`${sign}${integer}.${fraction}`;
    return Number.isNaN(num) ? null : num;
  }
}
