/**
 * @generated SignedSource<<31ef5734dc445ed84561a48391ce0930>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as representativeLabelResolverType } from "../../../__resolvers__/RepresentativeLabelResolver";
// Type assertion validating that `representativeLabelResolverType` resolver is correctly implemented.
// A type error here indicates that the type signature of the resolver module is incorrect.
(representativeLabelResolverType satisfies (
  rootKey: RepresentativeLabelResolver$key,
) => string | null | undefined);
export type ClientBaseFields_RepresentativeFragment$data = {
  readonly projectManager: {
    readonly deletedAt: string | null | undefined;
    readonly id: string;
    readonly label: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ClientBaseFields_RepresentativeFragment";
};
export type ClientBaseFields_RepresentativeFragment$key = {
  readonly " $data"?: ClientBaseFields_RepresentativeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientBaseFields_RepresentativeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientBaseFields_RepresentativeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Representative",
      "kind": "LinkedField",
      "name": "projectManager",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "fragment": {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RepresentativeLabelResolver"
          },
          "kind": "RelayResolver",
          "name": "label",
          "resolverModule": require('./../../../__resolvers__/RepresentativeLabelResolver').label,
          "path": "projectManager.label"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deletedAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ClientInternalBase",
  "abstractKey": null
};

(node as any).hash = "edcc82643c8e1361a3b5fceadb40ac83";

export default node;
