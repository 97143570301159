import { ConfigurationTabs } from './ConfigurationTabs';
import graphql from 'babel-plugin-relay/macro';
import { ListLayout, SidebarContentProps } from '../layout/Layouts';
import { namedPageTitle, usePageTitle } from '../common/hooks/usePageTitle';
import { useLazyLoadQuery } from 'react-relay';
import { Skeleton } from '@mui/material';
import { Suspense, useCallback } from 'react';
import { useOutletContext } from 'react-router';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { RequireAdmin, UnauthorizedFallback } from '../auth/Authorization';
import { ConfigurationPageQuery$data } from './__generated__/ConfigurationPageQuery.graphql';
import { NavigationMenu } from '../layout/SidebarDrawer';
import { ErrorBanner } from '../common/components/ErrorBanner';
import { TabBillingCodesQuery } from './__generated__/TabBillingCodesQuery.graphql';
import { BillingCodesList } from './billingCodes/BillingCodesList';

export function TabBillingCodes() {
  const { t } = useAmbientTranslation();
  usePageTitle(namedPageTitle('sidebar.configuration'));
  const $data = useOutletContext<ConfigurationPageQuery$data>();

  const sidebar = useCallback((props: SidebarContentProps) => <NavigationMenu {...props} $key={$data} />, [$data]);
  const flagName = 'app_navigation_configuration';

  return (
    <RequireAdmin
      $key={$data}
      fallback={
        <ListLayout heading={t('configuration')} flagName={flagName} sidebarProvider={sidebar} $key={$data}>
          <UnauthorizedFallback />
        </ListLayout>
      }>
      <ListLayout heading={t('configuration')} flagName={flagName} sidebarProvider={sidebar} $key={$data}>
        <ErrorBanner />
        <ConfigurationTabs tab='billing-codes'></ConfigurationTabs>

        <Suspense fallback={<ContentSkeleton />}>
          <BillingCodesListContent />
        </Suspense>
      </ListLayout>
    </RequireAdmin>
  );
}

function BillingCodesListContent() {
  const $data = useLazyLoadQuery<TabBillingCodesQuery>(
    graphql`
      query TabBillingCodesQuery {
        ...BillingCodesListFragment
      }
    `,
    {},
    { fetchPolicy: 'network-only' },
  );

  return <BillingCodesList $key={$data} />;
}

function ContentSkeleton() {
  return <Skeleton variant='rounded' height='30rem' />;
}
