import graphql from 'babel-plugin-relay/macro';
import { DataID, useFragment } from 'react-relay';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { resolvedLanguage } from '../../i18n';
import {
  ResponsiveGridColumnDefinition,
  ResponsiveGridContent,
  ResponsiveGridHeader,
  ResponsiveGridItem,
} from '../../common/components/ResponsiveGrid';
import { EmptyList } from '../../layout/components/form/FormList';
import { EllipsisedTypography } from '../../common/components/EllipsisedTypography';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { NaturesOfWorkListItemFragment$key } from './__generated__/NaturesOfWorkListItemFragment.graphql';
import { NaturesOfWorkListFragment$key } from './__generated__/NaturesOfWorkListFragment.graphql';

export function NaturesOfWorkList({ $key }: { $key: NaturesOfWorkListFragment$key | null | undefined }) {
  const { t } = useAmbientTranslation();
  const $data = useFragment(
    graphql`
      fragment NaturesOfWorkListFragment on Query {
        natureOfWorks(order: { code: ASC }) {
          id
          ...NaturesOfWorkListItemFragment
        }
      }
    `,
    $key,
  );

  const columns: ResponsiveGridColumnDefinition[] = [
    { id: 'code', label: t('natureOfWork.code'), size: 'auto' },
    { id: 'description', label: t('natureOfWork.description'), size: 'minmax(1rem, 1fr)' },
  ];

  return $data?.natureOfWorks?.length ? (
    <ResponsiveGridContent
      gridMode
      gridTemplateColumns='auto minmax(1rem, 1fr)'
      listSx={{
        // Adds a padding in cells to improve readability, specially when elipsing.
        'li.responsive-grid__header  > *': {
          px: '1rem',
        },
        'li:not(.responsive-grid__header)  > *': {
          px: '0.5rem',
        },
      }}>
      <ResponsiveGridHeader columnsDefinitions={columns} />
      {$data?.natureOfWorks.map((n) => <NaturesOfWorkListRow key={n.id} $key={n} />)}
    </ResponsiveGridContent>
  ) : (
    <EmptyList />
  );
}

function NaturesOfWorkListRow({ $key }: { $key: NaturesOfWorkListItemFragment$key }) {
  const { i18n } = useTranslation();
  const lang = resolvedLanguage(i18n);
  const navigate = useNavigate();

  const $data = useFragment(
    graphql`
      fragment NaturesOfWorkListItemFragment on NatureOfWorkLookup {
        id
        code
        description
      }
    `,
    $key,
  );

  const handleItemClick = useCallback((id: DataID) => navigate(`/configuration/natures-of-work/${id}`), [navigate]);

  return (
    <ResponsiveGridItem id={$data.id} onItemClick={handleItemClick}>
      <EllipsisedTypography key='code' variant='body2' component='div'>
        {$data.code}
      </EllipsisedTypography>
      <EllipsisedTypography key='description' variant='body2' component='div'>
        {$data.description[lang]}
      </EllipsisedTypography>
    </ResponsiveGridItem>
  );
}
