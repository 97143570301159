import { AppBar, Box, IconButton, Theme, ThemeProvider, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ReactElement, ReactNode, Suspense, useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { SidebarDrawer, SidebarVariant, useSidebarAnimation } from './SidebarDrawer';
import { usePreviousValue } from '../common/hooks/usePreviousValue';
import { useRefSync } from '../common/hooks/useRefSync';
import { AutoSplitButton, AutoSplitButtonProps } from '../common/components/AutoSplitButton';
import { AutoSpeedDial, AutoSpeedDialProps } from '../common/components/AutoSpeedDial';
import { Link, useLocation } from 'react-router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import { useFlag } from '@unleash/proxy-client-react';
import MenuIcon from '@mui/icons-material/Menu';
import { GuayLogo } from '../common/svg/GuayLogo';
import { visuallyHidden } from '@mui/utils';
import { UserMenu } from './UserMenu';
import { SxProps } from '@mui/system';
import { ThemeProviderProps } from '@mui/material/styles/ThemeProvider';
import { NotificationButton, NotificationMenu } from './NotificationMenu';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { LayoutsToolbarMainFragment$key } from './__generated__/LayoutsToolbarMainFragment.graphql';
import { LayoutsDetailsLayoutToolbarFragment$key } from './__generated__/LayoutsDetailsLayoutToolbarFragment.graphql';
import { LayoutsListLayoutToolbarFragment$key } from './__generated__/LayoutsListLayoutToolbarFragment.graphql';
import { LayoutsDetailsLayoutFragment$key } from './__generated__/LayoutsDetailsLayoutFragment.graphql';
import { LayoutsListLayoutFragment$key } from './__generated__/LayoutsListLayoutFragment.graphql';
import { LayoutsSearchLayoutFragment$key } from './__generated__/LayoutsSearchLayoutFragment.graphql';
import { useScrollPosition } from '../common/hooks/useScrollPosition';
import { EllipsisedTypography } from '../common/components/EllipsisedTypography';
import { LayoutsSimpleLayoutFragment$key } from './__generated__/LayoutsSimpleLayoutFragment.graphql';
import { LayoutsSimpleLayoutToolbarFragment$key } from './__generated__/LayoutsSimpleLayoutToolbarFragment.graphql';
import { MaintenanceWithMenus } from '../Maintenance';

export type SidebarContentProps = { targetVariant: SidebarVariant; open: boolean; onDismiss?: () => void };

/**
 * A base component that renders the application's shell, including a h1 tag,
 * with slots for a toolbar and sidebar.
 *
 * This component is not meant to be used directly, rather, it is meant to be
 * used by specialized and reusable layout components such that most of the
 * nuances between the different possible layouts remain centralized.
 *
 * @param props The props object that contains the configuration for the ShellLayout.
 * @param props.toolbar A function that returns the JSX element for the toolbar. It receives a toggleSidebar function as a parameter, which can be used to toggle the sidebar visibility.
 * @param props.sidebar A function that returns the JSX element for the sidebar. It receives the sidebarProps as params to handle sidebar variant, open state and callback on dismiss.
 * @param props.children The main content of the page. Will be placed into view such that it isn't obscured by the shell's UI elements.
 * @constructor
 * @return The rendered ShellLayout component.
 */
function ShellLayout({
  toolbar,
  sidebar,
  children,
}: {
  toolbar: (toggleSidebar: () => void) => ReactElement;
  sidebar: (sidebarProps: SidebarContentProps) => ReactElement | null;
  children: ReactNode;
}) {
  const theme = useTheme();
  const noneView = useMediaQuery(theme.breakpoints.down('sm'));
  const narrowView = useMediaQuery(theme.breakpoints.between('sm', 'xl'));
  const wideView = useMediaQuery(theme.breakpoints.up('xl'));

  const { t } = useTranslation('layout');

  const [sidebarOpened, setSidebarOpened] = useState(wideView);

  const handleDismissSidebar = () => setSidebarOpened(false);

  const previousNoneView = useRefSync(usePreviousValue(noneView));
  const previousNarrowView = useRefSync(usePreviousValue(narrowView));
  const previousWideView = useRefSync(usePreviousValue(wideView));

  useLayoutEffect(() => {
    if (sidebarOpened && !previousNoneView.current && noneView) {
      setSidebarOpened(false);
    } else if (sidebarOpened && !previousNarrowView.current && narrowView) {
      setSidebarOpened(false);
    } else if (!sidebarOpened && !previousWideView.current && wideView) {
      setSidebarOpened(true);
    }
  }, [narrowView, noneView, previousNarrowView, previousNoneView, previousWideView, sidebarOpened, wideView]);

  const [safeZonesSx] = useSidebarAnimation(
    noneView ? 'temporary' : 'permanent',
    sidebarOpened,
    {
      dialog: {
        '--shell-padding-top': '0.5rem',
        '--shell-padding-right': '0px',
        '--shell-padding-left': '0px',
        '--safe-zone-top': `calc(${theme.mixins.toolbar.minHeight} + var(--shell-padding-top))`,
      },
      none: {
        '--shell-padding-top': '0.5rem',
        '--shell-padding-right': '0px',
        '--shell-padding-left': '0px',
        '--safe-zone-top': `calc(${theme.mixins.toolbar.minHeight} + var(--shell-padding-top))`,
      },
      narrow: {
        '--shell-padding-top': '1rem',
        '--shell-padding-right': '1rem',
        '--shell-padding-left': '1rem',
        '--safe-zone-top': `calc(${theme.mixins.toolbar.minHeight} + var(--shell-padding-top))`,
        '--safe-zone-right': 'var(--shell-padding-right)',
        '--safe-zone-left': `calc(${theme.mixins.drawer.width.narrow} + var(--shell-padding-left))`,
      },
      wide: {
        '--shell-padding-top': '1rem',
        '--shell-padding-right': '1rem',
        '--shell-padding-left': '1rem',
        '--safe-zone-top': `calc(${theme.mixins.toolbar.minHeight} + var(--shell-padding-top))`,
        '--safe-zone-right': 'var(--shell-padding-right)',
        '--safe-zone-left': `calc(${theme.mixins.drawer.width.wide} + var(--shell-padding-left))`,
      },
    },
    ['padding'],
  );

  const targetVariant = noneView ? 'temporary' : 'permanent';
  const tool = useMemo(() => toolbar(() => setSidebarOpened((p) => !p)), [toolbar]);
  const side = useMemo(
    () => sidebar({ targetVariant, open: sidebarOpened, onDismiss: handleDismissSidebar }),
    [sidebar, sidebarOpened, targetVariant],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        p: 'var(--safe-zone-top, 0) var(--safe-zone-right, 0) var(--safe-zone-bottom, 0) var(--safe-zone-left, 0)',
        '--shell-padding-bottom': '1rem',
        '--safe-zone-bottom': 'var(--shell-padding-bottom)',
        ...safeZonesSx,
      }}>
      <h1 style={visuallyHidden}>{t('app.title')}</h1>
      {tool}
      {children}
      <SidebarDrawer targetVariant={targetVariant} open={sidebarOpened} onDismiss={handleDismissSidebar}>
        {side}
      </SidebarDrawer>
    </Box>
  );
}

/**
 * The default toolbar configuration for the SimpleLayout.
 * @param onToggleSideBar A callback that triggers when the sidebar should be toggled.
 * @constructor
 */
export function SimpleLayoutToolbar({
  onToggleSidebar: handleToggle,
  $key,
}: {
  onToggleSidebar: () => void;
  $key: LayoutsSimpleLayoutToolbarFragment$key | null | undefined;
}) {
  const $data = useFragment(
    graphql`
      fragment LayoutsSimpleLayoutToolbarFragment on Query {
        ...LayoutsToolbarMainFragment
      }
    `,
    $key,
  );
  return <ToolbarMain onToggleSideBar={handleToggle} $key={$data} />;
}

export function SimpleLayout({
  heading,
  toolbarProvider,
  sidebarProvider,
  children,
  $key,
}: {
  heading: string;
  toolbarProvider?: (heading: string, actions: ReactNode | undefined, handleToggle: () => void) => ReactElement;
  sidebarProvider: (props: SidebarContentProps) => ReactElement;
  children?: ReactNode;
  $key: LayoutsSimpleLayoutFragment$key | null | undefined;
}) {
  const $data = useFragment(
    graphql`
      fragment LayoutsSimpleLayoutFragment on Query {
        ...LayoutsSimpleLayoutToolbarFragment
      }
    `,
    $key,
  );

  const toolbar = useCallback(
    (handleToggle: () => void) =>
      toolbarProvider ? (
        toolbarProvider(heading, undefined, handleToggle)
      ) : (
        <SimpleLayoutToolbar onToggleSidebar={handleToggle} $key={$data} />
      ),
    [toolbarProvider, heading, $data],
  );

  return (
    <ShellLayout toolbar={toolbar} sidebar={sidebarProvider}>
      {children}
    </ShellLayout>
  );
}

/**
 * A generic back button with configurable back depth.
 * @param backDepth How high should the back action climb the back-stack to reach the previous page level.
 * @param withSearch Should search params be included when going back.
 * @param sx The system prop that allows defining system overrides as well as additional CSS styles.
 * @constructor
 */
function BackButton({ backDepth = 1, withSearch, sx }: { backDepth?: number; sx?: SxProps<Theme>; withSearch?: boolean }) {
  const { search } = useLocation();
  const searchParams = withSearch ? search : '';
  const backLink = useMemo(() => new Array(backDepth).fill('..').join('/') + (searchParams ?? ''), [backDepth, searchParams]);

  return (
    <IconButton component={Link} relative='path' to={backLink} sx={sx} data-testid='layouts.backButton'>
      <ArrowBackIcon />
    </IconButton>
  );
}

/**
 * A versatile page header that renders a heading, adornments and actions
 * dynamically for different screen sizes.
 *
 * The heading is the page's main title and will be rendered as a h2.
 * Adornments are decorations to display near the heading, giving additional
 * context to what is displayed on the page, such as the status of the current
 * entry.
 * Actions are components that changes the general state of the page, such as
 * saving or creating a new item.
 * @param heading The page header.
 * @param actions A ReactNode that manages the actions of the page.
 * @param adornments A ReactNode that manages the adornments of the page.
 * @param backDepth How high should the back action climb the back-stack to reach the previous page level.
 * @param tabs The sticky navigation tabs to be shown under the heading
 * @constructor
 */
function PageHeader({
  heading,
  actions,
  adornments,
  backDepth,
  tabs,
}: {
  heading: string;
  actions: ReactNode;
  adornments: ReactNode;
  backDepth?: number;
  tabs?: ReactNode;
}) {
  const gap = '0.5rem';

  const { y } = useScrollPosition();
  const isScrolled = y > 0;
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
          position: 'sticky',
          mt: `calc(0rem - var(--shell-padding-top))`,
          mr: `calc(0rem - var(--shell-padding-right))`,
          ml: `calc(0rem - var(--shell-padding-left))`,
          mb: 0,
          p: '1rem 1rem 0 1rem',
          top: 'calc(var(--safe-zone-top) - var(--shell-padding-top))',
          borderBottom: `1px solid ${theme.palette.background.default}`,
          ...(isScrolled
            ? {
                borderBottom: `1px solid ${theme.palette.divider}`,
                zIndex: 1099,
              }
            : {}),
          transition: theme.transitions.create('border', { duration: theme.transitions.duration.standard }),
          backgroundColor: theme.palette.background.default,
        },
        [theme.breakpoints.down('sm')]: { pr: actions ? '1rem' : 0 },
      })}>
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          gap,
          mb: '0.5rem',
          border: `0 solid ${theme.palette.background.default}`,
        })}>
        <Box
          sx={(theme) => ({
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            gap,
            overflow: 'auto',
            maskImage: 'linear-gradient(270deg, transparent, black 2rem)',
            transition: theme.transitions.create(['padding-left', 'padding-right'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.short,
            }),
            [theme.breakpoints.down('sm')]: { px: '1rem' },
          })}>
          {backDepth !== 0 && <BackButton backDepth={backDepth} sx={{ mr: `-${gap}` }} />}
          <Typography variant='h6' component='h2' sx={{ flexShrink: 0 }} data-label-key={heading}>
            {heading}
          </Typography>
          {adornments}
        </Box>
        {actions}
      </Box>
      {tabs}
    </Box>
  );
}

// Toolbar Variants
////////////////////////////////////////////////////////////////////////////////

/**
 * The main toolbar variant of the application. This toolbar is meant to be used
 * for pages at the root of the navigation tree, as it includes a way to toggle
 * the sidebar, as well as a main logo, and a user menu.
 * @param onToggleSideBar A callback that triggers when the sidebar should be toggled.
 * @constructor
 */
export function ToolbarMain({
  onToggleSideBar: handleToggleSideBar,
  $key,
}: {
  onToggleSideBar: () => void;
  $key: LayoutsToolbarMainFragment$key | null | undefined;
}) {
  const notificationEnabled = useFlag('app_allow_notifications');

  const $data = useFragment(
    graphql`
      fragment LayoutsToolbarMainFragment on Query {
        ...NotificationMenuFragment
      }
    `,
    $key,
  );

  return (
    <AppBar aria-label='toolbar.main'>
      <Toolbar sx={{ flexGrow: 1, alignItems: 'center', pr: '1rem' }} disableGutters>
        <IconButton aria-label='sidebarDrawer.button' onClick={handleToggleSideBar} sx={{ margin: '0 0.5rem', color: 'inherit' }}>
          <MenuIcon />
        </IconButton>
        <GuayLogo sx={{ flexGrow: 1 }} />
        {notificationEnabled && (
          <Suspense fallback={<NotificationButton onClick={() => {}} unreadBadgeContent='' />}>
            {$data && <NotificationMenu $key={$data} />}
          </Suspense>
        )}
        <UserMenu sx={{ pr: 0 }} />
      </Toolbar>
    </AppBar>
  );
}

/**
 * A toolbar variant meant to be used for nested pages in the navigation tree,
 * usually for small screens. It includes a configurable back button, a page
 * title, and a space for custom actions.
 * @param heading The page header.
 * @param actions A ReactNode that manages the actions of the page.
 * @param backDepth How high should the back action climb the back-stack to reach the previous page level.
 * @constructor
 */
export function ToolbarSub({
  heading,
  actions,
  backDepth,
}: {
  heading: string;
  actions?: ReactNode;
  withBackButton?: boolean;
  backDepth?: number;
}) {
  const gap = '0.5rem';
  const { pathname } = useLocation();
  // keep search when going back between screens on mobile except when going back to a list page
  const withSearch = pathname.split('/').length > 3;

  return (
    <AppBar>
      <Toolbar
        sx={{
          display: 'flex',
          gap,
          pl: '0.25rem',
        }}
        disableGutters>
        <BackButton backDepth={backDepth} withSearch={withSearch} sx={{ color: 'inherit', mr: `-${gap}` }} />
        <EllipsisedTypography variant='h6' component='h2' sx={{ flexGrow: 1, flexShrink: 1 }}>
          {heading}
        </EllipsisedTypography>
        {actions}
      </Toolbar>
    </AppBar>
  );
}

/**
 * The default toolbar configuration for the ListLayout.
 * @param onToggleSideBar A callback that triggers when the sidebar should be toggled.
 * @constructor
 */
export function ListLayoutToolbar({
  onToggleSidebar: handleToggle,
  $key,
}: {
  onToggleSidebar: () => void;
  $key: LayoutsListLayoutToolbarFragment$key | null | undefined;
}) {
  const $data = useFragment(
    graphql`
      fragment LayoutsListLayoutToolbarFragment on Query {
        ...LayoutsToolbarMainFragment
      }
    `,
    $key,
  );
  return <ToolbarMain onToggleSideBar={handleToggle} $key={$data} />;
}

/**
 * The default toolbar configuration for the DetailsLayout
 * @param heading The page header.
 * @param actions A ReactNode that manages the actions of the page.
 * @param onToggleSideBar A callback that triggers when the sidebar should be toggled.
 * @param backDepth How high should the back action climb the back-stack to reach the previous page level.
 * @param compactTheme Replaces the compact toolbar theme.
 * @constructor
 */
export function DetailsLayoutToolbar({
  heading,
  actions,
  onToggleSideBar: handleToggle,
  compactTheme,
  backDepth,
  $key,
}: {
  heading: string;
  actions: ReactNode | undefined;
  onToggleSideBar: () => void;
  compactTheme?: ThemeProviderProps['theme'];
  backDepth?: number;
  $key: LayoutsDetailsLayoutToolbarFragment$key | null | undefined;
}) {
  const theme = useTheme();
  const compact = useMediaQuery<Theme>(theme.breakpoints.down('sm'));

  const $data = useFragment(
    graphql`
      fragment LayoutsDetailsLayoutToolbarFragment on Query {
        ...LayoutsToolbarMainFragment
      }
    `,
    $key,
  );

  return compact ? (
    <ThemeProvider theme={compactTheme ?? theme}>
      <ToolbarSub heading={heading} actions={actions} backDepth={backDepth} />
    </ThemeProvider>
  ) : (
    <ToolbarMain onToggleSideBar={handleToggle} $key={$data} />
  );
}

// Layout Variants
////////////////////////////////////////////////////////////////////////////////

/**
 * A specialized version of the ShellLayout designed for pages displaying lists of items.
 * @param heading The page header.
 * @param flagName The feature flag name
 * @param flagFallback The fallback if feature flag is off, defaults to MaintenanceWithMenus
 * @param actions A ReactNode that manages the actions of the page, typically a ListLayoutAction.
 * @param adornments A ReactNode that manages the adornments of the page.
 * @param toolbarProvider A factory to provide a custom toolbar component.
 * @param sidebarProvider A factory to provide a custom sidebar component.
 * @param tabs The tabs to be shown under the heading
 * @param children The content of the page.
 * @param $key The LayoutsListLayoutFragment key.
 * @constructor
 */
export function ListLayout({
  heading,
  flagName,
  flagFallback,
  actions,
  adornments,
  toolbarProvider,
  sidebarProvider,
  children,
  $key,
}: {
  heading: string;
  flagName: string;
  flagFallback?: ReactNode;
  actions?: ReactNode;
  adornments?: ReactNode;
  toolbarProvider?: (heading: string, actions: ReactNode | undefined, handleToggle: () => void) => ReactElement;
  sidebarProvider: (props: SidebarContentProps) => ReactElement;
  children?: ReactNode;
  $key: LayoutsListLayoutFragment$key | null | undefined;
}) {
  const floatingActions = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const $data = useFragment(
    graphql`
      fragment LayoutsListLayoutFragment on Query {
        ...LayoutsListLayoutToolbarFragment
      }
    `,
    $key,
  );

  const toolbar = useCallback(
    (handleToggle: () => void) =>
      toolbarProvider ? toolbarProvider(heading, actions, handleToggle) : <ListLayoutToolbar onToggleSidebar={handleToggle} $key={$data} />,
    [toolbarProvider, heading, actions, $data],
  );

  const flagEnabled = useFlag(flagName);
  if (!flagEnabled) return flagFallback ?? <MaintenanceWithMenus />;

  return (
    <ShellLayout toolbar={toolbar} sidebar={sidebarProvider}>
      <PageHeader heading={heading} actions={!floatingActions && actions} adornments={adornments} backDepth={0} />
      {floatingActions && actions}
      {children}
    </ShellLayout>
  );
}

/**
 * Describes the core properties of an action for use in the ListLayoutActions component.
 */
export type ListLayoutAction = {
  /**
   * The label of the action to render on the page.
   */
  label: string;
  /**
   * The icon of the action to render on the page.
   */
  icon?: ReactElement;
  /**
   * A callback that executes when the action button is clicked.
   */
  onClick: () => void;
};

/**
 * A companion component that simplifies the use of actions on ListLayouts by
 * automatically switching between fixed and floating action styles depending
 * on the screen resolution.
 * @param mainAction  A main action that remains visible at all time.
 * @param otherActions A set of secondary actions that can be hidden in sub menus.
 * @param componentProps Property overrides for internal components
 * @constructor
 */
export function ListLayoutActions({
  mainAction,
  otherActions,
  componentProps,
}: {
  mainAction: ListLayoutAction;
  otherActions?: Record<string, ListLayoutAction>;
  componentProps?: {
    autoSpeedDial?: AutoSpeedDialProps<[string, ListLayoutAction]>['componentProps'];
    autoSplitButton?: AutoSplitButtonProps<[string, ListLayoutAction]>['componentProps'];
  };
}) {
  const floating = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  return floating ? (
    <AutoSpeedDial<[string, ListLayoutAction]>
      mainOption={['main', mainAction]}
      options={(otherActions && Object.entries(otherActions)) ?? undefined}
      keySelector={([k]) => k}
      iconSelector={([, a]) => a.icon}
      labelSelector={([, a]) => a.label}
      onOptionClick={([, a]) => a.onClick()}
      componentProps={{
        root: {
          sx: { position: 'fixed', bottom: '1rem', right: '1rem' },
          ...componentProps?.autoSpeedDial?.root,
        },
        fab: {
          sx: { position: 'fixed', bottom: '1rem', right: '1rem' },
          ...componentProps?.autoSpeedDial?.fab,
        },
        speedDialAction: {
          ...componentProps?.autoSpeedDial?.speedDialAction,
          sx: (
            [
              (theme) => ({
                '& .MuiButtonBase-root': {
                  backgroundColor: theme.palette.primary.main,
                  '& > svg': {
                    color: theme.palette.common.white,
                  },
                },
                '& > span': {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.common.white,
                  whiteSpace: 'nowrap',
                },
              }),
              componentProps?.autoSpeedDial?.speedDialAction?.sx ?? null,
            ] satisfies SxProps<Theme>[]
          ).flat(),
        },
        speedDialActionOption: componentProps?.autoSpeedDial?.speedDialActionOption,
        dialog: componentProps?.autoSpeedDial?.dialog,
        listItemButtonOption: ([k]) => ({
          ...componentProps?.autoSpeedDial?.listItemButtonOption,
          'data-label-key': `listItemButtonOption.${k}`,
        }),
      }}
    />
  ) : (
    <AutoSplitButton<[string, ListLayoutAction]>
      mainOption={['main', mainAction]}
      options={(otherActions && Object.entries(otherActions)) ?? undefined}
      keySelector={([k]) => k}
      labelSelector={([, a]) => a.label}
      onOptionClick={([, a]) => a.onClick()}
      componentProps={{
        ...componentProps?.autoSplitButton,
        menuPaper: {
          ...componentProps?.autoSplitButton?.menuPaper,
          sx: (
            [
              (theme) => ({
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.getContrastText(theme.palette.primary.main),
              }),
              componentProps?.autoSplitButton?.menuPaper?.sx ?? null,
            ] satisfies SxProps<Theme>[]
          ).flat(),
        },
        buttonMain: {
          ...componentProps?.autoSplitButton?.buttonMain,
          'data-label-key': 'listMainAction',
        },
        buttonMenu: {
          ...componentProps?.autoSplitButton?.buttonMenu,
          'data-label-key': 'listButtonMenu',
        },
        menuItemOption: ([k]) => ({
          ...componentProps?.autoSplitButton?.menuItemOption,
          'data-label-key': `listMenuItemOption.${k}`,
        }),
      }}
    />
  );
}

/**
 * A specialized version of the ShellLayout design for pages editing the details of a single item.
 * @param heading The page header.
 * @param flagName The feature flag name
 * @param flagFallback The fallback if feature flag is off, defaults to MaintenanceWithMenus
 * @param actions A ReactNode that manages the actions of the page.
 * @param adornments A ReactNode that manages the adornments of the page.
 * @param toolbarProvider A factory to provide a custom toolbar component.
 * @param sidebarProvider A factory to provide a custom sidebar component.
 * @param compactToolbarTheme  Replaces the compact toolbar theme.
 * @param children The content of the page.
 * @param backDepth The number of parent pages to visit
 * @constructor
 */
export function DetailsLayout({
  heading,
  flagName,
  flagFallback,
  actions,
  adornments,
  sidebarProvider,
  compactToolbarTheme,
  children,
  backDepth,
  tabs,
  $key,
}: {
  heading: string;
  flagName: string;
  flagFallback?: ReactNode;
  actions?: ReactNode;
  adornments?: ReactNode;
  sidebarProvider: (props: SidebarContentProps) => ReactElement;
  compactToolbarTheme?: ThemeProviderProps['theme'];
  children?: ReactNode;
  backDepth?: number;
  tabs?: ReactNode;
  $key: LayoutsDetailsLayoutFragment$key | null | undefined;
}) {
  const compact = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const $data = useFragment(
    graphql`
      fragment LayoutsDetailsLayoutFragment on Query {
        ...LayoutsDetailsLayoutToolbarFragment
      }
    `,
    $key,
  );

  const toolbar = useCallback(
    (handleToggle: () => void) => (
      <DetailsLayoutToolbar
        heading={heading}
        actions={actions}
        onToggleSideBar={handleToggle}
        compactTheme={compactToolbarTheme}
        backDepth={backDepth}
        $key={$data}
      />
    ),
    [heading, actions, compactToolbarTheme, backDepth, $data],
  );

  const flagEnabled = useFlag(flagName);
  if (!flagEnabled) return flagFallback ?? <MaintenanceWithMenus />;

  return (
    <ShellLayout toolbar={toolbar} sidebar={sidebarProvider}>
      {compact ? (
        adornments && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
              overflow: 'auto',
              px: '1rem',
            }}>
            {adornments}
          </Box>
        )
      ) : (
        <PageHeader heading={heading} actions={actions} adornments={adornments} backDepth={backDepth} tabs={tabs} />
      )}
      {children}
    </ShellLayout>
  );
}

const emptySidebar = () => null;

/**
 * A minimal layout that displays an empty sidebar
 * @param children
 * @constructor
 */
export function EmptyLayout({ children }: { children?: ReactNode }) {
  // 24px for icon width, mx 0.5rem, px 0.5rem
  const sidebarMenuWidth = 'calc(24px + 2rem)';
  return (
    <ShellLayout
      toolbar={() => (
        <AppBar aria-label='toolbar.main'>
          <Toolbar sx={{ flexGrow: 1, alignItems: 'center', pr: '1rem', pl: sidebarMenuWidth }} disableGutters>
            <GuayLogo sx={{ flexGrow: 1 }} />
            <UserMenu />
          </Toolbar>
        </AppBar>
      )}
      sidebar={emptySidebar}>
      {children}
    </ShellLayout>
  );
}

/**
 * Layout used in compact mode to display search layout instead of a full screen dialog
 * @param heading The page header.
 * @param flagName The feature flag name
 * @param flagFallback The fallback if feature flag is off, defaults to MaintenanceWithMenus
 * @param actions A ReactNode that manages the actions of the page.
 * @param compactToolbarTheme  Replaces the compact toolbar theme.
 * @param children The content of the page.
 * @param backDepth The number of parent pages to visit
 * @param detailsLayoutToolbar$key The LayoutsDetailsLayoutToolbar fragment key
 * @constructor
 */
export function SearchLayout({
  heading,
  flagName,
  flagFallback,
  actions,
  compactToolbarTheme,
  children,
  backDepth,
  $key,
}: {
  heading: string;
  flagName: string;
  flagFallback?: ReactNode;
  actions?: ReactNode;
  compactToolbarTheme?: ThemeProviderProps['theme'];
  children?: ReactNode;
  backDepth?: number;
  $key: LayoutsSearchLayoutFragment$key;
}) {
  const compact = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const $data = useFragment(
    graphql`
      fragment LayoutsSearchLayoutFragment on Query {
        ...LayoutsDetailsLayoutToolbarFragment
      }
    `,
    $key,
  );

  const toolbar = useCallback(
    (handleToggle: () => void) => (
      <DetailsLayoutToolbar
        heading={heading}
        actions={actions}
        onToggleSideBar={handleToggle}
        compactTheme={compactToolbarTheme}
        backDepth={backDepth}
        $key={$data}
      />
    ),
    [heading, actions, compactToolbarTheme, backDepth, $data],
  );

  const flagEnabled = useFlag(flagName);
  if (!flagEnabled) return flagFallback ?? <MaintenanceWithMenus />;

  // fixme right now, this case is never used. Need to embed the SearchDialog here and refactor current usages
  if (!compact) return children;

  return (
    <ShellLayout toolbar={toolbar} sidebar={() => <></>}>
      {children}
    </ShellLayout>
  );
}
