import { Dimension } from '.';
import { defaultLogger, Logger } from '../logging';
import { Namespace, TFunction } from 'i18next';

export const massUnits = ['lbs', 'kg'] as const;
export type MassUnit = (typeof massUnits)[number];

export function isMassUnit(val: unknown): val is MassUnit {
  return massUnits.includes(val as MassUnit);
}

export class Mass extends Dimension<MassUnit> {
  protected static readonly logger = new Logger(defaultLogger, 'Mass', new Date().toISOString());

  static parse(serializedMass: string | null | undefined): Mass | null {
    return this.baseParse(serializedMass, isMassUnit, ({ scalar, unit }) => new Mass(scalar, unit));
  }

  constructor(scalar: number, unit: MassUnit) {
    super({ scalar, unit }, [scalar, unit]);
  }

  format(t: TFunction<Namespace>): string {
    return this.baseFormat(t, 'mass').formattedString;
  }
}
