import { ChangeEvent, FocusEvent, ForwardedRef, forwardRef, ReactElement, ReactNode, RefAttributes, useMemo } from 'react';
import { InputAdornment, MenuItem, SelectChangeEvent } from '@mui/material';
import { AdornmentSelect } from './AdornmentSelect';
import { NumberInput } from './NumberInput';

interface Props<T> {
  scalar: number | undefined;
  onScalarChange: (value: number | null, event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  unit: T;
  supportedUnits: readonly T[];
  onUnitChange: (event: SelectChangeEvent) => void;
  onBlur?: (event: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  renderMenuValue: (val: T) => string;
  renderAdornmentValue: (val: T) => string;
  className?: string;
  label?: ReactNode;
  'data-label-key'?: string;
}

export const UnitInput = forwardRef<HTMLTextAreaElement | HTMLInputElement, Props<string>>(function UnitInput<T extends string>(
  {
    scalar,
    onScalarChange: handleScalarChange,
    unit,
    supportedUnits,
    onUnitChange: handleUnitChange,
    onBlur: handleBlur,
    required,
    disabled,
    error,
    renderMenuValue,
    renderAdornmentValue,
    className,
    label,
    'data-label-key': dataLabelKey,
  }: Props<T>,
  ref: ForwardedRef<HTMLTextAreaElement | HTMLInputElement>,
) {
  const UnitMenu = useMemo<ReactNode>(
    () =>
      supportedUnits.map((u) => (
        <MenuItem key={u} value={u}>
          {renderMenuValue(u)}
        </MenuItem>
      )),
    [renderMenuValue, supportedUnits],
  );

  return (
    <NumberInput
      inputRef={ref}
      value={scalar ?? null}
      onChange={handleScalarChange}
      onBlur={handleBlur}
      required={required}
      disabled={disabled}
      error={error}
      className={className}
      label={label}
      data-label-key={dataLabelKey}
      data-testid='unitTextBox'
      sx={{
        '.MuiOutlinedInput-root': {
          paddingRight: '0.25rem',
        },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <AdornmentSelect<T>
              data-testid='unitSelect'
              value={unit}
              defaultValue={supportedUnits[0]}
              onChange={handleUnitChange}
              variant='standard'
              disabled={disabled}
              renderValue={renderAdornmentValue}>
              {UnitMenu}
            </AdornmentSelect>
          </InputAdornment>
        ),
      }}
    />
  );
}) as <T extends string>(props: Props<T> & RefAttributes<HTMLTextAreaElement | HTMLInputElement>) => ReactElement;
