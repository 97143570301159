import { Tab, Tabs } from '@mui/material';
import { SyntheticEvent } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

interface Props {
  tab: 'data-sync';
}

export function MaintenanceTabs({ tab }: Props) {
  const { t } = useTranslation('maintenance');
  const navigate = useNavigate();

  const handleChange = (_: SyntheticEvent, newValue: string) => {
    navigate(`../${newValue}`);
  };

  return (
    <Tabs value={tab} onChange={handleChange} sx={{ mb: '1rem' }}>
      <Tab value='data-sync' label={t('section.dataSync')} />
    </Tabs>
  );
}
