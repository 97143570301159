import { Box, Skeleton, Stack, useMediaQuery, useTheme } from '@mui/material';

export function FormSectionSkeleton() {
  const theme = useTheme();
  const compact = useMediaQuery(theme.breakpoints.down('sm'));

  if (compact) {
    return (
      <Box sx={{ mt: '0.5rem' }}>
        <Skeleton variant='rectangular' height='5.125rem' />
      </Box>
    );
  }

  return (
    <Stack sx={{ gap: '0.5rem' }}>
      <Skeleton width='3rem' />
      <Skeleton variant='rounded' height='10rem' />
    </Stack>
  );
}
