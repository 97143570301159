/**
 * @generated SignedSource<<ad611e2d710b4694e8c499e281a0c193>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TabNaturesOfWorkQuery$variables = Record<PropertyKey, never>;
export type TabNaturesOfWorkQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"NaturesOfWorkListFragment">;
};
export type TabNaturesOfWorkQuery = {
  response: TabNaturesOfWorkQuery$data;
  variables: TabNaturesOfWorkQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TabNaturesOfWorkQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "NaturesOfWorkListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TabNaturesOfWorkQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "order",
            "value": {
              "code": "ASC"
            }
          }
        ],
        "concreteType": "NatureOfWorkLookup",
        "kind": "LinkedField",
        "name": "natureOfWorks",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          }
        ],
        "storageKey": "natureOfWorks(order:{\"code\":\"ASC\"})"
      }
    ]
  },
  "params": {
    "cacheID": "3164674db9c6e2d521819b1d06400cea",
    "id": null,
    "metadata": {},
    "name": "TabNaturesOfWorkQuery",
    "operationKind": "query",
    "text": "query TabNaturesOfWorkQuery {\n  ...NaturesOfWorkListFragment\n}\n\nfragment NaturesOfWorkListFragment on Query {\n  natureOfWorks(order: {code: ASC}) {\n    id\n    ...NaturesOfWorkListItemFragment\n  }\n}\n\nfragment NaturesOfWorkListItemFragment on NatureOfWorkLookup {\n  id\n  code\n  description\n}\n"
  }
};

(node as any).hash = "d42c3192a22f2a370650a442f46d52cc";

export default node;
