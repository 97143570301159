/**
 * @generated SignedSource<<08151a69f4933db407d6d709ada8de74>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SubRegionAutocompleteListFragmentQuery$variables = {
  count?: number | null | undefined;
  cursor?: string | null | undefined;
  searchTerm?: string | null | undefined;
};
export type SubRegionAutocompleteListFragmentQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SubRegionAutocompleteListFragment">;
};
export type SubRegionAutocompleteListFragmentQuery = {
  response: SubRegionAutocompleteListFragmentQuery$data;
  variables: SubRegionAutocompleteListFragmentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": 25,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchTerm"
  }
],
v1 = {
  "kind": "Variable",
  "name": "searchTerm",
  "variableName": "searchTerm"
},
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SubRegionAutocompleteListFragmentQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          },
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "SubRegionAutocompleteListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SubRegionAutocompleteListFragmentQuery",
    "selections": [
      {
        "alias": "searchResults",
        "args": (v2/*: any*/),
        "concreteType": "SearchSubRegionsConnection",
        "kind": "LinkedField",
        "name": "searchSubRegions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SearchSubRegionsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SubRegion",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "name": "label",
                    "args": null,
                    "fragment": {
                      "kind": "InlineFragment",
                      "selections": [
                        (v4/*: any*/)
                      ],
                      "type": "SubRegion",
                      "abstractKey": null
                    },
                    "kind": "RelayResolver",
                    "storageKey": null,
                    "isOutputType": true
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Region",
                    "kind": "LinkedField",
                    "name": "region",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "searchResults",
        "args": (v2/*: any*/),
        "filters": [
          "searchTerm"
        ],
        "handle": "connection",
        "key": "SubRegionAutocompleteListFragment_searchResults",
        "kind": "LinkedHandle",
        "name": "searchSubRegions"
      }
    ]
  },
  "params": {
    "cacheID": "b7a4dafa55b81e6e235918039802c493",
    "id": null,
    "metadata": {},
    "name": "SubRegionAutocompleteListFragmentQuery",
    "operationKind": "query",
    "text": "query SubRegionAutocompleteListFragmentQuery(\n  $count: Int = 25\n  $cursor: String\n  $searchTerm: String\n) {\n  ...SubRegionAutocompleteListFragment_1YZSDV\n}\n\nfragment SubRegionAutocompleteListFragment_1YZSDV on Query {\n  searchResults: searchSubRegions(searchTerm: $searchTerm, after: $cursor, first: $count) {\n    edges {\n      node {\n        id\n        ...SubRegionLabelResolver\n        region {\n          description\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment SubRegionLabelResolver on SubRegion {\n  description\n}\n"
  }
};
})();

(node as any).hash = "1a900f5ebba619390e0f688439ecd087";

export default node;
