import { useMatch, useNavigate } from 'react-router';
import { namedPageTitle, usePageTitle } from '../common/hooks/usePageTitle';
import graphql from 'babel-plugin-relay/macro';
import { DataID, useLazyLoadQuery } from 'react-relay';
import { Skeleton, Stack, Theme, useMediaQuery } from '@mui/material';
import { Suspense, useLayoutEffect } from 'react';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { EmptyLayout, ListLayout, ListLayoutActions, SidebarContentProps } from '../layout/Layouts';
import { Add } from '@mui/icons-material';
import { RequireRead, RequireWrite, UnauthorizedFallback } from '../auth/Authorization';
import { NavigationMenu } from '../layout/SidebarDrawer';
import { EmployeeNotFoundError, EmployeeNotFoundErrorBoundary } from '../auth/EmployeeNotFoundErrorBoundary';
import { ListPageErrorBoundary } from '../layout/ListPageErrorBoundary';
import { ListPageRootErrorBoundary } from '../layout/ListPageRootErrorBoundary';
import { useJobFiltersSearchParams } from '../jobs/JobFilters';
import { createSharedStateKey } from '../common/utils/sharedState';
import { WorkPlanningFilters_Regular } from './WorkPlanningFilters';
import { WorkPlanningList_Regular } from './WorkPlanningList.Regular';
import { WorkPlanningList_Page_RootQuery } from './__generated__/WorkPlanningList_Page_RootQuery.graphql';
import { useShowRepresentativeList } from '../jobs/useShowRepresentativeList';
import { WorkPlanningList_Representative } from './WorkPlanningList.Representative';

const flagName = 'app_navigation_work_plannings';

export function WorkPlanningList_Page() {
  const { t } = useAmbientTranslation();

  return (
    <ListPageErrorBoundary heading={t('pages.list.title')} flagName={flagName}>
      <EmployeeNotFoundErrorBoundary>
        <Suspense fallback={<ListPageSkeleton />}>
          <ListPageRoot />
        </Suspense>
      </EmployeeNotFoundErrorBoundary>
    </ListPageErrorBoundary>
  );
}

function ListPageSkeleton() {
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return <EmptyLayout>{compact ? <ListSkeleton /> : <GridSkeleton />}</EmptyLayout>;
}

function ListPageRoot() {
  const $data = useLazyLoadQuery<WorkPlanningList_Page_RootQuery>(
    graphql`
      query WorkPlanningList_Page_RootQuery {
        ...AuthorizationReadFragment
        ...AuthorizationWriteFragment
        ...SidebarDrawerFragment
        ...LayoutsListLayoutFragment
        ...ListPageRootErrorBoundaryFragment
        me {
          ...useShowRepresentativeListFragment
          ...WorkPlanningList_RepresentativeFragment
          ...WorkPlanningList_RegularFragment
        }
      }
    `,
    {},
  );

  if (!$data.me) throw new EmployeeNotFoundError();

  usePageTitle(namedPageTitle('sidebar.workPlannings'));
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { t } = useAmbientTranslation();
  const navigate = useNavigate();
  const sidebar = (props: SidebarContentProps) => <NavigationMenu {...props} $key={$data} />;
  const handleItemClick = (id: DataID) => navigate(`/work-plannings/${id}`);
  const heading = t('pages.list.title');
  const showRepresentativeList = useShowRepresentativeList($data.me);
  const searchParams = useJobFiltersSearchParams(workPlanningFiltersSharedStateKey);
  const routeMatch = useMatch('*');

  useLayoutEffect(() => {
    if (!routeMatch?.pathname) return;

    const basePath = '/work-plannings';

    if (showRepresentativeList && routeMatch.pathname === basePath) {
      navigate(`${basePath}/my-jobs${searchParams}`);
      return;
    }
    if (
      !showRepresentativeList &&
      (routeMatch.pathname === `${basePath}/my-jobs` ||
        routeMatch.pathname === `${basePath}/my-regions` ||
        routeMatch.pathname === `${basePath}/my-clients`)
    ) {
      navigate(basePath);
    }
  }, [navigate, routeMatch?.pathname, searchParams, showRepresentativeList]);

  return (
    <ListPageRootErrorBoundary $key={$data} heading={heading} flagName={flagName}>
      <RequireRead
        $key={$data}
        fallback={
          <ListLayout heading={heading} flagName={flagName} sidebarProvider={sidebar} $key={$data}>
            <UnauthorizedFallback />
          </ListLayout>
        }>
        <ListLayout
          heading={heading}
          flagName={flagName}
          sidebarProvider={sidebar}
          $key={$data}
          actions={
            <RequireWrite $key={$data}>
              <ListLayoutActions
                mainAction={{
                  label: t('button.create', { ns: 'common' }),
                  icon: <Add />,
                  onClick: () => navigate('/work-plannings/new'),
                }}
                componentProps={{
                  autoSplitButton: {
                    root: { 'aria-label': t('createWorkPlanningAction.ariaLabels.root') },
                    buttonMain: { 'aria-label': t('createWorkPlanningAction.ariaLabels.main') },
                  },
                  autoSpeedDial: {
                    fab: {
                      'aria-label': 'createWorkPlanningAction.ariaLabels.main',
                    },
                  },
                }}
              />
            </RequireWrite>
          }>
          <Suspense fallback={compact ? <ListSkeleton /> : <GridSkeleton />}>
            {showRepresentativeList ? (
              <WorkPlanningList_Representative
                $key={$data.me}
                compact={compact}
                onItemClick={handleItemClick}
                paginationSx={(theme) => ({ [theme.breakpoints.down('sm')]: { mb: '6rem' } })}
              />
            ) : (
              <WorkPlanningList_Regular
                $key={$data.me}
                compact={compact}
                onItemClick={handleItemClick}
                paginationSx={(theme) => ({ [theme.breakpoints.down('sm')]: { mb: '6rem' } })}
              />
            )}
          </Suspense>
        </ListLayout>
      </RequireRead>
    </ListPageRootErrorBoundary>
  );
}

export const workPlanningFiltersSharedStateKey = createSharedStateKey<WorkPlanningFilters_Regular | null>(() => null);

export function useWorkPlanningListPageUrl() {
  const searchParams = useJobFiltersSearchParams(workPlanningFiltersSharedStateKey);
  return `/work-plannings${searchParams}`;
}

function GridSkeleton() {
  return <Skeleton variant='rounded' height='40rem' />;
}

function ListSkeleton() {
  return (
    <Stack gap={0.5}>
      <Skeleton variant='rounded' height='5.3rem' />
      <Skeleton variant='rounded' height='5.3rem' />
      <Skeleton variant='rounded' height='5.3rem' />
      <Skeleton variant='rounded' height='5.3rem' />
      <Skeleton variant='rounded' height='5.3rem' />
      <Skeleton variant='rounded' height='5.3rem' />
      <Skeleton variant='rounded' height='5.3rem' />
      <Skeleton variant='rounded' height='5.3rem' />
      <Skeleton variant='rounded' height='5.3rem' />
      <Skeleton variant='rounded' height='5.3rem' />
    </Stack>
  );
}
